import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CROSS2 } from 'src/app/core/assets.location';

@Component({
  selector: 'app-solution-modal',
  templateUrl: './solution-modal.component.html',
  styleUrls: ['./solution-modal.component.scss'],
})
export class SolutionModal implements OnInit {
  cross = CROSS2;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  dismiss() {
    this.dialogRef.close();
  }

  ngOnDestroy() {}
}
