<article class="root" *ngIf="coming_source !== 'afterPayment'">
  <section class="top_container">
    <div
      class="reward_container"
      [ngClass]="{
        disabled:
          enterCoupon ||
          couponApplied ||
          !isWalletEnabled ||
          maxWalletPoint === 0
      }"
      *ngIf="maxWalletPoint"
    >
      <div class="flex gap-2">
        <img
          [src]="
            enterCoupon ||
            couponApplied ||
            !isWalletEnabled ||
            maxWalletPoint === 0
              ? disabledWallet
              : walletImg
          "
          alt=""
        />
        <div class="flex flex-col">
          <span class="body-2 medium text-gray-500">
            Redeem {{ maxWalletPoint }} wallet points
          </span>
          <span class="caption-1 bold"
            >to save
            <span class="extra_reward">₹ {{ maxWalletPoint }}</span> more</span
          >
        </div>
      </div>
      <button
        class="body-2 bold"
        [disabled]="
          enterCoupon ||
          couponApplied ||
          !isWalletEnabled ||
          maxWalletPoint === 0
        "
        id="apply_btn"
        (click)="applyWattetPoints()"
        [ngClass]="{ 'wallets-applied': walletsApplied }"
      >
        {{ walletsApplied ? 'REMOVE' : 'APPLY' }}
      </button>
    </div>
    <app-coupon [walletsApplied]="walletsApplied" source="fbt"></app-coupon>
    <app-donate-card
      *ngIf="donationDetail.isDonationEnabled"
      (ischeckedEvent)="isDonationChecked($event)"
      [isDefaultSelected]="donationDetail.isDonationDefaultSelected"
      [donationAmount]="donationDetail.donationAmount"
    ></app-donate-card>
  </section>

  <section
    class="bottom_container"
    id="{{ batchDetail?._id + '_' + 'payment_summary_card' }}"
  >
    <div class="summary_container">
      <span class="heading-4 bold black-color">Payment Summary</span>
      <div class="flex flex-col gap-2">
        <div class="flex flex-col gap-1 border-b border-gray-300 pb-2">
          <div
            class="flex items-center justify-between"
            *ngIf="paymentDetail.price"
          >
            <span class="body-2 medium black-color" id="price"
              >Price ({{ cartItems.length }} items)</span
            >
            <span class="body-2 medium black-color" id="price_value"
              >₹ {{ paymentDetail.price | getFloorValue }}</span
            >
          </div>
          <div class="flex items-center justify-between">
            <span class="body-2 medium black-color" id="discount"
              >Discount</span
            >
            <span
              class="body-2 medium discount black-color"
              id="discounted_value"
              >₹ {{ paymentDetail.discount | getCeilValue }}</span
            >
          </div>
          <div class="flex items-center justify-between black-color">
            <span class="body-2 medium" id="delivery">Delivery Charges</span>
            <span class="body-2 medium" id="charges_value"
              >₹ {{ paymentDetail.deliveryCharge | getFloorValue }}</span
            >
          </div>
          <div class="flex items-center justify-between black-color">
            <span class="body-2 medium">Coupon Disc.</span>
            <span class="body-2 medium discount" id="coupon_discount"
              >₹ {{ couponDisc | getFloorValue }}</span
            >
          </div>
          <div
            class="flex items-center justify-between black-color"
            *ngIf="
              donationDetail.isDonationEnabled &&
              donationDetail.isDonationDefaultSelected
            "
          >
            <span class="body-2 medium" id="pw_foundation_donation"
              >PW Foundation Donation</span
            >
            <span class="body-2 medium" id="charges_value"
              >₹ {{ donationDetail.donationAmount | getFloorValue }}</span
            >
          </div>
        </div>
        <div class="flex items-center justify-between black-color">
          <span class="body-1 bold" id="total_amount">Total Amount</span>
          <div class="flex flex-col text-right">
            <span class="body-1 bold" id="total_amount_value"
              >₹ {{ totalAmount | getFloorValue }}</span
            >
            <ng-container *ngIf="selectedMode">
              <span class="caption-2 bold-1 mode">{{
                selectedMode | paymentMode
              }}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="selectedMode">
      <section class="mode_card flex flex-col gap-2">
        <span class="caption-1 medium">Payment Mode</span>
        <div class="flex items-center justify-between">
          <div
            class="flex gap-2 my-auto cursor-pointer"
            (click)="toggleRadioButton(modeSelected.EMI)"
          >
            <div
              class="radio my-auto"
              [ngClass]="{ checked: selectedMode === modeSelected.EMI }"
            >
              <div
                class="radio-1"
                [ngClass]="{ checked: selectedMode === modeSelected.EMI }"
              ></div>
            </div>

            <label
              class="semibold caption-1 cursor-pointer"
              for="Partial payment (EMI)"
              >Partial payment (EMI)</label
            >
            <img
              [src]="infoImg"
              alt="info"
              (click)="openEMIAndFeeStructureOverLay($event)"
              class="cursor-pointer"
            />
          </div>
          <div
            class="flex gap-2 cursor-pointer"
            (click)="toggleRadioButton(modeSelected.FULL)"
          >
            <div
              class="radio my-auto"
              [ngClass]="{ checked: selectedMode === modeSelected.FULL }"
            >
              <div
                class="radio-1"
                [ngClass]="{ checked: selectedMode === modeSelected.FULL }"
              ></div>
            </div>
            <label class="semibold caption-1 cursor-pointer" for="Full payment"
              >Full Payment</label
            >
          </div>
        </div>
      </section>
    </ng-container>

    <button
      class="bold heading-5 w-full flex items-center justify-center gap-2"
      (click)="onButtonClick($event)"
      id="{{ batchDetail?._id + '_' + 'proceed_buy' }}"
    >
      {{
        totalAmount !== 0
          ? selectedMode === modeSelected.EMI
            ? 'BOOK A SEAT'
            : 'PROCEED TO BUY'
          : 'ENROLL NOW'
      }}
      <div class="loader" *ngIf="processing"></div>
    </button>
  </section>
</article>

<div *ngIf="coming_source === 'afterPayment'">
  <div class="grid grid-cols-12 bg-white p-3 rounded-lg box_shadow">
    <div class="flex items-center col-span-4">
      <span class="heading-3 black_1b semibold"
        ><span class="medium">₹</span> {{ totalAmount }}</span
      >
    </div>
    <button
      class="bold heading-4 btn-buy col-span-8 flex items-center justify-center gap-2"
      (click)="onButtonClick()"
      id="buy_now_btn"
    >
      BUY NOW
      <div class="loader" *ngIf="processing"></div>
    </button>
  </div>
</div>

<div class="overlay" *ngIf="processing || couponProcessing"></div>

<div *ngIf="showForm" class="batch-form">
  <app-batch-form
    [cityId]="cityId"
    [cId]="cId"
    [dept]="dept"
    [cls]="cls"
    [netPrice]="netPrice"
    [phase]="phase"
    [batchId]="batchId"
    [preference]="preference"
    [schemeId]="schemeId"
    [courseDuration]="courseDuration"
    [date]="date"
    [currentCohortName]="currentCohortName"
    [currentCohortClass]="currentCohortClass"
    [discountDetailName]="discountDetailName"
    [discountDetailAmount]="discountDetailAmount"
    [couponDisc]="couponDisc"
    (clickedEventEmitter)="batchFormSubmit($event)"
    (closeForm)="closeForm($event)"
    [paymentMode]="selectedMode"
    [gst]="gst"
  ></app-batch-form>
</div>
