import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-doubt-submit-dialog',
  templateUrl: './doubt-submit-dialog.component.html',
  styleUrls: ['./doubt-submit-dialog.component.scss'],
})
export class DoubtSubmitDialogComponent {
  openFrom: string;
  coming_source = '';
  constructor(
    public dialogRef: MatDialogRef<DoubtSubmitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.openFrom = data.openFrom;
    this.coming_source = data?.coming_source || '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmitClick(): void {
    if (this.openFrom === 'comment thread') this.dialogRef.close(true);
    else this.dialogRef.close('create-doubt');
  }
}
