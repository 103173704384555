import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ShowErrorComponent } from '../../components/show-error/show-error/show-error.component';

@Injectable({
  providedIn: 'root',
})
export class ShowErrorService {
  showAlert: boolean;
  public errorMessage = new BehaviorSubject<any>('');
  dialogRef: any;

  constructor(private dialog: MatDialog) {
    this.showAlert = false;
  }

  getErrorMessage(err: any): { status: any; message: string } {
    const errorMsg = {
      status: err.error && err.error.status ? err.error.status : '',
      message: '',
    };
    if (
      err &&
      err['error'] &&
      err['error'] &&
      err['error'].message &&
      typeof err.error['message'] === 'string' &&
      (err['error'].message !== null || err['error'].message !== '')
    ) {
      errorMsg.message = err['error'].message;
    } else if (
      err &&
      err['error'] &&
      err['error']['error'] &&
      err['error']['error'].message &&
      typeof err.error.error['message'] === 'string' &&
      (err['error'].error.message !== null || err['error'].error.message !== '')
    ) {
      errorMsg.message = err.error.error.message;
    } else if (
      err &&
      err['errorMessage'] &&
      typeof err['errorMessage'] === 'string' &&
      (err['errorMessage'] !== null || err['errorMessage'] !== '')
    ) {
      errorMsg.message = err['errorMessage'];
    } else if (
      err &&
      err['message'] &&
      typeof err['message'] === 'string' &&
      (err.message !== null || err.message !== '')
    ) {
      errorMsg.message = err['message'];
    } else if (!navigator.onLine) {
      errorMsg.message = `There is no internet connection, Please check your connection`;
    } else {
      errorMsg.message =
        'There were some error(s). Please check your internet connection or try again later';
    }
    return errorMsg;
  }

  async showError(err: any) {
    const errorMsg = this.getErrorMessage(err);
    const displayMessage = {
      status: `${errorMsg.status}` || '',
      message: `${errorMsg.message}` || '',
    };
    if (errorMsg) {
      await this.presentAlert(displayMessage);
    } else {
    }
  }

  presentAlert(message: object) {
    if (this.dialogRef) return;
    this.errorMessage.next(message);

    if (this.dialogRef) return;

    this.dialogRef = this.dialog.open(ShowErrorComponent, {
      panelClass: 'custom-dialog-container',
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = undefined;
    });
  }
}
