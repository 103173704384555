import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';

export interface SuccessResponse {
  success: boolean;
  message: string;
}

export interface RecentCohortResponse {
  success: boolean;
  data: RecentCohortData;
}

export interface RecentCohorts {
  _id: string;
  name: string;
  webIcon: string;
  mobIcon: string;
  exam: string;
  subExam: string;
  stream: string;
  class: string;
  language: string;
  board: string;
}

export interface RecentCohortData {
  recentCohorts: Array<RecentCohorts>;
  selectedCohortId: string;
}

export interface RecommendedCohort extends RecentCohorts {
  cohortId: string;
  exam: string;
  priority: number;
}
export class CohortConfigResponse {
  success: boolean;
  data: CohortConfig;
  error: Error;
  constructor(props: any) {
    this.success = props.success || false;
    this.data = new CohortConfig(props.data || {});
    this.error = props.error || {};
  }
}

interface Error {
  message: string;
  status: number;
}

export class CohortResponse {
  data: CohortQuestionModel;
  constructor(props: any) {
    this.data = new CohortQuestionModel(props.data || {});
  }
}

export interface CohortBatchesResponse {
  success: boolean;
  data: CohortBatchListModel[];
  message?: string;
  paginate: { totalCount: number };
}

export class CohortOptionsModel {
  value: string;
  key: string;
  hasChild: boolean;
  nextQuestion: CohortQuestionModel;
  mobIcon: string;
  webIcon: string;
  isGroup: boolean;
  description: string;

  constructor(props: any) {
    this.value = props.value || '';
    this.key = props.key || '';
    this.hasChild = props.hasChild || false;
    this.nextQuestion = new CohortQuestionModel(props.nextQuestion || {});
    this.webIcon = props.webIcon || '';
    this.mobIcon = props.mobIcon || '';
    this.isGroup = props.isGroup || false;
    this.description = props.description || '';
  }
}

export class CohortQuestionModel {
  question: string;
  key: string;
  isOptional: boolean;
  options: Array<CohortOptionsModel>;
  value: string;
  description: string;
  meta: CohortMeta[];

  constructor(props: any) {
    this.question = props.question || '';
    this.value = props.value || '';
    this.key = props.key || '';
    this.isOptional = props.isOptional || false;
    if (props && props.options) {
      this.options = props.options.map(
        (item: any) => new CohortOptionsModel(item)
      );
    } else {
      this.options = [];
    }
    this.description = props.description || '';
    if (props && props.meta) {
      this.meta = props.meta.map((item: any) => new CohortMeta(item));
    } else {
      this.meta = [];
    }
  }
}

export class CohortBatchListModel {
  _id: string;
  batchId: BatchDetailModel;
  cohortId: string;
  __v: number;
  priority: number;
  status: string;

  constructor(props: any) {
    this._id = props._id || '';
    this.batchId = new BatchDetailModel(props.batchId || {});
    this.cohortId = props.cohortId || '';
    this.__v = props.__v || 0;
    this.priority = props.priority || 0;
    this.status = props.status || '';
  }
}

export class FilterTag {
  _id: string;
  key: string;
  value: string;
  icon: string;
  iconActive: string;

  constructor(data: any = {}) {
    this._id = data._id || '';
    this.key = data.key || '';
    this.value = data.value || '';
    this.icon = data.icon || '';
    this.iconActive = data.iconActive || '';
  }
}
export class BatchTitles {
  lectures: string;
  notes: string;
  dpp: string;
  dppPdfs: string;
  dppVideos: string;
  description: string;
  allClasses: string;
  khazana: string;
  saarthi: string;
  tests: string;
  announcements: string;

  constructor(data: any) {
    data = data || {};
    this.lectures = data.lectures || '';
    this.notes = data.notes || '';
    this.dpp = data.dpp || '';
    this.dppPdfs = data.dppPdfs || '';
    this.description = data.description || '';
    this.allClasses = data.allClasses || '';
    this.khazana = data.khazana || '';
    this.saarthi = data.saarthi || '';
    this.tests = data.tests || '';
    this.announcements = data.announcements || '';
    this.dppVideos = data.dppVideos || '';
  }
}
export class CohortConfig {
  _id: string;
  filters: Array<CohortFilter> = [];
  template: CohortConfigTemplate;
  appConfig: AppConfigModel;
  webConfig: WebConfigModel;
  tags: Array<string> = [];
  status: string;
  name: string;
  exam: string;
  class: string;
  stream: string;
  board: string;
  language: string;
  createdAt: string;
  updatedAt: string;
  relevantCohorts: RecommendedCohort[] = [];
  banners: CohortBanners[] = [];
  mobIcon: string;
  webIcon: string;
  filterTags: FilterTag[];
  searchSuggession: SearchSuggestions[];
  batchTitles: BatchTitles;
  vpLive: VpLiveModel;

  constructor(props: any) {
    this._id = props._id || '';
    if (props && props.filters) {
      this.filters = props.filters.map((item: CohortFilter) => item);
    }
    this.template = props.template || {};
    this.appConfig = props.appConfig || {};
    this.webConfig = props.webConfig || {};
    this.tags = props.tags || [];
    this.status = props.status || '';
    this.name = props.name || '';
    this.exam = props.exam || '';
    this.class = props.class || '';
    this.stream = props.stream || '';
    this.board = props.board || '';
    this.language = props.language || '';
    this.createdAt = props.createdAt || '';
    this.updatedAt = props.updatedAt || '';
    this.relevantCohorts = props.relevantCohorts || [];
    if (props && props.banners) {
      this.banners = props.banners.map((item: CohortBanners) => item);
    }
    this.webIcon = props.webIcon || '';
    this.mobIcon = props.mobIcon || '';
    if (props.filterTags) {
      this.filterTags = props.filterTags.map(
        (filter: any) => new FilterTag(filter)
      );
    } else {
      this.filterTags = [];
    }
    if (props && props.searchSuggession) {
      this.searchSuggession = props.searchSuggession.map(
        (item: SearchSuggestions) => item
      );
    } else {
      this.searchSuggession = [];
    }
    this.batchTitles = new BatchTitles(props.batchTitles || {});
    this.vpLive = new VpLiveModel(props.vpLive || {});
  }
}

class VpLiveModel {
  name: string;
  batchCategoryId: string;
  masterBatchId: string;

  constructor(data: any = {}) {
    this.name = data?.name || '';
    this.batchCategoryId = data?.batchCategoryId || '';
    this.masterBatchId = data?.masterBatchId || '';
  }
}

export interface CohortConfigTemplate {
  isBatchListingEnabled: IsBatchListingEnabled;
  name: string;
}

export class IsBatchListingEnabled {
  isEnabled: boolean;
  isFiltersEnabled: boolean;
  isWalletEnabled: boolean;
  isBannersEnabled: boolean;
  isSearchEnabled: boolean;

  constructor(data: any) {
    this.isEnabled = data.isEnabled || false;
    this.isFiltersEnabled = data.isFiltersEnabled || false;
    this.isWalletEnabled = data.isWalletEnabled || false;
    this.isBannersEnabled = data.isBannersEnabled || false;
    this.isSearchEnabled = data.isSearchEnabled || false;
  }
}

export interface AppConfigModel {
  appHamburgerConfig: WebConfigModel;
  appBottomConfig: AppBottomConfig;
}

export interface AppBottomConfig {
  isSarthiEnabled: boolean;
  isLibraryEnabled: boolean;
  isTestSeriesEnabled: boolean;
  isStoreEnabled: boolean;
  isBatchListingEnabled: boolean;
  isScholarshipEnabled: boolean;
  isPWCentersEnabled: boolean;
  isAlakhAIEnabled?: boolean;
}

export interface WebConfigModel {
  isSarthiEnabled: boolean;
  isLibraryEnabled: boolean;
  isTestSeriesEnabled: boolean;
  isHomePageEnabled: boolean;
  isMyPurchaseEnabled?: boolean;
  isStoreEnabled: boolean;
  isProfileEnabled: boolean;
  isUpdateAcademicInfoEnabled: boolean;
  isNotificationsEnabled: boolean;
  isBookmarksEnabled: boolean;
  isFeedsEnabled: boolean;
  isMyDownloadEnabled: boolean;
  isReferAndEarnEnabled: boolean;
  isOffersEnabled: boolean;
  isWalletEnabled: boolean;
  isAboutUsEnabled: boolean;
  isContactUsEnabled: boolean;
  isTandCEnabled: boolean;
  isScholarshipEnabled: boolean;
  isVpLiveEnabled: boolean;
  isScholarEnabled: boolean;
  isPWCentersEnabled: boolean;
}

export class CohortBanners {
  _id: string;
  key: string;
  value: Array<CohortBannerValues> = [];

  constructor(props: any) {
    this._id = props._id || '';
    this.key = props.key || '';
    if (props && props.value) {
      this.value = props.value.map((item: CohortBannerValues) => item);
    }
  }
}

export interface CohortBannerValues {
  data: CohortBannerValuesTypes;
  _id: string;
  type: string;
  image: string;
  imageId: string;
}

export interface CohortBannerValuesTypes {
  type: string;
  typeId: string;
}

export class SelectedDetails {
  isOptional: boolean;
  value: string;
  key: string;
  parentKey: string;

  constructor(props: any) {
    this.isOptional = props.isOptional || false;
    this.value = props.value || '';
    this.key = props.key || '';
    this.parentKey = props.parentKey || '';
  }
}

export interface CohortFilter {
  name: string;
  key: string;
  isSingleSelect: boolean;
  possibleValues: PossibleValue[];
}

export interface PossibleValue {
  name: string;
  key: string;
}

export interface CohortRouteConfig {
  title: string;
  logo: string;
  url: string;
  isActive: boolean;
  isEnabled: boolean;
  configName: string;
}

export class CohortExamPreferences {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  organizationId: string;
  key: string;
  type: string;
  __v: number;
  value: string;

  constructor(props: any) {
    this._id = props._id || '';
    this.createdAt = props.createdAt || '';
    this.updatedAt = props.updatedAt || '';
    this.organizationId = props.organizationId || '';
    this.key = props.key || '';
    this.type = props.type || '';
    this.__v = props.__v || 0;
    this.value = props.value || '';
  }
}
export interface ExamPrefencesResponse {
  success: boolean;
  data: Array<CohortExamPreferences>;
  message: string;
}

export interface UserPreferencesModel {
  exam: string[] | string;
  class: string;
  language: string;
  stream?: string;
}

export interface CohortFilterQuery {
  classes: string;
  languages: string;
  boards: string;
  status: string;
  studyMode: string;
  batchDuration: string;
}

export interface ReferAndDataResponse {
  success: boolean;
  data: ReferEarnData;
}

export class ReferEarnData {
  _id: string;
  organizationId: string;
  updatedAt: string;
  referAndEarn: ReferEarnIconText;

  constructor(data: any) {
    this._id = data.id || '';
    this.organizationId = data.organizationId || '';
    this.updatedAt = data.updatedAt || '';
    this.referAndEarn = new ReferEarnIconText(data.referAndEarn) || {};
  }
}

export class ReferEarnIconText {
  icon: string;
  text: string;

  constructor(data: any) {
    this.icon = data.icon || '';
    this.text = data.text || '';
  }
}

export class SearchSuggestions {
  text: string;
  _id: string;

  constructor(data: any) {
    this.text = data.text || '';
    this._id = data._id || '';
  }
}

export class CohortMeta {
  name: string;
  description: string;
  appIcon: string;
  webIcon: string;
  appLink: string;
  webLink: string;

  constructor(data: any) {
    data = data || {};
    this.name = data.name || '';
    this.description = data.description || '';
    this.appIcon = data.appIcon || '';
    this.webIcon = data.webIcon || '';
    this.appLink = data.appLink || '';
    this.webLink = data.webLink || '';
  }
}
