import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { XP_ICON } from 'src/app/core/assets.location';
import { XP_SHINE_ICON, XP_RAYS } from 'src/app/core/assets.location';

@Component({
  selector: 'xp-gain-box',
  templateUrl: './xp-gain-box.component.html',
  styleUrls: ['./xp-gain-box.component.scss'],
})
export class XPGainBoxComponent implements OnInit {
  xpIcon = XP_ICON;
  xpShineIcon = XP_SHINE_ICON;
  xpRaysIcon = XP_RAYS;
  @Input() isFromVideo: any;
  @Input() earnedXp: number;
  @ViewChild('xpGainBox') xpGainBoxRef: ElementRef;
  constructor(public dialog: MatDialog, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      const xpGainBox = this.xpGainBoxRef?.nativeElement;
      if (xpGainBox) {
        this.renderer.removeClass(xpGainBox, 'xp-gain-box');
        this.renderer.addClass(xpGainBox, 'xp-gain-box-gone');
      }
    }, 3000);
  }
}
