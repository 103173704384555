<section
  class="bg-white pb-2 new-doubt-input-section mb-10"
  *ngIf="!isBlockFromParent"
></section>
<section
  class="bg-white py-1.5 new-doubt-input-section"
  *ngIf="!chatInputBlockedParent"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
>
  <div
    class="flex justify-between items-center new-doubt-input-container pr-2 pt-2"
  >
    <textarea
      (keyup)="autoGrowTextZone($event)"
      #input
      aria-label="search text"
      class="new-doubt-input w-full pl-2 caption-1 semibold sm-caption1"
      inputmode="text"
      [placeholder]="placeHolderText ? placeHolderText : 'Type Here...'"
      type="text"
      [disabled]="isBlockFromParent"
      [ngClass]="{
        'btn-primary': isBlockFromParent,
        'btn-secondary': !isBlockFromParent
      }"
    ></textarea>
    <button
      (click)="sendInput(input)"
      class="relative cursor-pointer pb-1 ml-1 sm-body1"
      [disabled]="isBlockFromParent"
      [ngClass]="{
        'btn-primary': isBlockFromParent,
        'btn-secondary': !isBlockFromParent
      }"
    >
      <img [src]="sendButton" alt="send" class="sm-img" />
    </button>
  </div>
</section>
<section
  class="bg-white py-1.5 px-1 md:px-2 new-doubt-input-section"
  *ngIf="chatInputBlockedParent"
>
  <div
    class="flex justify-between items-center new-doubt-input-container p-1.5"
  >
    <span class="block-input w-full pl-2 caption-1 semibold sm-caption1">
      {{ inputBlockMessageParent ? inputBlockMessageParent : '' }}
    </span>
  </div>
</section>
