<section class="flex flex-col gap-4 justify-center items-center">
  <img [src]="activation" class="p-5" />
  <span class="heading-4 bold">Batch Activation Form</span>
  <span class="body-1 text-center text-[#3D3D3D]"
    >Finish submitting your details to get access to batch.</span
  >

  <button
    class="book-now-cta items-center justify-center"
    [ngClass]="{
      'normal-batch': !isMasterBatch,
      'master-theme': isMasterBatch,
      disabled: !activationFormUrl
    }"
    (click)="handleActivationFormRedirection()"
  >
    <span class="text-white body-1 semibold">Start Activation Now </span>
  </button>
</section>
