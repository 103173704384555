import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoaderComponent } from '../../components/loader/loader/loader.component';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loading: boolean;
  dialogRef: any;
  public loaderMessage = new BehaviorSubject<string>('');
  private _loading = new BehaviorSubject<boolean>(false);

  constructor(public dialog: MatDialog) {}

  loadData(message: string = 'Loading') {
    if (message && message.length === 0) {
      return;
    }
    this.loaderMessage.next(message);
    this._loading.next(true);
    this.loading = true;
    this.loaderDialog();
  }

  unloadData(message: string = 'Loading') {
    this._loading.next(false);
    this.loaderMessage.next('');
    this.loading = false;
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  loaderDialog() {
    this.dialogRef = this.dialog.open(LoaderComponent, {
      panelClass: 'custom-dialog-container',
    });
  }

  isLoading(message: string) {
    return message === this.loaderMessage.getValue() && this.loading;
  }
}
