<!--<p>personal-assistant-dialog works!</p>-->
<div class="flex flex-wrap p-1 xl:p-4 header-section">
  <div class="w-full">
    <section class="">
      <div class="flex items-center justify-between md:mt-4 xl:mt-0">
        <div class="flex items-center">
          <img class="ask-doubt-img mr-4 xl:mr-4" [src]="doubtImg" />
          <h3 class="personal-heading semibold body-1">Personal Assistant</h3>
        </div>
        <div>
          <img
            (click)="onNoClick()"
            [src]="dialogCloseImg"
            class="close-icon cursor-pointer"
          />
        </div>
      </div>
    </section>
  </div>
</div>
<article
  [ngClass]="
    showInputBox ? 'container-with-input' : 'assistance-solution-container'
  "
>
  <div class="flex flex-wrap">
    <div class="w-full">
      <section
        class="solution_container mb-1 p-1 xl:p-3 md:flex md:justify-center"
      >
        <div class="w-full">
          <div class="flex flex-wrap">
            <ng-container
              *ngFor="
                let chat of allConversation;
                let i = index;
                let last = last
              "
            >
              <div
                *ngIf="
                  chat.sender === userInfo.id ||
                  chat.senderName.toLowerCase() !== 'admin'
                "
                class="my-1 mt-4 py-1 px-4 2xl:px-12 w-full overflow-hidden flex justify-between md:justify-end items-center"
              >
                <span
                  class="w-2/12 md:w-3/12 time-text medium caption-1 break-words"
                  >{{ chat.createdAt | date : 'short' }}</span
                >
                <mat-card
                  [ngClass]="!last ? 'replied' : 'not_replied'"
                  class="w-9/12 message-card"
                >
                  <mat-card-content
                    class="message-card-content body-1 break-words"
                  >
                    {{ chat.text }}
                  </mat-card-content>
                </mat-card>
              </div>

              <div
                *ngIf="
                  chat.sender !== userInfo.id &&
                  (chat | expertReplied : allConversation : userInfo.id)
                "
                class="my-4 p-1 px-4 2xl:px-12 w-full overflow-hidden flex justify-center"
              >
                <mat-card class="w-9/12 waiting-card-replied">
                  <mat-card-content class="w-full">
                    <div class="w-full flex flex-col items-center text-center">
                      <span class="body-2"
                        >Assistant Replied to Your Doubt</span
                      >
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <div
                *ngIf="
                  chat.sender !== userInfo.id &&
                  chat.senderName.toLowerCase() === 'admin'
                "
                class="my-1 mt-4 py-1 px-4 2xl:px-12 w-full overflow-hidden flex justify-between md:justify-end items-center"
              >
                <div class="w-9/12 pb-2.5">
                  <mat-card class="reply-message-card">
                    <mat-card-content
                      *ngIf="
                        chat.type === 'slidetxtSolution' ||
                        chat.type === 'slidetxtSpecificSolution' ||
                        chat.type === 'text'
                      "
                      class="reply-message-card-content caption-1 break-words"
                      [innerHtml]="chat.text | safe : 'html'"
                    >
                    </mat-card-content>
                    <mat-card-content
                      (click)="openSolModal(chat.text)"
                      *ngIf="
                        chat.type === 'slideUrlSolution' ||
                        chat.type === 'slideUrlSpecificSolution'
                      "
                      class="reply-message-card-content body-1 break-words cursor-pointer"
                    >
                      <a>Click here to view solution</a>
                    </mat-card-content>
                    <mat-card-content
                      *ngIf="
                        chat && chat.type === 'slideAudioUrlSpecificSolution'
                      "
                      class="reply-message-card-content body-1 break-words"
                    >
                      <div class="flex items-center justify-center">
                        <audio
                          class="audio-solution"
                          controls
                          controlsList="nodownload"
                        >
                          <source [src]="chat?.text" type="audio/webm" />
                        </audio>
                      </div>
                    </mat-card-content>
                    <mat-card-content
                      *ngIf="
                        chat.type === 'slideImageUrlSpecificSolution' ||
                        chat.type === 'slideImageUrlSolution' ||
                        chat.type === 'IMAGE'
                      "
                      class="reply-message-card-content body-1 break-words"
                    >
                      <img
                        *ngIf="!chat.text.includes('pdf')"
                        [src]="chat.text"
                        (click)="solutionModal(chat)"
                      />
                      <div *ngIf="chat.text.includes('pdf')">
                        <div class="flex items-center">
                          <img
                            (click)="downloadSoln(chat.text)"
                            [src]="viewPdfImg"
                            class="mr-4 cursor-pointer"
                          />
                          <div
                            (click)="downloadSoln(chat.text)"
                            class="clr-black caption-1 semibold mr-4 cursor-pointer"
                          >
                            Download Solution
                          </div>
                          <img
                            (click)="downloadSoln(chat.text)"
                            [src]="downloadImg"
                            class="cursor-pointer"
                          />
                        </div>
                      </div>
                    </mat-card-content>

                    <div
                      class="flex absolute items-center w-auto -bottom-2 right-4 chip-expert cursor-pointer"
                    >
                      <span
                        class="px-4 font-semibold caption-1 flex align-center"
                        (click)="openSmeDialog($event, chat.sender)"
                      >
                        Know Your Expert
                      </span>
                    </div>
                  </mat-card>
                </div>
                <div class="w-2/12 md:w-3/12">
                  <span class="time-text medium caption-1 flex justify-end">{{
                    chat.createdAt | date : 'short'
                  }}</span>
                </div>
              </div>
            </ng-container>

            <div
              class="mx-auto"
              *ngIf="
                processing === false &&
                doubt.status.toLowerCase().includes('shared')
              "
            >
              <div
                class="my-4 p-1 px-4 2xl:px-12 w-full overflow-hidden flex justify-center"
              >
                <mat-card class="waiting-card-replied solution-helpful-card">
                  <mat-card-content class="w-full">
                    <div class="w-full flex items-center">
                      <img
                        class="solution-helpful-img mr-1"
                        [src]="solutionHelpfulImg"
                      />
                      <span class="body-1"
                        >Is the Solution Helpful to you?</span
                      >
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <div
                class="my-1 p-1 px-4 2xl:px-12 w-full overflow-hidden flex justify-center"
              >
                <div class="assistant-dialog-controller flex items-center">
                  <span
                    #yes
                    (click)="setAssistantDialogValYes()"
                    class="medium controller-text w-1/2 flex justify-center items-center controller-border p-3 cursor-pointer"
                  >
                    Yes
                  </span>
                  <span
                    #no
                    (click)="setAssistantDialogValNo()"
                    class="medium controller-text w-1/2 flex justify-center items-center p-3 cursor-pointer"
                  >
                    No
                  </span>
                </div>
              </div>
            </div>
            <div
              *ngIf="doubt && doubt.status.toLowerCase().includes('awaiting')"
              class="w-full"
            >
              <div class="flex flex-wrap overflow-hidden">
                <div class="w-full overflow-hidden">
                  <div
                    class="my-1 p-1 px-4 2xl:px-12 w-full overflow-hidden flex justify-center"
                  >
                    <img class="waiting-img" [src]="waitingImg" />
                  </div>
                </div>

                <div class="w-full overflow-hidden">
                  <div
                    class="my-1 p-1 px-4 2xl:px-12 w-full overflow-hidden flex justify-center"
                  >
                    <mat-card class="w-9/12 waiting-card">
                      <mat-card-content class="w-full">
                        <div
                          class="w-full flex flex-col items-center text-center"
                        >
                          <span class="body-2 semibold"
                            >Waiting Assistant to Solve Your Doubt</span
                          >
                          <span class="body-2 semibold"
                            >It Usually takes 24 Hours</span
                          >
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</article>
<section
  *ngIf="showInputBox && (!doubt.parentId || doubt.parentId === '')"
  class="bg-white py-1.5 px-1 md:px-2 new-doubt-input-section"
>
  <div
    class="flex justify-between items-center new-doubt-input-container pr-2 pt-2"
  >
    <textarea
      (keyup)="autoGrowTextZone($event)"
      #inCompleteInput
      aria-label="search text"
      class="new-doubt-input w-full pl-2 caption-1 semibold"
      inputmode="text"
      placeholder="Ask your doubt here..."
      type="text"
    ></textarea>
    <div
      (click)="submitCompleteDoubt(inCompleteInput)"
      class="relative cursor-pointer pb-1"
    >
      <img [src]="sendButton" alt="send" />
    </div>
  </div>
</section>
