<!--<p>filter works!</p>-->

<article class="grid grid-cols-4">
  <section class="col-span-4 mb-4">
    <span class="font-bold text-xl">Update academic info</span>
  </section>

  <section class="col-span-4">
    <div class="mb-4">
      <div class="font-bold text-sm mb-3">Language</div>

      <div class="flex flex-wrap">
        <span
          *ngFor="let language of filterLanguages; let i = index"
          class="filter-content font-bold text-sm mt-2 mr-2 flex items-center justify-between cursor-pointer"
          (click)="selectLanguage(language)"
          [ngClass]="{ active: language.isActive }"
          [id]="'lang_btn-' + i"
        >
          <img *ngIf="language.isActive" [src]="tickImg" class="mr-2" alt="" />
          {{ language.title }}
        </span>
      </div>
    </div>
    <div class="mb-4">
      <div class="font-bold text-sm mb-3">Class</div>

      <div class="flex items-center flex-wrap gap-2">
        <span
          (click)="selectClass(class)"
          *ngFor="let class of filterClasses; let i = index"
          class="filter-content font-bold text-sm mr-2 cursor-pointer"
          [ngClass]="{ active: class.isActive }"
          [id]="'cls_btn-' + i"
        >
          <img *ngIf="class.isActive" [src]="tickImg" class="mr-2" alt="" />
          {{ class.title }}
        </span>
      </div>
    </div>

    <div class="mb-4">
      <div class="font-bold text-sm mb-3">Exam</div>

      <div class="flex flex-wrap gap-2">
        <span
          (click)="selectExam(exam)"
          *ngFor="let exam of filterExams; let i = index"
          class="filter-content font-bold text-sm mr-2 cursor-pointer"
          [ngClass]="{ active: exam.isActive }"
          [id]="'exam_btn-' + i"
        >
          <img *ngIf="exam.isActive" [src]="tickImg" class="mr-2" alt="" />
          {{ exam.title }}
        </span>
      </div>
    </div>

    <div class="mb-4">
      <div class="font-bold text-sm mb-3">Boards</div>

      <div class="flex flex-wrap gap-2">
        <span
          (click)="selectBoard(board)"
          *ngFor="let board of filterBoard; let i = index"
          class="filter-content font-bold text-sm mr-2 cursor-pointer"
          [ngClass]="{ active: board.isActive }"
          [id]="'board_btn-' + i"
        >
          <img *ngIf="board.isActive" [src]="tickImg" class="mr-2" alt="" />
          {{ board.title }}</span
        >
      </div>
    </div>
  </section>

  <section class="col-span-4">
    <hr />
  </section>

  <section class="col-span-4 mt-3 flex flex-col gap-2 md:flex-row justify-end">
    <div
      class="flex flex-wrap -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-2 xl:-mx-2"
    >
      <div
        class="px-2 w-1/2 overflow-hidden sm:px-2 sm:w-1/2 md:px-2 md:w-1/2 lg:px-2 lg:w-1/2 xl:px-2 xl:w-1/2"
      >
        <button
          (click)="updateUser()"
          mat-raised-button
          color="primary"
          class="apply-btn footer-btn"
          id="apply_btn"
        >
          Apply
        </button>
      </div>

      <div
        class="px-2 w-1/2 overflow-hidden sm:px-2 sm:w-1/2 md:px-2 md:w-1/2 lg:px-2 lg:w-1/2 xl:px-2 xl:w-1/2"
      >
        <!-- Column Content -->
        <button
          mat-stroked-button
          color="primary"
          class="close-btn footer-btn"
          (click)="close()"
          id="close_btn"
        >
          Close
        </button>
      </div>
    </div>
  </section>
</article>
