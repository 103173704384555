import { Pipe, PipeTransform } from '@angular/core';
import { DoubtConversationModel } from '../../services/doubts/doubt.model';

@Pipe({
  name: 'expertReplied',
})
export class ExpertRepliedPipe implements PipeTransform {
  transform(
    chat: DoubtConversationModel,
    allConvo: Array<DoubtConversationModel>,
    userId: string
  ): boolean {
    let index = allConvo.indexOf(chat);
    if (allConvo[index - 1]?.sender === userId) return true;
    else return false;
  }
}
