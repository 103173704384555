import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { environment } from '../../../../environments/environment';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  Observable,
  of,
  retry,
  take,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { handleError } from '../error-handler/error-handler.service';
import { StorageService } from '../storage/storage.service';
import { AppUrlService } from '../../app-url/app-url.service';
import { filter, finalize, tap } from 'rxjs/operators';
import { getDomainName } from '../../utils/utils';

@Injectable()
export class AuthService {
  BOARDS = 'BOARDS';
  CLASSES = 'CLASSES';
  EXAMS = 'EXAMS';
  LANGUAGES = 'LANGUAGES';
  TOKEN_EXPIRY_DURATION_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private apiService: PPApiService,
    private storage: StorageService,
    private appUrl: AppUrlService
  ) {}

  self(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/users/self`,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), debounceTime(1000), catchError(handleError));
  }

  verifyAuthToken(data?: any) {
    const url = this.appUrl.VERIFY_AUTH_TOKEN();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, options)
      .pipe(catchError(handleError));
  }

  setBoards(data: any) {
    this.storage.setValue(this.BOARDS, data, 'json');
  }

  setClasses(data: any) {
    this.storage.setValue(this.CLASSES, data, 'json');
  }

  setExams(data: any) {
    this.storage.setValue(this.EXAMS, data, 'json');
  }

  setLanguages(data: any) {
    this.storage.setValue(this.LANGUAGES, data, 'json');
  }

  getBoards() {
    return this.storage.getValue(this.BOARDS, 'json');
  }

  getClasses() {
    return this.storage.getValue(this.CLASSES, 'json');
  }

  getExams() {
    return this.storage.getValue(this.EXAMS, 'json');
  }

  getLanguages() {
    return this.storage.getValue(this.LANGUAGES, 'json');
  }

  setToken(value: string) {
    this.storage.setValue('TOKEN', value, 'string');
  }

  setRefreshToken(token: string) {
    this.storage.setRefreshToken(token);
  }

  setExpiryTime(time: number) {
    this.storage.setExpiresIn(time + '');
  }

  getExpiryTime() {
    return this.storage.getExpiresIn();
  }

  removeExpiresIn() {
    this.storage.removeValue('expires_in');
  }

  removeRefreshToken() {
    this.storage.removeValue('REFRESH_TOKEN');
  }

  getTokenAsValue() {
    return this.storage.getValue('TOKEN', 'string') || '';
  }

  /*  self(query?) {
    let url = this.appUrl.SELF;

    if (!this.isEmpty(query)) {
      url = url + '?';
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          //
          url += key + '=' + query[key] + '&';
        }
      }
      url = url.substr(0, url.length - 1);
    }

    if (this.isHasValue(url) && this.globalService.isOfflineMode) {
      return this.getFromLocal(url);
    } else {
      return this.getFromServer(url);
    }
  }*/

  /***login start***/
  sendOtp(data: any, smsType: number): Observable<any> {
    const options: PPApiOptions = {
      apiPath: `v1/users/get-otp`,
      config: {
        baseUrl: environment.baseUrl,
      },
      params: new PPApiParams().append('smsType', smsType),
    };
    return this.apiService
      .post(data, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  publicEnquiryTicket(data: any) {
    const url = this.appUrl.PUBLIC_TICKET_ENQUIRY;
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post(data, options).pipe(catchError(handleError));
  }

  resendOtp(data: any, smsType: number): Observable<any> {
    const options: PPApiOptions = {
      apiPath: `v1/users/resend-otp`,
      config: {
        baseUrl: environment.baseUrl,
      },
      params: new PPApiParams().append('smsType', smsType),
    };
    return this.apiService
      .post(data, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  /*** login end***/

  /*** signup start***/
  registerWithOtp(user: any) {
    const options: PPApiOptions = {
      apiPath: `v1/users/register/${ORGANIZATION_ID}`,
    };
    return this.apiService
      .post<any>(user, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  verifyOtp(user: any) {
    const loginDetails = {
      username: user.mobile,
      otp: user.otp,
      client_id: 'system-admin',
      client_secret: 'KjPXuAVfC5xbmgreETNMaL7z',
      grant_type: 'password',
      organizationId: ORGANIZATION_ID,
      latitude: user.latitude || 0,
      longitude: user.longitude || 0,
    };
    const options: PPApiOptions = {
      apiPath: `v3/oauth/token`,
      config: {
        baseUrl: environment.baseUrl,
      },
    };
    return this.apiService
      .post<any>(loginDetails, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  verifyOtpForSignup(userId: string, otp: any) {
    const options: PPApiOptions = {
      apiPath: `v1/users/${userId}/verify-otp`,
      config: {
        baseUrl: environment.baseUrl,
      },
    };
    return this.apiService
      .post<any>(otp, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  checkTokenExpiry() {
    const expiresIn = this.storage.getValue('expires_in', 'json') || '';
    const currentTimestamp =
      new Date().getTime() + this.TOKEN_EXPIRY_DURATION_IN_MILLISECONDS / 2;

    if (currentTimestamp > expiresIn && expiresIn) {
      // this.refreshToken().subscribe((data) => {
      //   if (data?.data) {
      //     this.setRefreshToken(data['data']['refresh_token']);
      //     this.setExpiryTime(data['data']['expires_in']);
      //     this.setToken(data['data']['access_token']);
      //   }
      // });
      return true;
    } else {
      return false;
    }
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1)
      );
    } else {
      this.refreshTokenInProgress = true;
      const refreshToken = this.storage.getValue('REFRESH_TOKEN', 'string');
      if (!refreshToken) {
        return of(null);
      }
      this.setRefreshToken('');
      this.setExpiryTime(0);
      const _data = {
        client_id: 'system-admin',
        client_secret: 'KjPXuAVfC5xbmgreETNMaL7z',
        refresh_token: refreshToken,
      };
      return this.refreshTokenApiCall(_data);
    }
  }

  refreshTokenApiCall(_data: any): Observable<any> {
    const options: PPApiOptions = {
      apiPath: 'v3/oauth/refresh-token',
    };

    return this.apiService.post(_data, options).pipe(
      tap((tokens: any) => {
        this.setToken(tokens?.data?.access_token);
        this.setRefreshToken(tokens?.data?.refresh_token);
        const data = {
          token: tokens['data']['access_token'],
          refresh_token: tokens['data']['refresh_token'],
          expires_in: tokens['data']['expires_in'],
          randomId: this.storage.getRandomId(),
        };
        const expiry = new Date(
          new Date().getTime() + 60 * 60 * 24 * 7 * 1000
        ).toUTCString();
        document.cookie = `TOKEN_CONTEXT=${JSON.stringify(
          data
        )};domain=.${getDomainName()};path=/;expiry=${expiry};max-age=${
          3600 * 24 * 8
        }`;
        this.refreshTokenSubject.next(tokens.jwt);
      }),
      finalize(() => {
        this.refreshTokenInProgress = false;
      }),
      catchError(handleError)
    );
  }

  setUserInfoAndPassword(orgId: string, userId: string, userInfo: any) {
    const options: PPApiOptions = {
      apiPath: `v1/users/${userId}/register/${orgId}/set-profile`,
      config: {
        baseUrl: environment.baseUrl,
      },
    };
    return this.apiService
      .post<any>(userInfo, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  exchangeTokenApiCall(): Observable<any> {
    const options: PPApiOptions = {
      apiPath: this.appUrl.EXCHANGE_TOKEN(),
    };

    return this.apiService.get(options).pipe(catchError(handleError));
  }

  /***signup end***/

  /**Logout*/
  logoutUser(deviceId: string) {
    const data = { deviceId: deviceId || '' };

    const options: PPApiOptions = {
      apiPath: `v1/oauth/logout`,
    };
    return this.apiService.post(data, options).pipe(catchError(handleError));
  }

  // Get Preferences
  getPreferences(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/organizations/preferences`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }

  exchangeToken() {
    const url = this.appUrl.EXCHANGE_TOKEN();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }
  getUserProfileInfo(query?: any) {
    const url = this.appUrl.GET_USER_PROFILE_INFO();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }
}
