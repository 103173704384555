<!--<p>doubt-feedback-dialog works!</p>-->
<mat-card class="report-submit-card">
  <div class="flex items-center justify-end">
    <img
      (click)="onNoClick()"
      [src]="dialogCloseImg"
      class="close-icon cursor-pointer"
    />
  </div>
  <mat-card-title class="title-text body-1 bold text-center"
    >User Feedback
  </mat-card-title>

  <mat-card-content class="w-full card-content">
    <div class="flex flex-wrap overflow-hidden">
      <div class="w-full overflow-hidden">
        <div class="flex flex-wrap overflow-hidden">
          <div
            *ngFor="let tag of reportTags"
            class="my-3 px-1 w-1/2 overflow-hidden flex justify-center items-center"
          >
            <button
              (click)="setTag(tag)"
              [ngClass]="{ active_tag_button: tag.isActive === true }"
              class="w-full tag-button flex justify-center items-center medium break-words"
              mat-stroked-button
            >
              {{ tag.name }}
            </button>
          </div>
        </div>
      </div>

      <div class="w-full overflow-hidden">
        <textarea
          #message
          class="mt-2 mb-4 w-full feedback-textarea bold"
          matInput
          placeholder="Enter your feedback here (Optional)"
        ></textarea>
      </div>

      <div class="w-full overflow-hidden flex justify-center items-center">
        <button
          (click)="onSubmitClick(message)"
          class="bold active-continue-btn footer-btn"
          color="primary"
          mat-raised-button
          [ngClass]="{
            'disabled-btn':
              activeTags.length === 0 ||
              (activeTags.includes('Others') &&
                message.value.toString().trim().length === 0)
          }"
          [disabled]="
            activeTags.length === 0 ||
            (activeTags.includes('Others') &&
              message.value.toString().trim().length === 0)
          "
        >
          Continue
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
