import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import {
  catchError,
  debounceTime,
  map,
  retry,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { handleError } from '../error-handler/error-handler.service';
import { ShowErrorService } from '../showError/show-error.service';
import { GlobalObjectService } from '../global-object/global-object.service';
import {
  OfferDetailObject,
  OffersObject,
} from 'src/app/pages/offers/offers.model';
import { StorageService } from '../storage/storage.service';
import { AppUrlService } from '../../app-url/app-url.service';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import { Observable, of } from 'rxjs';
import { Order, OrdersObject } from './store.model';

function _window(): any {
  // return the global native browser window object
  return window;
}
@Injectable()
export class StoreService {
  OFFER_DURATION: string = 'OFFER_DURATION';
  constructor(
    private apiService: PPApiService,
    private showErrorService: ShowErrorService,
    private globalObjectService: GlobalObjectService,
    private storage: StorageService,
    private appUrl: AppUrlService
  ) {}

  // get nativeWindow(): any {
  //   return this.globalObjectService.nativeWindow;
  // }

  get nativeWindow(): any {
    return _window();
  }

  setOffer(offer: any) {
    this.storage.setValue(this.OFFER_DURATION, offer, 'json');
  }

  getOfferFromLocalStorage() {
    return this.storage.getValue(this.OFFER_DURATION, 'json');
  }

  removeOffer() {
    this.storage.removeValue(this.OFFER_DURATION);
  }

  addToCartBulk(data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/cart/multi`,
    };

    return this.apiService
      .post(data, options)
      .pipe(retry(2), debounceTime(1000), catchError(handleError));
  }
  getDonationAmount(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/orders/donation`,
      params: new HttpParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }

  getOrderStatus(orderId: string) {
    const url = this.appUrl.GET_ORDER_STATUS(orderId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  createOrder(data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/orders`,
    };

    return this.apiService
      .post(data, options)
      .pipe(retry(2), debounceTime(1000), catchError(handleError));
  }

  getOrders(query: any): Observable<Order[]> {
    const options: PPApiOptions = {
      apiPath: `v1/orders`,
      params: new HttpParams().appendAll(query),
    };

    return this.apiService.get<OrdersObject>(options).pipe(
      debounceTime(1000),
      catchError(handleError),
      map((res) => res.data)
    );
  }

  addToCartSingle(data: any) {
    const options: PPApiOptions = {
      apiPath: 'v1/cart',
    };

    return this.apiService
      .post(data, options)
      .pipe(retry(2), debounceTime(1000), catchError(handleError));
  }

  emptyCart() {
    const options: PPApiOptions = {
      apiPath: 'v1/cart',
    };

    return this.apiService
      .delete(options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  getCart() {
    const options: PPApiOptions = {
      apiPath: 'v1/cart',
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      retry(1),
      catchError(handleError)
    );
  }

  applyRefer(data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/orders/apply-referrer`,
    };

    return this.apiService
      .put(data, options)
      .pipe(retry(2), debounceTime(1000), catchError(handleError));
  }

  applyCoupon(data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/orders/apply-coupons`,
    };

    return this.apiService
      .put(data, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  cancelOrder(orderId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/orders/${orderId}/cancel-order`,
    };

    return this.apiService.put<any>({}, options).pipe(
      retry(1),
      catchError((err) => this.showErrorService.showError(err))
    );
  }

  getStoreItems(params?: any) {
    const options: PPApiOptions = {
      apiPath: 'v2/packages',
    };

    if (!this.isEmpty(params)) {
      options.params = new PPApiParams().appendAll(params);
    }

    return this.apiService.get<OffersObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getStoreDetails(params?: any) {
    const options: PPApiOptions = {
      apiPath: 'v2/packages/detail',
    };

    if (!this.isEmpty(params)) {
      options.params = new PPApiParams().appendAll(params);
    }

    return this.apiService.get<OfferDetailObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      retry(1),
      catchError(handleError)
    );
  }

  payOrderByPoints(orderId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/orders/${orderId}/pay-via-wallet`,
    };

    return this.apiService
      .post(orderId, options)
      .pipe(retry(2), debounceTime(1000), catchError(handleError));
  }

  getTransactions(query?: any) {
    const options: PPApiOptions = {
      apiPath: this.appUrl.WALLET_HISTORY,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  jusPayInitSignature(data: any) {
    const url = this.appUrl.JUSPAY_INIT_SIGNATURE();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, options)
      .pipe(catchError(handleError), tap());
  }

  getPaymentMethod() {
    const url = this.appUrl.GET_PAYMENT_METHOD(ORGANIZATION_ID);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError), tap());
    // return of({
    //   data: {
    //     juspay: false,
    //   },
    // });
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
