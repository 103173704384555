import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCeilValue',
})
export class GetCeilValuePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): number {
    if (!value) return 0;
    return Math.ceil(value);
  }
}
