import { Injectable } from '@angular/core';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { NotificationRootObject } from '../../../pages/notifications/notification.model';
import { retry, tap } from 'rxjs/operators';
import { catchError, map, Observable, of } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import { PPApiService } from '../../api/api.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private apiService: PPApiService,
    private storage: StorageService
  ) {}

  getNotification(query?: any) {
    const options = {
      apiPath: `v1/notification`,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<NotificationRootObject>(options).pipe(
      map((res) => res),
      tap(),
      catchError(handleError)
    );
  }
  //@ts-ignore
  subscribeToNotif(data: any) {
    if (data.deviceId.length > 0) {
      const url = `v1/` + `notification/subscribe-topic`;
      const options: PPApiOptions = {
        apiPath: url,
      };
      return this.apiService
        .post(data, options)
        .pipe(retry(1), catchError(handleError));
    }
    return new Observable<any>();
  }
}
