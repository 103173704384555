<article class="flex justify-end cursor-pointer">
  <img [src]="cross" alt="" (click)="close()" />
</article>

<article class="w-full px-4 pb-2">
  <!--Heading Section-->
  <section class="flex justify-center items-center mb-3">
    <div class="heading-5 text-[#333333] semibold">
      {{ reaction === 'report' ? 'Tell us about the issue' : 'User Feedback' }}
    </div>
  </section>

  <!--Checkbox Section-->
  <section class="my-1">
    <div class="flex flex-col" *ngIf="!processing">
      <ng-container *ngFor="let item of tags">
        <mat-checkbox
          class="my-1 body-2 semibold"
          (click)="addCheckboxes(item)"
          >{{ item }}</mat-checkbox
        ></ng-container
      >
    </div>
    <div *ngIf="processing">
      <ngx-shimmer-loading
        *ngFor="let item of [1, 1, 1, 1, 1]"
        class="col-span-1 my-2"
        height="20px"
        shape="rect"
        width="100%"
      >
      </ngx-shimmer-loading>
    </div>
    <!-- <div *ngIf="reaction === 'dislike'" class="flex flex-col">
      <ng-container *ngFor="let item of dislikeFeedbacks">
        <mat-checkbox
          class="my-1 body-2 semibold"
          (click)="addCheckboxes(item)"
          >{{ item }}</mat-checkbox
        ></ng-container
      >
    </div>

    <div *ngIf="reaction === 'report'" class="flex flex-col">
      <ng-container *ngFor="let item of reportReasons">
        <mat-checkbox
          class="my-1 body-2 semibold"
          (click)="addCheckboxes(item)"
          >{{ item }}</mat-checkbox
        ></ng-container
      >
    </div> -->
  </section>

  <!--Textarea Section-->
  <section class="flex flex-col">
    <div class="bold my-2">Write your isssue</div>
    <div class="feedback-input-container">
      <textarea
        name="text"
        class="feedback-input p-3 w-full"
        placeholder="Type here..."
        #message
      ></textarea>
    </div>
  </section>

  <!--Submit button Section-->
  <section class="flex items-center justify-center mt-4">
    <div
      class="submit-button px-12 py-3 cursor-pointer"
      (click)="submit(message)"
    >
      Submit
    </div>
  </section>
</article>
