<section class="flex flex-col gap-4 relative">
  <span
    class="cursor-pointer absolute right-0 top-0 hover:bg-gray-100 rounded-sm fadeIn animated duration-500"
    (click)="handleOnClose()"
  >
    <img [src]="crossIcon" />
  </span>

  <span class="text-center bold heading-4 mt-2">Due Date Reminder</span>
  <ng-container *ngIf="emiSubText">
    <span class="text-center">{{ emiSubText }}</span>
  </ng-container>

  <section class="due-card-container">
    <ng-container *ngFor="let item of instalmentsData">
      <app-due-date-card
        [upcomingInstalment]="item"
        [hidePayCTA]="true"
        [isDueDatePassed]="item.daysLeft <= 0"
        [isMultipleEMIAvailable]="isMultipleEMIAvailable"
        [selectedCardId]="selectedCardId"
        [isActive]="
          instalmentsData.length > 1 && item?.batchId?._id === selectedCardId
        "
        (emiCardClick)="handleEmiCardClick($event)"
      ></app-due-date-card>
    </ng-container>
  </section>

  <button
    class="payNowCTA"
    [ngClass]="{ 'cursor-not-allowed opacity-40': (_isLoading$ | async) }"
    (click)="handleEmiPayNowClick()"
    [disabled]="(_isLoading$ | async) && !selectedCardId"
  >
    Pay Now
  </button>
</section>
