<div class="absolute top-6 right-3 md:top-9 md:right-8 z-50 cursor-pointer">
  <img
    (click)="closeModal()"
    *ngIf="data.openFrom !== 'batchList'"
    alt="close_btn"
    src="assets/batches/close.svg"
  />
</div>

<app-cohort-goal-list
  (sectionData)="changeSection($event)"
  *ngIf="activeSection === 'goal-list'"
>
</app-cohort-goal-list>

<div *ngIf="activeSection === 'cohort-list'">
  <app-cohort-list
    (changeSection)="changeSection($event)"
    [openFrom]="data.openFrom"
  ></app-cohort-list>
</div>
