import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ROTATE } from 'src/app/core/assets.location';
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { SaarthiService } from 'src/app/common/services/saarthi/saarthi.service';
import { Router } from '@angular/router';
const { v4: uuidv4 } = require('uuid');
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  rotate = ROTATE;
  state: string = 'default';
  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  imageUrl: any;
  uploadUrl: any;
  fileName: string;
  extension: string;
  formData: any;
  type: string;
  imageId: any;
  canvasRotation = 0;
  transform: ImageTransform = {};
  isSending: boolean = false;
  base64Image: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalComponent>,
    private saarthiService: SaarthiService,
    public router: Router
  ) {}

  async ngOnInit() {
    this.fileName = this.data?.file?.files[0]?.name;
    this.extension =
      this.data?.file?.files[0]?.name.split('.')[
        this.data.file.files[0].name.split('.').length - 1
      ];
    this.imgChangeEvt = this.data.fileEvt;
  }
  async cropImg(e: ImageCroppedEvent) {
    this.base64Image = e.base64;
    this.cropImgPreview = this._base64ToArrayBuffer(e.base64);
    this.formData = this.cropImgPreview;
    this.saarthiService.image = this.formData;
  }
  imgLoad() {}
  initCropper() {}
  imgFailed() {
    alert('image failed to show');
  }

  submitImage() {
    this.isSending = true;
    if (this.data?.cameFrom?.toLowerCase() === 'saarthi_chat') {
      this.saarthiService.chatDoubtImageData$.next({
        filename: this.fileName,
        extension: this.extension,
        type: 'ASK_DOUBT',
        imageUploaded: true,
        file: this.formData,
        croppedImage: this.base64ToFileLocal(this.base64Image, this.fileName),
      });
      this.dialogRef.close();
    } else {
      this.type = 'ASK_DOUBT';
      this.dialogRef.close();
      this.router.navigate(['saarthi/doubts/solution-listing'], {
        queryParams: {
          filename: this.fileName,
          extension: this.extension,
          randomId: uuidv4(),
        },
      });
    }
  }

  onClose() {
    if (this.data?.cameFrom?.toLowerCase() === 'saarthi_chat') {
      this.saarthiService.chatDoubtImageData$.next({
        filename: this.fileName,
        extension: this.extension,
        type: 'ASK_DOUBT',
        imageUploaded: false,
        file: this.formData,
      });
    }
    this.dialogRef.close();
  }

  rotateElem() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  _base64ToArrayBuffer(base64: any) {
    let array = base64.split(',');
    var binary_string = window.atob(array[1]);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  base64ToFileLocal(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
