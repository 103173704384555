import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TestSeriesService } from '../../../../common/services/tests/test-series.service';
import { Router } from '@angular/router';
import { TestList } from '../../tests.model';
import { GlobalService } from '../../../../common/services/global/global.service';
import { MAIN_PROGRAM_SLUG } from '../../../../constants/global-constant/global-constant.service';
import { DIALOG_CLOSE_IMG } from 'src/app/core/assets.location';

@Component({
  selector: 'app-resume-test-dialog',
  templateUrl: './resume-test-dialog.component.html',
  styleUrls: ['./resume-test-dialog.component.scss'],
})
export class ResumeTestDialogComponent implements OnInit {
  query: any;
  test: TestList;
  batchId: string;
  closeImg = DIALOG_CLOSE_IMG;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResumeTestDialogComponent>,
    private _testService: TestSeriesService,
    private router: Router,
    private globalService: GlobalService
  ) {
    this.query = data.obj;
    this.test = data.testList;
    this.batchId = data.batchId;
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  goToTest() {
    this.globalService.setTestBackUrl(this.router.url);
    this.router.navigate(
      [`${MAIN_PROGRAM_SLUG}/tests/${this.test.slug}/test-engine`],
      {
        queryParams: {
          jeePattern: this.test.enableNewJeeTestPatter ? 'true' : 'false',
          neetPattern: this.test.enableNewNeetTestPatter ? 'true' : 'false',
          testMappingId: this.query.testMappingId,
          batchId: this.batchId,
        },
      }
    );
    this.dialogRef.close();
  }

  resumeTest(data: string) {
    this.dialogRef.close(data);
  }
}
