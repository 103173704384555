import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { FormList, GlobalService } from '../../services/global/global.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShowErrorService } from '../../services/showError/show-error.service';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom, Subscription } from 'rxjs';
import { UpdateAcademicInfoComponent } from '../update-academic-info/update-academic-info.component';
import { collapsablePages, sidePagesDesktop } from './hamburger.config';
import {
  HOLI_GIF,
  NEW_LABEL,
  PW_LOGO,
  TEACHERS_DAY_LOTTIE,
} from '../../../core/assets.location';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics/firebase-analytics.service';
import { AnimationOptions } from 'ngx-lottie';
import {
  COHORT_CHANGE,
  COHORT_SELECT,
  CONTACT_US_EVENTS,
  MARKETPLACE_VISIT,
} from 'src/app/core/analytics-events';
import {
  STORAGE_ENUM,
  StorageService,
} from '../../services/storage/storage.service';
import { SaarthiService } from '../../services/saarthi/saarthi.service';
import format from 'date-fns/format';
import { ResultService } from '../../services/results/result.service';
import { CohortService } from '../../services/cohort/cohort.service';
import { GlobalObjectService } from '../../services/global-object/global-object.service';
import { CohortDialogComponent } from 'src/app/pages/batch/batchlist/cohort-dialog/cohort-dialog.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { isAfter, isBefore } from 'date-fns';
import { CenterPageService } from '../../services/center-page.service';
import { FORM_TRIGGERS } from 'src/app/constants/form.constant';
import { environment } from 'src/environments/environment';
import { StudyFunctionalityService } from '../../services/study-functionality/study-functionality.service';
import { MAIN_PROGRAM_SLUG } from 'src/app/constants/global-constant/global-constant.service';
import { AdmissionJourneyService } from '../../services/admission-journey/admission-journey.service';
import {
  PW_SCHOLARSHIP_TEST_SERIES,
  PwSatService,
} from '../../services/sat/pw-sat.service';
import { PremiumBatchService } from '../../services/premium-batch/premium-batch.service';
import { TestSeriesNewService } from '../../services/test-series-new/test-series-new.service';
import { MoengageService } from '../../services/moengage/moengage.service';
import { FinzyService } from '../../services/finzy/finzy.service';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  providers: [FinzyService],
  styleUrls: ['./hamburger.component.scss'],
})
export class HamburgerComponent implements OnInit, OnDestroy, AfterViewInit {
  isSideNavOpen: boolean;
  mobileQuery: any;
  sideNavSub: Subscription;
  activeTab: string;
  sidePagesDesktop = sidePagesDesktop;
  isExpanded: boolean;
  collapsablePages = collapsablePages;
  pwLogo = PW_LOGO;
  options: AnimationOptions = {
    path: NEW_LABEL,
  };
  teacherLogoOptions: AnimationOptions = {
    path: TEACHERS_DAY_LOTTIE,
  };
  isTeachersDay: boolean = false;
  cohortIdString: string = '';
  currentUrl: string;
  userInfo: any;
  userInfoSubs: Subscription;
  routeQuery: string = '';
  isBlinking: boolean = true;
  //cohort

  cohortConfigSub: Subscription;
  cohortSidebarConfig: any;
  cohortConfigApiSub: Subscription;
  showExpandToggle: boolean = true;
  formList: FormList[] = [];
  routerSub: Subscription;
  holiImage = HOLI_GIF;
  type: string = '';
  isCameFrom: string = '';
  fata: any = {};
  private _mobileQueryListener: () => void;
  vpTitle: String = '';
  isMonengageTriggered: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private _globalService: GlobalService,
    private _showErrorService: ShowErrorService,
    private globalObjectService: GlobalObjectService,
    public router: Router,
    private firebaseAnalytics: FirebaseAnalyticsService,
    public dialog: MatDialog,
    private satService: PwSatService,
    private storageService: StorageService,
    private saarthiService: SaarthiService,
    private resultService: ResultService,
    private cohortService: CohortService,
    private globalObject: GlobalObjectService,
    private cohortDialog: NgDialogAnimationService,
    private activatedRoute: ActivatedRoute,
    private centerPageSevice: CenterPageService,
    private globalService: GlobalService,
    private studyFunctionaltiyService: StudyFunctionalityService,
    private admissionJourneyService: AdmissionJourneyService,
    private premiumService: PremiumBatchService,
    private _testSeriesNewService: TestSeriesNewService,
    private moengage: MoengageService,
    private finzyService: FinzyService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 769px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.sidePagesDesktop.forEach(async (res) => {
      await this.activeRouteHandling(router.url, res);
      if (
        router.url.toLowerCase().includes('webinar') &&
        res.url === '/pw-centres'
      ) {
        this.handleItemClick(res);
      }

      if (
        (router.url.toLowerCase().includes('mentorship') ||
          router.url.includes('BATCH_TEST_SERIES')) &&
        res.url === '/batches/study'
      ) {
        this.handleItemClick(res);
      }

      if (this.router.url.split('?')[0] === res.url) this.handleItemClick(res);
      if (
        (router.url.toLowerCase().includes('test-series') ||
          router.url.toLowerCase().includes('test-result')) &&
        !router.url.toLowerCase().includes('/batch-overview') &&
        !router.url.includes('BATCH_TEST_SERIES') &&
        res.url === '/test-series'
      ) {
        this.handleItemClick(res);
      }
    });
    this.currentUrl = this.router.url;

    this.routeQuery =
      this.activatedRoute.snapshot.queryParamMap.get('cameFrom') || '';
    this.isBlinking = !this.storageService.getValue(
      'TEST_SERIES_CLICKED',
      'json'
    );
    this.routeCheck();

    this.type = this.activatedRoute.snapshot.queryParamMap.get('type') || '';

    this.getSideNav();
    if (this.type === 'dpp') {
      this._globalService.openSideNav(true);
    }
    if (router?.url?.toLowerCase()?.includes('new-infinite-practice-engine')) {
      this._globalService.openSideNav(false);
    }
    if (router?.url?.toLowerCase()?.includes('sahayak-ip-engine')) {
      this._globalService.openSideNav(false);
    }

    this.isCameFrom =
      this.activatedRoute.snapshot.queryParamMap.get('isCameFrom') || '';
  }

  ngOnInit(): void {
    // this.getSideNav();
    this.checkDate();
    this.getCohort();
    this.getUser();
    this.getCohortConfig();
    this.admissionJourneyService.removeCentreDetails();
    if (this.routeQuery === 'k8') {
      setTimeout(() => {
        this._globalService.openSideNav(false);
      }, 500);
      this.storageService.setCameFromK8('true');
    }
  }

  ngAfterViewInit(): void {
    const formList = this._globalService.getFormListFromStorage();

    if (formList) {
      this.formList = formList.data;
    }

    setTimeout(() => {
      if (this.formList.length > 0) {
        this.formList.forEach((form) => {
          const triggerEventType = form.triggerEvents.find((item) => {
            return item.type == FORM_TRIGGERS.HAMBURGER;
          });
          if (form.needToDisplay && triggerEventType) {
            sidePagesDesktop.forEach((item) => {
              if (item.title === 'Submit Your Admit Card') {
                item.isEnabled = true;
              }
            });
          }
        });
      }
    }, 500);
  }

  routeCheck() {
    this.routerSub = this.router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.currentUrl = res.url;
        this.sidePagesDesktop.forEach(async (res) => {
          await this.activeRouteHandling(this.currentUrl, res);
          if (
            this.currentUrl === res.url ||
            this.currentUrl.split('?')[0] === res.url
          ) {
            this.handleItemClick(res);
          }
        });
      }
    });
  }

  getUser() {
    setTimeout(() => {
      this.studyFunctionaltiyService.setRecentVideoList([]);
    }, 10);

    this.userInfoSubs = this._globalService.getUser().subscribe((user) => {
      this.userInfo = user;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.cohortConfigSub?.unsubscribe();
    this.cohortConfigApiSub?.unsubscribe();
    this.userInfoSubs?.unsubscribe();
  }

  redirectToHome() {
    this.router.navigate(['/batches']);
    this.sidePagesDesktop.forEach(
      (res) => (res.isActive = res.title === 'Batches' ? true : false)
    );
  }

  handleToggle(item: any) {
    if (this.mobileQuery.matches) {
      if (this.isSideNavOpen) {
        this._globalService.openSideNav(false);
      }
    }
    this.activeTab = item.title;
    this.handleItemClick(item);
  }

  toggleSideNav() {
    this._globalService.openSideNav(!this.isSideNavOpen);
  }

  openUpdateInfo() {
    const dialogRef = this.dialog.open(UpdateAcademicInfoComponent, {
      panelClass: 'update-academic-info',
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  expand() {
    this.isExpanded = !this.isExpanded;
  }

  getSideNav() {
    this.sideNavSub = this._globalService.isSideNavOpen().subscribe(
      (res) => {
        this.isSideNavOpen = res;
      },
      () => {}
    );
  }

  async logEvent(item: any) {
    if (item.title == 'Saarthi') {
      this.fata['origin'] = 'saarthi_study';
      await this.saarthiService.logEvent(MARKETPLACE_VISIT, this.fata);
    } else if (item.title == 'Our results') {
      const userResult = JSON.parse(this.storageService.getUser() || '');

      this.fata = {
        origin: 'results_page',
        board: userResult.profileId.board,
        class: userResult.profileId.class,
        exam: userResult.profileId.exams,
        user_created_at: userResult.createdAt,
        source: 'hamburger',
      };
      await this.resultService.logEvent(MARKETPLACE_VISIT, this.fata);
    }
  }

  async getPremiumBatchListing() {
    const query = {
      purchased: true,
    };
    try {
      const res = await lastValueFrom(
        this.premiumService.getPremiumBatchListing(this.cohortIdString, query)
      );

      return res;
    } catch (err) {
      console.log(err);
    }
  }

  checkDate() {
    const today = format(new Date(), 'MM/dd/yyyy');
    const teachersDayEnd = '03/09/2023';
    const teachersDayStart = '03/05/2023';
    this.isTeachersDay =
      isBefore(new Date(today), new Date(teachersDayEnd)) &&
      isAfter(new Date(today), new Date(teachersDayStart));
  }

  openCohortModal() {
    const dialogRef = this.cohortDialog.open(CohortDialogComponent, {
      panelClass: 'cohort-dialog',
      position: { right: '0px' },
      disableClose: true,
      data: { openFrom: 'hamburger' },
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
      },
    });
  }

  getCohort() {
    this.cohortConfigApiSub = this.cohortService.cohortId$.subscribe(
      async (id) => {
        if (!id || this.cohortIdString === id) {
          return;
        }
        const previousCohortDetails =
          this.cohortService.getCohortConfigFromLocal();
        try {
          const res = await lastValueFrom(
            this.cohortService.getCohortConfig(id)
          );
          if (res?.webConfig) {
            this.cohortService.setCohortCofig$(res);
            this.cohortIdString = id;
            this.cohortService.setCohortConfigToLocal(res);
            if (this.isMonengageTriggered) {
              this.moengage.trackMoengageEvent(COHORT_CHANGE, {
                cohort_name: res?.name || '',
                existing_class: previousCohortDetails?.class || '',
                existing_exam: previousCohortDetails?.exam || '',
                updated_exam: res?.exam || '',
                updated_class: res?.class || '',
              });
            }
            if (this.globalService.getNewUserLogin()) {
              this.moengage.trackMoengageEvent(COHORT_SELECT, {
                class: res?.class || '',
                exam: res?.exam || '',
                cohort_name: res?.name || '',
              });
            }
          }
        } catch (e) {
          this._showErrorService.showError(e);
        } finally {
          this.globalService.setNewUserLogin(false);
          this.isMonengageTriggered = true;
        }
      }
    );
  }

  getCohortConfig() {
    this.cohortConfigSub = this.cohortService.cohortConfig$.subscribe((res) => {
      if (res._id && this.cohortSidebarConfig !== res.webConfig) {
        this.vpTitle = res?.vpLive?.name || 'VP Live';
        this.cohortSidebarConfig = res.webConfig;
        const coming_source = this.storageService.getFbtComingSource() || '';
        if (
          !window.location.href.includes(
            PW_SCHOLARSHIP_TEST_SERIES.SCHOLARSHIP
          ) &&
          res.template.name === 'k8' &&
          !this.routeQuery &&
          !(coming_source.length && coming_source === 'k8')
        ) {
          this.globalObjectService.window!.location.replace(
            window.location.origin + '/k8'
          );

          return;
        }
        const extraData = {
          isBatchPageEnabled: res?.template?.isBatchListingEnabled?.isEnabled,
          isResultPageEnabled: true,
        };
        this.cohortSidebarConfig = {
          ...this.cohortSidebarConfig,
          ...extraData,
        };
        this.sidePagesDesktop.forEach((element, index) => {
          if (element.configName in this.cohortSidebarConfig) {
            element.isEnabled = this.cohortSidebarConfig[element.configName];
          } else if (element.title === 'PW Centres') {
            element.isEnabled = true;
          } else {
            element.isEnabled = false;
          }
        });

        this.collapsablePages.forEach((element) => {
          if (element.configName in this.cohortSidebarConfig) {
            element.isEnabled = this.cohortSidebarConfig[element.configName];
          }
        });
        this.showExpandToggle = this.collapsablePages.some(
          (element) => element.isEnabled
        );
        let allConfig = [...this.sidePagesDesktop, ...this.collapsablePages];
        this.cohortService.setCohortRouteConfig$(allConfig);
        this.handleCohortNavigation(allConfig);
      }
    });
  }

  handleCohortNavigation(allConfig: any) {
    if (!this.checkCurrentUrl(allConfig)) return;
    if (allConfig.every((res: any) => res.isEnabled === false)) {
      this.navigateByCohort(this.findByConfigName('isHomePageEnabled'));
    } else {
      let batchlist = this.findByConfigName('isBatchPageEnabled');
      if (batchlist?.isEnabled === true) {
        this.navigateByCohort(batchlist);
      } else {
        const enabledConfig = allConfig.find(
          (res: any) => res.isEnabled === true && res?.configName !== ''
        );
        this.navigateByCohort(enabledConfig);
      }
    }
    if (!this.mobileQuery.matches) {
      setTimeout(() => {
        this._globalService.openSideNav(true);
      }, 500);
    }
  }

  setCohortIdToLocal(id: string) {
    this.cohortService.setCohortIdToLocal(id);
  }

  checkCurrentUrl(allConfig: any) {
    let arr = this.currentUrl.split('/').filter(Boolean);
    let currentSection = arr.shift();
    let currentCohort = this.cohortService.getCohortIdFromLocal() || '';
    if (currentSection?.includes('?')) {
      currentSection = currentSection.split('?')[0];
    }

    for (let i = 0; i < sidePagesDesktop.length; i++) {
      if (
        currentSection?.includes('saarthi') &&
        this.sidePagesDesktop[i].title == 'Saarthi' &&
        this.routeQuery != 'dpp' &&
        this.isCameFrom != 'TestSaarthi'
      ) {
        this.sidePagesDesktop[i].isActive = true;
        break;
      } else if (
        currentSection?.includes('saarthi') &&
        (this.routeQuery == 'dpp' || this.isCameFrom == 'TestSaarthi') &&
        this.globalService.getSelectedNav() == 'Study' &&
        this.sidePagesDesktop[i].title == 'Study'
      ) {
        this.sidePagesDesktop[i].isActive = true;
        break;
      } else if (
        currentSection?.includes('saarthi') &&
        (this.routeQuery == 'dpp' || this.isCameFrom == 'TestSaarthi') &&
        this.globalService.getSelectedNav() == 'Batches' &&
        this.sidePagesDesktop[i].title == 'Batches'
      ) {
        this.sidePagesDesktop[i].isActive = true;
        break;
      }
    }
    if (
      currentSection?.includes('after-payment') ||
      currentSection?.includes('mentorship') ||
      currentSection?.includes('my-result') ||
      currentSection?.includes('test-result') ||
      currentSection?.includes('results') ||
      currentSection?.includes('q-bank') ||
      currentSection?.includes('pw-centres') ||
      currentSection?.includes('test-series') ||
      currentSection?.includes('batches') ||
      currentSection?.includes('logout') ||
      currentSection?.includes('redirection') ||
      currentSection?.includes('profile') ||
      currentSection?.includes('under-maintenance') ||
      currentSection?.includes('tests') ||
      currentSection?.includes(MAIN_PROGRAM_SLUG) ||
      currentSection?.includes('video-player') ||
      currentSection?.includes('saarthi') ||
      currentSection?.includes('scholarship') ||
      currentSection?.includes('payments') ||
      currentSection?.includes('my-purchase') ||
      currentSection?.includes('ai-guru') ||
      currentSection?.includes('cohort-selection')
    ) {
      return false;
    }

    const itemIndex = allConfig.findIndex((res: any) =>
      res.url.includes(currentSection || '')
    );

    if (itemIndex >= 0 && allConfig[itemIndex].isEnabled) {
      this.sidePagesDesktop.forEach((item) => {
        item.isActive = false;
      });
      allConfig[itemIndex].isActive = true;
    } else {
      if (!allConfig.isStudyPageEnabled) {
        return true;
      }
      if (arr.length) return false;
      return true;
    }
    return false;
  }

  findByConfigName(configName: string) {
    return this.sidePagesDesktop.find((res) => res.configName === configName);
  }

  navigateByCohort(item: any) {
    this.router.navigate(['/' + item.url], { replaceUrl: true });
    this.sidePagesDesktop.forEach((item) => {
      item.isActive = false;
    });
    if (
      item.title.toLowerCase() === 'home' ||
      item.title.toLowerCase() === 'pw-centers' ||
      item.title.toLowerCase() === 'test-series'
    )
      item.isEnabled = true;
    item.isActive = true;
  }

  trackByMethod(i: number) {
    return i;
  }

  /**
   * A description of the entire function.
   *
   * @param {string} url - description of parameter
   * @param {any} res - description of parameter
   */
  async activeRouteHandling(url: string, res: any) {
    if (url.includes(res.url)) {
      if (
        url.split('?')[0].includes('batches/study') &&
        res.url !== '/batches'
      ) {
        this.handleItemClick(res);
      }
      if (
        !url.split('?')[0].includes('batches/study') &&
        res.url === '/batches'
      ) {
        this.handleItemClick(res);
      }
    }
  }

  /**
   * Asynchronously handles the item click event.
   * This code defines an asynchronous function handleItemClick
   *  that handles various events based on the clicked item.
   *  It performs different actions like setting navigation,
   *  handling form submissions, logging events, opening URLs,
   *  and navigating to different routes based on the clicked
   *  item and other conditions. It also manages state updates
   *  and removes certain values from storage based on the
   *  clicked item's title and conditions.
   *
   * @param {any} item - the item being clicked
   * @param {boolean} fromHamburger - indicates if the click originated from the hamburger menu
   */
  async handleItemClick(item: any, fromHamburger: boolean = false) {
    if (item?.url === '/contact-us') {
      this.globalService.setPreviousUrl$(this.globalService._previousUrl$);
    } else {
      this.globalService.setPreviousUrl$(item?.url);
    }

    if (fromHamburger) {
      this.storageService.removeLastVisitedUrl();
      this.storageService.removePartPaymentEnabledInABatch();
    }
    // setSelectedNav is for saarthi use
    this.globalService.setSelectedNav(item.title);

    if (item.title.toLowerCase().includes('study') && fromHamburger) {
      this.storageService.setLandingType('hamburger');
    }

    this.globalService.removeDppBackUrl();

    if (item?.title?.toLowerCase() !== 'saarthi' && fromHamburger) {
      this.storageService.removeValue('SAARTHI_ID');
      this.globalService.removeChatSaarthiBackUrl();
    }
    if (item?.title?.toLowerCase() !== 'batches') {
      this.storageService.removeValue('SELECTED_SAARTHI');
    }
    if (item.title === 'Test Series' && fromHamburger) {
      this.isBlinking = false;
      this.storageService.setValue('TEST_SERIES_CLICKED', 'true', 'string');
      this.storageService.removeBatchData();
      if (!item.isActive) {
        this._testSeriesNewService.setTestSeriesPageArray([]);
      }
    }
    if (item.title !== 'Test Series') {
      this.storageService.removeValue(this.storageService.TEST_SERIES_FILTER);
      this.storageService.removeValue('TEST_SERIES_CLICKED');
      if (fromHamburger) {
        this.storageService.removeSessionValue(
          STORAGE_ENUM.BATCH_CLUBBING_TEST_DATA
        );
        this._testSeriesNewService.setTestSeriesPageArray([]);
      }
    }
    // this.centerPageSevice.setCentrePageArray([]);
    if (item.title === 'Submit Your Admit Card') {
      if (this.formList.length > 0) {
        this.formList.forEach((form) => {
          if (form.isAlreadySubmitted) {
            this._globalService.showSnackBar('Form already submitted!');
            return;
          } else {
            form.triggerEvents.forEach((item) => {
              if (item.type === FORM_TRIGGERS.HAMBURGER) {
                this._globalService.triggerAdmitCard(FORM_TRIGGERS.HAMBURGER);
                return;
              }
            });
          }
        });
      }
      return;
    }
    if (item.title != 'Saarthi') {
      this.firebaseAnalytics.logEvents(MARKETPLACE_VISIT, {
        origin: item.title.toLowerCase().replace(' ', '_'),
        source: 'hamburger',
      });
    }
    if (fromHamburger && item.url === '/contact-us') {
      this.firebaseAnalytics.logEvents(CONTACT_US_EVENTS.CONTACT_US_VISIT, {
        coming_from: 'hamburger',
        user_cohort_id:
          this.cohortService.getCohortIdFromLocal() ||
          this.userInfo?.profileId?.cohortId,
        user_type:
          this._globalService.getIsPathShalaFromStorage() === 'true'
            ? 'offline'
            : 'online',
        user_segment: this._globalService.getUserSegmentFromStorage(),
      });
    }
    if (item.url.includes('https') || item.url.includes('http')) {
      if (item['configName'] === 'isYogyaEnabled') {
        this.finzyService.handleNavigation(item.url);
        return;
      }
      const token = this._globalService.getAccessToken().value;
      const uuidFromStorage: string =
        this.globalObject.window!.localStorage.getItem('uuid') || '';
      if (item.url.includes('skills')) {
        this.globalObjectService.window!.open(
          `${item.url}?domain=${environment.skillsDomain}&token=${token}&uuid=${uuidFromStorage}&redirected_from=pwlive`
        );
      } else {
        this.globalObjectService.window!.open(`${item.url}?token=${token}`);
      }
    } else {
      this.sidePagesDesktop.forEach((item) => {
        item.isActive = false;
      });
      this.collapsablePages.forEach((item) => {
        item.isActive = false;
      });
      item.isActive = true;

      this.logEvent(item);

      if (fromHamburger) {
        if (item.url === '/vp-live') {
          const premiumBatches = await this.getPremiumBatchListing();

          if (premiumBatches?.[0]?.batchId?.slug) {
            this.router.navigateByUrl(
              `${item.url}/${premiumBatches?.[0]?.batchId?.slug}/premium-batch-overview`
            );
          }
          return;
        }
        this.router.navigateByUrl(item.url);
      }
    }

    if (item?.title !== 'PW Centres') {
      this.centerPageSevice.removeCentrePageArray();
    }

    if (item?.title !== 'Scholarship') {
      this.storageService.removeValue('categoryName');
      this.storageService.removeValue('scholarship_result');
      this.storageService.removeValue('sat_test');
      this.storageService.removeValue('isInstructionBack');
      this.satService.removeScholarshipPageArray();
    }

    if (item?.title == 'Scholarship') {
      this.storageService.setValue('isCohortUpdated', 'true', 'string');
    }

    if (item?.title !== 'Batches') {
      this.storageService.removeMahapackCheckData();
      this.admissionJourneyService.removeCentreDetails();
    }

    if (item?.title !== 'Study') {
      this.storageService.removeMyDashboardUrls();
    }
  }
}
