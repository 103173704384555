<article
  *ngIf="
    isShowMyDashboard &&
      widget &&
      widget.type === 'Dashboard' &&
      variant === 'default';
    else allClasses
  "
  class="dashboard"
>
  <section class="flex justify-between mt-4 dashboard-container px-6 py-4">
    <div class="left-div flex flex-col flex-[0.7] gap-5">
      <div class="flex flex-col">
        <div class="flex flex-col gap-2.5">
          <img [src]="newImg" alt="new" height="16px" width="39px" />
          <span
            [innerHtml]="
              (widget && widget.title && widget.title.length > 0
                ? widget.title
                : 'My dashboard'
              )
                | truncateText : 242
                | safe : 'html'
            "
            class="bold heading-5 card-title"
          ></span>
        </div>

        <span
          [innerHtml]="
            widget.subTitle ||
              'Analyze your performance and identify any areas where you can improve.'
              | safe : 'html'
          "
          class="card-subtitle body-1 medium"
        ></span>
      </div>
      <div>
        <button
          class="explore-btn body-2 text-white flex gap-1 items-center w-fit"
          (click)="goToMyDashBoard()"
        >
          <span>Explore</span>
          <img [src]="right_arrow" alt="" />
        </button>
      </div>
    </div>
    <img [src]="dashboardImg" alt="" />
  </section>
</article>

<ng-template #allClasses>
  <app-quick-access-tile
    *ngIf="isShowMyDashboard"
    (click)="goToMyDashBoard()"
    [title]="item && item.title"
    [subTitle]="item && item.subTitle"
    [image]="item && item.image.baseUrl + item.image.key"
  ></app-quick-access-tile>
</ng-template>
