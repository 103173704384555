export interface NpfEvent {
  eventName: string;
  lastActivity: string;
  eventMetaData: MetaDetaVidyapeeth;
}

export interface NpfEvent {
  eventName: string;
  lastActivity: string;
  eventMetaData: MetaDetaVidyapeeth;
}

export interface MetaDetaVidyapeeth {
  name: string;
  email: string;
  cohort?: string;
  city?: string;
  gender?: string;
  centerName?: string;
  cityName?: string;
  batch_offering?: string;
  batch_name?: string;
  class?: string;
  exam?: string;
  batchName?: string;
  courseDuration?: string;
  paymentStatus?: string;
  gaurdianName?: string;
  gaurdianMobileNumber?: string;
  countryDialCode?: string;
}

export const VIDYAPEETH_LEAD = 'VIDYAPEETH_LEAD';

export enum VIDYAPEETH_LEAD_EVENT {
  REGISTRATION_FORM_FILLED = 'REGISTRATION_FORM_FILLED',
  BUY_NOW = 'BUY_NOW',
  BATCH_EXPLORE_PAGE = 'BATCH_EXPLORE_PAGE',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAY_NOW = 'PAY_NOW',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
}
