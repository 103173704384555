<button class="body-1 medium px-5 py-2" (click)="onClick()">
  <ng-container *ngIf="loading; else load">
    <div class="flex items-center gap-2 loader">
      <span class="semibold">Loading</span>
      <mat-spinner [diameter]="25"></mat-spinner>
    </div>
  </ng-container>
  <ng-template #load>
    <div class="semibold">Load More</div>
  </ng-template>
</button>
