import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { lastValueFrom, Subscription } from 'rxjs';
import {
  CommonDoubtModel,
  DoubtCommentModel,
} from 'src/app/common/services/doubts/doubt.model';
import { DoubtsService } from 'src/app/common/services/doubts/doubts.service';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import {
  ATTACHMENT_IMG,
  DELETE_RED,
  DISLIKE_ACTIVE,
  DISLIKE_DISABLED,
  DISLIKE_INACTIVE,
  LIKE_ACTIVE,
  LIKE_DISABLED,
  LIKE_INACTIVE,
  MIKE_IMG,
  PERSONAL_ASSISTANT_VERIFIED,
  PIN,
  REPLY_TO_ANSWER,
  REPORT,
  SAME_DOUBT_ACTIVE,
  SAME_DOUBT_DEFAULT,
  SEND_BUTTON,
  USER_IMG,
} from 'src/app/core/assets.location';
import { SOLVER_PROFILE_CLICK } from 'src/app/core/analytics-events';
import { DoubtReplyFeedbackModalComponent } from 'src/app/pages/batch/batch-video-player/doubt/ask-doubt/doubt-reply-feedback-modal/doubt-reply-feedback-modal.component';
import { DoubtSolverModalComponent } from 'src/app/pages/batch/batch-video-player/doubt/ask-doubt/doubt-solver-modal/doubt-solver-modal.component';
import { DoubtSubmitDialogComponent } from 'src/app/pages/batch/batch-video-player/doubt/ask-doubt/doubt-submit-dialog/doubt-submit-dialog.component';
import { SolutionModal } from 'src/app/pages/batch/batch-video-player/doubt/ask-doubt/personal-assistant-solution/soultion-modal/solution-modal.component';
import { VideoSolModalComponent } from 'src/app/pages/batch/batch-video-player/doubt/video-sol-modal/video-sol-modal.component';

@Component({
  selector: 'app-doubt-reply-card',
  templateUrl: './doubt-reply-card.component.html',
  styleUrls: ['./doubt-reply-card.component.scss'],
})
export class DoubtReplyCardComponent implements OnInit {
  @Input('doubtSolution') doubtSolution: DoubtCommentModel;
  @Output('changeSection') changeSection: EventEmitter<string> =
    new EventEmitter<string>();
  @Output('feedback') feedback: EventEmitter<string> =
    new EventEmitter<string>();
  @Input('isComment') isComment: boolean = false;
  @Output('deleteComment') deleteComment: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() coming_source: string = '';
  @Input('doubt') doubt: CommonDoubtModel;
  sendButton = SEND_BUTTON;
  sameDoubtActive = SAME_DOUBT_ACTIVE;
  sameDoubtDefault = SAME_DOUBT_DEFAULT;
  reportImg = REPORT;
  mikeImg = MIKE_IMG;
  attachmentImg = ATTACHMENT_IMG;
  pinImg = PIN;
  textValue: string = '';
  verifiedAssistant = PERSONAL_ASSISTANT_VERIFIED;
  likeInactive = LIKE_INACTIVE;
  dislikeInactive = DISLIKE_INACTIVE;
  dislikeActive = DISLIKE_ACTIVE;
  likeActive = LIKE_ACTIVE;
  replyImg = REPLY_TO_ANSWER;
  userImg = USER_IMG;
  deleteImg = DELETE_RED;
  likeDisabled = LIKE_DISABLED;
  dislikeDisabled = DISLIKE_DISABLED;
  isLiked: boolean = false;
  userInfo: any;
  userSubs: Subscription;

  constructor(
    private globalService: GlobalService,
    private dialog: MatDialog,
    private doubtService: DoubtsService,
    private showErrorService: ShowErrorService,
    private firebaseAnalytics: FirebaseAnalyticsService
  ) {
    this.userSubs = this.globalService.getUser().subscribe((res) => {
      if (res) this.userInfo = res;
    });
  }

  ngOnInit(): void {
    if (this.isComment)
      this.isLiked = this.doubtSolution.rated === 1 ? true : false;
  }

  replyToSolution() {
    if (!this.isComment) {
      this.doubtService.activeDoubtSolution$.next(this.doubtSolution);
      this.changeSection.emit('doubtSolutionComments');
    }
  }

  openFeedbackModal(event: any, reaction: string) {
    if (this.userInfo.id === this.doubtSolution.createdBy._id)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_GIVE_FEEDBACK_TO_YOUR_OWN_SOLUTION
      );

    if (reaction !== 'report' && this.doubtSolution.rated !== 0)
      return this.globalService.showSnackBar(SNACKBAR_MESSAGE.ALREADY_RATED);

    let dialogPosition: DialogPosition = {};
    if (event.view.innerWidth >= 1024 && event.view.innerWidth <= 1350) {
      dialogPosition = {
        right: 30 + 'px',
      };
    }
    if (event.view.innerWidth > 1350) {
      dialogPosition = {
        right: 3 + '%',
      };
    }
    const dialog = this.dialog.open(DoubtReplyFeedbackModalComponent, {
      data: {
        reaction: reaction,
      },
      panelClass: 'doubt-reply-feedback-modal',
      position: dialogPosition,
    });
    dialog.afterClosed().subscribe(async (data) => {
      if (data) {
        if (reaction !== 'report') this.rateDoubtSolution(data, reaction);
        else this.reportDoubtSolution(data, reaction);
      }
    });
  }

  async rateDoubtSolution(data: object, reaction: string) {
    data = { ...data, ...{ typeId: this.doubtSolution._id } };
    try {
      const res = await lastValueFrom(
        this.doubtService.rateDoubtSolution(data)
      );
      if (res && res['success']) {
        if (reaction === 'like') {
          this.doubtSolution.rated = 1;
          this.doubtSolution.upVoteCount++;
        } else this.doubtSolution.rated = -1;
        this.feedback.emit(reaction);
      }
    } catch (e) {
      this.showErrorService.showError(e);
    }
  }

  async reportDoubtSolution(data: object, reaction: string) {
    try {
      const res = await lastValueFrom(
        this.doubtService.reportComment(data, this.doubtSolution._id)
      );
      if (res && res['success']) {
        if (reaction) this.feedback.emit(reaction);
      }
    } catch (e: any) {
      if (e.error.message === 'Doubt is already Reported')
        return this.globalService.showSnackBar(
          SNACKBAR_MESSAGE.CANNOT_REPORT_SAME_COMMENT_TWICE
        );
      this.showErrorService.showError(e);
    }
  }

  openSolverModal(event: any) {
    if (
      this.doubtSolution.displayOrder === 3 ||
      this.doubtSolution.createdBy._id === this.userInfo.id
    )
      return;
    this.logEvent();
    let dialogPosition: DialogPosition = {};
    if (event.view.innerWidth >= 1024 && event.view.innerWidth <= 1350) {
      dialogPosition = {
        right: 10 + 'px',
      };
    }
    if (event.view.innerWidth > 1350) {
      dialogPosition = {
        right: 2 + '%',
      };
    }
    let dialogRef = this.dialog.open(DoubtSolverModalComponent, {
      panelClass: 'doubt-solver-modal',
      position: dialogPosition,
      data: {
        createdBy: this.doubtSolution.createdBy,
        displayOrder: this.doubtSolution.displayOrder,
      },
    });
  }

  logEvent() {
    this.firebaseAnalytics.logEvents(SOLVER_PROFILE_CLICK);
  }

  likeComment() {
    if (this.userInfo.id === this.doubtSolution.createdBy._id)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_LIKE_OWN_COMMENT
      );
    this.isLiked = !this.isLiked;
    let data = {
      ratingType: 'Voting',
      type: 'DOUBTS',
    };
    if (this.isLiked) {
      this.rateDoubtSolution({ ...data, ratingValue: 1 }, '');
      this.doubtSolution.upVoteCount++;
    } else {
      this.rateDoubtSolution({ ...data, ratingValue: 0 }, '');
      this.doubtSolution.upVoteCount--;
    }
  }

  deleteUserComment(event: any) {
    let dialogPosition: DialogPosition = {};
    if (event.view.innerWidth >= 1024 && event.view.innerWidth <= 1350) {
      dialogPosition = {
        right: 50 + 'px',
      };
    }
    if (event.view.innerWidth > 1350) {
      dialogPosition = {
        right: 5 + '%',
      };
    }
    const dialogRef = this.dialog.open(DoubtSubmitDialogComponent, {
      panelClass:
        this.coming_source === 'samsung_tv'
          ? 'samsung-oubts-submit-dialog'
          : 'doubts-submit-dialog',
      data: { openFrom: 'comment thread', coming_source: this.coming_source },
      position: dialogPosition,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) this.deleteComment.emit(true);
    });
  }

  ngOnDestroy(): void {
    this.userSubs?.unsubscribe();
  }

  openSolutionModal(event: any) {
    let dialogPosition: DialogPosition = {};
    if (event.view.innerWidth >= 1024 && event.view.innerWidth <= 1350) {
      dialogPosition = {
        right: 45 + 'px',
      };
    }
    if (event.view.innerWidth > 1350) {
      dialogPosition = {
        right: 4.5 + '%',
      };
    }
    const dialog = this.dialog.open(SolutionModal, {
      panelClass: 'doubt-solution-modal',
      data: {
        imageLink:
          this.doubtSolution.imageId.baseUrl + this.doubtSolution.imageId.key,
      },
      position: dialogPosition,
    });
  }

  async detectUrl(data: string, event: any) {
    event.preventDefault();
    if (data.includes('<!DOCTYPE html>')) {
      data = data.replace(/<(.|\n)*?>/g, '').trim();
    }
    data = this.extractUrl(data);

    const youtubeRegex =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const checkInput = youtubeRegex.test(data);

    if (checkInput) {
      this.openSolModal(data);
    }
  }

  extractUrl(data: string) {
    let urlRegex = /(https?:[^\s]+)/;
    let url: any = data.match(urlRegex);
    if (url) {
      url = url[1];
    }
    return url;
  }

  openSolModal(url: string) {
    const data = {
      url,
      cssClass: 'video-sol-modal',
    };
    const dialogRef = this.dialog.open(VideoSolModalComponent, {
      panelClass: data.cssClass,
      data,
    });
  }

  calculateTextLength() {
    if (this.doubtSolution?.isExpanded) {
      return this.doubtSolution?.text?.length;
    } else if (this.doubtSolution?.text.includes('<!DOCTYPE html>')) {
      return 475;
    } else {
      return 175;
    }
  }
}
