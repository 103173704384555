import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GlobalObjectService } from '../global-object/global-object.service';

function getLocalStorage(): Storage {
  return localStorage;
}

@Injectable({
  providedIn: 'root',
})
export class StorageRefService {
  // @ts-ignore
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private globalObjectService: GlobalObjectService
  ) {}

  // @ts-ignore
  get localStorage(): Storage {
    if (isPlatformBrowser(this.platformId)) {
      return getLocalStorage();
    } else {
      // use alternative or throw error
      return this.globalObjectService.window?.localStorage!;
    }
  }
}
