<article
  class="comments-container"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="loadMore()"
  [scrollWindow]="false"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
>
  <div
    class="flex flex-wrap overflow-hidden px-2 md:px-3 py-2 relative sm-padding3"
    *ngIf="!doubt?.isRecommended"
  >
    <div
      *ngIf="!doubt.slide?.id"
      class="flex items-center justify-center w-full spinner-height"
    >
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <img
      *ngIf="doubt.slide?.id"
      [src]="doubt.slide.imgUrl.url"
      class="slide-img"
    />
    <div
      *ngIf="doubt.slide?.id"
      class="absolute theme-color-bg px-5 py-1 left-5 bottom-4"
    >
      <span class="caption-1 text-white sm-caption1">Current Slide</span>
    </div>
  </div>

  <section class="flex flex-col px-2 md:px-3 py-2 sm-padding3">
    <!--First row of doubt and doubt owner-->
    <div class="flex justify-between items-center">
      <div class="flex flex-row items-center">
        <span
          class="caption-2 semibold doubt-chip rounded-full px-3 py-0.5 sm-caption2"
        >
          Doubt
        </span>
        <span
          class="semibold time-text caption-2 sm-caption2"
          *ngIf="!doubt?.isRecommended"
        >
          &nbsp; {{ doubt.createdAt | timeDifference }}
        </span>
      </div>
      <div class="flex flex-row items-center" *ngIf="!doubt?.isRecommended">
        <img
          [src]="doubt.createdBy.imgUrl.url || userImg"
          class="user-img mr-2"
        />
        <span class="semibold username-text caption-1 sm-caption1">{{
          doubt && doubt.createdBy && doubt.createdBy.fullName
        }}</span>
      </div>
    </div>

    <!--Second row of doubt description-->
    <div class="my-2 break-words">
      <span class="medium comment-text body-2 font-medium sm-body2">
        {{ doubt.description | decodedHTML }}
      </span>
    </div>

    <!--Third row of same doubt button and report-->
    <div
      class="flex justify-between items-center my-1"
      *ngIf="!doubt?.isRecommended"
    >
      <div
        class="flex items-center caption-1 chip-samedoubt pr-2 pl-1 rounded-full"
      >
        <img [src]="sameDoubtDefault" class="sm-img" />
        <span class="semibold text-[#5a4bda] sm-caption1">{{
          doubt.sameDoubtCount
        }}</span>
      </div>
    </div>
  </section>

  <section class="flex items-center justify-center py-2 w-full bg-grey my-2">
    <span class="body-2 semibold sm-body2"
      >Total Answers({{ doubt.commentCount }})</span
    >
  </section>
  <!-- [ngClass]="{
      'warning-height': isBlock,
      'parmanent-height': chatInputBlocked
    }" -->
  <section
    class="py-2 px-2 md:px-3"
    [ngClass]="{
      'warning-height': isBlock || isBlockFromParent,
      'parmanent-height': chatInputBlocked || chatInputBlockedParent
    }"
  >
    <ng-container *ngIf="doubtSolution.length > 0">
      <div
        *ngFor="let item of doubtSolution; let i = index"
        [ngClass]="
          i !== doubtSolution.length - 1 && !doubt?.isRecommended
            ? 'border-bottom'
            : ''
        "
        class="mb-2"
      >
        <app-doubt-reply-card
          [doubtSolution]="item"
          (changeSection)="goToDoubtComments(item)"
          (feedback)="confirmationDialog($event)"
          (deleteComment)="deleteUserComment(item)"
          [coming_source]="coming_source"
          [doubt]="doubt"
        ></app-doubt-reply-card></div
    ></ng-container>
    <div
      *ngIf="!doubtSolution.length && !processing"
      class="flex items-center justify-center lg:mt-32"
    >
      <span class="body-1 flex justify-center semibold sm-body1"
        >No Answer yet!</span
      >
    </div>

    <div *ngIf="processing" class="flex justify-center">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </section>
</article>

<section
  class="w-full items-center flex justify-center"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
  *ngIf="confirmationText !== ''"
  [@fadeInOut]
>
  <div
    class="confirmation-text flex items-center justify-center relative py-2 px-3 sm-padding3"
  >
    <span>{{ confirmationText }}</span>
  </div>
</section>

<section
  *ngIf="previewImage"
  class="preview-image-container absolute flex justify-between p-2 mb-4"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
  [ngStyle]="{ bottom: previewImagePosition }"
>
  <div class="flex row">
    <img [src]="previewImage" class="preview-image" alt="image preview" />
    <div class="flex flex-col justify-center ml-3">
      <span class="body-2 sm-body2">Attachment File:</span>
      <span class="body-1 semibold sm-body1"> {{ selectedFile.name }}</span>
    </div>
  </div>
  <mat-icon
    class="flex items-center justify-center cursor-pointer"
    (click)="clearPreview()"
    >close</mat-icon
  >
</section>
<div
  [ngClass]="{
    'bg-white pb-2 new-doubt-input-section mb-10': !doubt?.isRecommended
  }"
>
  <app-profanity-alert-warning
    *ngIf="relevant"
    [irreleventMessage]="irreleventMessage"
  ></app-profanity-alert-warning>
  <app-block-warning
    *ngIf="isBlock || isBlockFromParent"
    [blockMessage]="blockMessage || blockMessageParent"
  ></app-block-warning>
</div>
<section
  class="bg-white py-1.5 new-doubt-input-section"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
  *ngIf="
    (!chatInputBlocked || !chatInputBlockedParent) && !doubt?.isRecommended
  "
>
  <div
    class="flex justify-between items-center new-doubt-input-container pr-2 pt-2"
    [ngClass]="isRecording ? 'pl-2 pb-1' : ''"
  >
    <ng-container *ngIf="!isRecording">
      <textarea
        (keyup)="autoGrowTextZone($event)"
        [(ngModel)]="textValue"
        #input
        aria-label="search text"
        class="new-doubt-input w-full pl-2 caption-1 semibold sm-caption1"
        inputmode="text"
        placeholder="You can also answer this doubt here..."
        type="text"
        [disabled]="isBlock || isBlockFromParent"
        [ngClass]="{
          'btn-primary': isBlock || isBlockFromParent,
          'btn-secondary': !isBlock || !isBlockFromParent
        }"
      ></textarea>
      <div
        class="relative cursor-pointer pb-1 pr-1 ml-0.5 flex flex-row items-center"
        [ngClass]="{
          'btn-primary': isBlockFromParent,
          'btn-secondary': !isBlockFromParent
        }"
      >
        <button
          [disabled]="isBlock || isBlockFromParent"
          [ngClass]="{
            'btn-primary': isBlock || isBlockFromParent,
            'btn-secondary': !isBlock || !isBlockFromParent
          }"
          (click)="fileInput.click()"
        >
          <img [src]="attachmentImg" alt="send" class="mr-1 sm-img" />
        </button>

        <input
          class="file-selection"
          type="file"
          (change)="onFileChanged($event)"
          (click)="onFileClick($event)"
          #fileInput
          [disabled]="isBlock || isBlockFromParent"
          [ngClass]="{
            'btn-primary': isBlock || isBlockFromParent,
            'btn-secondary': !isBlock || !isBlockFromParent
          }"
        />
        <button
          [disabled]="isBlock || isBlockFromParent"
          [ngClass]="{
            'btn-primary': isBlock || isBlockFromParent,
            'btn-secondary': !isBlock || !isBlockFromParent
          }"
          (click)="preparingData(input)"
          *ngIf="textValue !== '' || previewImage"
        >
          <img [src]="sendButton" alt="send" class="sm-img" />
        </button>
        <button
          [disabled]="isBlock || isBlockFromParent"
          [ngClass]="{
            'btn-primary': isBlock || isBlockFromParent,
            'btn-secondary': !isBlock || !isBlockFromParent
          }"
          (click)="startRecording()"
          *ngIf="textValue === '' && !previewImage"
        >
          <img [src]="mikeImg" alt="send" class="sm-img" />
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="isRecording">
      <img
        [src]="deleteRed"
        alt="delete recording"
        (click)="stopRecording('cancel')"
        class="cursor-pointer sm-img"
      />

      <audio
        controls
        controlsList="nodownload"
        *ngIf="readyToSend"
        class="set-audio-height"
      >
        <source [src]="blobUrl" type="audio/webm" />
      </audio>

      <div class="flex flex-row" *ngIf="!readyToSend">
        <img [src]="soundWave" alt="" class="mr-2 sm-img" />
        <span class="caption-1 sm-caption1">{{ recTimer | formatTime }}</span>
      </div>

      <img
        [src]="stopRec"
        alt="stop recording"
        (click)="stopRecording('stop')"
        class="cursor-pointer sm-img"
        *ngIf="!readyToSend"
      />
      <img
        class="cursor-pointer sm-img"
        [src]="sendButton"
        alt="send"
        (click)="sendRecording()"
        *ngIf="readyToSend"
      />
    </ng-container>
  </div>
</section>
<section
  class="bg-white py-1.5 px-1 md:px-2 new-doubt-input-section"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
  *ngIf="chatInputBlocked || chatInputBlockedParent"
>
  <div
    class="flex justify-between items-center new-doubt-input-container p-1.5"
  >
    <span class="block-input w-full pl-2 caption-1 semibold sm-caption1">
      {{
        inputBlockMessage
          ? inputBlockMessage
          : '' || inputBlockMessageParent
          ? inputBlockMessageParent
          : ''
      }}
    </span>
  </div>
</section>
