import { Injectable } from '@angular/core';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { catchError, debounceTime, map, retry } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import { FileModal } from '../base/base.modal';
import { APP_VERSION } from 'src/app/constants/global-constant/global-constant.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(
    private appUrl: AppUrlService,
    private apiService: PPApiService
  ) {}

  uploadFile(file: any) {
    let url = this.appUrl.IMAGE_UPLOAD();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(file, options)
      .pipe(retry(1), catchError(handleError));
  }

  sendRecording(data: any) {
    let url = this.appUrl.BASE64TOAUDIO();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  getVimeoDownloadUrl(query: any) {
    let url = this.appUrl.GET_VIMEO_DOWNLOAD_URL;
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams()
        .append('vimeoId', query.vimeoId)
        .append('type', query.type),
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), debounceTime(1000), catchError(handleError));
  }

  // Ask concepts
  getSuggestiveContent(query: any) {
    let url = this.appUrl.GET_SUGGESTIVE_CONTENT();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), debounceTime(3000), catchError(handleError));
  }

  getContentResult(query: any, saarthiId: string = '') {
    //   console.log("query : ",query);
    //   console.log("query.text : ",query.text);
    if (saarthiId.length > 0) {
      query = { ...query, saarthi_id: saarthiId };
    }
    let url = this.appUrl.GET_CONTENT_RESULT();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), debounceTime(3000), catchError(handleError));
  }

  //   getContentDetails(org_id: any, contentId: any) {
  getContentDetails(query: any) {
    let url = this.appUrl.GET_CONTENT_DETAILS();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), debounceTime(3000), catchError(handleError));
  }

  // Rating
  getRatingTags() {
    let url = this.appUrl.GET_RATING_TAGS();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  getTeacherRatingTags(query: any) {
    let url = this.appUrl.GET_TEACHER_RATING_TAGS();
    let options: PPApiOptions = { apiPath: url };
    options = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  getUserTeacherFeedback(data: any) {
    const url = this.appUrl.GET_MY_RATINGS_TEACHER_TAGS();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  getUserRatings(contentId: string) {
    const url = this.appUrl.GET_MY_RATINGS(contentId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  getReportingTags() {
    let url = this.appUrl.GET_REPORTING_TAGS();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  deleteComments(commentId: string) {
    const url = this.appUrl.CONTENT_COMMENT_ID(commentId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.delete<any>(options).pipe(catchError(handleError));
  }

  getComments(scheduleId: string, query: any) {
    let url = this.appUrl.GET_COMMENTS(scheduleId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  rateVideo(data: any) {
    let url = this.appUrl.POST_RATING();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  reportBatchComments(id: string, data: any) {
    let url = this.appUrl.BATCH_COMMENT_REPORT(id);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  toggleUpvote(contentId: string) {
    const url = this.appUrl.CONTENT_UPVOTE_COMMENT(contentId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.post<any>({}, options).pipe(catchError(handleError));
  }

  addComment(data: any) {
    let url = this.appUrl.ADD_COMMENT();
    const option: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, option)
      .pipe(catchError(handleError));
  }

  saveVideo(data: any) {
    const url = this.appUrl.SAVE_VIDEO();

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  savePdf(data: any) {
    const url = this.appUrl.SAVE_PDF();

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  setRating(data: any) {
    const url = this.appUrl.POST_RATING();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  addUpvotes(data: any) {
    const url = this.appUrl.CONTENT_UPVOTE_TOGGLE();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  getMyRatingByIds(data: any) {
    const url = this.appUrl.MY_RATINGS_BY_IDS();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(data),
    };
    return this.apiService.get<RatingRootObject>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }

  getUserDetailsList(data: any) {
    const url = this.appUrl.USER_DETAILS_LIST();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(data),
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }
  getDppCommentsList(typeId: string, queryParams?: any) {
    queryParams = queryParams || {};
    const options: PPApiOptions = {
      apiPath: this.appUrl.GET_DPP_COMMENTS_LIST(typeId),
      params: new PPApiParams().appendAll(queryParams),
    };
    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }

  addDppComment(data: any) {
    const url = this.appUrl.ADD_DPP_COMMENTS();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  reportDppComments(commentId: string, data: any) {
    const url = this.appUrl.REPORT_DPP_COMMENTS(commentId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  deleteDppComments(commentId: string, queryParams?: any) {
    queryParams = queryParams || {};
    const url = this.appUrl.DELETE_DPP_COMMENTS(commentId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(queryParams),
    };
    return this.apiService.delete<any>(options).pipe(catchError(handleError));
  }
}

export class CommentsModel {
  _id: string;
  text: string;
  upvoteCount: number;
  createdBy: CreatedBy;
  isLiked: boolean;
  createdAt: string;
  isPinned: boolean;
  audioId: FileModal;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.text = data.text || '';
    this.upvoteCount = data.upvoteCount || 0;
    if (data && data.createdBy) {
      this.createdBy = new CreatedBy(data.createdBy) || new CreatedBy({});
    } else {
      this.createdBy = new CreatedBy({});
    }
    this.isLiked = data.isLiked || false;
    this.createdAt = data.createdAt || '';
    this.isPinned = data.isPinned || false;
    this.audioId = new FileModal(data.audioId || {});
  }
}

export class CreatedBy {
  _id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  imgUrl: FileModal;
  fullName: string;
  name: string;
  networkData: any;

  constructor(data: any) {
    data = data || {};
    this.networkData = data;
    this.name = data.name || '';
    this._id = data._id || data.id || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.userName = data.username || '';
    this.email = data.email || '';
    this.imgUrl =
      new FileModal(data.image) ||
      new FileModal(data.imgUrl) ||
      new FileModal(data.img) ||
      new FileModal({});

    if (this.imgUrl._id.length === 0 && data.imageId) {
      this.imgUrl = new FileModal(data.imageId);
    }
    if (this.firstName.length > 0 && this.lastName.length > 0) {
      this.fullName = this.firstName + ' ' + this.lastName;
    } else {
      this.fullName = this.name;
    }
  }
}

export class SaveVideoStatsModal {
  batchId: string;
  batchSubjectId: string;
  chapterId: string;
  isComplete: boolean;
  lastWatchedPointInSec: number;
  programId: string;
  subTopicId: string;
  subjectId: string;
  topicId: string;
  type: string;
  typeId: string;
  userId: string;
  videoId: string;
  videoLength: number;
  tagId?: string;
  entryPoint?: string;
  watchSessions: Array<WatchSessionsModal> = [];
  constructor(props: any) {
    props = props || '';
    this.batchId = props.batchId || '';
    this.batchSubjectId = props.batchSubjectId || '';
    this.chapterId = props.chapterId || '';
    this.isComplete = props.isComplete || false;
    this.lastWatchedPointInSec = props.lastWatchedPointInSec || 0;
    this.programId = props.programId || '';
    this.subTopicId = '';
    this.subjectId = props.subjectId || '';
    this.topicId = props.topicId || '';
    this.type = props.type || '';
    this.typeId = props.typeId || '';
    this.userId = props.userId || '';
    this.videoId = props.videoId || '';
    this.tagId = props.tagId || '';
    this.videoLength = props.videoLength || 0;
    if (props.watchSessions) {
      props.watchSessions.forEach((item: any) =>
        this.watchSessions.push(new WatchSessionsModal(item))
      );
    } else {
      this.watchSessions = [];
    }
  }
}

export class WatchSessionsModal {
  deviceType: string;
  medium: string;
  isLive: boolean;
  videoType: string;
  clientVersion: number;
  watchStats: Array<WatchStatsModal> = [];
  constructor(props: any) {
    this.isLive = props.isLive || false;
    this.videoType = props.videoType || '';
    this.clientVersion = props.clientVersion || 0;
    props = props || {};
    this.deviceType = props.deviceType || 'Desktop';
    this.medium = props.medium || 'Web';
    if (props.watchStats) {
      props.watchStats.forEach((item: any) =>
        this.watchStats.push(new WatchStatsModal(item))
      );
    } else {
      this.watchStats = [];
    }
  }
}

export class WatchStatsModal {
  endTime: number;
  endTimeTimestamp: number;
  startTime: number;
  startTimeTimestamp: number;
  constructor(props: any) {
    props = props || {};
    this.endTime = props.endTime || 0;
    this.endTimeTimestamp = props.endTimeTimestamp || 0;
    this.startTime = props.startTime || 0;
    this.startTimeTimestamp = props.startTimeTimestamp || 0;
  }
}

export class PlayerEvent {
  play: boolean;
  pause: boolean;
  ended: boolean;
  duration: number;
  poster: string;
  volume: number;
  playTime: number;
  remainingTime: number;
  muted: boolean;
  fullScreen: boolean;

  constructor(data: any) {
    this.play = data.play || false;
    this.pause = data.pause || false;
    this.ended = data.ended || false;
    this.duration = Math.round(data.duration) || 0;
    this.poster = data.poster || '';
    this.volume = data.volumne || 0;
    this.playTime = Math.round(data.playTime) || 0;
    this.remainingTime = Math.round(data.remainingTime) || 0;
    this.muted = data.muted || false;
    this.fullScreen = data.fullScreen || false;
  }
}

export class SavePdfStatsModal {
  type: string;
  typeId: string | null;
  belongsTo: string;
  userId: string | null;
  batchId: string | null;
  programId: string | null;
  subjectId: string | null;
  batchSubjectId: string | null;
  chapterId: string | null;
  topicId: string | null;
  testId: string | null;
  testCategoryId: string | null;
  subTopicId: null;
  isDownloaded: boolean;
  downloadCount: number;
  sessions: Array<WatchPdfSessionsModal> = [];
  constructor(props: any) {
    props = props || {};
    this.type = props.type || null;
    this.typeId = props.typeId || null;
    this.belongsTo = props.belongsTo || null;
    this.userId = props.userId || null;
    this.programId = props.programId || null;
    this.subjectId = props.subjectId || null;
    this.batchSubjectId = props.batchSubjectId || null;
    this.chapterId = props.chapterId || null;
    this.topicId = props.topicId || null;
    this.testCategoryId = props.testCategoryId || null;
    this.testId = props.testId || null;
    this.subTopicId = props.subTopicId || null;
    this.isDownloaded = props.isDownloaded || false;
    this.downloadCount = props.downloadCount || 0;
    if (props.sessions) {
      props.sessions.forEach((item: any) => {
        this.sessions.push(new WatchPdfSessionsModal(item));
      });
    } else {
      this.sessions = [];
    }
  }
}

export class WatchPdfSessionsModal {
  medium: string;
  deviceType: string;
  networkOperator: string;
  startTimeTimestamp: number;
  endTimeTimestamp: number;
  constructor(props: any) {
    this.medium = props.medium || 'Web';
    this.deviceType = props.deviceType || 'Desktop';
    this.networkOperator = props.networkOperator || null;
    this.startTimeTimestamp = props.startTimeTimestamp || 0;
    this.endTimeTimestamp = props.endTimeTimestamp || 0;
  }
}

export interface RatingRootObject {
  success: boolean;
  data: RatingModel[];
}

export interface RatingModel {
  rating: string;
  type: string;
  status: string;
  _id: string;
  typeId: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  feedback: string;
  __v: number;
}
export class DppCommentsModel {
  _id: string;
  text: string;
  type: string;
  typeId: string;
  status: string;
  createdAt: string;
  childCommentCount: number;
  rated: number;
  reportCount: number;
  createdBy: CreatedBy;
  upVoteCount: number;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.text = data.text || '';
    this.type = data.type || '';
    this.typeId = data.typeId || '';
    this.status = data.status || '';
    this.createdAt = data.createdAt || '';
    this.childCommentCount = data.childCommentCount || 0;
    this.rated = data.rated || 0;
    this.reportCount = data.reportCount || 0;
    if (data && data.createdBy) {
      this.createdBy = new CreatedBy(data.createdBy);
    } else {
      this.createdBy = new CreatedBy({});
    }
    this.upVoteCount = data.upVoteCount || 0;
  }
}

export enum CommentType {
  COMMENT = 'Comments',
  THREAD = 'Thread',
}
export enum QueryType {
  DPP_VIDEOS = 'DPP_VIDEOS',
  DPP_VIDEOS_COMMENT = 'DPP_VIDEOS_COMMENTS',
}
export enum RatingType {
  VOTING = 'Voting',
}
export enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
