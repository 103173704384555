import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../common/services/global/global.service';

@Component({
  selector: 'app-cohort-dialog',
  templateUrl: './cohort-dialog.component.html',
  styleUrls: ['./cohort-dialog.component.scss'],
})
export class CohortDialogComponent implements OnInit {
  activeSection: string;

  constructor(
    private dialog: MatDialogRef<CohortDialogComponent>,
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: { openFrom: string }
  ) {
    if (data.openFrom === 'hamburger') this.activeSection = 'cohort-list';
    else this.activeSection = 'goal-list';
  }

  ngOnInit(): void {
    if (this.data.openFrom === 'batchList') {
      this.globalService.showSnackBar('Please update your preferences.');
      this.changeSection('cohort-list');
    }
  }

  changeSection(event: any): void {
    if (this.data.openFrom === 'hamburger') this.closeModal();
    else this.activeSection = event;
  }

  closeModal() {
    this.dialog.close();
  }
}
