import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { GlobalService } from 'src/app/common/services/global/global.service';

import {
  SaarthiDetails,
  SaarthiService,
} from 'src/app/common/services/saarthi/saarthi.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { SUBMIT_GOAL_CLICK } from 'src/app/core/analytics-events';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import {
  ADD_DESCRIPTION,
  GOALS_ADDED_SUCCESSFULLY,
  GOALS_UPDATED_SUCCESSFULLY,
  NO_DETAILS,
  SELECT_SUBJECT,
} from '../../snackbar.data';
import { CohortService } from 'src/app/common/services/cohort/cohort.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/common/services/loader/loader.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';

@Component({
  selector: 'app-add-new-goals',
  templateUrl: './add-new-goals.component.html',
  styleUrls: ['./add-new-goals.component.scss'],
  providers: [GlobalService],
})
export class AddNewGoalsComponent implements OnInit {
  addGoals: any = {};
  editBtn: any = false;
  today_date = new Date();
  userInfo: any;
  buttonClicked: boolean;
  saarthiId: string;
  cameFromStudy: string;
  subjects: Array<string> = [];
  saarthiDetails: SaarthiDetails;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddNewGoalsComponent>,
    public saarthiService: SaarthiService,
    public _globalService: GlobalService,
    public storageService: StorageService,
    public firebaseAnalytics: FirebaseAnalyticsService,
    private cohortService: CohortService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private globalService: GlobalService,
    private showErrorService: ShowErrorService
  ) {
    // console.log("modal data ",data);
    // this.saarthiId =
    //   this.activatedRoute.snapshot.queryParamMap.get('saarthi_id') || '';
    this.cameFromStudy =
      this.activatedRoute.snapshot.queryParamMap.get('came_from') || '';
    // if (this.cameFromStudy.toLocaleLowerCase() === 'batches') {
    //   this.setSaarthiDetails(this.saarthiId);
    // }
  }

  async ngOnInit() {
    if (
      this.cameFromStudy &&
      (this.cameFromStudy.toLocaleLowerCase() === 'study' ||
        this.cameFromStudy.toLocaleLowerCase() === 'batches')
    ) {
      await this.getSubjects();
    } else {
      const saarthiDetails = this.storageService.getSaarthiDetails();
      if (!this._globalService.isEmpty(saarthiDetails)) {
        saarthiDetails.subjects.forEach((data: any) => {
          this.subjects.push(data.name);
        });
      }
    }

    if (this.data.modal_type == 'edit') {
      this.addGoals.description = this.data.planner_data.description;

      this.addGoals.subject = this.data.planner_data.subject;
      if (!this.subjects.includes(this.data.planner_data.subject)) {
        this.subjects.push(this.data.planner_data.subject);
      }
      this.editBtn = true;
    }
    this.getUserInfo();
  }

  async setSaarthiDetails(saarthi_id: string) {
    this.loaderService.loadData('Loading...');
    try {
      const res = await lastValueFrom(
        this.saarthiService.getSaarthiDetails(saarthi_id)
      );

      this.saarthiDetails = new SaarthiDetails(res);
      if (this.saarthiDetails._id.length > 0) {
        this.storageService.setValue(
          'SAARTHI_ID',
          this.saarthiDetails._id,
          'string'
        );
        this.storageService.setSaarthiDetails(this.saarthiDetails);
      } else {
        this.globalService.showSnackBar(NO_DETAILS);
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.loaderService.unloadData('Loading...');
    }
  }

  getUserInfo() {
    const user = this._globalService.getUser().getValue();
    this.userInfo = typeof user === 'string' ? JSON.parse(user) : user;
  }

  onNoClick(data?: any): void {
    this.dialogRef.close({ data: data });
  }

  async getSubjects() {
    const cohortId = this.cohortService.getCohortIdFromLocal() || '';
    try {
      const res = await lastValueFrom(
        this.saarthiService.getPlannerSubjects(cohortId)
      );
      if (res) {
        this.subjects = res.data.map((res: any) => res.name);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async add_goals() {
    if (
      !this.addGoals.description ||
      this.addGoals.description == '' ||
      this.addGoals.description.trim() === ''
    ) {
      this.buttonClicked = false;
      this._globalService.showSnackBar(ADD_DESCRIPTION);
      return;
    } else {
      this.buttonClicked = true;
    }
    if (!this.addGoals.subject || this.addGoals.subject == '') {
      this.buttonClicked = false;
      this._globalService.showSnackBar(SELECT_SUBJECT);
      return;
    } else {
      this.buttonClicked = true;
    }

    this.addGoals.date = new Date(this.saarthiService.date);

    this.addGoals.date.setDate(this.addGoals.date.getDate() + 1);

    try {
      const response = await lastValueFrom(
        this.saarthiService.addGoals(this.addGoals)
      );
      if (response && response.success) {
        this._globalService.showSnackBar(GOALS_ADDED_SUCCESSFULLY);
        this.saarthiService.goalsAdded$.next(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.dialogRef.close();
    }

    const fata = {
      planner_date: this.saarthiService.date,
      goal_text: this.addGoals.description,
      subject: this.addGoals.subject,
      source: 'student',
      status: 'create',
    };
    this.saarthiService.logEvent(SUBMIT_GOAL_CLICK, fata);
  }

  async edit_goals() {
    if (!this.addGoals.subject || this.addGoals.subject == '') {
      this._globalService.showSnackBar(SELECT_SUBJECT);
      return;
    }
    if (!this.addGoals.description || this.addGoals.description == '') {
      this._globalService.showSnackBar(ADD_DESCRIPTION);
      return;
    }
    this.addGoals.date = new Date(this.saarthiService.date);
    this.addGoals.date.setDate(this.addGoals.date.getDate() + 1);

    this.addGoals.status = 'Active';
    this.addGoals.is_completed = this.data.planner_data.is_completed;
    this.addGoals.completed_date = this.today_date;
    try {
      const response = await lastValueFrom(
        this.saarthiService.editGoals(this.addGoals, this.data.planner_data._id)
      );
      this._globalService.showSnackBar(GOALS_UPDATED_SUCCESSFULLY);
      this.saarthiService.goalEdited$.next(true);
    } catch (error) {
      console.log(error);
    } finally {
      this.dialogRef.close();
    }
    const fata = {
      planner_date: this.saarthiService.date,
      goal_text: this.addGoals.description,
      subject: this.addGoals.subject,
      source: 'student',
      status: 'edit',
    };
    this.saarthiService.logEvent(SUBMIT_GOAL_CLICK, fata);
  }
}
