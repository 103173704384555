import { Component, OnInit } from '@angular/core';
import { ShowErrorService } from '../../../services/showError/show-error.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-error',
  templateUrl: './show-error.component.html',
  styleUrls: ['./show-error.component.scss'],
})
export class ShowErrorComponent implements OnInit {
  errorSub: Subscription;
  message: any;

  constructor(private showErrorService: ShowErrorService) {
    this.errorSub = this.showErrorService.errorMessage.subscribe((data) => {
      if (data) {
        this.message = data;
      }
    });
  }

  ngOnInit(): void {}
}
