import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from '../../services/global/global.service';
import { environment } from 'src/environments/environment';
import { GlobalObjectService } from '../../services/global-object/global-object.service';
import {
  ApplicationTypes,
  IFrameActions,
  IFrameMessageProps,
} from '../../services/learn-2-earn/learn-2-earn.service';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'learn-2-earn',
  templateUrl: './learn-2-earn.component.html',
  styleUrls: ['./learn-2-earn.component.scss'],
})
export class Learn2EarnComponent implements OnInit {
  url = environment.LEARN_2_EARN_LINK;
  constructor(
    public dialog: MatDialog,
    private globalService: GlobalService,
    private globalObjectService: GlobalObjectService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.globalObjectService.windowObj.addEventListener(
      'message',
      this.eventHandler
    );
    const cohortId = this.storageService.getValue('COHORT_ID', 'string') || '';
    const batchData = this.storageService.getValue('BATCH_DATA', 'json') || '';
    this.url =
      this.url +
      `authenticate?token=${this.globalService.accessToken}&cohortId=${cohortId}&batchId=${batchData?._id}`;
  }

  eventHandler = (event: MessageEvent<IFrameMessageProps>) => {
    if (
      event.data.action === IFrameActions.CLOSE_SIDE_BAR &&
      event.data.applicationType === ApplicationTypes.PW_LEARN_2_EARN
    ) {
      this.globalService.openLearnToEarnSideBar(false);
    }
  };
}
