import { Pipe, PipeTransform } from '@angular/core';
import { BANNER_IMG } from 'src/app/core/assets.location';

@Pipe({
  name: 'getImagePipe',
})
export class GetImagePipePipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    let url = '';
    if (value && (value.base_url || value?.baseUrl) && value?.key) {
      url = (value?.base_url || value?.baseUrl) + value?.key;
    }

    return url && url !== 'undefined' ? url : BANNER_IMG;
  }
}
