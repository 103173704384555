import { Component, Inject, OnInit } from '@angular/core';
import { CLOSE } from 'src/app/core/assets.location';
import { USER_IMG, VERIFIED } from '../../../../../../core/assets.location';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreatedBy } from '../../../../../feeds/feeds-tab/feeds.model';
import { ShowErrorService } from '../../../../../../common/services/showError/show-error.service';
import { GlobalService } from '../../../../../../common/services/global/global.service';
import { lastValueFrom } from 'rxjs';
import { DoubtsService } from '../../../../../../common/services/doubts/doubts.service';
import {
  ExpertInfoModel,
  StudentSolvingDetails,
  UserProfileData,
} from 'src/app/common/services/doubts/doubt.model';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { BatchService } from '../../../../../../common/services/batch/batch.service';
import { CreatedByModel } from '../../../../../../common/services/doubts/doubt.model';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';

@Component({
  selector: 'app-doubt-solver-modal',
  templateUrl: './doubt-solver-modal.component.html',
  styleUrls: ['./doubt-solver-modal.component.scss'],
})
export class DoubtSolverModalComponent implements OnInit {
  cross = CLOSE;
  userImg = USER_IMG;
  verified = VERIFIED;
  createdBy: CreatedByModel;
  expertInfo: ExpertInfoModel;
  solverImage: string = '';
  batchDetails: BatchDetailModel;
  processing: boolean = false;
  displayOrder: number;
  studentDetails: StudentSolvingDetails;
  userProfileData: UserProfileData;
  constructor(
    private dialogRef: MatDialogRef<DoubtSolverModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { createdBy: CreatedByModel; displayOrder: number },
    private showErrorService: ShowErrorService,
    private globalService: GlobalService,
    private doubtService: DoubtsService,
    private batchService: BatchService
  ) {
    this.createdBy = new CreatedByModel(data.createdBy || {});
    this.displayOrder = data.displayOrder;
    this.batchDetails = new BatchDetailModel({});
  }

  ngOnInit(): void {
    this.batchDetails = this.batchService.getBatchData();
    if (this.displayOrder === 2) this.getSmeData();
    else this.getStudentData();

    this.getStudentProfileDetails();
  }

  close() {
    this.dialogRef.close();
  }

  async getSmeData() {
    if (!this.createdBy._id || !this.batchDetails._id) {
      this.close();
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.SOMETHING_WENT_WRONG
      );
    }
    this.processing = true;
    try {
      const res = await lastValueFrom(
        this.doubtService.getExpertInfo(
          this.batchDetails._id,
          this.createdBy._id
        )
      );
      if (res) {
        this.expertInfo = new ExpertInfoModel(res);
        this.solverImage =
          this.expertInfo.imageId?.baseUrl + this.expertInfo.imageId?.key;
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }

  async getStudentData() {
    if (!this.createdBy._id) {
      this.close();
      this.globalService.showSnackBar(SNACKBAR_MESSAGE.SOMETHING_WENT_WRONG);
      return;
    }
    this.processing = true;
    try {
      const res = await lastValueFrom(
        this.doubtService.getStudentDetails({ userId: this.createdBy._id })
      );
      if (res) {
        this.studentDetails = new StudentSolvingDetails(res.data);
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }

  async getStudentProfileDetails() {
    if (!this.createdBy._id) {
      this.close();
      this.globalService.showSnackBar(SNACKBAR_MESSAGE.SOMETHING_WENT_WRONG);
      return;
    }
    this.processing = true;
    try {
      const userData = await lastValueFrom(
        this.doubtService.getUserProfileDetails(this.createdBy._id)
      );

      if (userData) {
        this.userProfileData = new UserProfileData(userData);
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }
}
