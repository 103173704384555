<mat-card class="card cursor-pointer flex items-center bg-white">
  <div class="flex flex-wrap overflow-hidden justify-center items-center">
    <div
      class="my-1 px-1 w-2/12 sm:w-1/12 overflow-hidden flex justify-center items-center"
    >
      <!-- Column Content -->
      <img [src]="quizImg" />
    </div>

    <div
      class="my-1 px-1 w-8/12 sm:w-10/12 overflow-hidden flex justify-start items-center"
    >
      <!-- Column Content -->
      <div class="flex flex-wrap -mx-1 overflow-hidden">
        <div class="w-full overflow-hidden">
          <!-- Column Content -->
          <div>
            <span class="test-title" [matTooltip]="test.name">
              {{ test.name | stringTruncate : 35 }}
            </span>

            <h4 class="test-meta medium" *ngIf="test.totalMarks > 0">
              {{
                test.enableNewNeetTestPatter
                  ? '720'
                  : test.enableNewJeeTestPatter
                  ? '300'
                  : test.totalMarks
              }}
              Marks
            </h4>
          </div>
        </div>

        <!-- <div class="w-full overflow-hidden"> -->
        <!-- Column Content -->
        <!-- <span class="text-left">Questions</span>
        </div> -->
      </div>
    </div>

    <div
      class="my-1 px-1 w-2/12 sm:w-1/12 overflow-hidden flex justify-center items-center"
    >
      <!-- Column Content -->
      <img [src]="quizArrowImg" />
    </div>
  </div>
</mat-card>
