import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportWidgetComponent } from './report-widget/report-widget.component';
import { PipesModule } from '../pipes/pipes.module';
import { SafePipe } from '../pipes/safe/safe.pipe';

@NgModule({
  declarations: [ReportWidgetComponent],
  imports: [CommonModule, PipesModule],
})
export class SharedModule {}
