import { LoaderService } from './../../services/loader/loader.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../services/global/global.service';
import { MatDialogRef } from '@angular/material/dialog';

import { EditprofileService } from '../../services/edit-profile/editprofile.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { FORM_TRIGGERS } from 'src/app/constants/form.constant';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-name-email-entry',
  templateUrl: './name-email-entry.component.html',
  styleUrls: ['./name-email-entry.component.scss'],
  providers: [],
})
export class NameEmailEntryComponent implements OnInit {
  @ViewChild('name') name: ElementRef;
  @ViewChild('email') email: ElementRef;
  userInfo: any;
  userName: string;
  userInfoSub: Subscription;

  constructor(
    private globalService: GlobalService,
    private dialog: MatDialogRef<NameEmailEntryComponent>,
    private loaderService: LoaderService,
    private editProfileService: EditprofileService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.getUser();

    this.globalService.handleFormList();
  }

  ngOnDestroy() {
    this.userInfoSub?.unsubscribe();
  }

  getUser() {
    // this.userInfoSub = this.globalService.getUser().subscribe((res) => {
    //   console.log(res);
    //   if (res) {
    //     this.userInfo = res;
    //     this.userName = this.userInfo.firstName || '';
    //   }
    // });

    if (typeof window !== 'undefined') {
      this.userInfo = JSON.parse(this.storageService.getUser() || '{}');

      this.userName = this.userInfo.firstName || '';
    }
  }

  submit(nameElement: any, emailElement: any) {
    const name = nameElement.value;
    const email = emailElement.value;
    const nameRegex = /^[a-zA-Z ]+$/;
    if (!nameRegex.test(name)) {
      this.globalService.showSnackBar('Please enter valid name');
      return;
    }
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      this.globalService.showSnackBar('Please enter valid email');
      return;
    }
    const data = {
      firstName: name,
      email,
    };
    this.setNameAndEmail(data);
  }

  close() {
    this.dialog.close();
  }

  async setNameAndEmail(data: any) {
    this.loaderService.loadData('Loading...');
    try {
      const res = await lastValueFrom(
        this.editProfileService.updateProfile(data)
      );

      if (res) {
        this.userInfo.firstName = data.firstName;
        this.userInfo.email = data.email;
        await this.globalService.setUser(this.userInfo);
        this.globalService.showSnackBar('Profile updated successfully...');
        this.close();
      }
    } catch (e: any) {
      this.globalService.showSnackBar(e);
    } finally {
      this.loaderService.unloadData('Loading...');
      this.close();

      this.globalService.triggerAdmitCard(FORM_TRIGGERS.BATCH);
    }
  }
}
