import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../services/storage/storage.service';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { BatchService } from '../../services/batch/batch.service';
import { PaymentService } from '../../services/payment/payment.service';

class DialogData {}

@Component({
  selector: 'app-purchase-alert',
  templateUrl: './purchase-alert.component.html',
  styleUrls: ['./purchase-alert.component.scss'],
})
export class PurchaseAlertComponent implements OnInit {
  batchId: string;
  batchDetail = new BatchDetailModel({});
  routeToNewFbt = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PurchaseAlertComponent>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private batchService: BatchService,
    private paymentService: PaymentService
  ) {
    this.batchId = this.data.batchId;
    this.batchDetail = this.storageService.getBatchData();
  }

  ngOnInit(): void {
    this.routeToNewFbt = this.paymentService._shouldRunNewFBT$.getValue();
  }

  purchase() {
    this.dialogRef.close();
    const isMahapack = this.storageService.getMahapackCheckData();
    if (isMahapack && isMahapack?.isMahapack) {
      this.router.navigate(
        [`batches/${isMahapack?.mahapack_url}/batch-overview/order-summary`],
        {
          queryParams: {
            ...this.activatedRoute.snapshot.queryParams,
            cameFrom: 'mahapack-details',
          },
        }
      );
      return;
    }

    if (this.batchDetail?.isBatchPlusEnabled) {
      this.batchService.openBatchPlanComparisonTable(
        this.batchDetail,
        window?.location?.pathname.includes('subjects')
          ? 'all_classes_subjects'
          : 'purchase-alert'
      );

      return;
    }

    if (this.routeToNewFbt) {
      this.dialogRef.close();
      const utmParamsString = this.storageService.getCampaignData() || '';

      const data = {
        batchSlug: this.batchDetail?._id as string,
        utmParamsString,
        cameFrom: 'purchase-alert',
        queryParams: this.data.route,
      };
      this.paymentService.redirectToNewFBT(data);
      return;
    }

    this.router.navigate(
      [`batches/${this.batchId}/batch-overview/order-summary`],
      {
        queryParams: {
          ...this.activatedRoute.snapshot.queryParams,
        },
      }
    );
  }
}
