import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { UpcomingInstalments } from 'src/app/common/services/batch/batch.modal';
import { PartPaymentService } from 'src/app/common/services/part-payment/part-payment.service';
import { CROSS_ICON } from 'src/app/core/assets.location';

@Component({
  selector: 'app-payment-reminder',
  templateUrl: './payment-reminder.component.html',
  styleUrls: ['./payment-reminder.component.scss'],
})
export class PaymentReminderComponent implements OnInit {
  crossIcon = CROSS_ICON;
  isMultipleEMIAvailable: boolean = false;
  instalmentsData: Array<UpcomingInstalments> = [];
  surl: string;
  furl: string;
  selectedCardId: string;
  ngOnInit(): void {}
  _isLoading$ = new BehaviorSubject<boolean>(false);
  emiSubText: string = '';
  pageName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialog: MatDialogRef<PaymentReminderComponent>,
    private _partPaymentService: PartPaymentService
  ) {
    this.instalmentsData = this.data?.instalments;
    this.selectedCardId = this.instalmentsData[0]?.batchId?._id || '';
    this.isMultipleEMIAvailable = this.instalmentsData.length > 1;
    this.surl = this.data?.surl;
    this.furl = this.data?.furl;
    this.pageName = this.data?.page;
    if (this.instalmentsData && !this.isMultipleEMIAvailable) {
      this.handleEMISubText(this.instalmentsData[0]?.daysLeft);
    }
  }

  handleEMISubText(daysLeft: number) {
    if (daysLeft === 0) {
      this.emiSubText = EMI_SUBTEXT.TODAY;
    } else if (daysLeft < 0) {
      this.emiSubText = EMI_SUBTEXT.OVERDUE;
    } else if (daysLeft > 0) {
      this.emiSubText = EMI_SUBTEXT.COMING_SOON;
    }
  }

  handleOnClose() {
    this.dialog.close();
  }

  handleEmiCardClick(batchId: string) {
    if (this.selectedCardId !== batchId) {
      this.selectedCardId = batchId;
    }
  }

  async handleEmiPayNowClick() {
    if (!this._isLoading$.getValue() && this.selectedCardId) {
      const emiDetail = this.instalmentsData?.find(
        (instalment: UpcomingInstalments) =>
          instalment?.batchId?._id == this.selectedCardId
      );
      this._isLoading$.next(true);
      await this._partPaymentService.handleEMIPayOnClick(
        {
          batchId: this.selectedCardId,
          surl: this.surl,
          furl: this.furl,
          installment_number: emiDetail?.installmentNumber,
          due_date: emiDetail?.dueDate,
          time_window: emiDetail?.daysLeft,
          page: this.pageName,
        },
        true
      );
      this._isLoading$.next(false);
    }
  }
}

enum EMI_SUBTEXT {
  TODAY = 'Your due date of EMI instalment is due today.',
  OVERDUE = 'You missed the due date of EMI instalment.',
  COMING_SOON = 'Your due date of EMI instalment is coming soon.',
}
