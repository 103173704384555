<section class="animate-fade-in-left pb-6" *ngIf="type === 'test-series'">
  <div *ngIf="testListing.length > 0">
    <div class="flex-1 px-1pt-4">
      <span
        class="heading-2 bold py-4 px-2 mb-12"
        *ngIf="title && title.length > 0"
        >{{ title }}</span
      >

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 p-2 bg-transparent">
        <app-test-card
          *ngFor="let test of testListing; let i = index"
          [test]="test"
          (click)="getTestStatus(test); handleAttachmentClick()"
        ></app-test-card>
      </div>
    </div>

    <div
      class="flex justify-center items-center my-8"
      *ngIf="testPaginate && !testProcessing"
    >
      <app-load-more
        [loading]="testProcessing"
        (loadMore)="loadMoreTest($event)"
      ></app-load-more>
    </div>
  </div>
  <div
    *ngIf="testListing.length == 0 && !testProcessing"
    class="w-full vertical-align animate-fade-in-left"
  >
    <img [src]="comingSoonImg" id="coming-soon" class="my-5" />
  </div>
</section>

<section class="animate-fade-in-left" *ngIf="type === 'dpp'">
  <div class="px-1" *ngIf="quizContents.length > 0 && !processing">
    <span
      class="heading-2 bold py-4 px-2 mb-12 text-color"
      *ngIf="title && title.length > 0"
      >{{ title }}</span
    >

    <div class="flex flex-col">
      <div *ngIf="tag === 'all'">
        <div *ngFor="let dppData of bgDppList">
          <div class="bg-dpp-container">
            <app-battle-ground-dpp-card [dppData]="dppData">
            </app-battle-ground-dpp-card>
            <div
              class="flex flex-col items-center gap-1"
              [ngClass]="[
                (dppData?.studentSideProgressStatus == 'ENDED' &&
                  dppData?.studentBattleStatus == 'NOT_STARTED') ||
                (!this.batchData.isPurchased && !dppData?.isSharingEnabled)
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              ]"
              (click)="joinBattle(dppData)"
            >
              <img
                [src]="rightBlueArrow"
                height="32"
                width="32"
                class="dpp-battle-arrow-icon"
              />
              <button
                *ngIf="
                  dppData?.studentSideProgressStatus == 'ENDED' ||
                  dppData?.studentBattleStatus == 'COMPLETED'
                "
                class="dpp-mobile-btn"
                [ngClass]="[
                  (dppData?.studentSideProgressStatus == 'ENDED' &&
                    dppData?.studentBattleStatus == 'NOT_STARTED') ||
                  (!this.batchData.isPurchased && !dppData?.isSharingEnabled)
                    ? 'button-disabled'
                    : ''
                ]"
              >
                View Result
              </button>
              <button
                *ngIf="
                  dppData?.studentSideProgressStatus != 'ENDED' &&
                  dppData?.studentBattleStatus != 'COMPLETED'
                "
                class="dpp-mobile-btn"
              >
                Join Battle
              </button>
              <p
                *ngIf="
                  dppData?.studentSideProgressStatus == 'ENDED' ||
                  dppData?.studentBattleStatus == 'COMPLETED'
                "
                class="join-battle"
              >
                View Result
              </p>
              <p
                *ngIf="
                  dppData?.studentSideProgressStatus != 'ENDED' &&
                  dppData?.studentBattleStatus != 'COMPLETED'
                "
                class="join-battle"
              >
                Join Battle
              </p>
            </div>
          </div>
        </div>
        <div
          class="flex justify-center items-center my-8"
          *ngIf="getMoreBGDPP && !processing"
        >
          <app-load-more
            [loading]="processing"
            (loadMore)="loadMoreBGDpp()"
          ></app-load-more>
        </div>
      </div>
      <div
        *ngFor="let quiz of quizContents; let i = index; trackBy: trackByMethod"
        class="mt-4 py-1 px-1 w-full cursor-pointer"
      >
        <app-quiz-card
          [isSubjective]="isSubjective"
          [quiz]="quiz"
          (click)="subjectiveDpp(quiz); handleAttachmentClick()"
        >
        </app-quiz-card>
      </div>
    </div>
    <div
      class="flex justify-center items-center my-8"
      *ngIf="getMoreDpps && !processing"
    >
      <app-load-more
        [loading]="processing"
        (loadMore)="loadMore($event)"
      ></app-load-more>
    </div>
  </div>

  <div
    class="w-full vertical-align animate-fade-in-left"
    *ngIf="quizContents?.length === 0 && bgDppList?.length === 0 && !processing"
  >
    <img [src]="comingSoonImg" id="coming-soon" class="my-5" />
  </div>
</section>

<!-- <div
  class="app-scroller-div px-1 mt-10"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="loadMore()"
  no-scrollbar
>  -->
<!-- infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="loadMore()"
  (scrollWindow]="false"-->
<!-- <section
    class="flex flex-wrap mx-1 overflow-hidden cursor-pointer"
    *ngIf="isDppSelected"
  >
    <div
      *ngFor="let quiz of quizContents; let i = index; trackBy: trackByMethod"
      class="my-2 py-1 px-1 w-full overflow-hidden"
    >
      <app-quiz-card [quiz]="quiz" (click)="startTest(quiz)"></app-quiz-card>
    </div>
    <div *ngIf="isSubjective">
      <button (click)="hello()">Redirect to Selective Page</button>
    </div>
  </section> -->
<!-- <section
    *ngIf="batchData && mainArray && mainArray.length > 0"
    class="animate-fade-in-left"
  >
    <div *ngFor="let item of mainArray">
      <div
        *ngFor="let test of item.testList; let i = index"
        [hidden]="item.processing"
      >
        <app-quiz-card
          [test]="test"
          (click)="getTestStatus(test)"
          [isDppSelected]="isDppSelected"
          [isTestSeriesSelected]="isTestSeriesSelected"
        ></app-quiz-card>
      </div>
      <div *ngIf="item.processing" class="mt-5">
        <div *ngFor="let item of [1, 1, 1, 1]" class="mb-2">
          <ngx-shimmer-loading
            height="100px"
            width="100%"
          ></ngx-shimmer-loading>
        </div>
      </div>
      <div
        *ngIf="!item.processing && item?.testList?.length === 0"
        class="w-full vertical-align mt-8"
      >
        <p class="heading-5 semibold">No Test Found...</p>
      </div>
    </div>
    <div *ngIf="isSubjective">
      <button (click)="subjectiveRedirect()">Redirect to Selective Page</button>
    </div>
  </section> -->
<!-- <section
    *ngIf="batchData && mainArray?.length === 0"
    class="mt-6 text-center"
  >
    <h1>No test found...</h1>
  </section> -->
<!-- </div>  -->
<!-- <div
    class="w-full vertical-align animate-fade-in-left"
    *ngIf="quizContents.length === 0 && !processing"
  >
    <img [src]="comingSoonImg" id="coming-soon" class="my-5" />
  </div> -->
