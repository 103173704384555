import { Injectable } from '@angular/core';
import { getDomainName } from '../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class CookieServiceService {
  config = {
    [CookieKeys.TOKEN_CONTEXT]: {
      expiryInSeconds: 60 * 60 * 24 * 7,
      domainsToShare: 'pw.live',
    },
    [CookieKeys.KEY_PAIR_ID]: {
      expiryInSeconds: 60 * 60 * 6,
      domainsToShare: 'pw.live',
    },
    [CookieKeys.POLICY]: {
      expiryInSeconds: 60 * 60 * 6,
      domainsToShare: 'pw.live',
    },
    [CookieKeys.SIGNATURE]: {
      expiryInSeconds: 60 * 60 * 6,
      domainsToShare: 'pw.live',
    },
  };
  constructor() {}

  getValue = (key: CookieKeys) => {
    let name = key + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        const val = c.substring(name.length, c.length);
        if (val) {
          return JSON.parse(val);
        }
        return '';
      }
    }
    return '';
  };
  setJSONValue = (key: CookieKeys, data: any = '') => {
    const _config = this.config[key];
    if (_config) {
      document.cookie = `${key}=${data}; expires=${new Date(
        new Date().getTime() + _config.expiryInSeconds * 1000
      ).toUTCString()}; domain:${_config.domainsToShare};path=/`;
    }
  };

  // setJSONValue = (key: CookieKeys, data: any = '') => {
  //   const _config = this.config[key];
  //   console.log(key, data);
  //   if (_config) {
  //     document.cookie = `${key}=${data};domain:pw.live;path=/`;
  //   }
  // };

  setValue = (key: CookieKeys, data: any = '') => {
    const _config = this.config[key];
    console.log(_config);
    if (_config) {
      document.cookie = `${key}=${JSON.stringify(data)}; expires=${new Date(
        new Date().getTime() + _config.expiryInSeconds * 1000
      ).toUTCString()}; domain:${_config.domainsToShare};path=/`;
    }
  };

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf('=');
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
    document.cookie = `TOKEN_CONTEXT=;domain=.${getDomainName()};expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}

export enum CookieKeys {
  'TOKEN_CONTEXT' = 'TOKEN_CONTEXT',
  'KEY_PAIR_ID' = 'CloudFront-Key-Pair-Id',
  'POLICY' = 'CloudFront-Policy',
  'SIGNATURE' = 'CloudFront-Signature',
}
