import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import { Doubt } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import {
  CommonDoubtModel,
  DoubtCommentModel,
  SlideModel,
} from '../../services/doubts/doubt.model';
import {
  DoubtsService,
  warningType,
} from '../../services/doubts/doubts.service';
import { GlobalService } from '../../services/global/global.service';
import { ShowErrorService } from '../../services/showError/show-error.service';

@Component({
  selector: 'app-doubt-modal',
  templateUrl: './doubt-modal.component.html',
  styleUrls: ['./doubt-modal.component.scss'],
})
export class DoubtModalComponent implements OnInit {
  doubt: CommonDoubtModel;
  activeSection: string = 'doubt answers';
  doubtSolution: DoubtCommentModel;
  addCommentIds: any;
  isBlock: boolean = false;
  blockMessage: string = '';
  inputBlockMessage: string = '';
  chatInputBlocked: boolean = false;
  coming_source = '';

  constructor(
    private dialog: MatDialogRef<DoubtModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      doubt: Doubt;
      scheduleId: string;
      batchSubjectId: string;
      chapterId: string;
      subjectId: string;
      batchId: string;
      doubtFromStorage: CommonDoubtModel;
      coming_source: string;
    },
    private globalService: GlobalService,
    private doubtsService: DoubtsService,
    private showErrorService: ShowErrorService
  ) {
    this.doubt = data?.doubt?.doubtId?.length
      ? new CommonDoubtModel(data.doubt)
      : data.doubtFromStorage;

    this.addCommentIds = {
      scheduleId: data.scheduleId,
      batchSubjectId: data.batchSubjectId,
      batchId: data.batchId, //will get in case of doubt notification
      chapterId: data.chapterId,
      subjectId: data.subjectId, //will get in case of doubt notification
    };
    this.coming_source = this.data?.coming_source;
  }

  ngOnInit(): void {
    this.getSlideDetails(this.doubt);
  }

  changeSection(answer?: DoubtCommentModel) {
    if (answer?._id) {
      this.activeSection = 'doubt comments';
      this.doubtSolution = answer;
    } else this.activeSection = 'doubt answers';
  }

  closeModal() {
    this.dialog.close();
  }

  async getSlideDetails(item: CommonDoubtModel) {
    this.isUserBlocked();
    if (!item.slideId)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.SOMETHING_WENT_WRONG
      );
    try {
      const res = await lastValueFrom(
        this.doubtsService.getSlideDetails(item.slideId)
      );
      if (res) {
        this.doubt.slide = new SlideModel(res['data']['slides'][0]);
      }
    } catch (e) {
      this.showErrorService.showError(e);
    }
  }
  async isUserBlocked() {
    this.chatInputBlocked = false;
    try {
      const res = await lastValueFrom(this.doubtsService.userIsBlocked());
      if (res && res['success'] && res['data']['warningType']) {
        if (res['data']['warningType'] === warningType.BLOCK) {
          this.blockMessage = res['data']['message'];
          this.isBlock = true;
        }
        if (res['data']['warningType'] === warningType.PERMANENTBLOCK) {
          this.chatInputBlocked = true;
          this.isBlock = false;
          this.inputBlockMessage = res['data']['message'];
        }
      }
    } catch (e: any) {
      if (e && !e['success']) {
        await this.showErrorService.showError(e);
      }
    }
  }
}
