<!-- <p>add-new-goal works!</p> -->
<div class="w-full flex items-center justify-center mb-6 mt-2">
  <span class="add-goal-text grow text-center">{{
    !editBtn ? 'Add Goal' : 'Edit Goal'
  }}</span>
  <mat-icon (click)="onNoClick()" class="clr-dark-black cursor-pointer">
    close</mat-icon
  >
</div>

<div class="w-full flex items-center justify-center mb-5">
  <textarea
    class="add-goal-text-area"
    cols="50"
    placeholder="Type here"
    rows="4"
    [(ngModel)]="addGoals.description"
  ></textarea>
</div>

<div class="w-full flex items-start justify-items-start mb-4">
  <span class="bold body-1 select-subject-text">Select Subject</span>
</div>

<article class="flex flex-wrap w-full">
  <div
    *ngFor="let subject of subjects"
    class="select-sub mb-3 flex items-center justify-center"
  >
    <span
      class="subject-space px-3 py-2 cursor-pointer"
      (click)="addGoals.subject = subject"
      [ngClass]="{ active_subject: addGoals.subject == subject }"
    >
      {{ subject | titlecase }}
    </span>
  </div>
</article>
<!--   (click)="!editBtn ? add_goals() : edit_goals()"  -->

<div class="w-full flex items-center justify-center mt-4">
  <button
    *ngIf="!editBtn"
    class="bold heading-5 add-goal-btn"
    mat-raised-button
    [disabled]="buttonClicked"
    (click)="add_goals()"
    id="add-goal_btn"
  >
    Add Goal
  </button>
  <button
    *ngIf="editBtn"
    class="bold heading-5 add-goal-btn"
    mat-raised-button
    (click)="edit_goals()"
    id="edit-btn"
  >
    Edit Goal
  </button>
</div>
