<section class="flex flex-col">
  <div class="flex items-center justify-between container_top">
    <span class="heading-4 bold">EMI Breakup</span>
    <img
      (click)="closeDialog()"
      class="close"
      [src]="close"
      alt="cancel"
      id="close_btn"
    />
  </div>

  <section class="flex flex-col gap-4 installment-card">
    <div class="flex flex-col gap-6">
      <app-all-instalments-table
        [instalmentData]="data.instalmentData"
      ></app-all-instalments-table>
      <button
        *ngIf="instalmentNumberToPay && !allEmisPaid"
        class="book-now-cta items-center justify-center"
        (click)="handleEMIPaymentClick()"
        [disabled]="(_isLoading$ | async) || shouldDisable"
        [ngClass]="{
          'opacity-40 cursor-not-allowed':
            (_isLoading$ | async) || shouldDisable,
          'batch-theme': !isMasterBatch,
          'master-theme': isMasterBatch
        }"
      >
        <span class="text-white"
          >Pay {{ instalmentNumberToPay | getOrdinalValue }} Instalment</span
        >
      </button>
    </div>
  </section>
</section>
