<div class="flex items-center justify-between w-full mb-2">
  <h5 class="modal-text w-full text-center">Change Exam & Class</h5>
  <img (click)="close()" [src]="closeicon" class="w-6 h-5 cursor-pointer" />
</div>

<div>
  <h5 class="modal-text-1 pt-4" *ngIf="activePlanModal.length > 0">
    Active Saarthi
  </h5>
  <div class="flex pt-4 overflow-x-auto no-scrollbar">
    <app-active-plan
      *ngFor="let item of activePlanModal; let i = index"
      [activeItem]="item"
      (click)="getActiveDetails(item)"
      [id]="'active-plan-' + i"
    ></app-active-plan>
  </div>
</div>

<div class="pt-2 flex flex-col">
  <div *ngIf="activePlanModal.length > 0">
    <hr />
    <p class="class-exam flex justify-center items-center pt-4">
      Other Saarthi
    </p>
  </div>

  <!-- <mat-form-field appearance="outline"> -->
  <mat-label class="body-1 semibold py-2 mt-5 text-base">Select Exam</mat-label>
  <mat-select class="width" [(ngModel)]="selectedExam">
    <mat-option
      class="hover padd body-2 semibold"
      *ngFor="let exam of examDetails"
      (click)="showClass(exam.exams)"
      [value]="exam.exams"
    >
      {{ exam.exams }}
    </mat-option>
  </mat-select>
  <!-- </mat-form-field> -->

  <!-- <mat-form-field appearance="outline"> -->
  <!-- (selectionChange)="selectClass($event)" -->
  <mat-label class="body-1 semibold py-2 mt-5 text-base"
    >Select Class</mat-label
  >
  <mat-select class="width" [(ngModel)]="selectedClass">
    <mat-option
      class="hover p-2 body-2 semibold"
      *ngFor="let class of classes?.classes; let i = index"
      [value]="class"
      (onSelectionChange)="selectClass($event)"
    >
      {{ class }}
    </mat-option>
  </mat-select>
  <mat-label class="body-1 semibold py-2 mt-5 text-base"
    >Select Time Slot</mat-label
  >
  <mat-select class="width" [(ngModel)]="selectedSlot">
    <mat-option
      class="hover p-2 body-2 semibold"
      *ngFor="let slot of timeSlots"
      [value]="slot"
      (onSelectionChange)="selectSlot($event)"
    >
      {{ slot }}
    </mat-option>
  </mat-select>
</div>
<div class="vertical-align items-center justify-center">
  <button class="continue h-16 w-60 mt-6" (click)="update()" id="continue_btn">
    Continue
  </button>
</div>
