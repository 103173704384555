<div class="flex items-center justify-between border-b pb-1">
  <h4 class="heading-4 semibold mb-2 pb-1 mx-6 text-[#1B2124]">Attachments</h4>

  <img
    (click)="onNoClick()"
    class="cursor-pointer p-2"
    [src]="dialogCloseImg"
  />
</div>

<div
  class="mt-4"
  *ngIf="
    scheduleData &&
    scheduleData.homeworkIds &&
    scheduleData.homeworkIds.length > 0
  "
>
  <h3 class="semibold my-5 text-[#1B2124]">Notes</h3>
  <div
    *ngFor="let item of scheduleData?.homeworkIds"
    class="pdf-link mt-2 px-2 py-1 cursor-pointer"
  >
    <div class="play-div flex items-center justify-between mx-2">
      <div
        class="flex vertical-align py-3 w-11/12 dpp-detail"
        (click)="item?.actions?.includes('Open') ? openContent(item) : null"
      >
        <img class="w-4 h-4 mr-3" [src]="fileImg" />
        <h5 class="dpp-topic break-words">
          {{ item.topic }}
        </h5>
      </div>

      <div>
        <img
          (click)="download(item)"
          class="w-5 h-5"
          [src]="downloadImg"
          *ngIf="item && item.actions && item?.actions?.includes('Download')"
        />
      </div>
    </div>
  </div>
</div>
<div
  class="mt-4"
  *ngIf="
    scheduleData &&
    scheduleData.exerciseIds &&
    scheduleData.exerciseIds.length > 0
  "
>
  <h3 class="semibold my-5 text-[#1B2124]">DPP</h3>
  <div
    *ngFor="let item of scheduleData.exerciseIds"
    class="pdf-link mt-2 px-2 py-1"
  >
    <div class="play-div py-1 px-1 flex items-center justify-between">
      <div class="w-full flex justify-between">
        <div class="flex items-center w-2/3">
          <img class="w-5 h-5 mr-2" [src]="exerciseImg" />

          <h5 class="dpp-topic break-words">
            {{ item.title }}
          </h5>
        </div>
        <button
          mat-flat-button
          color="primary"
          class="w-1/4 cursor-pointer"
          (click)="startExercise(item)"
        >
          <span class="flex items-center gap-1">
            Start DPP<mat-icon class="dpp-arrow">arrow_forward</mat-icon>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
