import { Component, Inject, OnInit, SimpleChanges } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { DoubtsService } from '../../services/doubts/doubts.service';
import { ShowErrorService } from '../../services/showError/show-error.service';
import { GlobalService } from '../../services/global/global.service';
import { LoaderService } from '../../services/loader/loader.service';
import { lastValueFrom } from 'rxjs';
import { AssistantDialogComponent } from '../../../pages/batch/batch-video-player/doubt/ask-doubt/personal-assistant-solution/assistant-dialog/assistant-dialog.component';
import { VideoSolModalComponent } from '../../../pages/batch/batch-video-player/doubt/video-sol-modal/video-sol-modal.component';
import { DoubtFeedbackDialogComponent } from '../doubt-feedback-dialog/doubt-feedback-dialog.component';
import { DoubtsHelper } from '../../services/doubts/doubts.helper';
import {
  DIALOG_CLOSE_ICON,
  DIALOG_CLOSE_IMG,
  DOUBT_IMG,
  DOWNLOAD_LIBRARY,
  SEND_BUTTON,
  SOLUTION_HELPFUL_IMG,
  VIEW_PDF_IMG,
  WAITING_IMG,
  WAITING_PERSONAL_ASSISTANT,
} from '../../../core/assets.location';
import { FileService } from '../../services/file-service/file-service.service';
import {
  DoubtConversationModel,
  DoubtListModel,
} from '../../services/doubts/doubt.model';
import { DoubtSubmitDialogComponent } from 'src/app/pages/batch/batch-video-player/doubt/ask-doubt/doubt-submit-dialog/doubt-submit-dialog.component';
import { INFORMATION } from '../../../core/assets.location';
import { SmeDetailsModalComponent } from 'src/app/pages/batch/batch-video-player/doubt/ask-doubt/personal-assistant-solution/sme-details-modal/sme-details-modal.component';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { BatchService } from '../../services/batch/batch.service';
import { SolutionModal } from 'src/app/pages/batch/batch-video-player/doubt/ask-doubt/personal-assistant-solution/soultion-modal/solution-modal.component';
import { StorageService } from '../../services/storage/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personal-assistant-dialog',
  templateUrl: './personal-assistant-dialog.component.html',
  styleUrls: ['./personal-assistant-dialog.component.scss'],
})
export class PersonalAssistantDialogComponent implements OnInit {
  /*conversation = [
    {
      _id: '6245eae45a3a8100182928a5',
      pinnedBy: [],
      status: 'Send',
      conversationId: '6245eae45a3a8100182928a1',
      sender: '5eb393ee95fab7468a79d18b',
      senderName: 'Admin',
      organizationId: '5eb393ee95fab7468a79d189',
      type: 'slidetxtSolution',
      text: 'sol 1',
      createdAt: '2022-03-31T17:54:44.709Z',
      updatedAt: '2022-03-31T17:54:44.709Z',
      __v: 0,
      senderInfo: {
        _id: '5eb393ee95fab7468a79d18b',
        username: '8081339191',
      },
    },
    {
      _id: '6245eae45a3a8100182928a4',
      pinnedBy: [],
      status: 'Send',
      conversationId: '6245eae45a3a8100182928a1',
      sender: '5eb393ee95fab7468a79d18b',
      senderName: 'Admin',
      organizationId: '5eb393ee95fab7468a79d189',
      type: 'slideUrlSolution',
      text: 'https://www.youtube.com/embed/T8pCYpAMY4o',
      createdAt: '2022-03-31T17:54:44.708Z',
      updatedAt: '2022-03-31T17:54:44.708Z',
      __v: 0,
      senderInfo: {
        _id: '5eb393ee95fab7468a79d18b',
        username: '8081339191',
      },
    },
    {
      _id: '6245eae45a3a8100182928a3',
      pinnedBy: [],
      status: 'Send',
      sender: '6195db42eb98c80211068ffe',
      senderName: 'desmond',
      text: 'slide 01 fejnc',
      type: 'text',
      organizationId: '5eb393ee95fab7468a79d189',
      conversationId: '6245eae45a3a8100182928a1',
      createdAt: '2022-03-31T17:54:44.699Z',
      updatedAt: '2022-03-31T17:54:44.699Z',
      __v: 0,
      senderInfo: {
        _id: '6195db42eb98c80211068ffe',
        username: '7503054655',
      },
    },
  ];*/
  // userConversation: Array<DoubtConversationModel> = [];
  // isReplied: boolean = false;
  // adminConversation: any;
  // processing: boolean;
  dialogControllerValue: string = '';
  isReplied: boolean = false;
  userInfo: any;
  processing: boolean;
  adminConversation: Array<DoubtConversationModel> = [];
  userConversation: Array<DoubtConversationModel> = [];
  allConversation: Array<DoubtConversationModel> = [];
  doubt: DoubtListModel;
  doubtHelper: DoubtsHelper;
  dialogCloseImg = DIALOG_CLOSE_ICON;
  closeImg = DIALOG_CLOSE_IMG;
  viewPdfImg = VIEW_PDF_IMG;
  downloadImg = DOWNLOAD_LIBRARY;
  showInputBox: boolean = false;
  sendButton = SEND_BUTTON;
  informationPic = INFORMATION;
  solutionHelpfulImg = SOLUTION_HELPFUL_IMG;
  waitingImg = WAITING_IMG;
  doubtImg = DOUBT_IMG;
  batchDetails: BatchDetailModel;
  firebaseToken: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PersonalAssistantDialogComponent>,
    private doubtService: DoubtsService,
    private showErrorService: ShowErrorService,
    private globalService: GlobalService,
    private loaderService: LoaderService,
    public dialog: MatDialog,
    private fileService: FileService,
    private batchService: BatchService,
    private dialogRefSme: MatDialog,
    private storageService: StorageService
  ) {
    /*const userChat = {
      _id: '6245eae45a3a8100182928a3',
      pinnedBy: [],
      status: 'Send',
      sender: '6195db42eb98c80211068ffe',
      senderName: 'desmond',
      text: 'What is haplodiplontic, oogamo… Life Cycle',
      type: 'text',
      organizationId: '5eb393ee95fab7468a79d189',
      conversationId: '6245eae45a3a8100182928a1',
      createdAt: '2022-03-31T17:54:44.699Z',
      updatedAt: '2022-03-31T17:54:44.699Z',
      __v: 0,
      senderInfo: {
        _id: '6195db42eb98c80211068ffe',
        username: '7503054655',
      },
    };
    this.userConversation.push(new DoubtConversationModel(userChat));

    const userDoubt = {
      uiStatus: 'Awaiting',
      status: 'unresolved gs',
      description: 'What is haplodiplontic, oogamo… Life Cycle',
    };

    this.doubt = new DoubtListModel(userDoubt);*/
    this.doubt = new DoubtListModel({});
    this.batchDetails = new BatchDetailModel({});
    this.doubtHelper = new DoubtsHelper(this.doubtService);
    this.firebaseToken = this.storageService.getDeviceId() || '';
  }

  ngOnInit(): void {
    if (this.data.cameFrom === 'batch-doubts') {
      this.doubt = this.data.doubt;
      this.getConversation(this.doubt.conversationId);
    } else {
      if (this.data.data.parentId) {
        this.doubt.parentId = this.data.data.parentId;
      }
      if (this.data.data && this.data.data.status) {
        this.doubt.status = this.data.data.status;
      }
      this.doubt.conversationId = this.data.conversationId;
      this.doubt.doubtId = this.data.doubtId;
      this.getDoubtDetails();
    }

    if (this.doubt.status.toLowerCase() === 'incomplete') {
      this.showInputBox = true;
    }
    this.getUserInfo();
    this.batchDetails = this.batchService.getBatchData();
  }

  getDoubtDetails() {
    if (this.doubt.doubtId) {
      const doubtNotifArray = JSON.parse(
        this.storageService.getDoubtNotifications() || ''
      );
      if (doubtNotifArray && doubtNotifArray.length > 0) {
        this.doubt = new DoubtListModel(
          doubtNotifArray.filter((el: any) => el._id === this.doubt.doubtId)[0]
        );
      }
      if (this.doubt && this.doubt.conversationId) {
        this.getConversation(this.doubt.conversationId);
      }
    }
  }

  getUserInfo() {
    this.userInfo = this.globalService.getUser().getValue();
  }

  onNoClick(data?: any): void {
    this.dialogRef.close();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['doubt'] && changes['doubt']['currentValue']) {
      this.doubt = changes['doubt']['currentValue'];
      await this.getConversation(this.doubt.conversationId);
    }
  }

  async getConversation(conversationId: string) {
    if (conversationId.length === 0) {
      return;
    }
    this.processing = true;
    this.adminConversation = new Array<DoubtConversationModel>();
    this.userConversation = new Array<DoubtConversationModel>();
    try {
      this.loaderService.loadData('Loading...');
      let list: Array<DoubtConversationModel> = [];
      const res: any = await lastValueFrom(
        this.doubtService.getDoubtChat(conversationId)
      );
      if (res && res['data'] && res['data'].length > 0) {
        list = res['data'].map((item: any) => new DoubtConversationModel(item));
      }

      this.allConversation = [...this.allConversation, ...list];

      this.adminConversation = [
        ...this.adminConversation,
        ...list.filter((item) => item.sender !== this.userInfo.id),
      ];
      this.userConversation = [
        ...this.userConversation,
        ...list.filter((item) => item.sender === this.userInfo.id),
      ];
      this.adminConversation = this.adminConversation.reverse();
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
      this.processing = false;
      this.loaderService.unloadData('Loading...');
      this.allConversation.sort(
        (b, a) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      // if (
      //   this.adminConversation.length > 0 &&
      //   this.doubt.status.toLowerCase().includes('awaiting')
      // ) {
      //   // this.doubt.status = 'Shared GS';
      //   if (this.doubt.status.toLowerCase() === 'unresolved ss') {
      //     this.doubt.uiStatus = 'Resolved';
      //   } else if (this.doubt.status.toLowerCase() === 'unresolved gs') {
      //     this.doubt.uiStatus = 'Awaiting';
      //   } else if (this.doubt.status.toLowerCase().includes('shared')) {
      //     this.doubt.uiStatus = 'View solution';
      //   } else {
      //     this.doubt.uiStatus = this.doubt.status;
      //   }
      // } else {
      //   // if (this.subscriptionData && this.subscriptionData['slideId']) {
      //   //   await this.subscribeToNotification(this.subscriptionData['slideId']);
      //   // } else {
      //   //   if (this.subscriptionData) {
      //   //   }
      //   // }
      // }
    }
  }

  openAssistantDialog(): any {
    return this.dialog.open(AssistantDialogComponent, {
      width: '328px',
      data: {
        dialogValue: this.dialogControllerValue,
      },
      backdropClass: 'backdropBackground',
    });
  }

  async doubtStatusChangeNew(feedback: string, message?: any) {
    let status;
    if (feedback === 'Yes') {
      if (
        this.adminConversation.length > 0 &&
        this.adminConversation[this.adminConversation.length - 1].type
          .toLowerCase()
          .includes('specific')
      ) {
        status = 'Resolved SS';
      } else if (
        this.adminConversation.length > 0 &&
        !this.adminConversation[this.adminConversation.length - 1].type
          .toLowerCase()
          .includes('specific')
      ) {
        status = 'Resolved GS';
      }
    } else if (feedback === 'No') {
      if (
        this.adminConversation.length > 0 &&
        this.adminConversation[this.adminConversation.length - 1].type
          .toLowerCase()
          .includes('specific')
      ) {
        status = 'Unresolved SS';
      } else if (
        this.adminConversation.length > 0 &&
        !this.adminConversation[this.adminConversation.length - 1].type
          .toLowerCase()
          .includes('specific')
      ) {
        status = 'Unresolved GS';
        // await this.subscribeToNotification(this.doubt._id);
      }
    }
    const data = {
      status: status,
      message: message.msg || '',
      tags: message.tags.join(','),
    };

    const loader = 'Please Wait...';
    await this.loaderService.loadData(loader);
    try {
      const res = await this.doubtService
        .updateDoubt(this.doubt.doubtId, false, data)
        .toPromise();
      if (res && res['success'] === true) {
        // @ts-ignore
        this.doubt.status = status;
        this.doubt = new DoubtListModel(this.doubt);
        this.doubtService.doubtStatusChange$.next(this.doubt);

        this.globalService.showSnackBar('Thanks for your feedback!!');
        //   if (feedback === 'Yes') {
        //     this.setAssistantDialogValYes()
        //   } else if (feedback === 'No') {
        //     await this.setAssistantDialogValNo();
        //   }
      }
      // this.events.publish('doubt-status', this.doubt);
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
      await this.loaderService.unloadData(loader);
    }
  }

  /* async setAssistantDialogValYes() {
     this.dialogControllerValue = 'yes';
     this.openAssistantDialog();
     await this.doubtStatusChangeNew('Yes');
   }

   async setAssistantDialogValNo() {
     if (
       this.adminConversation.length > 0 &&
       this.adminConversation[this.adminConversation.length - 1].type
         .toLowerCase()
         .includes('specific')
     ) {
       await this.doubtStatusChangeNew('No');
       return;
     } else if (
       this.adminConversation.length > 0 &&
       !this.adminConversation[this.adminConversation.length - 1].type
         .toLowerCase()
         .includes('specific')
     ) {
       this.dialogControllerValue = 'no';
       const dialogRef = this.openAssistantDialog();

       dialogRef.afterClosed().subscribe(async (result: any) => {
         if (result === 'ask-again') {
           await this.doubtStatusChangeNew('No');
         } else if (result === 'not-ask-again') {
           await this.doubtStatusChangeNew('Yes');
         }
       });
     }
   }*/

  openSolModal(url: string) {
    const data = {
      url,
      cssClass: 'video-sol-modal',
    };
    const dialogRef = this.dialog.open(VideoSolModalComponent, {
      panelClass: data.cssClass,
      data,
    });
  }

  async setAssistantDialogValYes() {
    const dialogRef = this.dialog.open(DoubtFeedbackDialogComponent, {
      panelClass: 'report-dialog',
      data: {
        dialogValue: 'yes',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      const msg = result;
      if (!msg || (msg && msg.msg.length === 0 && msg.tags.length === 0)) {
        this.globalService.showSnackBar('Please provide the issue');
      } else if (msg) {
        this.dialogControllerValue = 'yes';
        await this.doubtStatusChangeNew('Yes', msg);
      }
    });
  }

  async setAssistantDialogValNo() {
    const dialogRef = this.dialog.open(DoubtFeedbackDialogComponent, {
      panelClass: 'report-dialog',
      data: {
        dialogValue: 'no',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      const msg = result;
      if (!msg || (msg && msg.msg.length === 0 && msg.tags.length === 0)) {
        this.globalService.showSnackBar('Please provide the issue');
      } else if (msg) {
        if (
          this.adminConversation.length > 0 &&
          this.adminConversation[this.adminConversation.length - 1].type
            .toLowerCase()
            .includes('specific')
        ) {
          await this.doubtStatusChangeNew('No', msg);
          return;
        } else if (
          this.adminConversation.length > 0 &&
          !this.adminConversation[this.adminConversation.length - 1].type
            .toLowerCase()
            .includes('specific')
        ) {
          this.dialogControllerValue = 'no';
          const dialogRef = this.openAssistantDialog();

          dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result === 'ask-again') {
              await this.doubtStatusChangeNew('No', msg);
            } else if (result === 'not-ask-again') {
              await this.doubtStatusChangeNew('Yes', msg);
            }
          });
        }
      }
    });
  }

  async downloadSoln(fileUrl: string) {
    try {
      if (fileUrl.length > 0) {
        await this.fileService.downloadPdfDesktop(fileUrl, 'Solution');
        this.globalService.showSnackBar(
          'Solution Downloaded Successfully.',
          'Ok'
        );
      } else {
        await this.globalService.showSnackBar('Pdf not available', 'Ok');
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
    }
  }

  solutionModal(chat: any) {
    const dialogRef = this.dialog.open(SolutionModal, {
      data: {
        imageLink: chat.text,
      },
      panelClass: 'image-sol-modal',
    });
  }

  openSmeDialog(event: any, smeId: string) {
    let dialogRef = this.dialogRefSme.open(SmeDetailsModalComponent, {
      panelClass: 'sme-detail-modal',
      data: {
        batchId: this.batchDetails._id,
        teacherId: smeId,
      },
    });
  }

  async submitCompleteDoubt(msg: any) {
    if (!msg || !this.doubt.doubtId) {
      this.globalService.showSnackBar(
        'Something went wrong, Please try again later!'
      );
      return;
    } else {
      if (msg && msg.value && msg.value.length > 0) {
        const dialogRef = this.dialog.open(DoubtSubmitDialogComponent, {
          panelClass: 'doubts-submit-dialog',
        });

        dialogRef.afterClosed().subscribe(async (result) => {
          if (result && result === 'create-doubt') {
            const relevancy = await this.checkRelevancy(msg.value);
            if (relevancy) {
              let data = {
                doubtId: this.doubt.doubtId,
                completeDobut: msg.value,
              };
              try {
                const res = await lastValueFrom(
                  this.doubtService.postCompleteDoubt(data)
                );
                if (res) {
                  this.completeDoubt(data);
                  this.showInputBox = false;
                }
              } catch (e) {
                this.showErrorService.showError(e);
              }
            }
          }
        });
      } else {
        this.globalService.showSnackBar('Doubt cannot be blank');
      }
    }
  }

  completeDoubt(msg: any) {
    let localData: DoubtConversationModel = this.allConversation[0];
    localData.text = msg.completeDobut;
    localData.createdAt = new Date();
    this.allConversation.push(localData);
    this.doubt.status = 'awaiting';
  }

  async checkRelevancy(data: any) {
    let relevant: boolean = false;
    const check = {
      doubt: data.trim(),
    };
    try {
      const res = await lastValueFrom(
        this.doubtService.checkDoubtRelevancy(check)
      );
      if (res && res['success']) {
        relevant = true;
      }
    } catch (e) {
      await this.showErrorService.showError(e);
      relevant = false;
    } finally {
      return relevant;
    }
  }

  autoGrowTextZone(e: any) {
    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 10 + 'px';
  }

  async detectUrl(data: string, event: any) {
    event.preventDefault();
    if (data.includes('<!DOCTYPE html>')) {
      data = data.replace(/<(.|\n)*?>/g, '').trim();
    }
    data = this.extractUrl(data);

    const youtubeRegex =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const checkInput = youtubeRegex.test(data);

    if (checkInput) {
      this.openSolModal(data);
    }
  }

  extractUrl(data: string) {
    let urlRegex = /(https?:[^\s]+)/;
    let url: any = data.match(urlRegex);
    if (url) {
      url = url[1];
    }
    return url;
  }
}
