import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
  transform(string: string, length: number): any {
    return string.length > length
      ? string.substring(0, length) + '..'
      : string + ' ';
  }
}
