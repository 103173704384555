// connect

export const BLOCKED_BY_TEACHER = 'You have been blocked by the teacher!!!';
export const BLOCKED_MESSAGE = 'You have been blocked!!!';
export const ENTER_MESSAGE = 'Enter message to start conversation';
export const IMAGE_UPLOADED = 'Image uploaded succesfully';
export const IMAGE_NOT_UPLOADED = 'Image Is Not Uploaded';
export const AUDIO_UPLOADED = 'Audio File Uploaded Successfully';
export const AUDIO_NOT_UPLOADED = 'Audio File is Not Uploaded';
export const FILE_UPLOADED = 'File Uploaded Successfully';
export const FILE_NOT_UPLOADED = 'File is Not Uploaded';
export const UPLOAD_FILE = 'Please upload file';
export const DOWNLOADED_FILE =
  'Downloading file. Please check download section of your browser';

// Ask concept snackbar
export const SEARCH_CONCEPT_MESSAGE = 'Please type to Search Concept';
export const ERROR_WITH_DATA = 'Some error with the given data.';
export const ERROR_MESSAGE = 'Some Error Occured !';

// Exam Modal
export const SELECT_CLASS = 'Please Select Class';
export const SELECT_EXAM = 'Please Select Exam';

// Add New Goals
export const ADD_DESCRIPTION = 'Please Add Description to yours goals';
export const SELECT_SUBJECT = 'Please Select Subject';
export const GOALS_ADDED_SUCCESSFULLY =
  'Your Goal has been Added Successfully...';
export const GOALS_UPDATED_SUCCESSFULLY =
  'Your Goal has been Updated Successfully...';

// Plans Modals
export const ENROLLING_MESSAGE = 'Thanks for enrolling!!!';
export const PAYMENT_SUCCESSFULL = 'Payment Successful';
export const PAYMENT_UNSUCCESSFULL = 'Payment Unsuccessful';

// Teacher Rating
export const REMARKS = 'Please enter remarks';
export const FEEDBACK = 'Thanks for your valubale feedback';
export const DESCRIPTION = 'Please enter description';

// Landing page
export const FEATURE_COMING_SOON = 'Coming Soon!!!';
export const NO_DETAILS = 'No Details Found';
export const SELECT_SLOT = 'Please Select Slot';
