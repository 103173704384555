export class FileModal {
  _id: string;
  baseUrl: string;
  key: string;
  name: string;
  url: string;
  status: string;
  iconUrl: string;

  constructor(file: any) {
    file = file || {};
    this._id = file._id || '';
    if (file.base_url) {
      this.baseUrl = file.base_url || '';
    } else if (file.baseUrl) {
      this.baseUrl = file.baseUrl;
    } else {
      this.baseUrl = '';
    }

    this.key = file.key || '';
    this.name = file.name || '';
    this.url = this.baseUrl + this.key || '';
    this.status = '';
    this.iconUrl = '';
  }
}

export interface Paginate {
  totalCount: number;
  limit: number;
}

export class VideoDetailModal {
  id: string;
  name: string;
  title: string;
  image: string;
  thumbnail_url: string;
  embedCode: string;
  hls_url: string;
  videoUrl: string;
  vimeoId: string;
  videoId: string;
  status: string;
  description: string;
  duration: string;
  downloadStatus: string;
  types: Array<string> = [];
  video_id: string;
  vtt_url: string;
  createdAt: Date;
  // title: "1_Euclid’s Division Lemma.mp4"
  // duration: "00:04:49"
  // description: null
  // thumbnail_url: "https://i.vimeocdn.com/video/756778382_1280x960.jpg?r=pad"
  // hls_url: "https://player.vimeo.com/external/315092050.m3u8?s=b1bc1cb85bc5cc248bfc009f514a0c33f7b539dd"
  // video_id: "315092050"
  // vimeoId: "315092050"

  constructor(data: any) {
    const videoDetailData = data || {};
    this.id = videoDetailData.id || videoDetailData._id || '';
    this.name = videoDetailData.name || '';
    this.title = videoDetailData.title || '';
    this.image = videoDetailData.image || '';
    this.thumbnail_url = videoDetailData.thumbnail_url || '';
    this.embedCode = videoDetailData.embedCode || '';
    this.hls_url = videoDetailData.hls_url || '';
    this.videoUrl = videoDetailData.videoUrl || '';
    this.vimeoId = videoDetailData.vimeoId || '';
    this.videoId = videoDetailData.videoId || '';
    this.status = videoDetailData.status || 'ready';
    this.description = videoDetailData.description || '';
    this.duration = videoDetailData.duration || '';
    this.downloadStatus = videoDetailData.downloadStatus || '';
    this.types = videoDetailData.types || [];
    this.video_id = videoDetailData.video_id || '';
    this.vtt_url = videoDetailData.vtt_url || '';
    this.createdAt = videoDetailData.createdAt || new Date();
  }
}

export class TimelineModal {
  id: string;
  title: string;
  time: number;

  constructor(props: any) {
    props = props || {};
    this.id = props._id || '';
    this.title = props.title || '';
    this.time = props.time || 0;
  }
}
