import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PPApiConfig } from './api.type';
import { PP_API_CONFIG } from './api.constant';
import { PPApiService } from './api.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
})
export class PPApiModule {
  static forRoot(config: PPApiConfig): ModuleWithProviders<PPApiModule> {
    return {
      ngModule: PPApiModule,
      providers: [{ provide: PP_API_CONFIG, useValue: config }, PPApiService],
    };
  }
}
