import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getOrdinalValue',
})
export class GetOrdinalValuePipe implements PipeTransform {
  transform(value: number = 0, ...args: unknown[]): string {
    if (value === 0) return '0';

    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = value % 100;
    return value + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }
}
