import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { handleError } from 'src/app/common/services/error-handler/error-handler.service';
import { AppUrlService } from './../../app-url/app-url.service';
import { PPApiOptions } from './../../api/api.type';
import {
  BatchDetailModel,
  BatchStudentFee,
} from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  lastValueFrom,
  map,
} from 'rxjs';
import { PPApiService } from '../../api/api.service';
import {
  AfterPaymentOrder,
  Coupon,
  FbtFeeResponse,
  PackageList,
  SaarthiPlanList,
} from './payment.type';
import { PPApiParams } from '../../api/api.type';
import {
  AddMoreData,
  SelectedType,
} from 'src/app/pages/batch/new-order-summary/order-summary-components/add-more-wrapper/add-more-wrapper.component';
import { PlansModel } from '../saarthi/saarthi.service';
import { BatchService } from '../batch/batch.service';
import { GlobalService } from '../global/global.service';
import { THANKU_PAGE_EVENTS } from 'src/app/core/analytics-events';
import { StorageService } from '../storage/storage.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { BatchPlan, Plans } from '../batch/batch.modal';
import { FirebaseRemoteConfigService } from '../firebase-remote-config/firebase-remote-config.service';
import { Router } from '@angular/router';
import { Base64 } from '../../utils/encode-utilities';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private batchDetail$: BehaviorSubject<BatchDetailModel> =
    new BehaviorSubject<BatchDetailModel>(new BatchDetailModel({}));

  private feeId$: BehaviorSubject<BatchStudentFee> =
    new BehaviorSubject<BatchStudentFee>(new BatchStudentFee({}));
  private activePaymentKey$: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  private couponCode$: BehaviorSubject<Coupon> = new BehaviorSubject<Coupon>(
    new Coupon({})
  );

  private deliveryAddress$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private saarthiPlanList$: BehaviorSubject<SaarthiPlanList[]> =
    new BehaviorSubject<SaarthiPlanList[]>([]);

  private selectedIds$: BehaviorSubject<SelectedType[]> = new BehaviorSubject<
    SelectedType[]
  >([]);

  private saarthiPlans$: BehaviorSubject<PlansModel[]> = new BehaviorSubject<
    PlansModel[]
  >([]);

  private cartItems$: BehaviorSubject<CartItemsType[]> = new BehaviorSubject<
    CartItemsType[]
  >([]);

  private selectedCombo$: BehaviorSubject<PackageList> =
    new BehaviorSubject<PackageList>(new PackageList({}));

  private addOnsList$: BehaviorSubject<AddMoreData[]> = new BehaviorSubject<
    AddMoreData[]
  >([]);
  private walletApplied$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private combosData$: BehaviorSubject<PackageList[]> = new BehaviorSubject<
    PackageList[]
  >([]);

  private isWalletEnabled$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private maxWalletPoint$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  private addressEdited$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private changeCombo$: BehaviorSubject<SuggestionType> =
    new BehaviorSubject<SuggestionType>(<SuggestionType>{
      index: -1,
      type: 'none',
    });

  private walletPoints$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private isRegistrationEnded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private orderId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private showSuggetion$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private PaymentFeedback$: BehaviorSubject<AfterPaymentOrder> =
    new BehaviorSubject<AfterPaymentOrder>(new AfterPaymentOrder({}));

  private selectedPlans$: BehaviorSubject<Plans> = new BehaviorSubject<Plans>(
    new Plans({})
  );

  private isStoreItemInPlan$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private selectedBatchPlusPlan$: BehaviorSubject<BatchPlan> =
    new BehaviorSubject<BatchPlan>(new BatchPlan({}));

  private shouldRunNewFBT$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  private unleashOrder: UnleashDataType;
  private renderer: Renderer2;
  private clarityScriptId = 'clarity-script';
  constructor(
    private apiService: PPApiService,
    private appUrlService: AppUrlService,
    private _batchService: BatchService,
    private showErrorService: ShowErrorService,
    private storageService: StorageService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private remoteConfigService: FirebaseRemoteConfigService,
    private router: Router,
    private globalService: GlobalService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get _isStoreItemInPlan$() {
    return this.isStoreItemInPlan$;
  }

  get _walletPoint$() {
    return this.walletPoints$;
  }

  get _isRegistrationEnded() {
    return this.isRegistrationEnded$;
  }

  get _orderId$() {
    return this.orderId$.getValue();
  }

  get _maxWalletPoint$() {
    return this.maxWalletPoint$;
  }

  get _isWalletEnabled() {
    return this.isWalletEnabled$;
  }

  get _walletApplied$() {
    return this.walletApplied$;
  }

  get _addressEdited$() {
    return this.addressEdited$;
  }

  get _selectedCombo$() {
    return this.selectedCombo$;
  }

  get _couponCode$() {
    return this.couponCode$.asObservable();
  }

  get _batchDetail$() {
    return this.batchDetail$;
  }

  get _feeId$() {
    return this.feeId$;
  }

  get _activePaymentKey$() {
    return this.activePaymentKey$.getValue();
  }

  get getSaarthiPlanList() {
    return this.saarthiPlanList$;
  }

  get getDeliveryAddress() {
    return this.deliveryAddress$;
  }

  get getSelectedIds() {
    return this.selectedIds$;
  }

  get getSaarthiPlans() {
    return this.saarthiPlans$;
  }

  get getCartItems() {
    return this.cartItems$;
  }

  get getAddonsListData() {
    return this.addOnsList$;
  }

  get getCombosData() {
    return this.combosData$;
  }

  get getShowSuggetion() {
    return this.showSuggetion$;
  }

  get getChangeCombo() {
    return this.changeCombo$;
  }

  get getPaymentFeedback() {
    return this.PaymentFeedback$;
  }

  get getSelectedPlans() {
    return this.selectedPlans$;
  }

  setIsStoreItemInPlan(data: boolean) {
    this.isStoreItemInPlan$.next(data);
  }

  setWalletPoint(data: any) {
    this.walletPoints$.next(data);
  }

  setIsRegistrationEnded(data: boolean) {
    this.isRegistrationEnded$.next(data);
  }

  setOrderId(id: string) {
    this.orderId$.next(id);
  }

  setMaxWalletPoint(point: number) {
    this.maxWalletPoint$.next(point);
  }

  setIsWalletEnabled(data: any) {
    this.isWalletEnabled$.next(data);
  }

  setWalletApplied(data: boolean) {
    this.walletApplied$.next(data);
  }

  setAddressEdited(data: boolean) {
    this.addressEdited$.next(data);
  }

  setSelectedCombo(combo: PackageList) {
    this.selectedCombo$.next(combo);
  }

  setCouponCode(code: Coupon) {
    this.couponCode$.next(code);
  }

  setBatchDetail(data: BatchDetailModel) {
    this.batchDetail$.next(data);
  }

  setFeeId(id: BatchStudentFee) {
    this.feeId$.next(id);
  }

  setactivePaymentKey(data: string) {
    this.activePaymentKey$.next(data);
  }

  setSaarthiPlanList(data: SaarthiPlanList[]) {
    this.saarthiPlanList$.next(data);
  }

  setDeliveryAddress(data: any) {
    this.deliveryAddress$.next(data);
  }

  setSelectedIds(data: any) {
    this.selectedIds$.next(data);
  }

  setSaarthiPlans(data: PlansModel[]) {
    this.saarthiPlans$.next(data);
  }

  setCartItems(data: CartItemsType[]) {
    this.cartItems$.next(data);
  }

  setAddonsListData(data: AddMoreData[]) {
    this.addOnsList$.next(data);
  }

  setCombosData(data: PackageList[]) {
    this.combosData$.next(data);
  }

  setShowSuggetion(data: boolean) {
    this.showSuggetion$.next(data);
  }

  setChangeCombo(data: SuggestionType) {
    this.changeCombo$.next(data);
  }

  setPaymentFeedback(data: AfterPaymentOrder) {
    this.PaymentFeedback$.next(data);
  }

  setSelectedPlans(data: Plans) {
    this.selectedPlans$.next(data);
  }

  getPackageList(query: any) {
    let url = this.appUrlService.GET_PACKAGE_LIST();

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  getAddOnsList(batchId: string) {
    let url = this.appUrlService.GET_ADDONS_LIST(batchId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getCouponList() {
    let url = this.appUrlService.GET_COUPON_LIST();

    const option: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(option).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  createFbtFee(data: any) {
    let url = this.appUrlService.CREATE_FBT_FEE();

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<FbtFeeResponse>(data, options)
      .pipe(debounceTime(1000), catchError(handleError));
  }

  async enrollInFreeBatch(batchDetail: BatchDetailModel) {
    try {
      const res = await lastValueFrom(
        this._batchService.enrollStudent(batchDetail._id)
      );
    } catch (err) {
      this.showErrorService.showError(err);
    }
  }

  getDiscountedPrice(price: number, discountInPercentage: number) {
    const res = Math.floor(price - (price * discountInPercentage) / 100);
    return res;
  }

  getSelfLearningPlans(batchId: string, query?: any) {
    const q = query || {};
    let url = this.appUrlService.GET_SELF_LEARNING_PLANS(batchId);

    const option: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(q),
    };

    return this.apiService.get<any>(option).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  setSelectedBatchPlusPlan(plan: BatchPlan) {
    this.selectedBatchPlusPlan$.next(plan);
  }

  get _selectedBatchPlusPlan$() {
    return this.selectedBatchPlusPlan$;
  }

  reSetAllData() {
    this.setBatchDetail(new BatchDetailModel({}));
    this.setFeeId(new BatchStudentFee({}));
    this.setactivePaymentKey('');
    this.setCouponCode(new Coupon({}));
    this.setDeliveryAddress({});
    this.setSaarthiPlanList([]);
    this.setSelectedIds([]);
    this.setSaarthiPlans([]);
    this.setCartItems([]);
    this.setSelectedCombo(new PackageList({}));
    this.setAddonsListData([]);
    this.setIsWalletEnabled(null);
    this.setMaxWalletPoint(0);
    this.setAddressEdited(false);
    this.setOrderId('');
    this.setChangeCombo(<SuggestionType>{});
    this.setShowSuggetion(false);
    this.setIsRegistrationEnded(false);
    this.setSelectedPlans(new Plans({}));
  }

  logFirebaseEvent(event: any, isAddon?: boolean) {
    const { eventName } = event;
    const isItemAdded = this.getCartItems.getValue().length > 0 ? 'yes' : 'no';

    const eventData = {
      order_id: this.PaymentFeedback$.getValue()?.orderId || '',
      page_type: this.PaymentFeedback$.getValue()?.status || '',
    };
    const coming_source =
      this.storageService.getAfterPaymentComingSource() || '';

    switch (eventName) {
      case THANKU_PAGE_EVENTS.THANKYOU_ACTION_CLICK:
        this.logEvent(
          {
            ...eventData,
            action_click: event['action'],
            item_added: isItemAdded,
          },
          eventName,
          true
        );
        break;

      case THANKU_PAGE_EVENTS.ADD_ITEM:
        this.logEvent(eventData, eventName, true);
        break;

      case THANKU_PAGE_EVENTS.FAQ_CLICK:
        this.logEvent(
          {
            ...eventData,
            question: event['question'],
            item_added: isItemAdded,
          },
          eventName,
          true
        );
        break;

      case THANKU_PAGE_EVENTS.BUY_NOW_CLICKED:
        this.logEvent({ came_from: coming_source }, eventName, true);
        break;

      case THANKU_PAGE_EVENTS.PROCEED_TO_PAYMENT:
        this.logEvent({ came_from: coming_source }, eventName, true);
        break;

      case THANKU_PAGE_EVENTS.TRANSACTION_SUCCESS:
        this.logEvent({ came_from: coming_source }, eventName, true);
        break;
      default:
        this.logEvent(
          { ...eventData, items_present: isAddon ? 'yes' : 'no' },
          THANKU_PAGE_EVENTS.THANKYOU_PAGE_VISIT,
          true
        );
    }
  }

  logEvent(data: any, eventName: string, isUser: boolean) {
    this.firebaseAnalyticsService.logEvents(eventName, data, isUser);
  }

  setShouldRunNewFbt(val: boolean) {
    this.shouldRunNewFBT$.next(val);
  }

  get _shouldRunNewFBT$() {
    return this.shouldRunNewFBT$;
  }

  redirectToNewFBT(config: any, redirect_url: string = '/') {
    this.storageService.setLastVisitedUrl(this.router.url);
    const info = Base64.encode(JSON.stringify(config));
    this.router.navigate(['/payments'], {
      queryParams: {
        redirect_url: redirect_url,
        info,
      },
    });
  }

  getUnleashFeatureFlagApi(data: UnleashApiPayloadType) {
    const url = this.appUrlService.UNLEASH_FEATURE_FLAG();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post(data, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async unleashFeatureFlag(
    data: UnleashApiPayloadType
  ): Promise<UnleashDataType> {
    try {
      const res = await lastValueFrom(this.getUnleashFeatureFlagApi(data));
      if (res) {
        return <UnleashDataType>res;
      }
      return <UnleashDataType>{};
    } catch (error) {
      this.showErrorService.showError(error);
      return <UnleashDataType>{};
    }
  }

  async getOrderUnleashFeatureFlag(url: string): Promise<UnleashDataType> {
    const userInfo = this.globalService.getUser().getValue() || {};
    const unleashQuery: UnleashApiPayloadType = {
      userId: userInfo?.id || '',
      featureName: 'order-api-fe',
      experimentName: 'order-api-fe',
      customData: {
        id: '',
      },
      sendAnalytics: true,
    };
    if (!url.includes('test-series')) {
      return <UnleashDataType>{};
    }
    if (this.unleashOrder?.variantData) {
      return this.unleashOrder;
    } else {
      this.unleashOrder = await this.unleashFeatureFlag(unleashQuery);
      return this.unleashOrder;
    }
  }

  async getClarityFeatureFlag() {
    const userInfo = this.globalService.getUser().getValue() || {};
    const unleashQuery: UnleashApiPayloadType = {
      userId: userInfo?.id || '',
      featureName: 'clarity-fe-lms',
      experimentName: 'clarity-fe-lms',
      customData: {
        id: '',
      },
      sendAnalytics: true,
    };
    if (this.unleashOrder?.variantData) {
      return this.unleashOrder;
    } else {
      this.unleashOrder = await this.unleashFeatureFlag(unleashQuery);
      return this.unleashOrder;
    }
  }

  injectClarityScript(): void {
    if (document.getElementById(this.clarityScriptId)) {
      return;
    }
    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'id', this.clarityScriptId);
    const scriptContent = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "n9d05k6abu");
    `;
    this.renderer.setProperty(script, 'text', scriptContent);
    this.renderer.appendChild(document.head, script);
  }
}

export interface CartItemsType {
  name: string;
  price: number;
  img?: any;
  type: string;
  id: string;
  discount: number;
  deliveryCharge?: number;
  _id?: string;
  selectedSaarthiPlanId?: string;
  _idKhazana?: string;
}

export interface SuggestionType {
  index: number;
  type: string;
}

export interface UnleashDataType {
  isEnabled: boolean;
  variantData: VariantData;
}

export interface VariantData {
  name: string;
  payload: Payload;
  enabled: boolean;
  feature_enabled: boolean;
  featureEnabled: boolean;
}

export interface Payload {
  type: string;
  value: string;
}

export interface UnleashApiPayloadType {
  userId: string;
  featureName: string;
  experimentName: string;
  customData: any;
  sendAnalytics: boolean;
}

export const enum UNLEASH_PAYLOAD_ENUM {
  KHAZANA_ENABLED = 'KHAZANA_ENABLED',
  DESCRIPTION_ENABLED = 'DESCRIPTION_ENABLED',
  FBT_ENABLED = 'FBT_ENABLED',
  CLARITY_ENABLED = 'CLARITY_ENABLED',
  LISTING_ENABLED = 'LISTING_ENABLED',
}
