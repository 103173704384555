import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { BehaviorSubject, catchError, lastValueFrom, map } from 'rxjs';
import { AccessPendingCardComponent } from 'src/app/pages/batch/part-payment/components/access-pending-card/access-pending-card.component';
import { BatchActivationCardComponent } from 'src/app/pages/batch/part-payment/components/batch-activation-card/batch-activation-card.component';
import { EmiBreakupTableComponent } from 'src/app/pages/batch/part-payment/components/emi-breakup-table/emi-breakup-table.component';
import { FeeBreakupSideoverlayComponent } from 'src/app/pages/batch/part-payment/components/fee-breakup-sideoverlay/fee-breakup-sideoverlay.component';
import { AppUrlService } from '../../app-url/app-url.service';
import { BatchService } from '../batch/batch.service';
import {
  BatchDetailConfig,
  BatchDetailModel,
  EmiCreationEnum,
} from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { PPApiOptions } from '../../api/api.type';
import { handleError } from '../error-handler/error-handler.service';
import { PPApiService } from '../../api/api.service';
import {
  AllInstalmentResponse,
  BatchPlans,
  EMIInfoResponse,
  EzPayRequiredPayload,
  EzPayResponse,
  Instalment,
  UpcomingInstalmentData,
  UpcomingInstalmentResponse,
  UpcomingInstalments,
  UpcomingInstalmentsData,
} from '../batch/batch.modal';
import { GlobalService } from '../global/global.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import {
  PART_PAYMENT_POPUP,
  PAY_NOW_PART_PAYMENT_CLICK,
} from 'src/app/core/analytics-events';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PartPaymentService {
  userSegmentGA: string;
  emiTableCaption: string =
    '*First EMI amount is inclusive of Registration/Token Amount';

  private partPaymentFeatureFlag$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private prePurchasedFeeAndEmiStructure$: BehaviorSubject<BatchPlans> =
    new BehaviorSubject<BatchPlans>(new BatchPlans({}));

  private upcomingInstallment$: BehaviorSubject<UpcomingInstalmentResponse> =
    new BehaviorSubject<UpcomingInstalmentResponse>(
      new UpcomingInstalmentResponse({})
    );

  private allInstalments$: BehaviorSubject<AllInstalmentResponse> =
    new BehaviorSubject<AllInstalmentResponse>(new AllInstalmentResponse({}));

  public orderIdSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  private selectedPartPaymentOption$: BehaviorSubject<PartPaymentOptions> =
    new BehaviorSubject<PartPaymentOptions>(PartPaymentOptions.FULL);

  private shouldDisableForPartPayment$ = new BehaviorSubject<boolean>(false);

  constructor(
    private slideOver: NgDialogAnimationService,
    private dialog: MatDialog,
    private appUrlService: AppUrlService,
    private batchService: BatchService,
    private apiService: PPApiService,
    private _globalService: GlobalService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private storageService: StorageService
  ) {
    this.userSegmentGA = this._globalService.getUserSegmentFromStorage();
  }
  openModal(
    showBtn: boolean,
    event?: MouseEvent,
    plansData?: BatchPlans,
    callback?: () => void
  ) {
    this.slideOver.open(FeeBreakupSideoverlayComponent, {
      panelClass: 'fee-breakup',
      position: { right: '0px' },
      disableClose: false,
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
      },
      data: { showContinueBtn: showBtn, plansData, callback },
    });
    if (event) {
      event.stopPropagation();
    }
  }

  setOrderId(orderId: string): void {
    this.orderIdSubject$.next(orderId);
  }
  get _partPaymentFeatureEnabled$() {
    return this.partPaymentFeatureFlag$;
  }

  get _selectedPartPaymentOption$() {
    return this.selectedPartPaymentOption$;
  }

  get _shouldDisableForPartPayment$() {
    return this.shouldDisableForPartPayment$;
  }

  partPaymentFeatureEnabled(value: boolean) {
    this.partPaymentFeatureFlag$.next(value);
  }

  get _prePurchasedFeeAndEmiStructure$() {
    return this.prePurchasedFeeAndEmiStructure$;
  }

  setPrePurchasedFeeAndEmiStructure(data: BatchPlans) {
    this.prePurchasedFeeAndEmiStructure$.next(data);
  }

  get _upcomingInstallment$() {
    return this.upcomingInstallment$;
  }

  setUpcomingInstallment(data: UpcomingInstalmentResponse) {
    this.upcomingInstallment$.next(data);
  }

  setPartPaymentSelectedOption(data: PartPaymentOptions) {
    this.selectedPartPaymentOption$.next(data);
  }

  openOverlay(
    allInstalmentData: Instalment[],
    isMasterBatch: boolean,
    shouldDisable: boolean,
    allEmisPaid: boolean
  ) {
    this.slideOver.open(EmiBreakupTableComponent, {
      panelClass: 'premium-book-session',
      position: { right: '0px' },
      disableClose: false,
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
      },
      data: {
        instalmentData: allInstalmentData,
        isMasterBatch,
        shouldDisable,
        allEmisPaid,
      },
    });
  }

  setShouldDisableForPartPayment(value: boolean) {
    this.shouldDisableForPartPayment$.next(value);
  }

  showCardPopup(
    cardType: PART_PAYMENT_CARDS,
    isMasterBatch?: boolean,
    pageName?: string,
    instalmentData?: UpcomingInstalmentData
  ) {
    switch (cardType) {
      case PART_PAYMENT_CARDS.BATCH_ACTIVATION_CARD:
        return this.dialog.open(BatchActivationCardComponent, {
          width: '480px',
          data: { isMasterBatch },
        });
      case PART_PAYMENT_CARDS.ACCESS_BLOCKED_CARD:
        return this.dialog.open(AccessPendingCardComponent, {
          width: '480px',
          data: {
            type: PART_PAYMENT_CARDS.ACCESS_BLOCKED_CARD,
            isMasterBatch,
            pageName,
          },
        });
      case PART_PAYMENT_CARDS.BATCH_ACCESS_PENDING_CARD:
        return this.dialog.open(AccessPendingCardComponent, {
          width: '480px',
          data: {
            type: PART_PAYMENT_CARDS.BATCH_ACCESS_PENDING_CARD,
            isMasterBatch,
            pageName,
          },
        });
      case PART_PAYMENT_CARDS.ACCESS_BLOCKED_AND_PAY:
        return this.dialog.open(AccessPendingCardComponent, {
          width: '480px',
          data: {
            type: PART_PAYMENT_CARDS.ACCESS_BLOCKED_AND_PAY,
            isMasterBatch,
            payEnable: true,
            instalmentData,
            pageName,
          },
        });
      default:
        return null;
    }
  }

  handleBatchPopup(
    batchDetail: BatchDetailModel,
    upcomingInstalment: UpcomingInstalmentData,
    pageName: string
  ) {
    const {
      isAccessRevoked,
      contactSupport,
      emiCreation,
      isFormFilled,
      batchActivationFormLink,
    } = batchDetail?.config;
    const isMasterBatch = Boolean(batchDetail?.masterBatchId);
    if (emiCreation === EmiCreationEnum.FAILED) {
      this.showCardPopup(
        PART_PAYMENT_CARDS.BATCH_ACCESS_PENDING_CARD,
        isMasterBatch,
        pageName
      );
    } else if (!isFormFilled && batchActivationFormLink) {
      this.showCardPopup(
        PART_PAYMENT_CARDS.BATCH_ACTIVATION_CARD,
        isMasterBatch,
        pageName
      );
    } else if (isAccessRevoked) {
      this.showCardPopup(
        PART_PAYMENT_CARDS.ACCESS_BLOCKED_AND_PAY,
        isMasterBatch,
        pageName,
        upcomingInstalment
      );
    } else if (contactSupport) {
      this.showCardPopup(
        PART_PAYMENT_CARDS.ACCESS_BLOCKED_CARD,
        isMasterBatch,
        pageName
      );
    }
  }

  getBatchPlans(batchId: string) {
    const url = this.appUrlService.GET_BATCH_PLANS(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getUpcomingInstalment(batchId: string) {
    const url = this.appUrlService.GET_UPCOMING_INSTALMENT(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<UpcomingInstalmentResponse>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getAllInstalments(batchId: string) {
    const url = this.appUrlService.GET_ALL_INSTALMENTS(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<UpcomingInstalmentResponse>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getEmiInvoice(orderId: string) {
    const url = this.appUrlService.GET_EMI_INVOICE(orderId);

    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getEMIInfo(payload: { batchId: string; surl: string; furl: string }) {
    const url = this.appUrlService.GET_EMI_INFO();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post<any>(payload, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getEzPayUrl(payload: EMIInfoResponse) {
    const url = this.appUrlService.GET_EZ_PAY_SDK_URL();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.post<any>(payload, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async fetchEzPayInfo(res: EMIInfoResponse) {
    const data = new EMIInfoResponse(res);

    const ezPayRes = await lastValueFrom(this.getEzPayUrl(data));

    return new EzPayResponse(ezPayRes);
  }

  async fetchEMIInfo(payload: { batchId: string; surl: string; furl: string }) {
    const res = await lastValueFrom(this.getEMIInfo(payload));
    if (res) {
      const data = new EMIInfoResponse(res);

      const ezPayRes = await lastValueFrom(this.getEzPayUrl(data));

      return new EzPayResponse(ezPayRes);
    }

    return new EzPayResponse({});
  }

  fetchUpcomingInstallments() {
    const options: PPApiOptions = {
      apiPath: this.appUrlService.GET_UPCOMING_INSTALMENTS(),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async getUpcomingInstallmentsData() {
    const res = await lastValueFrom(this.fetchUpcomingInstallments());

    if (res) {
      return new UpcomingInstalmentsData(res);
    }

    return new UpcomingInstalmentsData(res);
  }

  shouldLockedForPartPayment(config: BatchDetailConfig) {
    const {
      isAccessRevoked,
      contactSupport,
      emiCreation,
      isFormFilled,
      batchActivationFormLink,
    } = config;
    return (
      isAccessRevoked ||
      contactSupport ||
      emiCreation === EmiCreationEnum.FAILED ||
      (!isFormFilled && batchActivationFormLink)
    );
  }

  async handleEMIPayOnClick(
    payload: EzPayRequiredPayload,
    isPopup: boolean = false
  ) {
    const eventData = {
      batch_id: payload?.batchId,
      installment_number: payload?.installment_number,
      due_date: payload?.due_date,
      time_window: payload?.time_window,
      user_type:
        this._globalService.getIsPathShalaFromStorage() === 'true'
          ? 'offline'
          : 'online',
      user_segment: this.userSegmentGA,
      is_popup: isPopup ? 'yes' : 'no',
      page: payload?.page,
    };
    this.firebaseAnalyticsService.logEvents(
      PAY_NOW_PART_PAYMENT_CLICK,
      eventData,
      false,
      true,
      true,
      true
    );
    await this._globalService.handleEMIPayment(payload);
  }

  isTimePassed(popUpExpiryTime: string): boolean {
    const getTimeDifference =
      new Date().getTime() - new Date(popUpExpiryTime).getTime();
    const hours = getTimeDifference / (1000 * 60 * 60);

    return hours >= 2.5;
  }

  logReminderEvent(installmentsData: UpcomingInstalmentsData, page: string) {
    const eventData = {
      user_type:
        this._globalService.getIsPathShalaFromStorage() === 'true'
          ? 'offline'
          : 'online',
      user_segment: this.userSegmentGA,
      batch_id: installmentsData?.instalments?.filter(
        (installments: UpcomingInstalments) => installments?.batchId
      ),
      installment_number: installmentsData?.instalments?.filter(
        (installments: UpcomingInstalments) => installments?.installmentNumber
      ),
      due_date: installmentsData?.instalments?.filter(
        (installments: UpcomingInstalments) => installments?.dueDate
      ),
      is_multi_installment:
        installmentsData?.instalments?.length > 1 ? 'yes' : 'no',
      page,
    };
    this.firebaseAnalyticsService.logEvents(
      PART_PAYMENT_POPUP,
      eventData,
      false,
      true,
      true,
      true
    );
  }

  async triggerPendingEMIInfo(
    surl: string = '',
    furl: string = '',
    page: string = ''
  ) {
    const res: UpcomingInstalmentsData =
      await this.getUpcomingInstallmentsData();
    this.logReminderEvent(res, page);
    const isPopExpiryTimeLogged =
      this.storageService.getShowEMIPopUpExpiryTime() as string;
    if (res.instalments.length > 0) {
      if (
        !isPopExpiryTimeLogged ||
        (isPopExpiryTimeLogged && this.isTimePassed(isPopExpiryTimeLogged))
      ) {
        this.storageService.setShowEMIPopUpExpiryTime(new Date().toISOString());
        this._globalService.showEMIReminder({
          isMultipleEMIAvailable: true,
          instalments: res.instalments,
          surl,
          furl,
          page,
        });
      }
    }
  }
}

export enum PART_PAYMENT_CARDS {
  BATCH_ACTIVATION_CARD = 'BATCH_ACTIVATION_CARD',
  BATCH_ACCESS_PENDING_CARD = 'BATCH_ACCESS_PENDING_CARD',
  ACCESS_BLOCKED_CARD = 'ACCESS_BLOCKED_CARD',
  ACCESS_BLOCKED_AND_PAY = 'ACCESS_BLOCKED_AND_PAY',
}

export enum PartPaymentOptions {
  EMI = 'PART',
  FULL = 'FULL',
}
