import { Pipe, PipeTransform } from '@angular/core';
import { StudyService } from 'src/app/common/services/study/study.service';

@Pipe({
  name: 'getVideoImg',
})
export class GetVideoImgPipe implements PipeTransform {
  constructor(private studyService: StudyService) {}

  transform(value: any, ...args: any[]): any {
    return this.studyService.getVideoImage(value);
  }
}
