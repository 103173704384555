import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../services/global/global.service';

@Component({
  selector: 'app-confirm-logout-dialog',
  templateUrl: './confirm-logout-dialog.component.html',
  styleUrls: ['./confirm-logout-dialog.component.scss'],
})
export class ConfirmLogoutDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmLogoutDialogComponent>,
    private _globalService: GlobalService
  ) {}

  ngOnInit(): void {}

  onSubmit(data: string) {
    this.dialogRef.close(data);
    this._globalService.removeSessionStorage();
  }
}
