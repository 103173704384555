<ng-container *ngIf="!couponProcessing && !applyCouponProcessing">
  <ng-container *ngIf="recommendedCoupon?._id; else noCoupon">
    <section
      class="coupon_container"
      [ngClass]="{ disabled: walletsApplied }"
      id="{{ batchDetail?._id }}"
    >
      <ng-container *ngIf="!couponApplied">
        <div
          class="coupon__top"
          [ngClass]="{
            borderBottom: !couponApplied
          }"
        >
          <div class="flex items-center gap-2">
            <img
              [src]="walletsApplied ? disabledDiscount : discountImg"
              alt=""
            />
            <div class="flex flex-col">
              <p
                class="body-2 medium leading-5"
                [ngClass]="{ gray: walletsApplied }"
                id="save_btn"
              >
                Save
                <span
                  class="semibold text-green"
                  [ngClass]="{ gray: walletsApplied }"
                  >₹ {{ recommendedCoupon.discountAmount }}</span
                >
                more on this order
              </p>
              <p
                class="bold caption-2 leading-4"
                [ngClass]="{ gray: walletsApplied }"
              >
                code:
                <span class="uppercase">{{
                  recommendedCoupon.couponCode
                }}</span>
              </p>
            </div>
          </div>
          <button
            class="bold"
            (click)="throttleButtonClick.next()"
            [disabled]="walletsApplied"
            id="{{ batchDetail?._id + '_' + 'apply_button' }}"
          >
            Apply
          </button>
        </div>
        <div
          class="coupon__bottom"
          role="button"
          (click)="openCouponDialog()"
          [ngClass]="{ 'cursor-not-allowed': walletsApplied }"
          id="view_all_btn"
        >
          <p class="semibold" [ngClass]="{ gray: walletsApplied }">
            View all Coupons
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M6.16023 11.8304C6.21068 11.8842 6.27061 11.9268 6.33659 11.9559C6.40257 11.985 6.4733 12 6.54473 12C6.61617 12 6.6869 11.985 6.75288 11.9559C6.81886 11.9268 6.87879 11.8842 6.92923 11.8304L10.5396 7.99234C10.5799 7.94961 10.6119 7.89886 10.6337 7.84298C10.6555 7.78711 10.6667 7.72722 10.6667 7.66673C10.6667 7.60624 10.6555 7.54634 10.6337 7.49047C10.6119 7.4346 10.5799 7.38385 10.5396 7.34112L6.92923 3.50307C6.71635 3.27676 6.37312 3.27676 6.16023 3.50307C5.94735 3.72938 5.94735 4.09425 6.16023 4.32056L9.30575 7.66904L6.15589 11.0175C5.94735 11.2392 5.94735 11.6087 6.16023 11.8304Z"
              fill="#414347"
              stroke="#414347"
              stroke-width="0.333333"
            />
          </svg>
        </div>
      </ng-container>

      <ng-container *ngIf="couponApplied">
        <div class="coupon__top">
          <div class="flex items-center gap-2">
            <img
              [src]="walletsApplied ? disabledDiscount : discountImg"
              alt=""
            />
            <div class="flex flex-col w-full">
              <ng-container
                *ngIf="couponResponse.refereeAmountType === 'CASHBACK'"
              >
                <span class="body-2 bold blue">
                  YAY! You'll get ₹
                  {{ couponResponse.refereeCashBackAmount }} cashback !
                </span>

                <p class="bold caption-2" id="applied_tag">
                  {{ couponResponse.coupon.couponCode }}
                  Applied!
                </p>
              </ng-container>

              <ng-container
                *ngIf="couponResponse.refereeAmountType !== 'CASHBACK'"
              >
                <ng-container
                  *ngIf="couponResponse?.coupon?.couponKind === 'MARKETING'"
                >
                  <p
                    class="body-2 bold blue"
                    [matTooltip]="couponResponse.coupon.name"
                    [matTooltipDisabled]="
                      couponResponse.coupon.name.length < 31
                    "
                  >
                    {{ couponResponse.coupon.name | truncateText : 31 }}
                  </p>
                  <p class="bold caption-2">Applied!</p>
                </ng-container>

                <ng-container
                  *ngIf="couponResponse?.coupon?.couponKind !== 'MARKETING'"
                >
                  <p class="body-2 bold blue">
                    YAY! You saved ₹ {{ couponResponse.discountedAmount }}!
                  </p>

                  <p class="bold caption-2" id="applied_tag">
                    {{ couponResponse.coupon.couponCode }}
                    Applied!
                  </p>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <button
            class="bold remove-btn"
            (click)="removeCoupon()"
            id="remove_btn"
          >
            Remove
          </button>
        </div>
      </ng-container>
    </section>
    <ng-container *ngIf="couponResponse.refereeAmountType === 'CASHBACK'">
      <div class="flex flex-col mt-1 pl-1">
        <div class="caption-1 medium cashback-message">
          *cashback amount will be directly credited into your bank account from
          <span class="refer-earn">refer & earn</span> page
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #noCoupon>
    <ng-container *ngIf="!couponApplied">
      <section
        class="coupon_container"
        [ngClass]="{ disabled: walletsApplied }"
      >
        <div
          class="coupon__top"
          [ngClass]="{
            borderBottom: !couponApplied
          }"
        >
          <div class="flex items-center gap-2">
            <img
              [src]="walletsApplied ? disabledDiscount : discountImg"
              alt=""
            />
            <div class="flex flex-col">
              <p
                class="body-2 medium leading-5"
                [ngClass]="{ gray: walletsApplied }"
                id="apply_coupon_btn"
              >
                Apply Code / Coupon
              </p>
              <p
                class="bold caption-2 leading-4"
                [ngClass]="{ gray: walletsApplied }"
              >
                no coupons available
              </p>
            </div>
          </div>
          <button
            class="bold disabled-btn"
            (click)="applyCoupon()"
            disabled
            id="apply_btn"
          >
            Apply
          </button>
        </div>
        <div
          class="coupon__bottom"
          role="button"
          (click)="openCouponDialog()"
          [ngClass]="{ 'cursor-not-allowed': walletsApplied }"
        >
          <p
            class="semibold"
            [ngClass]="{ gray: walletsApplied }"
            id="{{ batchDetail?._id + '_' + 'apply_coupon' }}"
          >
            Apply Coupon Code
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M6.16023 11.8304C6.21068 11.8842 6.27061 11.9268 6.33659 11.9559C6.40257 11.985 6.4733 12 6.54473 12C6.61617 12 6.6869 11.985 6.75288 11.9559C6.81886 11.9268 6.87879 11.8842 6.92923 11.8304L10.5396 7.99234C10.5799 7.94961 10.6119 7.89886 10.6337 7.84298C10.6555 7.78711 10.6667 7.72722 10.6667 7.66673C10.6667 7.60624 10.6555 7.54634 10.6337 7.49047C10.6119 7.4346 10.5799 7.38385 10.5396 7.34112L6.92923 3.50307C6.71635 3.27676 6.37312 3.27676 6.16023 3.50307C5.94735 3.72938 5.94735 4.09425 6.16023 4.32056L9.30575 7.66904L6.15589 11.0175C5.94735 11.2392 5.94735 11.6087 6.16023 11.8304Z"
              fill="#414347"
              stroke="#414347"
              stroke-width="0.333333"
            />
          </svg>
        </div>
      </section>
    </ng-container>

    <ng-container *ngIf="couponApplied">
      <section class="coupon_container">
        <div class="coupon__top">
          <div class="flex items-center gap-2">
            <img
              [src]="walletsApplied ? disabledDiscount : discountImg"
              alt=""
            />

            <div class="flex flex-col">
              <ng-container
                *ngIf="couponResponse.refereeAmountType === 'CASHBACK'"
              >
                <span class="body-2 bold blue">
                  YAY! You'll get ₹
                  {{ couponResponse.refereeCashBackAmount }} cashback !
                </span>
              </ng-container>

              <ng-container
                *ngIf="couponResponse.refereeAmountType !== 'CASHBACK'"
              >
                <p class="body-2 bold blue">
                  YAY! You saved ₹ {{ couponResponse.discountedAmount }}!
                </p>
              </ng-container>

              <span class="bold caption-2"
                >{{ couponResponse.coupon.couponCode }} Applied!</span
              >
            </div>
          </div>
          <button
            class="bold remove-btn"
            (click)="removeCoupon()"
            id="remove_btn"
          >
            Remove
          </button>
        </div>
      </section>
      <ng-container *ngIf="couponResponse.refereeAmountType === 'CASHBACK'">
        <div class="flex flex-col mt-1 pl-1">
          <div class="caption-1 medium cashback-message">
            *cashback amount will be directly credited into your bank account
            from
            <span class="refer-earn">refer & earn</span> page
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>

<ng-container *ngIf="couponProcessing">
  <section class="coupon_skeleton h-16">
    <div class="flex-1 flex flex-col gap-2 skeleton-text">
      <span class="skeleton line1"></span>
      <span class="skeleton line2"></span>
    </div>
    <div class="skeleton-btn skeleton"></div>
  </section>
</ng-container>

<ng-container *ngIf="applyCouponProcessing">
  <div class="coupon_loader h-16 px-3">
    <img [src]="walletsApplied ? disabledDiscount : discountImg" alt="" />
    <div class="flex items-center gap-4">
      <span class="semibold body-1" id="apply_btn"
        >Applying Coupon Code...</span
      >
      <div class="loader"></div>
    </div>
  </div>
</ng-container>
