<div
  class="flex items-center justify-between border rounded-xl py-2 px-3 bg-white gap-2.5 bg-cover"
  [style.background-image]="'url(' + nudgeBGimg + ')'"
  style="
    border-bottom: 2px solid #5e50ff5e;
    box-shadow: 0 2px 2px -2px #5e50ff5e;
  "
>
  <div class="flex flex-row gap-2 items-center justify-center">
    <div
      class="bg-[#FFF5E1] w-8 h-8 flex items-center justify-between rounded p-1"
    >
      <img
        [src]="saveMoneyLogo"
        alt="Donate for a cause"
        class="w-6 h-6 relative flex-col justify-start items-start flex"
      />
    </div>
    <div class="flex flex-col break-words hyphens-auto">
      <span
        class="font-semibold lg:text-sm text-xs text-neutral-700 leading-tight"
        >Donate to PW foundation.</span
      >
      <span class="font-bold text-xs">
        <span
          class="text-[#5A4BDA] w-[189px] text-xs font-bold leading-[18px] cursor-pointer"
          (click)="openDialog()"
        >
          Know more
        </span>
      </span>
    </div>
  </div>
  <div
    class="flex gap-2 items-center justify-between font-semibold text-sm lg:flex-row sm:flex-row"
  >
    <div
      class="h-7 px-4 py-1 bg-white rounded border border-gray-300 justify-center items-center gap-0.5 inline-flex text-neutral-700 text-sm"
    >
      ₹
      <div class="text-neutral-700 text-sm font-semibold leading-tight">
        {{ donationAmount ? donationAmount : 0 }}
      </div>
    </div>
    <div class="pb-[3px]">
      <mat-checkbox
        type="checkbox"
        [(ngModel)]="isChecked"
        [disabled]="false"
        (change)="emitCheckboxEvent($event)"
      >
      </mat-checkbox>
    </div>
  </div>
</div>
