import { Pipe, PipeTransform } from '@angular/core';
import { PartPaymentOptions } from '../../services/part-payment/part-payment.service';
import { PaymentModeText } from 'src/app/pages/batch/new-order-summary/order-summary-components/payment-card/payment-card.component';
@Pipe({
  name: 'paymentMode',
})
export class PaymentModePipe implements PipeTransform {
  transform(mode: string | null | undefined): string {
    if (!mode) {
      return '';
    }
    return `(${
      mode === PartPaymentOptions.EMI
        ? PaymentModeText.EMI
        : PaymentModeText.FULL
    })`;

    return `(${mode})`;
  }
}
