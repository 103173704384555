import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CLOSE } from 'src/app/core/assets.location';
import { GlobalService } from '../../../../../../common/services/global/global.service';
import { DoubtsService } from '../../../../../../common/services/doubts/doubts.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { ShowErrorService } from '../../../../../../common/services/showError/show-error.service';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';

@Component({
  selector: 'app-doubt-reply-feedback-modal',
  templateUrl: './doubt-reply-feedback-modal.component.html',
  styleUrls: ['./doubt-reply-feedback-modal.component.scss'],
})
export class DoubtReplyFeedbackModalComponent implements OnInit {
  cross = CLOSE;
  feedbackArray: Array<string> = [];
  reaction: string;
  tags: Array<string> = [];
  processing: boolean = false;
  constructor(
    private doubtService: DoubtsService,
    public dialogRef: MatDialogRef<DoubtReplyFeedbackModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reaction: string },
    private globalService: GlobalService,
    private showErrorService: ShowErrorService
  ) {
    this.reaction = data.reaction;
  }

  ngOnInit(): void {
    switch (this.reaction) {
      case 'report':
        this.getReportTags();
        break;
      case 'like':
        this.getSolutionTags({ status: 1 });
        break;
      case 'dislike':
        this.getSolutionTags({ status: 0 });
        break;
    }
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  async getReportTags() {
    this.processing = true;
    try {
      const res = await lastValueFrom(this.doubtService.getReportTags());
      if (res && res.success) {
        let list: Array<string> = [];
        list = res.data.map((items) => items);

        this.tags = [...this.tags, ...list];
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }

  async getSolutionTags(status: object) {
    this.processing = true;
    try {
      const res = await lastValueFrom(
        this.doubtService.getSolutionTags(status)
      );
      if (res && res.success) {
        let list: Array<string> = [];
        list = res.data.map((items) => items);

        this.tags = [...this.tags, ...list];
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }

  addCheckboxes(feedback: string) {
    if (this.feedbackArray.includes(feedback)) {
      let index = this.feedbackArray.indexOf(feedback);
      this.feedbackArray.splice(index, 1);
    } else {
      this.feedbackArray.push(feedback);
    }
  }

  submit(message: HTMLTextAreaElement) {
    if (this.feedbackArray.length === 0)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.PLEASE_PROVIDE_ISSUE
      );
    if (
      !message.value &&
      this.feedbackArray.some((res) => res.toLowerCase() === 'others')
    )
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.PLEASE_PROVIDE_ISSUE
      );

    let data = {};
    if (this.reaction === 'report') {
      data = {
        message: this.feedbackArray.join('_') + '_' + message.value,
      };
    } else {
      let tagObject = {};
      for (let i = 0; i < this.feedbackArray.length; i++) {
        if (this.feedbackArray[i].toLowerCase() !== 'others') {
          let localObj = { [this.feedbackArray[i]]: this.feedbackArray[i] };
          tagObject = { ...tagObject, ...localObj };
        }
      }
      data = {
        ratingType: 'Voting',
        type: 'DOUBTS',
        ratingValue: this.reaction === 'like' ? 1 : 0,
        tag: tagObject,
        feedback: message.value,
      };
    }
    this.close(data);
  }
}
