import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SEND_BUTTON } from '../../../core/assets.location';
import { lastValueFrom } from 'rxjs';
import {
  DoubtsService,
  warningType,
} from '../../services/doubts/doubts.service';
import { ShowErrorService } from '../../services/showError/show-error.service';
import { GlobalService } from '../../services/global/global.service';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';

@Component({
  selector: 'app-doubt-input-box',
  templateUrl: './doubt-input-box.component.html',
  styleUrls: ['./doubt-input-box.component.scss'],
})
export class DoubtInputBoxComponent implements OnInit {
  @Input('placeHolderText') placeHolderText: string;
  @Input('isBlockFromParent') isBlockFromParent: boolean = false;
  @Output('inputData') inputData: EventEmitter<string> =
    new EventEmitter<string>();
  @Input('chatInputBlockedParent') chatInputBlockedParent: boolean = false;
  @Input('inputBlockMessageParent') inputBlockMessageParent: string;
  @Input() coming_source: string;
  sendButton = SEND_BUTTON;
  constructor(
    public doubtsService: DoubtsService,
    private showErrorService: ShowErrorService,
    private globalService: GlobalService
  ) {}
  isSending: boolean = false;

  ngOnInit() {
    console.log(this.isBlockFromParent);
  }

  autoGrowTextZone(e: any) {
    if (e.keyCode === 13 && !e.shiftKey) this.sendInput(e.target);
    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 10 + 'px';
  }
  async sendInput(input: HTMLTextAreaElement) {
    this.inputData.emit(input.value);
    input.value = '';
    input.style.height = '30px';
  }
}
