export const REFER_AND_EARN_ROUTES = {
  VOUCHER_PAGE: ':product_id/discount-voucher',
  WALLET_PAGE: 'pw-wallet/:slug',
  FAQ_PAGE: 'faq',
  REFERRAL_REDEEM_NOW: `referral-new/redeem-now`,
  REDEEM_NOW: 'redeem-now',
  TERMS_AND_CONDITIONS: 'tnc',

  FAQ: 'faq',
  VIDEO_PLAYER: 'video-player-public',
  WALLET: 'referral-new/pw-wallet',
  DISCOUNT_VOUCHER_PAGE: ':product_id/discount-voucher',
  DISCOUNT_VOUCHER_ROUTE: (productId: string) =>
    `referral-new/${productId}/discount-voucher`,
};
export const STUDENT_MASTER_PROGRAM_ROUTES = {
  VIDEO_PLAYER: 'video-player-public',
};

export enum REFER_AND_EARN_ROUTE_PARAMS {
  REFER = 'refer',
  USER_WALLET = 'user-wallet',
  UPI_BANK_TRANSFER = 'upi-bank-transfer',
  BANK_TRANSFER = 'bank-transfer',
}

export const SAARTHI_ROUTES = {
  VIDEO_PLAYER: 'video-player-public',
};
