import { Component, Input, OnInit } from '@angular/core';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { lastValueFrom } from 'rxjs';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { RED_DOT_LOTTIE } from 'src/app/core/assets.location';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-quick-access-tile',
  templateUrl: './quick-access-tile.component.html',
  styleUrls: ['./quick-access-tile.component.scss'],
})
export class QuickAccessTileComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() image: string;
  @Input() isBattleGroundWidget: boolean;
  @Input() cameFrom?: string;
  battleStatus: boolean;
  battleNudgeText: string;
  cohortId: string;
  redDotLottie: AnimationOptions = {
    path: RED_DOT_LOTTIE,
  };
  constructor(
    private batchService: BatchService,
    private storageService: StorageService
  ) {
    this.cohortId = this.storageService.getValue('COHORT_ID', 'string') || '';
  }

  ngOnInit(): void {
    this.getBattleStatus();
  }

  async getBattleStatus() {
    try {
      if (this?.cohortId && this?.batchService && this.isBattleGroundWidget) {
        const res = await lastValueFrom(
          this.batchService.getBattleStatus(this?.cohortId || '')
        );
        this.battleNudgeText = res?.status;
        this.battleStatus = res?.isBattlegroundActive;
      }
    } catch (err) {
      console.error(err);
    }
  }
}
