export enum FORM_TRIGGERS {
  STUDY = 'study',
  BATCH = 'batch',
  MY_BATCHES = 'my_batches',
  CLASSROOM = 'classroom',
  KHAZANA = 'khazana',
  BATCH_TEST_SERIES = 'batch_test_series',
  TEST_SERIES = 'test_series',
  HAMBURGER = 'hamburger',
  BATCH_SPECIFIC = 'batch_specific',
  SUBJECT = 'subject',
  CHAPTER = 'chapter',
  PROFILE = 'profile',
}
export enum SELECTED_TAB {
  TESTS = 'Tests',
  KHAZANA = 'Khazana',
  TEST_SERIES = 'Test Series',
  ALL_CLASSES = 'All Classes',
}
export enum FORMS {
  ADMIT_CARD = 'Admit card 1', // got this name from v1/form api ,
}
