import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { ORGANIZATION_ID } from 'src/app/constants/app.constant';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions } from '../../api/api.type';
import { handleError } from '../error-handler/error-handler.service';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentKeyService {
  paymentKeys: Array<PaymentKeyModal> = [];

  constructor(
    private apiService: PPApiService,
    private globalService: GlobalService
  ) {}

  getPaymentKeyInfo(orgId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/organizations/${orgId}/payment-info`,
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }

  async getPaymentKeys() {
    return await this.getKeysFromServer();
  }

  async getKeysFromServer() {
    this.paymentKeys = [];
    try {
      const res = await this.getPaymentKeyInfo(ORGANIZATION_ID).toPromise();
      const keys: any = [];
      if (res && res['data']) {
        res['data'].forEach((item: any) => {
          keys.push(new PaymentKeyModal(item));
        });
      }

      this.paymentKeys = [...this.paymentKeys, ...keys];
      await this.globalService.setPaymentKeys(this.paymentKeys);
      return this.paymentKeys;
    } catch (e) {
      console.log(e);
    }
    return;
  }
}

export class PaymentKeyModal {
  id: string;
  name: string;
  paymentSource: string;

  constructor(props: any) {
    this.id = props.id || '';
    this.name = props.name || '';
    this.paymentSource = props.paymentKeys || '';
  }
}

export class PaymentKeyObject {
  success: boolean;
  data: PaymentKeyModal[];
}
