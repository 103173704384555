import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, lastValueFrom, map } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import { ShowErrorService } from '../showError/show-error.service';
import { PPApiService } from '../../api/api.service';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';

@Injectable({
  providedIn: 'root',
})
export class UnderMaintenanceService {
  env = environment;
  outageFlagValue: boolean = false;
  constructor(
    private http: HttpClient,
    private showErrorService: ShowErrorService,
    private appUrlService: AppUrlService,
    private apiService: PPApiService
  ) {}

  getFlagStatus(): Observable<StatusObject> {
    const url = this.env.UNDER_MAINTENANCE;

    return this.http.get<StatusObject>(url).pipe(
      map((res) => res),
      catchError(handleError)
    );
  }
  async getStatus() {
    try {
      const res = await lastValueFrom(this.getFlagStatus());
      this.maintenanceMapper(res);
    } catch (e) {
      console.error(e);
      this.maintenanceMapper(e);
    } finally {
    }
  }

  maintenanceMapper(data: any) {
    if (data && data['maintenance']) {
      this.outageFlagValue =
        typeof data['maintenance'] === 'string'
          ? JSON.parse(data['maintenance'])
          : data['maintenance'];
    }
  }

  getOutageFlagValue(): boolean {
    return this.outageFlagValue;
  }

  getMockApi() {
    const url = 'https://httpstat.us/503';
    return this.http.get<MockObject>(url).pipe(
      map((res) => res),
      catchError(handleError)
    );
  }

  async getErrorMockApi() {
    try {
      const res = await lastValueFrom(this.getMockApi());
      console.log(res);
    } catch (e) {
      console.error(e);
    } finally {
    }
  }
}

export interface StatusObject {
  maintenance: string | boolean;
}

export interface MockObject {
  flag: any;
}
