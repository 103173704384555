import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  DPP_QUIZ_ICON,
  QUIZ_ARROW_IMG,
  REPLAY_ICON,
  RESUME_ICON,
  START_IMG,
  XP_ICON,
} from 'src/app/core/assets.location';
import { BatchDppQuiz } from 'src/app/common/services/batch/batch.modal';
import { Learn2EarnService } from 'src/app/common/services/learn-2-earn/learn-2-earn.service';

@Component({
  selector: 'app-quiz-card',
  templateUrl: './quiz-card.component.html',
  styleUrls: ['./quiz-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuizCardComponent implements OnInit {
  @Input('quiz') quiz: BatchDppQuiz;
  @Input() isSubjective: boolean;
  replayImg = REPLAY_ICON;
  resumeImg = RESUME_ICON;
  startImg = START_IMG;
  quizImg = DPP_QUIZ_ICON;
  iconType = ICON_TYPE;
  quizArrowImg = QUIZ_ARROW_IMG;
  xpIcon = XP_ICON;
  earnUptoXp: number;
  constructor(public learn2EarnService: Learn2EarnService) {}

  ngOnInit(): void {
    this.getLearn2EarnXPData();
  }

  getLearn2EarnXPData() {
    this.learn2EarnService.getConfigDataSubscribe().subscribe(
      (res) => {
        if (this.quiz.tag === this.iconType.REATTEMPT) {
          this.earnUptoXp =
            this.quiz.test.totalQuestions * res?.featureDetails?.DPP_RE_ATTEMPT;
          return;
        }
        this.earnUptoXp =
          this.quiz.test.totalQuestions *
          res?.featureDetails?.DPP_FIRST_ATTEMPT;
      },
      () => {
        console.log('Unable to get user menu value');
      }
    );
  }
}

export enum ICON_TYPE {
  START = 'Start',
  RESUME = 'Resume',
  REATTEMPT = 'Reattempt',
}
