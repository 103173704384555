import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFloorValue',
})
export class GetFloorValuePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    if (!value) return 0;
    return Math.floor(value);
  }
}
