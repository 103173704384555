import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class WebinarService {
  private webinarPage$: BehaviorSubject<IFrameMessageProps[]> =
    new BehaviorSubject<IFrameMessageProps[]>([]);

  constructor(private storageService: StorageService) {}
  setWebinarPageArray(data: IFrameMessageProps[]) {
    this.storageService.setWebinarPageArray(data);
    this.webinarPage$.next(data);
  }
  get getWebinarPageArray() {
    const data = this.storageService.getWebinarPageArray() || [];
    if (data.length > 0) {
      this.setWebinarPageArray(data);
    }
    return this.webinarPage$;
  }

  removeWebinarPageArray() {
    this.storageService.removeWebinarPageArray();
    this.webinarPage$.next([]);
  }
}

export enum IFrameActions {
  CHILD_NAVIGATION = 'CHILD_NAVIGATION',
  PARENT_NAVIAGTION = 'PARENT_NAVIAGTION',
  LOCALSTORAGE = 'LOCALSTORAGE',
  CHILD_NAVIGATION_BACK = 'CHILD_NAVIGATION_BACK',
}

export enum ApplicationTypes {
  PW_WEBINARS = 'PW_WEBINARS',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: any;
  applicationType: ApplicationTypes;
}
