import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalObjectService {
  elem: any;
  constructor(
    @Inject(DOCUMENT) private docRef: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private documentWin: any
  ) {}

  get windowObj() {
    return window;
  }

  get window() {
    return this.docRef.defaultView;
  }

  get screen() {
    return screen;
  }

  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    }
  }

  get navigator() {
    return this.this.docRef.defaultView?.navigator;
  }

  get document() {
    return this.docRef;
  }

  get this() {
    return this;
  }

  openFullscreen(): any {
    const elem: any = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.fullscreenElement) {
      if (this.documentWin.webkitExitFullscreen) {
        this.documentWin.webkitExitFullscreen();
      }
      if (this.documentWin.exitFullscreen !== null) {
        this.documentWin.exitFullscreen();
      } else if (this.documentWin.mozCancelFullScreen !== null) {
        /* Firefox */
        this.documentWin.mozCancelFullScreen();
      } else if (this.documentWin.webkitExitFullscreen !== null) {
        /* Chrome, Safari and Opera */
        this.documentWin.webkitExitFullscreen();
      } else if (this.documentWin.msExitFullscreen !== null) {
        /* IE/Edge */
        this.documentWin.msExitFullscreen();
      }
    }
  }
}
