import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { ORGANIZATION_ID } from '../../../constants/app.constant';

interface UserData {
  primaryNumber?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
}

@Injectable()
export class FinzyService {
  private readonly clientId = ORGANIZATION_ID;
  private phoneNumber = '';
  private name = '';

  constructor(private storageService: StorageService) {
    this.initializeUserData();
  }

  handleNavigation(url: string): void {
    const userData = {
      phone: this.phoneNumber,
      org_id: this.clientId,
      full_name: this.name,
    };

    const encodedData = this.encodeUserData(userData);
    const finalUrl = `${url}?userInfo=${encodedData}`;
    window.open(finalUrl);
  }

  private initializeUserData(): void {
    const user = this.storageService.getUser();
    if (user) {
      try {
        const parsedUser: UserData = JSON.parse(user);
        this.phoneNumber =
          parsedUser['primaryNumber'] || parsedUser['username'] || '';
        this.name = `${parsedUser.firstName || ''} ${parsedUser.lastName || ''}`
          .trim()
          .toLowerCase();
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }
  }

  private encodeUserData(data: Record<string, string>): string {
    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString);
    return encodeURIComponent(base64String);
  }
}
