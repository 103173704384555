import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CLOSE_OVERLAY } from 'src/app/core/assets.location';
import { BatchPlans } from 'src/app/common/services/batch/batch.modal';

@Component({
  selector: 'app-fee-breakup-sideoverlay',
  templateUrl: './fee-breakup-sideoverlay.component.html',
  styleUrls: ['./fee-breakup-sideoverlay.component.scss'],
})
export class FeeBreakupSideoverlayComponent implements OnInit {
  close = CLOSE_OVERLAY;
  batchPlans = new BatchPlans({});

  constructor(
    private dialogRef: MatDialogRef<FeeBreakupSideoverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.batchPlans = data?.plansData;
  }

  ngOnInit(): void {}
  handleOnClose() {
    this.dialogRef.close();
  }

  handleContinueClick() {
    this.dialogRef.close();
    if (this.data?.callback) {
      this.data?.callback();
    }
  }
}
