export interface UserCouponResponse {
  success: boolean;
  data: UserCoupons;
}

export class UserCoupons {
  recommendedCoupons: Coupon[];
  otherCoupons: Coupon[];
  brandCoupons: Coupon[];

  constructor(data: any = {}) {
    if (data.recommendedCoupons) {
      this.recommendedCoupons = data.recommendedCoupons.map(
        (c: any) => new Coupon(c)
      );
    } else {
      this.recommendedCoupons = [];
    }

    if (data.otherCoupons) {
      this.otherCoupons = data.otherCoupons.map((c: any) => new Coupon(c));
    } else {
      this.otherCoupons = [];
    }

    if (data.brandCoupons) {
      this.brandCoupons = data.brandCoupons.map((c: any) => new Coupon(c));
    } else {
      this.brandCoupons = [];
    }
  }
}

export class Coupon {
  type: string;
  offerType: string;
  currentUsage: number;
  status: string;
  packageIds: string[];
  _id: string;
  name: string;
  description: string;
  couponCode: string;
  startDate: string;
  endDate: string;
  usagePerCandidate: number;
  minPurchaseAmount: number;
  maxDiscountAmount: number;
  organization: string;
  couponAmount: number;
  maxUsage: number;
  createdAt: string;
  updatedAt: string;
  isApplicable: boolean;
  discountAmount: number;
  amountToAdd: number;
  isRecommended: boolean;
  module?: string;
  metaDescription?: string;
  couponKind?: string;
  userType?: string;
  event?: string;
  maturityTime?: string;
  info: string[];
  imageLink: string;
  companyName: string;
  constructor(data: any = {}) {
    this.type = data.type || '';
    this.offerType = data.offerType || '';
    this.currentUsage = data.currentUsage || 0;
    this.status = data.status || '';
    this.packageIds = data.packageIds || [];
    this._id = data._id || '';
    this.name = data.name || '';
    this.description = data.description || '';
    this.couponCode = data.couponCode || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.usagePerCandidate = data.usagePerCandidate || 0;
    this.minPurchaseAmount = data.minPurchaseAmount || 0;
    this.maxDiscountAmount = data.maxDiscountAmount || 0;
    this.organization = data.organization || '';
    this.couponAmount = data.couponAmount || 0;
    this.maxUsage = data.maxUsage || 0;
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.isApplicable = data.isApplicable || false;
    this.discountAmount = Math.floor(data.discountAmount) || 0;
    this.amountToAdd = data.amountToAdd || 0;
    this.module = data.module || '';
    this.metaDescription = data.metaDescription || '';
    this.couponKind = data.couponKind || '';
    this.userType = data.userType || '';
    this.event = data.event || '';
    this.maturityTime = data.maturityTime || '';
    this.isRecommended = data.isRecommended || false;
    this.info = data.info || [];
    this.imageLink = data.imageLink || '';
    this.companyName = data.companyName || '';
  }
}

export class CouponApplied {
  coupon: Coupon;
  totalAmount: number;
  discountedAmount: number;
  finalAmount: number;
  refereeAmountType: string;
  refereeCashBackAmount: number;

  constructor(data: any = {}) {
    this.coupon = new Coupon(data.coupon) || new Coupon({});
    this.totalAmount = data.totalAmount || 0;
    this.discountedAmount = Math.floor(data.discountedAmount) || 0;
    this.finalAmount = data.finalAmount || 0;
    this.refereeCashBackAmount = data.refereeCashBackAmount || 0;
    this.refereeAmountType = data.refereeAmountType || '';
  }
}
