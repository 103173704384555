<article
  class="comments-container"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="500"
  (scrolled)="loadMore()"
  [scrollWindow]="false"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
>
  <section class="py-2 px-2 md:px-3">
    <app-doubt-reply-card
      [doubtSolution]="doubtSolution"
      (feedback)="confirmationDialog($event)"
      [coming_source]="coming_source"
    ></app-doubt-reply-card>
  </section>

  <section class="flex items-center justify-center py-2 w-full bg-grey my-2">
    <span class="body-2 semibold sm-body2"
      >Total Comments({{ doubtSolution.childCommentCount }})</span
    >
  </section>

  <section
    class="py-2 px-2 md:px-3 sm-padding3"
    [ngClass]="{
      'warning-height': isBlock,
      'parmanent-height': chatInputBlocked
    }"
  >
    <ng-container *ngIf="doubtSolutionComments.length">
      <div
        *ngFor="let item of doubtSolutionComments; let i = index"
        [ngClass]="
          i !== doubtSolutionComments.length - 1 ? 'border-bottom' : ''
        "
        class="mb-2"
      >
        <app-doubt-reply-card
          [doubtSolution]="item"
          [isComment]="true"
          (deleteComment)="deleteUserComment(item)"
          (feedback)="confirmationDialog($event)"
          [coming_source]="coming_source"
        ></app-doubt-reply-card></div
    ></ng-container>

    <ng-container *ngIf="!doubtSolutionComments.length && !processing">
      <span class="body-1 flex justify-center semibold sm-body1"
        >No comments yet!</span
      >
    </ng-container>

    <div *ngIf="processing" class="flex justify-center">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </section>
</article>

<section
  class="w-full items-center flex justify-center"
  *ngIf="confirmationText !== ''"
  [@fadeInOut]
>
  <div
    class="confirmation-text flex items-center justify-center relative py-2 px-3"
  >
    <span>{{ confirmationText }}</span>
  </div>
</section>
<div class="bg-white pb-2 new-doubt-input-section mb-10">
  <app-profanity-alert-warning
    *ngIf="relevant"
    [irreleventMessage]="irreleventMessage"
  ></app-profanity-alert-warning>
  <app-block-warning
    *ngIf="isBlock"
    [blockMessage]="blockMessage"
  ></app-block-warning>
</div>
<app-doubt-input-box
  (inputData)="submitAnswer($event)"
  [placeHolderText]="'Comment on Answer'"
  [isBlockFromParent]="isBlock"
  [chatInputBlockedParent]="chatInputBlocked"
  [inputBlockMessageParent]="inputBlockMessage"
  [coming_source]="coming_source"
></app-doubt-input-box>
