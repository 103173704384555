import { Injectable } from '@angular/core';
import { PPApiOptions } from '../../api/api.type';
import { PPApiService } from '../../api/api.service';
import { map, tap, catchError, BehaviorSubject, firstValueFrom } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import { getUtcStartEndTime } from '../../utils/utils';

export enum IFrameActions {
  CLOSE_SIDE_BAR = 'CLOSE_SIDE_BAR',
  EARN_XP_DATA = 'EARN_XP_DATA',
}

export enum ApplicationTypes {
  PW_LEARN_2_EARN = 'PW_LEARN_2_EARN',
}
export interface IFrameMessageProps {
  action: IFrameActions;
  value: any;
  applicationType: ApplicationTypes;
}

@Injectable({
  providedIn: 'root',
})
export class Learn2EarnService {
  public weeklyXPData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public configData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private apiService: PPApiService) {}

  generateCacheBuster(): string {
    return `cb=${new Date().getTime()}`;
  }

  getLearn2EarnConfig(cohortId: string) {
    const options: PPApiOptions = {
      apiPath: `engagement/learn-to-earn/config/${cohortId}`,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  async getWeeklyUserXP(cohortId: string) {
    const configData = await this.getConfigData();
    const startDate = new Date(configData?.leaderboardUpdateNextDate);
    startDate.setDate(
      startDate.getDate() -
        (configData?.featureDetails?.LEADERBOARD_UPDATE_DAYS - 1)
    );
    const endDate = new Date(configData?.leaderboardUpdateNextDate);
    const { utcStartDate, utcEndDate } = getUtcStartEndTime(startDate, endDate);
    const cacheBuster = this.generateCacheBuster();
    const options: PPApiOptions = {
      apiPath: `engagement/learn-to-earn/weekly-user-xp/${cohortId}?startDate=${utcStartDate}&endDate=${utcEndDate}&${cacheBuster}`,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  async getProfileData(cohortId: string) {
    const configData = await this.getConfigData();
    const startDate = new Date(configData?.leaderboardUpdateNextDate);
    startDate.setDate(
      startDate.getDate() -
        (configData?.featureDetails?.LEADERBOARD_UPDATE_DAYS - 1)
    );
    const endDate = new Date(configData?.leaderboardUpdateNextDate);
    const { utcStartDate, utcEndDate } = getUtcStartEndTime(startDate, endDate);
    const options: PPApiOptions = {
      apiPath: `engagement/learn-to-earn/profile-data/${cohortId}?startDate=${utcStartDate}&endDate=${utcEndDate}`,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  putLearn2EarnUnreadXpShown(cohortId: string,activityId:string) {
    const options: PPApiOptions = {
      apiPath: `engagement/learn-to-earn/unread-xp/${cohortId}?activityId=${activityId || 'NA'}`,
    };
    return this.apiService.put<any>({}, options).pipe(
      map((res: any) => res),
      catchError(handleError)
    );
  }

  setConfigData(value: any) {
    this.configData$.next(value);
  }

  hasValue(value: any) {
    return value !== null && value !== undefined;
  }

  async getConfigData() {
    let value = await firstValueFrom(this.configData$);
    return value;
  }

  getConfigDataSubscribe() {
    return this.configData$;
  }

  setWeeklyXpData(value: any) {
    this.weeklyXPData$.next(value);
  }

  async getWeeklyXpData() {
    let value = await firstValueFrom(this.weeklyXPData$);
    return value;
  }

  getPageNameByRoute(url: any) {
    if (url?.includes('/study')) {
      return 'STUDY_PAGE';
    }
    if (url?.includes('test-series')) {
      return 'TEST_SERIES_LISTING_PAGE';
    }
    if (
      url?.includes('activeSection=Khazana') ||
      url?.includes('khazana-topics')
    ) {
      return 'KHAZANA_PAGE';
    }
    if (
      url?.includes('activeSection=Tests') ||
      url?.includes('activeSection=Test Series')
    ) {
      return 'TEST_SERIES_PAGE';
    }
    if (url?.includes('quiz?type=dpp')) {
      return 'DPP_LISTING_PAGE';
    }
    if (url?.includes('activeTab')) {
      return 'ALL_CLASSES';
    }
    if (url?.includes('subject-topics')) {
      return 'ALL_CLASSES';
    }
    if (url?.includes('dashboard')) {
      return 'HOME_PAGE';
    }
    if (url?.includes('/batch-overview')) {
      return 'ALL_CLASSES';
    }
    if (url?.includes('/batches')) {
      return 'BATCHES_PAGE';
    }
    return 'ALL_CLASSES';
  }
}
