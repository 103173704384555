import { HttpHeaders, HttpParams } from '@angular/common/http';
import {
  PPApiResponseType,
  PPApiTokenStorageType,
  PPApiTokenType,
} from './api.enum';

export interface PPApiConfig {
  baseUrl: string;
  tokenKey?: string;
  tokenType?: PPApiTokenType;
  storedIn?: PPApiTokenStorageType;
  headers?: PPApiHeaders;
}

export interface PPApiOptions {
  apiPath: string;
  params?: PPApiParams;
  headers?: PPApiHeaders;
  responseType?: PPApiResponseType;
  withCredentials?: boolean;
  config?: PPApiConfig;
}

export class PPApiParams extends HttpParams {}

export class PPApiHeaders extends HttpHeaders {}

export interface PPApiMethodOptions {
  headers?: PPApiHeaders;
  observe?: 'body' | 'events' | 'response';
  params?: PPApiParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}
