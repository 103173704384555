import { FileModal, TimelineModal, VideoDetailModal } from '../base/base.modal';

export class BatchSubjectContentModel {
  conversationId: string;
  _id: string;
  homeworkIds: Array<HomeworkModal> = [];
  exerciseIds: Array<any> = [];
  videoDetails: VideoDetailModal;
  timeline: Array<TimelineModal> = [];
  isFree: boolean;
  restrictedSchedule: boolean;
  restrictedTime: number;
  scheduleId: string;
  isLive: boolean;
  url: string;
  urlType: string;
  status: string;
  topic: string;
  date: string;
  isChatEnabled: boolean;
  isCommentDisabled: boolean;
  dRoomId: string;
  isDoubtEnabled: boolean;
  isBatchDoubtEnabled: boolean;
  startTime: string;
  endTime: string;
  isComplete: boolean;
  isPurchased: boolean;
  tags: any;
  lastWatchedPointInSec: number;
  constructor(batchSubjectContentData: any) {
    batchSubjectContentData = batchSubjectContentData || {};
    this.conversationId = batchSubjectContentData.conversationId || '';
    this._id = batchSubjectContentData._id || '';
    this.urlType =
      !batchSubjectContentData.urlType &&
      batchSubjectContentData.url &&
      batchSubjectContentData.url.includes('youtube')
        ? 'youtube'
        : !batchSubjectContentData.urlType &&
          batchSubjectContentData.url &&
          batchSubjectContentData.url.includes('vimeo')
        ? 'vimeo'
        : batchSubjectContentData.urlType && batchSubjectContentData.url
        ? batchSubjectContentData.urlType
        : '';
    this.url = batchSubjectContentData.url || '';
    this.isFree = batchSubjectContentData.isFree || false;
    this.isLive = batchSubjectContentData.isLive || false;
    this.restrictedSchedule =
      batchSubjectContentData.restrictedSchedule || false;
    this.scheduleId = batchSubjectContentData.scheduleId || '';
    this.restrictedTime = batchSubjectContentData.restrictedTime || 0;
    this.status = batchSubjectContentData.status
      ? 'Not Downloaded'
      : batchSubjectContentData.status || '';
    this.topic = batchSubjectContentData.topic || '';
    this.date = batchSubjectContentData.date || '';
    this.videoDetails = new VideoDetailModal(
      batchSubjectContentData.videoDetails || {}
    );
    this.homeworkIds = batchSubjectContentData.homeworkIds || [];
    this.exerciseIds = batchSubjectContentData.exerciseIds || [];
    this.isCommentDisabled = batchSubjectContentData.isCommentDisabled || false;
    this.isChatEnabled = batchSubjectContentData.isChatEnabled || false;
    if (
      batchSubjectContentData.timeline &&
      batchSubjectContentData.timeline.length > 0
    ) {
      batchSubjectContentData.timeline.forEach((item: any) => {
        this.timeline.push(new TimelineModal(item));
      });
    }
    this.isDoubtEnabled = batchSubjectContentData.isDoubtEnabled || false;
    this.isBatchDoubtEnabled =
      batchSubjectContentData.isBatchDoubtEnabled || false;
    this.dRoomId = batchSubjectContentData.dRoomId || '';
    this.startTime = batchSubjectContentData.startTime || '';
    this.endTime = batchSubjectContentData.endTime || '';
    this.isComplete = batchSubjectContentData.isComplete || false;
    this.isPurchased = batchSubjectContentData.isPurchased || false;
    this.tags = new Tag(batchSubjectContentData.tags) || new Tag({});
    if (batchSubjectContentData.tags && batchSubjectContentData.tags) {
      this.tags = batchSubjectContentData.tags.map((el: any) => new Tag(el));
    }
    this.lastWatchedPointInSec =
      batchSubjectContentData.lastWatchedPointInSec || 0;
  }
}

export class Tag {
  _id: string;
  name: string;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.name = data.name || '';
  }
}

export class HomeworkModal {
  _id: string;
  attachmentIds: Array<FileModal>;
  status: string;
  batchSubjectId: string;
  topic: string;
  note: string;
  actions: Array<string> = [];

  constructor(data: any) {
    const homeworkData = data || {};
    this.actions = homeworkData.actions || [];
    this._id = homeworkData._id || '';
    this.status = homeworkData.status || '';
    this.batchSubjectId = homeworkData.batchSubjectId || '';
    this.topic = homeworkData.topic || '';
    this.note = homeworkData.note || '';

    this.attachmentIds =
      homeworkData?.attachmentIds?.map((el: any) => new FileModal(el)) || [];
  }
}

export class BatchQuizModel {
  _id: string;
  isFree: boolean;
  status: string;
  date: string;
  startTime: string;
  exerciseIds: Array<ExerciseIdModel> = [];
  dRoomId: string;
  isBatchDoubtEnabled: boolean;

  constructor(data: any) {
    this._id = data._id || '';
    this.isFree = data.isFree || false;
    this.status = data.status || '';
    this.date = data.date || '';
    this.startTime = data.startTime || '';
    if (data && data.exerciseIds && data.exerciseIds.length > 0) {
      data.exerciseIds.forEach((el: any) => {
        this.exerciseIds.push(new ExerciseIdModel(el));
      });
    }
    this.dRoomId = data.dRoomId || '';
    this.isBatchDoubtEnabled = data.isBatchDoubtEnabled || false;
  }
}

export class DppTestType {
  includesObjectiveTests: boolean;
  includesSubjectiveTests: boolean;
  constructor(data: any = {}) {
    this.includesObjectiveTests = data?.includesObjectiveTests || false;
    this.includesSubjectiveTests = data?.includesSubjectiveTests || false;
  }
}

export class BatchDppQuiz {
  test: Test;
  testStudentMapping: TestStudentMapping;
  scheduleId: string;
  isFree: boolean;
  isPurchased: boolean;
  tag: string;
  contentId?: string;
  constructor(props: any) {
    props = props || {};
    this.test = new Test(props.test) || new Test({});

    this.testStudentMapping =
      new TestStudentMapping(props.testStudentMapping) ||
      new TestStudentMapping({});
    this.scheduleId = props.scheduleId || '';
    this.isFree = props.isFree || false;
    this.isPurchased = props.isPurchased || false;
    this.tag = props.tag || '';
    this.contentId = props?.contentId || '';
  }
}

export class BattleGroundDppQuiz {
  batchSubjectId: string;
  endDate: string;
  isSharingEnabled: boolean;
  questionCount: number;
  startDate: string;
  status: string;
  studentSideProgressStatus: string;
  studentBattleStatus: string;
  subjectName: string;
  testId: string;
  title: string;
  _id: string;
}

export class Test {
  _id: string;
  displayOrder: number;
  name: string;
  totalMarks: number;
  totalQuestions: number;
  maxDuration: number;
  constructor(props: any) {
    props = props || {};
    this._id = props._id || '';
    this.displayOrder = props.displayOrder || 0;
    this.name = props.name || '';
    this.totalMarks = props.totalMarks || 0;
    this.totalQuestions = props.totalQuestions || 0;
    this.maxDuration = props.maxDuration || 0;
  }
}
export class TestStudentMapping {
  _id: string;

  constructor(props: any) {
    props = props || {};
    this._id = props._id || '';
  }
}

export class ExerciseIdModel {
  type: string;
  availableFor: Array<any> = [];
  status: string;
  title: string;
  content: Array<ContentModel> = [];
  slug: string;
  constructor(props: any) {
    props = props || {};
    this.type = props.type || '';
    this.availableFor = props.availableFor;
    this.status = props.status || '';
    this.title = props.title || '';
    if (props && props.content && props.content.length > 0) {
      props.content.forEach((el: any) => {
        this.content.push(new ContentModel(el));
      });
    }
    this.slug = props.slug || '';
  }
}

export class ContentModel {
  _id: string;
  exerciseId: string;
  timeline: any[];
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.exerciseId = data.exerciseId || '';
    if (data && data.timeline) {
      this.timeline = [...data.timeline];
    } else {
      this.timeline = [];
    }
  }
}

export class CallSupportSection {
  phoneNumber: string;
  constructor(data: any) {
    this.phoneNumber = data?.phoneNumber || '';
  }
}

export class WebinarSection {
  heading: string;
  constructor(data: any) {
    this.heading = data?.heading || '';
  }
}

export class BatchConfig {
  _id: string;
  createdAt: string;
  updatedAt: string;
  batchId: string;
  status: string;
  isIntroSectionEnabled: boolean;
  introSection: IntroSection;
  isFomoSectionEnabled: boolean;
  fomoSection: FomoSection[];
  isMetaDescriptionEnabled: boolean;
  metaDataSection: MetaDataSection;
  isSubjectSectionsEnabled: boolean;
  subjectSection: SubjectSection;
  isTeacherSectionEnabled: boolean;
  teachersSection: TeachersSection;
  isContentSectionsEnabled: boolean;
  contentSection: ContentSection;
  isDecriptionSectionEnabled: boolean;
  descriptionSection: DescriptionSection;
  isMaterialsSectionEnabled: boolean;
  materialSection: MaterialSection;
  isDemoSectionEnabled: boolean;
  demoSection: DemoSection;
  isTestimonialsSectionEnabled: boolean;
  isHowTwoSectionsEnabled: boolean;
  howTwoSection: HowTwoSection;
  isQueryEnabled: boolean;
  isFAQEnabled: boolean;
  isRefferEnabled: boolean;
  premiumSection: PremiumSection;
  callSupportSection?: CallSupportSection;
  webinarSection?: WebinarSection;
  batchTabs: BatchTabs[];
  isPartPaymentEnabled: boolean;
  defaultPaymentMode: 'PART' | 'FULL';

  constructor(data: any = {}) {
    this._id = data._id || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.batchId = data.batchId || '';
    this.status = data.status || '';
    this.isIntroSectionEnabled = data.isIntroSectionEnabled || false;
    this.introSection =
      new IntroSection(data.introSection) || new IntroSection({});
    this.isFomoSectionEnabled = data.isFomoSectionEnabled || false;
    if (data.fomoSection) {
      this.fomoSection = data.fomoSection.map(
        (fomo: any) => new FomoSection(fomo)
      );
    } else {
      this.fomoSection = [];
    }
    this.isMetaDescriptionEnabled = data.isMetaDescriptionEnabled || false;
    this.metaDataSection =
      new MetaDataSection(data.metaDataSection) || new MetaDataSection({});
    this.isSubjectSectionsEnabled = data.isSubjectSectionsEnabled || false;
    this.subjectSection =
      new SubjectSection(data.subjectSection) || new SubjectSection({});
    this.isTeacherSectionEnabled = data.isTeacherSectionEnabled || false;
    this.teachersSection =
      new TeachersSection(data.teachersSection) || new TeachersSection({});
    this.isContentSectionsEnabled = data.isContentSectionsEnabled || false;
    this.contentSection =
      new ContentSection(data.contentSection) || new ContentSection({});
    this.isDecriptionSectionEnabled = data.isDecriptionSectionEnabled || false;
    this.descriptionSection =
      new DescriptionSection(data.descriptionSection) ||
      new DescriptionSection({});
    this.isMaterialsSectionEnabled = data.isMaterialsSectionEnabled || false;
    this.materialSection =
      new MaterialSection(data.materialSection) || new MaterialSection({});
    this.isDemoSectionEnabled = data.isDemoSectionEnabled || false;
    this.demoSection = new DemoSection(data.demoSection) || new DemoSection({});
    this.isTestimonialsSectionEnabled =
      data.isTestimonialsSectionEnabled || false;
    this.isHowTwoSectionsEnabled = data.isHowTwoSectionsEnabled || false;
    this.howTwoSection =
      new HowTwoSection(data.howTwoSection) || new HowTwoSection({});
    this.isQueryEnabled = data.isQueryEnabled || false;
    this.isFAQEnabled = data.isFAQEnabled || false;
    this.isRefferEnabled = data.isRefferEnabled || false;
    this.premiumSection =
      new PremiumSection(data.premiumSection) || new PremiumSection({});
    this.callSupportSection =
      new CallSupportSection(data.callSupportSection) ||
      new CallSupportSection({});
    this.webinarSection =
      new WebinarSection(data.webinarSection) || new WebinarSection({});
    if (data?.batchTabs?.length) {
      this.batchTabs = data.batchTabs.map((item: any) => new BatchTabs(item));
    } else {
      this.batchTabs = [];
    }

    this.isPartPaymentEnabled = data?.isPartPaymentEnabled || false;
    this.defaultPaymentMode = data?.defaultPaymentMode || 'FULL';
  }
}

export class BatchTabs {
  _id: string;
  pageName: string;

  constructor(data: any = {}) {
    this.pageName = data.pageName || '';
    this._id = data._id || '';
  }
}

export class PremiumSection {
  premiumIntroVideoThumbnail: PremiumIntroBannerSection[];
  premiumIntroVideoSection: PremiumIntroVideoSection;
  premiumIntroVideoSectionEnabled: boolean;
  premiumIntroBannerSection: PremiumIntroBannerSection[];
  premiumIntroBannerSectionEnabled: boolean;
  watchDemoSectionEnabled: boolean;
  watchDemoSection: WatchDemoSection;
  studySection: StudySection;
  personalTeacherSection: AllClassesSection;
  booksSection: AllClassesSection;
  resultsSection: PremiumIntroBannerSection[];
  isBooksSectionEnabled: boolean;
  isResultsSectionEnabled: boolean;
  connectWithUsSection: PremiumIntroBannerSection[];
  isConnectWithUsSectionEnabled: boolean;

  constructor(data: any = {}) {
    this.premiumIntroVideoSectionEnabled =
      data.premiumIntroVideoSectionEnabled || false;
    this.premiumIntroBannerSectionEnabled =
      data.premiumIntroBannerSectionEnabled || false;
    this.watchDemoSectionEnabled = data.watchDemoSectionEnabled || false;
    this.isBooksSectionEnabled = data.isBooksSectionEnabled || false;
    this.isResultsSectionEnabled = data.isResultsSectionEnabled || false;
    this.isConnectWithUsSectionEnabled =
      data.isConnectWithUsSectionEnabled || false;

    this.premiumIntroVideoSection =
      new PremiumIntroVideoSection(data.premiumIntroVideoSection) ||
      new PremiumIntroVideoSection({});

    if (data.premiumIntroVideoThumbnail?.length > 0) {
      this.premiumIntroVideoThumbnail = data.premiumIntroVideoThumbnail.map(
        (res: any) => new PremiumIntroBannerSection(res)
      );
    } else {
      this.premiumIntroVideoThumbnail = [new PremiumIntroBannerSection({})];
    }

    if (data.premiumIntroBannerSection?.length > 0) {
      this.premiumIntroBannerSection = data.premiumIntroBannerSection.map(
        (res: any) => new PremiumIntroBannerSection(res)
      );
    } else {
      this.premiumIntroBannerSection = [new PremiumIntroBannerSection({})];
    }

    if (data.connectWithUsSection?.length > 0) {
      this.connectWithUsSection = data.connectWithUsSection.map(
        (res: any) => new PremiumIntroBannerSection(res)
      );
    } else {
      this.connectWithUsSection = [new PremiumIntroBannerSection({})];
    }

    if (data.resultsSection?.length > 0) {
      this.resultsSection = data.resultsSection.map(
        (res: any) => new PremiumIntroBannerSection(res)
      );
    } else {
      this.resultsSection = [new PremiumIntroBannerSection({})];
    }

    this.watchDemoSection =
      new WatchDemoSection(data.watchDemoSection) || new WatchDemoSection({});

    this.booksSection =
      new AllClassesSection(data.booksSection) || new AllClassesSection({});

    this.studySection =
      new StudySection(data.studySection) || new StudySection({});

    this.personalTeacherSection =
      new AllClassesSection(data?.studySection?.personalTeacherSection) ||
      new AllClassesSection({});
  }
}

export class PremiumIntroVideoSection {
  videoUrl: string;
  videoType: string;

  constructor(data: any = {}) {
    this.videoUrl = data.videoUrl || '';
    this.videoType = data.videoType || '';
  }
}

export class PremiumIntroBannerSection {
  webImageUrl: string;
  appImageUrl: string;

  constructor(data: any = {}) {
    this.webImageUrl = data.webImageUrl || '';
    this.appImageUrl = data.appImageUrl || '';
  }
}

export class WatchDemoSection {
  text: string;

  constructor(data: any = {}) {
    this.text = data.text || '';
  }
}

export class StudySection {
  allClassesSection: AllClassesSection;
  testsSection: AllClassesSection;
  infiniteLearningSection: AllClassesSection;
  communitySection: AllClassesSection;

  constructor(data: any = {}) {
    this.allClassesSection =
      new AllClassesSection(data.allClassesSection) ||
      new AllClassesSection({});
    this.testsSection =
      new AllClassesSection(data.testsSection) || new AllClassesSection({});
    this.infiniteLearningSection =
      new AllClassesSection(data.infiniteLearningSection) ||
      new AllClassesSection({});
    this.communitySection =
      new AllClassesSection(data.communitySection) || new AllClassesSection({});
  }
}

export class AllClassesSection {
  heading: string;
  description: string;
  bannerImages: PremiumIntroBannerSection[];

  constructor(data: any = {}) {
    this.heading = data.heading || '';
    this.description = data.description || '';

    if (data.bannerImages?.length > 0) {
      this.bannerImages = data.bannerImages.map(
        (img: any) => new PremiumIntroBannerSection(img)
      );
    } else {
      this.bannerImages = [new PremiumIntroBannerSection({})];
    }
  }
}

export class BooksSection {
  heading: string;
  bannerImages: PremiumIntroBannerSection[];

  constructor(data: any = {}) {
    this.heading = data.heading || '';
    if (data.bannerImages) {
      this.bannerImages = data.bannerImages.map(
        (img: any) => new PremiumIntroBannerSection(img)
      );
    } else {
      this.bannerImages = [];
    }
  }
}

export class ResultsSection {
  heading: string;
  bannerImages: PremiumIntroBannerSection[];

  constructor(data: any = {}) {
    this.heading = data.heading || '';
    if (data.bannerImages) {
      this.bannerImages = data.bannerImages.map(
        (img: any) => new PremiumIntroBannerSection(img)
      );
    } else {
      this.bannerImages = [];
    }
  }
}

export class HowTwoSection {
  customTitle: string;
  videoUrl: string;
  videoType: string;
  previewImageUrl: string;

  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
    this.videoType = data.videoType || '';
    this.videoUrl = data.videoUrl || '';
    this.previewImageUrl = data.previewImageUrl || '';
  }
}

export class DemoSection {
  customTitle: string;
  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
  }
}

export class MaterialSection {
  customTitle: string;
  cards: Card[];

  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
    if (data.cards) {
      this.cards = data.cards.map((c: any) => new Card(c));
    } else {
      this.cards = [];
    }
  }
}

export class Card {
  type: string;
  imageUrl: string;
  typeData: string;
  description: string;

  constructor(data: any = {}) {
    this.type = data.type || '';
    this.imageUrl = data.imageUrl || '';
    this.typeData = data.typeData || '';
    this.description = data.description || '';
  }
}

export class ContentSection {
  customTitle: string;
  images: Image[];

  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
    if (data.images) {
      this.images = data.images.map((c: any) => new Image(c));
    } else {
      this.images = [];
    }
  }
}

export class Image {
  imageUrl: string;
  constructor(data: any = {}) {
    this.imageUrl = data.imageUrl || '';
  }
}

export class DescriptionSection {
  customTitle: string;
  isOpened: boolean;

  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
    this.isOpened = data.isOpened || false;
  }
}

export class MetaDataSection {
  customTitle: string;
  isViewBatchEnabled: boolean;
  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
    this.isViewBatchEnabled = data.isViewBatchEnabled || false;
  }
}

export class SubjectSection {
  customTitle: string;
  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
  }
}

export class TeachersSection {
  customTitle: string;
  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
  }
}

export class IntroSection {
  customTitle: string;
  introVideoUrl: string;
  introVideoType: string;
  introVideoTitle: string;
  introVideoDescription: string;
  introVideoImageUrl: string;
  introImageUrl: string;
  carouselTime: number;
  introVideos: IntroVideo[];
  introImages: IntroImage[];

  constructor(data: any = {}) {
    this.customTitle = data.customTitle || '';
    this.introVideoUrl = data.introVideoUrl || '';
    this.introVideoType = data.introVideoType || '';
    this.introVideoTitle = data.introVideoTitle || '';
    this.introVideoDescription = data.introVideoDescription || '';
    this.introVideoImageUrl = data.introVideoImageUrl || '';
    this.introImageUrl = data.introImageUrl || '';
    this.carouselTime = data.carouselTime || -1;
    if (data.introVideos) {
      this.introVideos = data.introVideos.map(
        (item: any) => new IntroVideo(item)
      );
    } else {
      this.introVideos = [];
    }

    if (data.introImages) {
      this.introImages = data.introImages.map(
        (item: any) => new IntroImage(item)
      );
    } else {
      this.introImages = [];
    }
  }
}

export class IntroVideo {
  introVideoType: string;
  introVideoUrl: string;
  introVideoTitle: string;
  introVideoDescription: string;
  introVideoImageUrl: string;

  constructor(data: any = {}) {
    this.introVideoDescription = data.introVideoDescription || '';
    this.introVideoImageUrl = data.introVideoImageUrl || '';
    this.introVideoTitle = data.introVideoTitle || '';
    this.introVideoType = data.introVideoType || '';
    this.introVideoUrl = data.introVideoUrl || '';
  }
}

export class CarouselContent {
  introVideoType: string;
  introVideoUrl: string;
  introVideoTitle: string;
  introVideoDescription: string;
  introVideoImageUrl: string;
  introImageUrl: string;

  constructor(data: any = {}) {
    this.introVideoDescription = data.introVideoDescription || '';
    this.introVideoImageUrl = data.introVideoImageUrl || '';
    this.introVideoTitle = data.introVideoTitle || '';
    this.introVideoType = data.introVideoType || '';
    this.introVideoUrl = data.introVideoUrl || '';
    this.introImageUrl = data.introImageUrl || '';
  }
}

export class IntroImage {
  introImageUrl: string;
  constructor(data: any = {}) {
    this.introImageUrl = data.introImageUrl || '';
  }
}

export class FomoSection {
  imageUrl: string;
  description: string;
  constructor(data: any = {}) {
    data = data || {};
    this.imageUrl = data.imageUrl || '';
    this.description = data.description || '';
  }
}

export enum MAHAPACK_SECTION {
  DESCRIPTION = 'description',
  BATCHES = 'batches',
  ANNOUNCEMENT = 'announcement',
}

export class FeeId {
  _id: string;
  tax: number;
  discount: number;
  amount: number;
  total: number;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.tax = data.tax || 0;
    this.discount = data.discount || 0;
    this.amount = data.amount || 0;
    this.total = data.total || 0;
  }
}

export class BatchDetail {
  _id: string;
  name: string;
  feeId: FeeId;
  slug: string;

  constructor(data: any) {
    data = data || {};
    this.name = data.name || '';
    this.slug = data.slug || '';
    this.feeId = new FeeId(data.feeId) || new FeeId({});
  }
}

export class MahapackBatchMapping {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
  mahapackId: string;
  batchId: string;
  exam: string;
  subExam: string;
  displayOrder: number;
  __v: number;
  batchDetail: BatchDetail;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.status = data.status || '';
    this.mahapackId = data.mahapackId || '';
    this.batchId = data.batchId || '';
    this.exam = data.exam || '';
    this.subExam = data.subExam || '';
    this.displayOrder = data.displayOrder || 0;
    this.__v = data.__v || 0;
    this.batchDetail = new BatchDetail(data.batchDetail) || new BatchDetail({});
  }
}

export class MahapackBatchMappingRoot {
  success: boolean;
  data: Array<MahapackBatchMapping>;

  constructor(data: any) {
    data = data || {};
    this.success = data.success || false;
    if (data && data.data.length > 0) {
      this.data = data.data.map((item: any) => new MahapackBatchMapping(item));
    } else {
      this.data = [];
    }
  }
}

export interface MentorshipBanner {
  _id: string;
  resolutionUrl: ResolutionUrl;
  type: string[];
  isFallBack: boolean;
  isActive: boolean;
  isDeleted: boolean;
  tags: string[];
  title: string;
  bannerUrl: string;
  redirectionUrl: string;
  expiresAt: string;
  startAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface ResolutionUrl {
  WEB: Web;
  MOBILE: Web;
}

export interface Web {
  url: string;
  redirectionUrl: string;
}

export class ImageId {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  organization: string;
  createdAt: string;

  constructor(data: any) {
    data = data || {};
    this.baseUrl = data?.baseUrl || '';
    this._id = data?._id || '';
    this.name = data?.name || '';
    this.key = data?.key || '';
    this.organization = data?.organization || '';
    this.createdAt = data?.createdAt || '';
  }
}

export class Plans {
  _id: string;
  price: number;
  duration: number;
  durationType: string;
  discount: number;
  displayOrder: number;
  isRecommended: boolean;
  status: string;
  title: string;
  description: string;
  imageId: ImageId;
  batchId: string;
  createdAt: Date;
  updatedAt: Date;
  total: number;
  pricePerMonth: number;
  isAvailableFromPoints: boolean;
  maxWalletPoints: number;
  isSelected: boolean;
  planId: string;
  tax: number;

  constructor(data: any) {
    data = data || {};

    this._id = data._id || '';
    this.price = data.price || '';
    this.duration = data.duration || '';
    this.durationType = data.durationType || '';
    this.discount = data.discount || '';
    this.displayOrder = data.displayOrder || '';
    this.isRecommended = data.isRecommended || '';
    this.status = data.status || '';
    this.title = data.title || '';
    this.description = data.description || '';
    this.imageId = new ImageId(data.imageId) || new ImageId({});
    this.batchId = data.batchId || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.total = data.total || '';
    this.pricePerMonth = data.pricePerMonth || '';
    this.isAvailableFromPoints = data.isAvailableFromPoints || false;
    this.maxWalletPoints = data.maxWalletPoints || 0;
    this.isSelected = data.isSelected || false;
    this.planId = data.planId || '';
    this.tax = data.tax || 0;
  }
}

export class BatchPlus {
  plans: BatchPlan[];
  comparisonTable: ComparisionTable[];

  constructor(data: any = {}) {
    if (data.plans) {
      this.plans = data.plans.map((plan: any) => new BatchPlan(plan));
    } else {
      this.plans = [new BatchPlan({})];
    }

    if (data.comparisonTable) {
      this.comparisonTable = data.comparisonTable.map(
        (item: any) => new ComparisionTable(item)
      );
    } else {
      this.comparisonTable = [];
    }
  }
}

export class BatchPlan {
  _id: string;
  offerings: Offerings;
  price: number;
  duration: number;
  durationType: string;
  discount: number;
  displayOrder: number;
  isRecommended: boolean;
  status: string;
  isAvailableFromPoints: boolean;
  maxWalletPoints: number;
  type: string;
  title: string;
  description: string;
  batchId: string;
  feeStructure: string;
  itemsIncluded: ItemsIncluded[];
  nudgeIcon: string;
  createdAt: string;
  updatedAt: string;
  isPlanPurchased: boolean;
  tax: number;
  priceAfterDiscount: number;
  total: number;
  linkedPlan: string;
  isStoreItemApplicable: boolean;
  marketingCutPrice: number;
  isBest: boolean;
  videoStripTitle: string;
  mainSheetTitle: string;
  videoDetails: VideoDetail[];
  banner: Banner;
  ctaTitle: string;
  imageId: string;
  planVideoId: string;
  offeringData: OfferingData[];
  isHighest: boolean;
  tagTitle: string;
  upgradeBanner: UpgradeBanner;

  constructor(data: any = {}) {
    this._id = data._id || '';
    this.offerings = new Offerings(data.offerings) || new Offerings({});
    this.price = data.price || 0;
    this.duration = data.duration || 0;
    this.durationType = data.durationType || '';
    this.title = data.title || '';
    this.discount = data.discount || 0;
    this.displayOrder = data.displayOrder || 0;
    this.isRecommended = data.isRecommended || false;
    this.status = data.status || '';
    this.isAvailableFromPoints = data.isAvailableFromPoints || false;
    this.maxWalletPoints = data.maxWalletPoints || 0;
    this.type = data.type || '';
    this.description = data.description || '';
    this.batchId = data.batchId || '';
    this.feeStructure = data.feeStructure || '';
    if (data.itemsIncluded) {
      this.itemsIncluded = data.itemsIncluded.map(
        (item: any) => new ItemsIncluded(item)
      );
    } else {
      this.itemsIncluded = [];
    }
    this.nudgeIcon = data.nudgeIcon || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.isPlanPurchased = data.isPlanPurchased || false;
    this.tax = data.tax || 0;
    this.priceAfterDiscount = data.priceAfterDiscount || 0;
    this.total = data.total || 0;
    this.linkedPlan = data.linkedPlan || '';
    this.isStoreItemApplicable = data.isStoreItemApplicable || false;
    this.marketingCutPrice = data.marketingCutPrice || 0;
    this.isBest = data.isBest || false;
    this.videoStripTitle = data.videoStripTitle || '';
    this.mainSheetTitle = data.mainSheetTitle || '';

    if (data.videoDetails) {
      this.videoDetails = data.videoDetails.map(
        (item: any) => new VideoDetail(item)
      );
    } else {
      this.videoDetails = [];
    }

    this.banner = new Banner(data.banner) || new Banner({});
    this.imageId = data.imageId || '';
    this.planVideoId = data.planVideoId || '';

    if (data?.offeringData) {
      this.offeringData = data?.offeringData?.map(
        (offering: OfferingData) => new OfferingData(offering)
      );
    } else {
      this.offeringData = [new OfferingData({})];
    }

    this.isHighest = data.isHighest || false;
    this.ctaTitle = data.ctaTitle || '';
    this.tagTitle = data.tagTitle || '';
    this.upgradeBanner =
      new UpgradeBanner(data?.upgradeBanner) || new UpgradeBanner({});
  }
}

class OfferingData {
  subOffering: string[];
  constructor(data: any) {
    this.subOffering = data?.subOffering?.map((item: string) => item) || [];
  }
}

export class Banner {
  description: string;
  image: BannerImage[];

  constructor(data: any = {}) {
    this.description = data.description || '';

    if (data?.image) {
      this.image = data.image.map((id: any) => new BannerImage(id));
    } else {
      this.image = [new BannerImage({})];
    }
  }
}

export class BannerImage {
  webImage: string;

  constructor(data: any = {}) {
    this.webImage = data.webImage || '';
  }
}

export class VideoDetail {
  title: string;
  sheetTitle: string;
  icon: string;
  videoIds: VideoID[];

  constructor(data: any = {}) {
    this.title = data.title || '';
    this.sheetTitle = data.sheetTitle || '';
    this.icon = data.icon || '';

    if (data.videoIds) {
      this.videoIds = data.videoIds.map((id: any) => new VideoID(id));
    } else {
      this.videoIds = [];
    }
  }
}

export class VideoID {
  _id: string;

  constructor(data: any = {}) {
    this._id = data._id || '';
  }
}

export class ItemsIncluded {
  type: string;
  typeId: string;
  price: number;
  isFree: boolean;
  _id: string;
  categoryId: string;

  constructor(data: any = {}) {
    this.type = data.type || '';
    this.typeId = data.typeId || '';
    this.price = data.price || 0;
    this.isFree = data.isFree || false;
    this._id = data._id || '';
    this.categoryId = data.categoryId || '';
  }
}

export class Offerings {
  liveSessions: boolean;
  doubtSolving: boolean;
  dailyDppWithSolutions: boolean;
  regularTests: boolean;
  SPD: boolean;
  communityAccess: boolean;
  handwrittenNotes: boolean;
  offlineRankerTest: boolean;
  pitara: boolean;
  mentorship: boolean;
  infinitePractice: boolean;
  khazana: boolean;
  saarthi: boolean;

  constructor(data: any = {}) {
    this.liveSessions = data?.liveSessions || false;
    this.doubtSolving = data?.doubtSolving || false;
    this.dailyDppWithSolutions = data?.dailyDppWithSolutions || false;
    this.regularTests = data?.regularTests || false;
    this.SPD = data?.SPD || false;
    this.communityAccess = data?.communityAccess || false;
    this.handwrittenNotes = data?.handwrittenNotes || false;
    this.offlineRankerTest = data?.offlineRankerTest || false;
    this.pitara = data?.pitara || false;
    this.mentorship = data?.mentorship || false;
    this.infinitePractice = data?.infinitePractice || false;
    this.khazana = data?.khazana || false;
    this.saarthi = data?.saarthi || false;
  }
}

export class ComparisionTable {
  bundleName: string;
  offering: Offering[];
  _id: string;

  constructor(data: any = {}) {
    this.bundleName = data.bundleName || '';
    this._id = data._id || '';

    if (data?.offering?.length > 0) {
      this.offering = data.offering.map((o: any) => new Offering(o));
    } else {
      this.offering = [];
    }
  }
}

class Offering {
  displayName: string;
  description: string;
  _id: string;

  constructor(data: any = {}) {
    this.displayName = data.displayName || '';
    this.description = data.description || '';
    this._id = data._id || '';
  }
}

export class ActiveTabs {
  _id: string;
  type: string;
  typeId: string;
  defaultSaarthiPlanId: string;

  constructor(data: any = {}) {
    this._id = data?._id || '';
    this.type = data?.type || '';
    this.typeId = data?.typeId || '';
    this.defaultSaarthiPlanId = data?.defaultSaarthiPlanId || '';
  }
}

export class PlanUpgradeModel {
  imageUrl: string;
  type: string;
  title: string;
  description: string;

  constructor(data: any) {
    let d = data || {};
    this.imageUrl = d.imageUrl || '';
    this.type = d.type || '';
    this.title = d.title || '';
    this.description = d.description || '';
  }
}

export class UpgradeBanner {
  imageUrl: string;
  type: string;
  title: string;
  totalUpgradePopUp: number;
  upgradeInterval: number;

  constructor(data: any = {}) {
    this.imageUrl = data?.imageUrl ?? '';
    this.type = data?.type ?? '';
    this.title = data?.title ?? '';
    this.totalUpgradePopUp = data?.totalUpgradePopUp ?? 0;
    this.upgradeInterval = data?.upgradeInterval ?? 0;
  }
}
// batch clubbing
export interface ClubbingOfferingApiResponse {
  success: boolean;
  data: ClubbingOfferingData;
}

export class ClubbingOfferingData {
  clubbingData: ClubbingOfferingList;
  storeItems: StoreItem[];

  constructor(data: any = {}) {
    this.clubbingData =
      new ClubbingOfferingList(data.clubbingData) ||
      new ClubbingOfferingList({});

    if (data?.storeItems?.length) {
      this.storeItems = data.storeItems.map((item: any) => new StoreItem(item));
    } else {
      this.storeItems = [];
    }
  }
}

export class ClubbingOfferingList {
  offerings: ClubbingOffering[];
  constructor(data: any = {}) {
    if (data?.offerings?.length) {
      this.offerings = data.offerings.map(
        (item: any) => new ClubbingOffering(item)
      );
    } else {
      this.offerings = [];
    }
  }
}

class ImageUrls {
  webImageUrl: string;
  constructor(data: any) {
    this.webImageUrl = data?.webImageUrl || '';
  }
}

export class ClubbingOffering {
  type: string;
  name: string;
  description: string;
  icon: string;
  deepLink: string;
  webDeepLink: string;
  message: string;
  imageUrls: ImageUrls;

  constructor(data: any = {}) {
    this.type = data.type || '';
    this.name = data.name || '';
    this.description = data.description || '';
    this.icon = data.icon || '';
    this.deepLink = data.deepLink || '';
    this.webDeepLink = data.webDeepLink || '';
    this.message = data.message || '';
    if (data.imageUrls) {
      this.imageUrls = new ImageUrls(data.imageUrls);
    }
  }
}

export class StoreItem {
  _id: string;
  pwStoreOrderId: string;
  isPurchased: boolean;
  id: string;
  type: string;
  ratingCount: number;
  quantity: number;
  price: number;
  name: string;
  description: string;
  language: string;
  image: ImageId;
  categoryId: string;

  constructor(data: any = {}) {
    this._id = data._id || '';
    this.pwStoreOrderId = data.pwStoreOrderId || '';
    this.isPurchased = data.isPurchased || false;
    this.id = data.id || '';
    this.type = data.type || '';
    this.ratingCount = data.ratingCount || 0;
    this.quantity = data.quantity || 0;
    this.price = data.price || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.language = data.language || '';
    this.categoryId = data.categoryId || '';
    this.image = new ImageId(data.image) || new ImageId({});
  }
}

export class InstalmentDetails {
  instalmentNumber: number;
  price: number;
  dueDate: string;

  constructor(data: any) {
    this.instalmentNumber = data?.instalmentNumber || 0;
    this.price = data?.price || 0;
    this.dueDate = data?.dueDate || '';
  }
}

export class PartPayment {
  centerId: string;
  price: number;
  totalAmount: number;
  total: number;
  discount: number;
  schemeId: number;
  instalmentDetails: Array<InstalmentDetails> | null;
  feeDetails: Array<FeeDetails> | null;

  constructor(data: any) {
    this.centerId = data?.centerId || '';
    this.price = data?.price || 0;
    this.discount = data?.discount || 0;
    this.schemeId = data?.schemeId || '';
    this.totalAmount = data?.totalAmount || 0;
    this.total = data?.total || 0;

    if (data?.instalmentDetails) {
      this.instalmentDetails = data?.instalmentDetails?.map(
        (detail: InstalmentDetails) => new InstalmentDetails(detail)
      );
    } else {
      this.instalmentDetails = null;
    }

    if (data?.feeDetails) {
      this.feeDetails = data?.feeDetails?.map(
        (detail: FeeDetails) => new FeeDetails(detail)
      );
    } else {
      this.feeDetails = null;
    }
  }
}

export class FeeDetails {
  label: string;
  price: number;
  displayOrder: number;

  constructor(data: any) {
    this.label = data?.label || '';
    this.price = data?.price || 0;
    this.displayOrder = data?.displayOrder || 0;
  }
}

export class FeeStructure {
  _id: string;
  price: number;
  discount: number;

  constructor(data: any) {
    this._id = data?._id || '';
    this.price = data?.price || 0;
    this.discount = data?.discount || 0;
  }
}

export class BatchPlansResponse {
  title: string;
  description: string;
  price: number;
  total: number;
  gst: number;
  duration: number;
  durationType: string;
  discount: number;
  displayOrder: number;
  batchId: string;
  status: string;
  feeStructure: FeeStructure;
  type: string;
  marketingCutPrice: number;
  itemsTotal: number;
  _id: string;
  partPayment: PartPayment;
  isStoreItemApplicable: boolean;

  constructor(data: any) {
    this.title = data?.title || '';
    this.description = data?.description || '';
    this.price = data?.price || 0;
    this.total = data?.total || 0;
    this.gst = data?.gst || 0;
    this.duration = data?.duration || 0;
    this.durationType = data?.durationType || '';
    this.discount = data?.discount || 0;
    this.displayOrder = data?.displayOrder || 0;
    this.batchId = data?.batchId || '';
    this.status = data?.status || '';
    this.type = data?.type || '';
    this.marketingCutPrice = data?.marketingCutPrice || 0;
    this.itemsTotal = data?.itemsTotal || 0;
    this.isStoreItemApplicable = data?.isStoreItemApplicable || false;
    this._id = data?._id || '';
    if (data?.feeStructure) {
      this.feeStructure = new FeeStructure(data?.feeStructure);
    } else {
      this.feeStructure = new FeeStructure({});
    }

    if (data?.partPayment) {
      this.partPayment = new PartPayment(data?.partPayment);
    } else {
      this.partPayment = new PartPayment({});
    }
  }
}

export class BatchPlans {
  plans: Array<BatchPlansResponse>;

  constructor(data: any) {
    this.plans =
      data?.plans?.map(
        (plan: BatchPlansResponse) => new BatchPlansResponse(plan)
      ) || [];
  }
}

export class Invoice {
  key: string;
  baseUrl: string;

  constructor(data: any) {
    this.key = data?.key || '';
    this.baseUrl = data?.baseUrl || '';
  }
}
export class EMIInVoiceData {
  invoiceNumber: string;
  invoice: Invoice;

  constructor(data: any) {
    this.invoiceNumber = data?.invoiceNumber || '';
    this.invoice = new Invoice(data?.invoice || {});
  }
}

export class EMIInvoiceResponse {
  data: EMIInVoiceData;

  constructor(data: any) {
    this.data = new EMIInVoiceData(data?.data);
  }
}

export class Instalment {
  orderId: string;
  amount: number;
  discount: number;
  installmentNumber: number;
  totalInstalments: number;
  paymentMode: string;
  emiId: string;
  dueDate: string;
  status: string;
  daysLeft: number;

  constructor(data: any) {
    data = data || {};
    this.orderId = data?.orderId || '';
    this.amount = data?.amount || 0;
    this.discount = data?.discount || 0;
    this.installmentNumber = data?.installmentNumber || 0;
    this.totalInstalments = data?.totalInstalments || 0;
    this.paymentMode = data?.paymentMode || '';
    this.emiId = data?.emiId || '';
    this.dueDate = data?.dueDate || '';
    this.status = data?.status || '';
    this.daysLeft = data?.daysLeft || 0;
  }
}

export class AllInstalmentData {
  instalment: Array<Instalment>;

  constructor(data: any) {
    this.instalment = data?.instalment?.map(
      (detail: Instalment) => new Instalment(detail)
    );
  }
}

export class AllInstalmentResponse {
  data: AllInstalmentData;

  constructor(data: any) {
    this.data = new AllInstalmentData(data?.data);
  }
}

export class UpcomingInstalmentData {
  orderId: string;
  amount: number;
  discount: number;
  installmentNumber: number;
  totalInstalments: number;
  paymentMode: string;
  emiId: string;
  dueDate: string;
  status: string;
  message: string;
  daysLeft: number;
  allEmisPaid: boolean;

  constructor(data: any) {
    this.orderId = data?.orderId || '';
    this.amount = data?.amount || 0;
    this.discount = data?.discount || 0;
    this.installmentNumber = data?.installmentNumber || 0;
    this.totalInstalments = data?.totalInstalments || 0;
    this.paymentMode = data?.paymentMode || '';
    this.emiId = data?.emiId || '';
    this.dueDate = data?.dueDate || '';
    this.status = data?.status || '';
    this.message = data?.message || '';
    this.daysLeft = data?.daysLeft;
    this.allEmisPaid = data?.allEmisPaid || false;
  }
}

export class UpcomingInstalmentResponse {
  data: UpcomingInstalmentData;

  constructor(data: any) {
    if (data) {
      this.data = new UpcomingInstalmentData(data?.data);
    } else {
      this.data = new UpcomingInstalmentData({});
    }
  }
}

class BatchId {
  _id: string;
  title: string;
  constructor(data: any) {
    this._id = data?._id || '';
    this.title = data?.title || '';
  }
}

export class UpcomingInstalments {
  amount: number;
  discount: number;
  batchId: BatchId;
  installmentNumber: number;
  paymentMode: string;
  emiId: string;
  dueDate: string;
  status: string;
  daysLeft: number;
  message: string;

  constructor(data: any) {
    this.amount = data?.amount || 0;
    this.discount = data?.discount || 0;
    this.batchId = data?.batchId ? new BatchId(data?.batchId) : new BatchId({});
    this.installmentNumber = data?.installmentNumber || 0;
    this.paymentMode = data?.paymentMode || '';
    this.emiId = data?.emiId || '';
    this.dueDate = data?.dueDate || '';
    this.status = data?.status || '';
    this.daysLeft = data?.daysLeft || 0;
    this.message = data?.message || '';
  }
}

export class UpcomingInstalmentsData {
  instalments: Array<UpcomingInstalments>;

  constructor(data: any) {
    if (data?.instalments) {
      this.instalments = data?.instalments?.map(
        (detail: UpcomingInstalments) => new UpcomingInstalments(detail)
      );
    } else {
      this.instalments = [new UpcomingInstalments({})];
    }
  }
}

export class EMIInfoResponse {
  publicKey: string;
  payerName: string;
  payerPhone: string;
  payerEmail: string | null;
  transactionId: string;
  orderId: string;
  productInfo: string;
  surl: string;
  furl: string;
  hash: string;
  uniqueId: string;
  clientPlatform: string;

  constructor(data: any) {
    this.publicKey = data?.publicKey || '';
    this.payerName = data?.payerName || '';
    this.payerPhone = data?.payerPhone || '';
    this.payerEmail = data?.payerEmail || null;
    this.transactionId = data?.transactionId || '';
    this.orderId = data?.orderId || '';
    this.productInfo = data?.productInfo || '';
    this.surl = data?.surl || '';
    this.furl = data?.furl || '';
    this.hash = data?.hash || '';
    this.uniqueId = data?.uniqueId || '';
    this.clientPlatform = 'WEB';
  }
}

export class EzPayResponse {
  url: string;
  token: string;

  constructor(data: any) {
    this.url = data?.url || '';
    this.token = data?.token || '';
  }
}

export interface EzPayRequiredPayload {
  surl: string;
  furl: string;
  batchId: string;
  installment_number?: number;
  due_date?: string;
  time_window?: number;
  page?: string;
}

export class BatchStatus {
  batchUserSegment: string;
  landingPage: string;
  isPathshala: boolean;
  seenNewMyIssues: boolean;
}
