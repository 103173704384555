import { Component, Input, OnInit } from '@angular/core';
import { TestInstructionData } from '../../../tests.model';

@Component({
  selector: 'app-start-test-detail',
  templateUrl: './start-test-detail.component.html',
  styleUrls: ['./start-test-detail.component.scss'],
})
export class StartTestDetailComponent implements OnInit {
  @Input('testInstructionData') testInstruction: TestInstructionData;
  @Input('query') query: any;
  @Input('batchId') batchId: any;
  userSubs: any;
  userInfo: any;

  constructor() {}

  ngOnInit(): void {}
}
