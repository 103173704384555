<section class="flex flex-col container_top gap-3">
  <div class="flex items-center justify-between">
    <span class="heading-4 bold">Fee and Instalments Breakup</span>
    <img
      (click)="handleOnClose()"
      class="close cursor-pointer"
      [src]="close"
      alt="cancel"
      id="close_btn"
    />
  </div>
  <div class="scrollable">
    <div>
      <div class="flex flex-col fee-card mt-3">
        <span class="bold body-1 ml-4 pt-4">Fee Structure</span>
        <app-fee-structure-table
          [variant]="'sideOverlay'"
          [plansData]="batchPlans.plans[0]"
        ></app-fee-structure-table>
      </div>
    </div>
    <div class="flex flex-col p-4 gap-4 justify-start fee-card mt-4">
      <span class="bold body-1 text-start">Instalment Details</span>
      <app-instalment-table
        [plansData]="batchPlans.plans[0].partPayment.instalmentDetails"
      ></app-instalment-table>
    </div>
  </div>
</section>
<footer
  class="book-now-cta items-center justify-center cursor-pointer"
  *ngIf="data.showContinueBtn"
  (click)="handleContinueClick()"
>
  <button>
    <span class="text-white bold-1 heading-5">Continue</span>
  </button>
</footer>
