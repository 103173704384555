import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  DialogInfo,
  TestSeriesService,
} from '../../../../common/services/tests/test-series.service';
import { lastValueFrom, Subscription, timer } from 'rxjs';
import { TestInstructionData, TestList } from '../../tests.model';
import { ShowErrorService } from '../../../../common/services/showError/show-error.service';
import { ActivatedRoute, Router } from '@angular/router';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { MAIN_PROGRAM_SLUG } from '../../../../constants/global-constant/global-constant.service';
import { GlobalService } from '../../../../common/services/global/global.service';
import { DIALOG_CLOSE_IMG } from 'src/app/core/assets.location';
import { InfoDialogComponent } from '../../info-dialog/info-dialog.component';
import { LoaderService } from '../../../../common/services/loader/loader.service';

@Component({
  selector: 'app-start-test-dialog',
  templateUrl: './start-test-dialog.component.html',
  styleUrls: ['./start-test-dialog.component.scss'],
})
export class StartTestDialogComponent implements OnInit {
  query: any;
  test: TestList;
  testInstruction: TestInstructionData;
  timeMin: number;
  timeHrs: number;
  step = 0;
  timeRemaining = 0;
  timerSubscribe: Subscription;
  batchId: string;
  test_source: 'TEST_SERIES';
  closeImg = DIALOG_CLOSE_IMG;
  testSource: string;
  permissionWarning: DialogInfo = {
    title: 'Before you start',
    description:
      'You have to compulsorily Grant access of Camera & Microphone for starting the test',
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StartTestDialogComponent>,
    private dialog: MatDialog,
    private _testService: TestSeriesService,
    private showErrorService: ShowErrorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private loaderService: LoaderService
  ) {
    this.query = data.obj;
    this.test = data.testList;
    this.testSource = data.obj.testSource;
    this.getTestInstructions(this.query);
    this.testInstruction = <TestInstructionData>{};
    this.batchId = this.data.batchId;
  }

  ngOnInit(): void {
    this.timeRemaining = differenceInSeconds(
      new Date(this.test.startTime),
      new Date()
    );

    this.timeMin = this.test.maxDuration;
    this.timeHrs = this.timeMin / 60;
    if (this.timeRemaining > 0) {
      this.timer();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async getTestInstructions(query: any) {
    try {
      const res = await lastValueFrom(
        this._testService.getTestInstructions(query)
      );
      if (res) {
        this._testService.setInstructionsData(res);
        this.testInstruction = res;
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
    }
  }

  checkProctoring() {
    if (this.test?.config?.proctoring && !this.query?.reattempt) {
      this.openPermissionDialog();
    } else {
      this.startTest();
    }
  }

  startTest() {
    const params: any = {
      jeePattern: this.test.enableNewJeeTestPatter ? 'true' : 'false',
      neetPattern: this.test.enableNewNeetTestPatter ? 'true' : 'false',
      testMappingId: this.query.testMappingId,
      batchId: this.batchId,
      testSource: this.testSource,
      reattempt: this.query.reattempt,
    };
    if (this.data.childUrl) {
      params['childUrl'] = this.data.childUrl;
    }
    this.globalService.setTestBackUrl(this.router.url);
    this.router.navigate(
      [`/${MAIN_PROGRAM_SLUG}/tests/${this.test.slug}/test-engine`],
      { queryParams: params }
    );
    this.dialogRef.close();
  }

  openPermissionDialog() {
    this._testService._infoData$.next(this.permissionWarning);
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '420px',
      panelClass: 'info-card',
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data && data === 'check_permission') {
        this.checkPermission();
      } else {
        this.globalService.showSnackBar(
          'Please allow permission to start the test'
        );
      }
    });
  }

  checkPermission() {
    this.loaderService.loadData('Loading...');
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        this.startTest();
        this.loaderService.unloadData('Loading...');
        // this.globalObjectService.window!.localStream = stream; // A
        // window.localAudio.srcObject = stream; // B
        // window.localAudio.autoplay = true; // C
      })
      .catch((err) => {
        // this.openPermissionDialog();
        this.globalService.showSnackBar(
          'Please allow permission to start the test'
        );
        this.loaderService.unloadData('Loading...');
        return false;
      });
  }

  timer() {
    const source = timer(1000, 1000);
    this.timerSubscribe = source.subscribe((val) => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        this.timerSubscribe.unsubscribe();
      }
    });
  }
}
