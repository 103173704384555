import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { PPApiOptions } from 'src/app/common/api/api.type';
import { PPApiService } from 'src/app/common/api/api.service';

import { handleError } from 'src/app/common/services/error-handler/error-handler.service';

import { BehaviorSubject, catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResultService {
  resultsActiveTab = new BehaviorSubject('');
  activeYearResult = new BehaviorSubject('');

  constructor(
    private storageService: StorageService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private apiService: PPApiService
  ) {}

  async logEvent(eventName: string, data: any) {
    this.firebaseAnalytics.logEvents(eventName, data);
  }

  getResults() {
    const options: PPApiOptions = {
      apiPath: 'v1/result',
    };
    return this.apiService.get(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }
}
