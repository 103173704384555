import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { ACTIVATION } from 'src/app/core/assets.location';
import { BatchDetailModel } from '../../../batch-overview/batch-overview.model';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { Subscription } from 'rxjs';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { ACTIVATION_FORM_START } from 'src/app/core/analytics-events';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-batch-activation-card',
  templateUrl: './batch-activation-card.component.html',
  styleUrls: ['./batch-activation-card.component.scss'],
})
export class BatchActivationCardComponent implements OnInit, OnDestroy {
  activation = ACTIVATION;
  @Input() isMasterBatch: boolean = false;
  @Input() pageName: string = '';
  batchDetail: BatchDetailModel;
  batchDetailSub: Subscription;
  activationFormUrl: string = '';
  userSegmentGA: string;
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isMasterBatch: boolean;
      pageName: string;
    },
    private batchService: BatchService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private _globalService: GlobalService,
    @Optional() private dialogRef: MatDialogRef<BatchActivationCardComponent>
  ) {
    this.batchDetailSub = this.batchService._batchDetails$.subscribe((res) => {
      this.batchDetail = res;
      this.activationFormUrl = res?.config?.batchActivationFormLink;
    });
    this.userSegmentGA = this._globalService.getUserSegmentFromStorage();
  }

  ngOnInit(): void {
    this.pageName = this.pageName || this.data?.pageName;
    this.isMasterBatch = this.isMasterBatch || this.data?.isMasterBatch;
  }

  ngOnDestroy(): void {
    this.batchDetailSub.unsubscribe();
  }

  handleActivationFormRedirection() {
    if (!this.batchDetail?.config?.isFormFilled && this.activationFormUrl) {
      const eventData = {
        batch_id: this.batchDetail?._id,
        batch_name: this.batchDetail?.name,
        isthis_batch_premium: this.batchDetail?.masterBatchId ? 'yes' : 'no',
        user_type:
          this._globalService.getIsPathShalaFromStorage() === 'true'
            ? 'offline'
            : 'online',
        user_segment: this.userSegmentGA,
        page: this.pageName,
      };
      this.firebaseAnalytics.logEvents(
        ACTIVATION_FORM_START,
        eventData,
        false,
        true,
        true,
        true
      );
      if (this.dialogRef) {
        this.dialogRef.close();
      }
      window.open(this.activationFormUrl, '_blank');
    }
  }
}
