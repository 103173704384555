import { CLOSE } from './../../../../../../../core/assets.location';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from '../../../../../../../common/services/global/global.service';
import { DoubtsService } from '../../../../../../../common/services/doubts/doubts.service';
import { lastValueFrom } from 'rxjs';
import { USER_IMG } from '../../../../../../../core/assets.location';
import { ExpertInfoModel } from 'src/app/common/services/doubts/doubt.model';
import { LoaderService } from '../../../../../../../common/services/loader/loader.service';

@Component({
  selector: 'app-sme-details-modal',
  templateUrl: './sme-details-modal.component.html',
  styleUrls: ['./sme-details-modal.component.scss'],
})
export class SmeDetailsModalComponent implements OnInit {
  userImg = USER_IMG;
  cross = CLOSE;
  batchId: string;
  teacherId: string;
  expertInfo: ExpertInfoModel;
  teacherImage: string;

  constructor(
    public dialogRef: MatDialogRef<SmeDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { batchId: string; teacherId: string },
    private globalService: GlobalService,
    private doubtService: DoubtsService,
    private loaderService: LoaderService
  ) {
    this.batchId = data.batchId;
    this.teacherId = data.teacherId;
  }

  ngOnInit(): void {
    this.getTeacherData();
  }

  close() {
    this.dialogRef.close();
  }

  async getTeacherData() {
    this.loaderService.loadData('Loading...');
    if (!this.teacherId && !this.batchId) {
      this.close();
      this.globalService.showSnackBar('Check yor internet connection!');
    } else {
      try {
        const res = await lastValueFrom(
          this.doubtService.getExpertInfo(this.batchId, this.teacherId)
        );
        if (res) {
          this.expertInfo = new ExpertInfoModel(res);
          this.teacherImage =
            this.expertInfo.imageId?.baseUrl + this.expertInfo.imageId?.key;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loaderService.unloadData('Loading...');
      }
    }
  }
}
