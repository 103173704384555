<article
  class="flex items-center justify-between w-full py-4 pl-3 md:pl-6 pr-2 md:pr-4"
  [ngClass]="{ 'border-b': titleLength > 0 }"
>
  <ng-container *ngIf="titleLength > 0">
    <span class="heading-4 semibold title">{{ title }}</span>
  </ng-container>

  <div
    class="close flex justify-center items-center w-8 h-8 rounded-full cursor-pointer"
    (click)="close('close')"
  >
    <img [src]="closeImg" alt="close" />
  </div>
</article>
<main class="px-3 md:px-6 pb-3 md:pb-0 py-4">
  <ng-container *ngIf="activeType === 'saarthi'">
    <app-saarthi-duration-wrapper
      (emitter)="close($event)"
    ></app-saarthi-duration-wrapper>
  </ng-container>

  <ng-container *ngIf="activeType === 'address'">
    <app-address-form (emitter)="close($event)"></app-address-form>
  </ng-container>

  <ng-container *ngIf="activeType === 'coupons'">
    <app-coupon-section
      [coupons]="coupons"
      (emitter)="close($event)"
      [cartAmount]="cartAmount"
      [couponSource]="couponSource"
    ></app-coupon-section>
  </ng-container>

  <ng-container *ngIf="activeType === 'combo-info'">
    <app-combo-info-section [comboData]="comboData"></app-combo-info-section>
  </ng-container>
</main>
