import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { GlobalObjectService } from '../../services/global-object/global-object.service';
import { GlobalService } from '../../services/global/global.service';
import { StorageService } from '../../services/storage/storage.service';
import { Subscription, fromEvent } from 'rxjs';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { environment } from 'src/environments/environment';

enum RESPONSE_TYPE {
  SUCCESS = 'success',
  FAIL = 'fail',
  ERROR = 'error',
}

@Component({
  selector: 'app-admit-card-modal',
  templateUrl: './admit-card-modal.component.html',
  styleUrls: ['./admit-card-modal.component.scss'],
})
export class AdmitCardModalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  isMandatory: boolean = false;
  formId: string = '';
  trigger: string = '';
  iframe: any;
  isloaded = true;
  formType: string = '';
  messageSub: Subscription;
  // url = 'https://widgets.pw.live/redirection';
  url = environment.WIDGETS_URL;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdmitCardModalComponent>,
    private globalObjectService: GlobalObjectService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private dialog: MatDialog
  ) {
    this.isMandatory = data?.isMandatory || false;
    this.formId = data?.formId || '';
    this.trigger = data?.trigger || '';
    this.formType = data?.formType || '';
  }

  handleClose() {
    this.globalService.closeAdmitCard();
    this.dialogRef.close();
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    this.isloaded = false;
  }

  ngOnInit(): void {
    const token = this.storageService.getUserToken();
    const randomId = this.globalService.getRandomId();
    const widget = 'ADMIT_CARD_FORM';

    this.url += `?token=${token}&random_id=${randomId}&widget=${widget}&form_id=${this.formId}&trigger=${this.trigger}`;
  }

  async ThankyouPagedata() {
    await this.globalService
      .getThankYouPageDetails(this.formId)
      .subscribe((data) => {
        this.openSuccessModal(data);
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.listenToMessages();
    }, 500);
  }

  listenToMessages() {
    this.messageSub = fromEvent(
      this.globalObjectService.window!,
      'message'
    ).subscribe((event: any) => {
      if (!this.globalService.isEmpty(event.data) && event.data.height) {
        this.setContentDimensions(event.data);
        return;
      }
      if (event.data === RESPONSE_TYPE.SUCCESS) {
        this.globalService.removeFormListFromStorage();
        this.globalService.handleFormList();
        this.dialogRef.close();
        this.ThankyouPagedata();
      }
    });
  }

  setContentDimensions(data: { height: number; width: number }) {
    this.iframe.style.height = data.height + 'px';
  }

  ngOnDestroy(): void {
    if (this.messageSub) this.messageSub.unsubscribe();
  }

  openSuccessModal(data: any) {
    const dialogRef = this.dialog.open(SuccessModalComponent, {
      panelClass: 'admit-card-success-modal',
      data: data,
      disableClose: true,
    });
  }
}
