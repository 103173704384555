import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-show-notification',
  templateUrl: './show-notification.component.html',
  styleUrls: ['./show-notification.component.scss'],
})
export class ShowNotificationComponent implements OnInit {
  notification = {
    title: '',
    image: '',
    body: '',
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowNotificationComponent>
  ) {
    this.notification = data.notifData;
  }

  ngOnInit(): void {}

  onNoClick(data?: any): void {
    this.dialogRef.close(data);
  }
}
