<mat-toolbar class="app-nav-toolbar border-b">
  <div class="flex w-full items-center justify-between">
    <button
      (click)="toggleSideNav()"
      class="side-nav"
      mat-button
      id="menu_btn"
      *ngIf="urlArray.length < 2"
    >
      <mat-icon class="menu-icon">menu</mat-icon>
    </button>

    <div class="cursor-pointer">
      <button
        mat-button
        class="bg-white text-gray-800 font-semibold rounded-full"
        (click)="goBack()"
      >
        <div class="flex gap-1 items-center">
          <mat-icon>arrow_back_ios</mat-icon> <span>Back </span>
        </div>
      </button>
    </div>
    <div *ngIf="urlArray.length < 2"></div>

    <div class="flex">
      <div
        (click)="openPlaystore()"
        class="hidden md:block"
        [ngClass]="{ 'border-left pl-4': showLottie() }"
        class="flex items-center mx-2 border-right pr-4 cursor-pointer"
      >
        <span class="caption-1 medium download-text">Download App</span>
        <img [src]="playstoreImg" class="object-contain w-20 ml-1" />
      </div>

      <div
        class="flex items-center"
        *ngIf="shouldShowProfile"
        [matMenuTriggerFor]="menu"
      >
        <h4 class="hi-text body-2 semibold hidden md:block">
          Hi, {{ userInfo?.firstName || '' }}
        </h4>
        <div
          class="flex items-center justify-center p-1 profile-img-div md:ml-4"
        >
          <img
            [src]="
              userInfo && userInfo.imageId
                ? userInfo.imageId.baseUrl + userInfo.imageId.key
                : userInfo &&
                  userInfo['profileId'].gender &&
                  userInfo['profileId'].gender === 'Male'
                ? boyImg
                : girlImg
            "
            alt=""
            class="rounded-full"
          />
        </div>
        &nbsp;
        <span
          class="flex items-center justify-center caret down cursor-pointer arrow"
        ></span>
      </div>
      <mat-menu #menu="matMenu" class="mat-menu">
        <div (click)="goToProfile()" class="flex items-center" mat-menu-item>
          <mat-icon>perm_identity</mat-icon>
          <span class="body-2 medium">My Profile</span>
        </div>
        <div
          (click)="goToPurchase()"
          class="flex items-center border-bottom"
          mat-menu-item
        >
          <mat-icon>card_travel</mat-icon>
          <span class="body-2 medium">My Purchase</span>
        </div>
        <span class="body-2 medium" (click)="handleLogout()" mat-menu-item>
          <mat-icon>logout</mat-icon>Logout
        </span>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<div
  *ngIf="isLoaded"
  class="h-[100vh] w-[100vh] flex justify-center items-center mt-5"
>
  <div class="loader"></div>
</div>

<article class="container" [hidden]="isLoaded">
  <iframe
    #webinarIframe
    [ngClass]="{
      'responsive-iframe-k8': centreK8 === 'true',
      'responsive-iframe': centreK8 !== 'k8'
    }"
    id="webinarIframe"
    (load)="checkLoaded($event, webinarIframe)"
    [src]="url | safe : 'resourceUrl'"
    allow="accelerometer; autoplay;fullscreen; encrypted-media; gyroscope; picture-in picture"
  ></iframe>
</article>
