<!-- <section class="image-sol-section">
  <div class="modal-title flex justify-end">
    <mat-icon
      (click)="dismiss()"
      role="img"
      class="mat-icon notranslate cursor-pointer material-icons mat-icon-no-color"
      aria-hidden="true"
      data-mat-icon-type="font"
      >close</mat-icon
    >
  </div>
  <div>
    <img class="image-sol mx-auto" [src]="data.imageLink" />
  </div>
</section> -->

<section class="flex flex-col items-center justify-center">
  <img class="image-sol" [src]="data.imageLink" />
  <img
    [src]="cross"
    alt="close"
    class="mt-2 cursor-pointer"
    (click)="dismiss()"
  />
</section>
