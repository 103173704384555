import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { differenceInSeconds, isAfter, isBefore } from 'date-fns';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { GlobalService } from 'src/app/common/services/global/global.service';
import {
  Placeholder,
  StudyService,
} from 'src/app/common/services/study/study.service';
import {
  ADD_ATTACHMENT_ICON,
  BANNER_IMG,
  BLUE_PLAY_ICON,
  CLOCK_IMG,
  CLOCK_IMG_STUDY_VIDEO_CARD,
  MARKED_COMPLETE_GREEN_ICON,
  MARK_COMPLETE_ICON,
  NEW_LIVE,
  NEW_PLAY_ICON,
  NOT_RATED_IMG,
  PLAY_IMG,
  RATED_IMG,
  RED_PLAY_ICON,
} from 'src/app/core/assets.location';
import {
  BatchDetailModel,
  TodayScheduleModel,
  Subject,
  AllPurchasedBatches,
} from '../../../batch-overview/batch-overview.model';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { WIDGET_CLICK } from 'src/app/core/analytics-events';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import { MatDialog } from '@angular/material/dialog';
import { RatingComponent } from '../../../batch-video-player/rating/rating.component';
import { interval, lastValueFrom, Subscription } from 'rxjs';
import {
  ContentService,
  SaveVideoStatsModal,
} from 'src/app/common/services/content/content.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { PurchaseAlertComponent } from 'src/app/common/components/purchase-alert/purchase-alert.component';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { MAIN_PROGRAM } from 'src/app/constants/global-constant/global-constant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachmentDialogComponent } from '../../../batch-weekly-schedule/attachment-dialog/attachment-dialog.component';
import { STUDY_PAGE_EVENT } from 'src/app/core/analytics-events';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { PartPaymentService } from 'src/app/common/services/part-payment/part-payment.service';
import { UpcomingInstalmentData } from 'src/app/common/services/batch/batch.modal';
@Component({
  selector: 'app-study-video-card',
  templateUrl: './study-video-card.component.html',
  styleUrls: ['./study-video-card.component.scss'],
  providers: [StudyService],
})
export class StudyVideoCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input('lectureData') lectureData: TodayScheduleModel;
  @Input('batchId') batchId: string;
  @Input() entryPointComponent: string;
  @Input() widget_type: string;
  @Input() widgetId: string;
  @Input() isOnline = true;
  @Input() variant: string;
  @Input() showRating?: boolean = true;
  @Input() showDate?: boolean = false;
  @Input() replaceStartTime?: boolean = false;
  @Input() isUnderMaintenance?: boolean = false;
  @Input() batchDetail = new BatchDetailModel({});
  @Input() upcomingInstalment = new UpcomingInstalmentData({});
  @Input() pageName: string = '';
  title: string;
  videoTitle: string;
  isPurchased: boolean;
  clockImg = CLOCK_IMG;
  time: any;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value = 0;
  bannerImg = BANNER_IMG;
  playImg = PLAY_IMG;
  markCompleteIcon = MARK_COMPLETE_ICON;
  addAttachmentIcon = ADD_ATTACHMENT_ICON;
  clockImage = CLOCK_IMG_STUDY_VIDEO_CARD;
  pagiante: boolean;
  processing: boolean;
  // batchList: Array<BatchDetailModel>;
  query: any;
  ratedImg = RATED_IMG;
  notRatedImg = NOT_RATED_IMG;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedBatch: AllPurchasedBatches;

  liveLottie: AnimationOptions = {
    path: NEW_LIVE,
  };

  playNewImg = NEW_PLAY_ICON;
  bluePlayBtn = BLUE_PLAY_ICON;
  redPlayBtn = RED_PLAY_ICON;
  markedCompleteIcon = MARKED_COMPLETE_GREEN_ICON;
  intervalSubs: Subscription;
  progress = 0;
  duration: any;
  batchDetails: BatchDetailModel;
  videoStats: SaveVideoStatsModal;
  batchSubjectId: string = '';
  userInfo: any;
  userSubs: Subscription;
  canMarkAsComplete: boolean = false;
  constructor(
    private studyService: StudyService,
    private globalService: GlobalService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private dialog: MatDialog,
    private contentService: ContentService,
    private showErrorService: ShowErrorService,
    private batchService: BatchService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private _partPaymentService: PartPaymentService
  ) {
    this.getUserInfo();
    this.getBatchDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes['lectureData'] &&
      changes['lectureData'].currentValue
    ) {
      this.lectureData = changes['lectureData'].currentValue;
      this.title =
        this.lectureData?.subjectId?.name || this.lectureData.topic || '';

      this.calculateWatchedProgress(this.lectureData);
    }
    if (changes && changes['isOnline'] && changes['isOnline'].currentValue) {
      this.isOnline = changes['isOnline'].currentValue;
    }

    if (changes && changes.isUnderMaintenance) {
      this.isUnderMaintenance = changes.isUnderMaintenance.currentValue;
    }
  }

  async ngOnInit() {
    this.selectedBatch = this.storageService.getScheduleClassBatch();
    await this.calculateTime();
    if (this.time !== 'Live' || this.time !== 'endLive') {
      this.updateTimer(this.lectureData.startTime);
    }
  }

  ngOnDestroy(): void {
    this.intervalSubs?.unsubscribe();
  }

  calculateWatchedProgress(lectureData: any) {
    const totalTime = this.studyService.changeTimeIntoSec(lectureData);
    if (this.lectureData?.lastWatchedPointInSec && totalTime > 0) {
      this.progress = Math.ceil(
        (this.lectureData.lastWatchedPointInSec / totalTime) * 100
      );
    }
  }

  formatTime(timeInSeconds: any) {
    const hours: number = Math.floor(timeInSeconds / 3600);
    const minutes: number = Math.floor((timeInSeconds % 3600) / 60);

    return (
      ('00' + hours).slice(-2) +
      ':' +
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(timeInSeconds - minutes * 60)).slice(-2)
    );
  }

  async calculateTime() {
    if (this.lectureData) {
      const startTime = new Date(this.lectureData.startTime);
      const endTime = new Date(this.lectureData.endTime);
      const now = new Date();

      if (
        this.lectureData.scheduleType &&
        this.lectureData.scheduleType === 'premiumCohort' &&
        this.lectureData.tag.length > 0
      ) {
        this.time = this.lectureData.tag;
      } else {
        if (isAfter(now, startTime) && isBefore(now, endTime)) {
          this.time = 'Live';
        } else if (isAfter(now, endTime)) {
          this.time = 'endLive';
          const timeInSeconds = differenceInSeconds(
            new Date(endTime).getTime(),
            new Date(startTime).getTime()
          );
          this.duration = this.formatTime(timeInSeconds);
        } else {
          this.time = this.lectureData.startTime;
        }

        if (isBefore(endTime, now)) {
          this.canMarkAsComplete = true;
        }

        if (this.time === 'endLive' || this.time === 'Live') {
          this.intervalSubs?.unsubscribe();
        }
      }
    }
  }

  logStudyEvent(url: string): void {
    const data = {
      widgetid: this.widgetId,
      widget_type: this.widget_type,
      widget_redirection: url,
      placeholder: Placeholder.STUDY_PAGE,
    };
    this.firebaseAnalytics.logEvents(
      WIDGET_CLICK,
      data,
      false,
      true,
      true,
      true,
      true
    );
  }

  handleVideoClickEvent(isAction?: boolean, action?: string) {
    const lecture = new TodayScheduleModel(this.lectureData);
    const data = {
      batch_selected: this.batchDetails.name.length
        ? this.batchDetails.name
        : this.selectedBatch.batch.name,
      batch_id: this.batchDetails._id.length
        ? this.batchDetails._id
        : this.selectedBatch.batch._id,
      video_id:
        (this.lectureData.videoDetails && this.lectureData.videoDetails._id) ||
        this.lectureData._id ||
        '',
      video_type: lecture?.isLive
        ? 'live'
        : lecture.isUpcoming
        ? 'upcoming'
        : 'ended',
      schedule_id: this.lectureData._id,
      video_section: this.variant.toLowerCase().includes('weekly')
        ? 'weekly_schedule'
        : 'todays_class',
      tab_selected:
        this.activatedRoute.snapshot.queryParamMap.get('week') || '',
    };
    if (isAction) {
      this.firebaseAnalytics.logEvents(
        STUDY_PAGE_EVENT.VIDEO_ACTION,
        {
          ...data,
          action,
        },
        true,
        true,
        true,
        true
      );
      return;
    }
    this.firebaseAnalytics.logEvents(
      STUDY_PAGE_EVENT.VIDEO_CLICK,
      data,
      true,
      true,
      true,
      true
    );
  }

  openPurchaseDialog() {
    const dialogRef = this.dialog.open(PurchaseAlertComponent, {
      data: {
        batchId: this.batchId,
      },
      height: '160px',
      width: '450px',
      panelClass: 'purchase-alert',
    });
  }

  isBatchLocked() {
    if (
      this.batchDetail?.isPurchased &&
      this._partPaymentService.shouldLockedForPartPayment(
        this.batchDetail?.config
      )
    ) {
      this._partPaymentService.handleBatchPopup(
        this.batchDetail,
        this.upcomingInstalment,
        this.pageName
      );
      return true;
    }

    return false;
  }

  goToVideo(event: any) {
    if (this.isBatchLocked()) {
      return;
    }
    // if (!this.batchData.isPurchased && !event.isFree) {
    //   this.openPurchaseDialog();
    // this.globalService.showSnackBar('Batch is not purchased');
    //   return;
    // }
    this.handleVideoClickEvent();
    if (this.lectureData.status === 'CANCELED') {
      this.globalService.showSnackBar('Class has been Canceled.');
      return;
    }

    if (
      this.time === 'Live' &&
      !this.isOnline &&
      !this.lectureData?.videoDetails?.embedCode &&
      !this.lectureData?.videoDetails?.videoUrl &&
      !this.lectureData?.videoDetails?.hls_url &&
      !this.lectureData?.url &&
      this.lectureData.urlType !== 'zoomWebinar'
    ) {
      this.globalService.showSnackBar(
        'Class is currently ongoing at your PW Centre. Recording of this class will be uploaded soon.'
      );
      return;
    }

    if (
      this.time === 'Live' &&
      this.isOnline &&
      !this.lectureData?.videoDetails?.embedCode &&
      !this.lectureData?.videoDetails?.videoUrl &&
      !this.lectureData?.videoDetails?.hls_url &&
      !this.lectureData?.url &&
      this.lectureData.urlType !== 'zoomWebinar'
    ) {
      this.globalService.showSnackBar(
        'Ops!! looks like there is some issue. Our team is working to rectify it. Please check after some time'
      );
      return;
    }

    if (
      this.time === 'endLive' &&
      !this.lectureData?.videoDetails?.embedCode &&
      !this.lectureData?.videoDetails?.videoUrl &&
      !this.lectureData?.videoDetails?.hls_url &&
      !this.lectureData?.url
    ) {
      this.globalService.showSnackBar(
        'The recording of the class will be uploaded soon.'
      );
      return;
    }
    if (
      this.time === 'endLive' &&
      ['vimeo', 'zoomwebinar'].includes(this.lectureData.urlType.toLowerCase())
    ) {
      this.globalService.showSnackBar('Class has been ended.');
      return;
    }

    if (this.batchId && event._id) {
      const query = {
        batchId: this.batchId,
        scheduleId: event._id,
      };
      this.studyService.goToVideo(
        event,
        query,
        this.isUnderMaintenance,
        this.entryPointComponent
      );
      this.logStudyEvent(event?.videoDetails?.name);
    } else {
      this.globalService.showSnackBar('Video details not Found');
    }
  }

  updateTimer(startTime: string) {
    const timeRemainingStart = differenceInSeconds(
      new Date(startTime),
      new Date()
    );

    if (timeRemainingStart / 86400 > 0) {
      this.intervalSubs = interval(30000).subscribe(() => {
        this.calculateTime();
      });
    }
  }

  animationCreated(animationItem: AnimationItem): void {}

  setStarRating(rating: number) {
    if (this.isBatchLocked()) {
      return;
    }

    if (this.lectureData.status === 'CANCELED') {
      this.globalService.showSnackBar('Class has been Canceled.');
      return;
    }

    const timeRemainingStart = differenceInSeconds(
      new Date(this.lectureData.startTime),
      new Date()
    );

    if (timeRemainingStart / 86400 > 0) {
      const message =
        "You can not rate this class now as class hasn't started yet";
      this.globalService.showSnackBar(message);
      return;
    }

    if (
      this.isOnline &&
      !this.lectureData?.videoDetails?.embedCode &&
      !this.lectureData?.videoDetails?.videoUrl &&
      !this.lectureData?.videoDetails?.hls_url &&
      !this.lectureData?.url
    ) {
      const message = `You can not rate this class now as class hasn't ${
        this.time === 'Live' ? 'started' : 'uploaded'
      } yet.`;
      this.globalService.showSnackBar(message);
      return;
    }

    if (this.lectureData.rating > 0) return;

    this.lectureData.rating = rating;
    // if (this.lectureData.rating > 2) {
    //   const data = {
    //     rating: this.lectureData.rating,
    //     feedback: '',
    //   };
    //   this.setRating(data);
    // } else {
    this.openRatingDialog();
    // }
  }

  openRatingDialog(action?: string): void {
    if (this.isBatchLocked()) {
      return;
    }

    if (this.lectureData.status === 'CANCELED') {
      this.globalService.showSnackBar('Class has been Canceled.');
      return;
    }

    if (this.lectureData.rating > 0 && this.lectureData?.feedback?.length > 0) {
      this.globalService.showSnackBar(SNACKBAR_MESSAGE.ALREADY_RATED);
      return;
    }

    const dialogRef = this.dialog.open(RatingComponent, {
      panelClass: 'rating-dialog',
      data: {
        rating: this.lectureData.rating,
        cameFrom: 'studycard',
        action: action ? action : 'auto',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const data = {
          rating: result?.rating || 0,
          feedback: result?.text || '',
        };
        result?.tags?.forEach((e: any) => {
          // @ts-ignore
          data['feedback'] = (data?.feedback || '') + ' #' + e;
        });
        this.lectureData.rating = data?.rating || 0;
        await this.setRating(data);
        this.handleVideoClickEvent(true, action ? 'tellus_more' : 'rating');
      } else {
        if (!action) {
          this.lectureData.rating = 0;
        }
      }
    });
  }

  async setRating(data: any) {
    data['type'] = 'BATCH_SUBJECT_SCHEDULE';
    data['typeId'] = this.lectureData._id;
    try {
      const res = await lastValueFrom(this.contentService.setRating(data));

      if (res && res['data']) {
        this.lectureData.feedback = res['data']?.feedback || '';
      }
      this.globalService.showSnackBar(SNACKBAR_MESSAGE.THANKS_FOR_RATING);
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
    }
  }

  getUserInfo() {
    this.userSubs = this.globalService.getUser().subscribe((res) => {
      if (res) {
        this.userInfo = res;
      }
    });
  }

  async getBatchDetails() {
    const data = this.batchService.getBatchData() || new BatchDetailModel({});
    this.batchDetails = data;
    const subject = this.batchDetails.subjects.filter(
      (res: Subject) => res.slug === 'this.subjectId'
    );
    this.batchSubjectId = (subject && subject[0] && subject[0]._id) || '';
  }

  async sendStats() {
    if (this.videoStats.tagId?.length === 0) return;
    this.handleVideoClickEvent(true, 'mark_complete');
    try {
      const res: any = lastValueFrom(
        this.contentService.saveVideo(this.videoStats)
      );
      if (res) {
        this.lectureData.isComplete = true;
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async markComplete() {
    if (this.isBatchLocked()) {
      return;
    }

    this.videoStats = {
      batchId: this.batchDetails?._id || '',
      batchSubjectId: this.batchSubjectId || '',
      chapterId: '',
      isComplete: true,
      lastWatchedPointInSec: 0,
      programId: MAIN_PROGRAM,
      subTopicId: '',
      subjectId: '',
      topicId: '',
      type: 'class',
      typeId: this.lectureData?._id || '',
      userId: this.userInfo.id || '',
      tagId: this.lectureData?.tags[0]?._id || '',
      videoId: this.lectureData?.videoDetails?.id || '',
      videoLength: 0,
      watchSessions: [],
    };
    this.sendStats();
  }

  openAttachmentsDialog(query: any, event: any) {
    if (this.isBatchLocked()) {
      return;
    }

    this.globalService.setTestBackUrl(this.router.url);
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      panelClass: 'attachment-dialog',
      data: {
        obj: query,
        batchId: this.batchId,
        isFree: query.isFree,
        // subjectName: this.subjectName,
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}

export enum VideoType {
  awsVideo = 'awsVideo',
}
