import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';

@Injectable({
  providedIn: 'root',
})
export class FirebaseRemoteConfigService {
  constructor(private remoteConfig: AngularFireRemoteConfig) {}

  fetchConfig() {
    return this.remoteConfig.fetchAndActivate();
  }

  getConfigValue(key: string): Promise<boolean | unknown> {
    return this.remoteConfig.getBoolean(key);
  }
}
