import { FileModal } from '../../common/services/base/base.modal';
import { ImageId } from '../tests/qBank.model';

export interface Tag {
  _id: string;
  name: string;
}

export interface Image {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
}

export interface Preview {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
}

export interface PreOrder {
  status: boolean;
  endDate: Date;
}

export interface UrlEmbeding {
  status: boolean;
  url: string;
}

export interface Author {
  name: string;
  description: string;
}

export interface Meta {
  key: string;
  value: string;
}

export interface VideoDetails {
  name: string;
  image: string;
  embedCode: string;
  description: string;
  duration: string;
  _id: string;
}

export class BookListModel {
  _id: string;
  type: string;
  name: string;
  description: string;
  quantity: number;
  status: string;
  organizationId: string;
  price: number;
  deliveryCharge: number;
  discount: number;
  tags: Tag[];
  image: Image;
  preview: Preview;
  createdAt: Date;
  displayOrder: number;
  preOrder: PreOrder;
  urlEmbeding: UrlEmbeding;
  videoType: string;
  stopSale: boolean;
  author: Author;
  meta: Meta[];
  enableStudyMaterial: boolean;
  studyMaterials: any[];
  programId?: any;
  videoDetails: VideoDetails;
  slug: string;
  isPurchased: boolean;

  constructor(data: any) {
    this._id = data._id || '';
    this.type = data.type || '';
    this.name = data.name || '';
    this.description = data.description || '';
    this.status = data.status || '';
    this.organizationId = data.organizationId || '';
    this.videoType = data.videoType || '';
    this.quantity = data.quantity || 0;
    this.price = data.price || 0;
    this.deliveryCharge = data.deliveryCharge || 0;
    this.discount = data.discount || 0;
    this.displayOrder = data.displayOrder || 0;
    this.stopSale = data.stopSale || false;
    this.enableStudyMaterial = data.enableStudyMaterial || false;
    this.tags = data.tags || [];
    this.image = data.image || {};
    this.preview = data.preview || {};
    this.createdAt = data.createdAt || '';
    this.preOrder = data.preOrder || '';
    this.urlEmbeding = data.urlEmbeding || '';
    this.author = data.author || {};
    this.meta = data.meta || [];
    this.studyMaterials = data.studyMaterials || [];
    this.programId = data.programId || '';
    this.videoDetails = data.videoDetails || '';
    this.slug = data.slug || '';
    this.isPurchased = data.isPurchased || false;
  }
}

export class CartItemModal {
  book: CartBookModal;
  chapter: object;
  content: object;
  createdAt: string;
  deliveryFee: number;
  quantity: number;
  subject: object;
  test: object;
  testCategory: object;
  type: string;
  typeId: string;
  id: string;
  isDeliverable: boolean;
  finalPrice: number;

  constructor(item: any) {
    this.isDeliverable = true;
    this.id = item._id || item.id || '';
    this.typeId = item.typeId || '';
    this.type = item.type || '';
    this.testCategory = item.testCategory || {};
    this.test = item.test || {};
    this.subject = item.subject || {};
    this.quantity = item.quantity || 1;
    this.deliveryFee = item.deliveryFee || item.deliveryCharge || 0;
    this.createdAt = item.createdAt || '';
    this.content = item.content || {};
    this.chapter = item.chapter || {};
    this.book = new CartBookModal(item.book) || new CartBookModal({});
    this.finalPrice = 0;
  }
}

export class CartBookModal {
  createdAt: string;
  deliveryCharge: number;
  description: string;
  imageUrl: string;
  isGSTApplicable: boolean;
  name: string;
  discount: number;
  price: number;
  quantity: number;
  type: string;
  weight: number;
  id: string;
  discountPrice: string;
  meta: Array<MetaModal> = [];
  isAvailableFromPoints: boolean;
  maxWalletPoints: number;

  constructor(book: any) {
    this.id = book._id || '';
    this.weight = book.weight || 0;
    this.type = book.type || '';
    this.quantity = book.quality || 0;
    this.price = book.price || 0;
    this.name = book.name || '';
    this.isGSTApplicable = book.isGstApplicable || false;
    if (book && book.image && book.image.key) {
      this.imageUrl = book.image.baseUrl + book.image.key;
    } else {
      this.imageUrl = '';
    }
    this.discount = book.discount || 0;
    this.description = book.description || '';
    this.deliveryCharge = book.deliveryCharge || book.deliveryFee || 0;
    this.createdAt = book.createdAt || '';
    this.discountPrice = (this.price * ((100 - this.discount) / 100)).toFixed(
      0
    );
    this.isAvailableFromPoints = book.isAvailableFromPoints || false;
    this.maxWalletPoints = book.maxWalletPoints || 0;
    if (book && book.meta && book.meta.length > 0) {
      book.meta.forEach((item: any) => {
        this.meta.push(new MetaModal(item));
      });
    } else {
      this.meta = [];
    }
  }
}

export class MetaModal {
  key: string;
  value: string;

  constructor(props: any) {
    props = props || {};
    this.key = props.key || '';
    this.value = props.value || '';
  }
}

export interface BookListObject {
  success: boolean;
  data: BookListModel[];
}

export class FaqModel {
  isShowing: boolean;
  status: string;
  displayOrder: number;
  _id: string;
  title: string;
  description: string;
  organizationId: string;
  categoryId: string;
  slug: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  videoUrl: string;
  videoType: string;
  imageId: ImageId;

  constructor(data: any) {
    this.status = data.status || '';
    this._id = data._id || '';
    this.title = data.title || '';
    this.description = data.description || '';
    this.organizationId = data.organizationId || '';
    this.categoryId = data.categoryId || '';
    this.slug = data.slug || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.isShowing = data.isShowing || '';
    this.status = data.status || '';
    this.__v = data.__v || '';
    this.videoType = data.videoType || '';
    this.videoUrl = data.videoUrl || '';
    this.imageId = data.imageId || <ImageId>{};
  }
}

export interface FaqModelObject {
  success: boolean;
  data: FaqModel[];
}

export class HardBookPlanModal {
  AWBNumber: string;
  id: string;
  price: number;
  status: string;
  address: any;
  user: any;
  orderDetails: Array<HardBookOrderDetailModal> = [];
  createdAt: string;
  response: any;
  deliveryPartner: string;

  constructor(hardBookPlan: any) {
    hardBookPlan = hardBookPlan || {};
    this.AWBNumber = hardBookPlan.AWBNumber || '';
    this.createdAt = hardBookPlan.createdAt || '';
    this.id = hardBookPlan._id || '';
    this.price = hardBookPlan.price || 0;
    this.status = hardBookPlan.status || '';
    this.address = hardBookPlan.address || {};
    this.user = hardBookPlan.user || {};
    this.response = hardBookPlan.response || {};
    this.deliveryPartner = hardBookPlan.deliveryPartner || '';
    if (
      hardBookPlan &&
      hardBookPlan.orderDetails &&
      hardBookPlan.orderDetails.length > 0
    ) {
      hardBookPlan.orderDetails.forEach((item: any) => {
        this.orderDetails.push(new HardBookOrderDetailModal(item));
      });
    } else {
      this.orderDetails = [];
    }
  }
}

export class PreOrderModal {
  endDate: string;
  status: boolean;

  constructor(props: any) {
    props = props || {};
    this.endDate = props.endDate || '';
    this.status = props.status || false;
  }
}

export class HardBookOrderDetailModal {
  id: string;
  orderId: string;
  type: string;
  price: number;
  createdAt: any;
  book: PurchasedBookModal;
  status: string;
  preOrder: PreOrderModal;

  constructor(orderDetails: any) {
    this.id = orderDetails._id || '';
    this.orderId = orderDetails.orderId || '';
    this.price = orderDetails.price || 0;
    this.type = orderDetails.type || 0;
    this.createdAt = orderDetails.createdAt || '';
    this.book =
      new PurchasedBookModal(orderDetails.book) || new PurchasedBookModal({});
    if (orderDetails && orderDetails.preOrder) {
      this.preOrder = new PreOrderModal(orderDetails.preOrder);
    } else {
      this.preOrder = new PreOrderModal({});
    }
  }
}

export class PurchasedBookModal {
  image: FileModal;
  name: string;
  price: number;
  type: string;
  discount: number;
  deliveryCharge: number;
  id: string;

  constructor(props: any) {
    props = props || {};
    this.name = props.name || '';
    this.id = props._id || '';
    this.price = props.price || 0;
    this.type = props.type || '';
    this.discount = props.discount || 0;
    this.deliveryCharge = props.deliveryCharge || 0;
    if (props && props.image) {
      this.image = new FileModal(props.image);
    } else {
      this.image = new FileModal({});
    }
  }
}

export interface HardBookObject {
  success: boolean;
  data: HardBookPlanModal[];
  paginate: {
    totalCount: number;
    limit: number;
  };
}

export interface CartObject {
  success: boolean;
  data: CartItemModal[];
}

export class CouponResModel {
  coupon: Coupon;
  totalAmount: number;
  discountedAmount: number;
  finalAmount: number;

  constructor(data: any) {
    data = data || {};
    this.coupon = new Coupon(data.coupon || {});
    this.totalAmount = data.totalAmount || 0;
    this.discountedAmount = data.discountedAmount || 0;
    this.finalAmount = data.finalAmount || 0;
  }
}

export class Coupon {
  _id: string;
  type: string;
  offerType: string;
  currentUsage: number;
  status: string;
  packageIds: Array<any> = [];
  name: string;
  description: string;
  couponCode: string;
  startDate: string;
  endDate: string;
  usagePerCandidate: number;
  minPurchaseAmount: number;
  maxDiscountAmount: number;
  organization: string;
  couponAmount: number;
  maxUsage: number;
  createdAt: string;
  updatedAt: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.type = data.type || '';
    this.offerType = data.offerType || '';
    this.currentUsage = data.currentUsage || 0;
    this.status = data.status || '';
    if (data && data.packageIds && data.packageIds.length > 0) {
      data.packageIds.forEach((el: any) => {
        this.packageIds.push(el);
      });
    }
    this.name = data.name || '';
    this.description = data.description || '';
    this.couponCode = data.couponCode || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.usagePerCandidate = data.usagePerCandidate || '';
    this.minPurchaseAmount = data.minPurchaseAmount || '';
    this.maxDiscountAmount = data.maxDiscountAmount || '';
    this.organization = data.organization || '';
    this.couponAmount = data.couponAmount || '';
    this.maxUsage = data.maxUsage || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
  }
}
