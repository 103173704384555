<!--<p>show-notification works!</p>-->
<mat-card class="doubt-submit-card w-full">
  <div class="flex flex-wrap overflow-hidden" style="height: 80px">
    <div class="w-1/6 overflow-hidden pw-icon">
      <!-- Column Content -->
      <img [src]="notification.image" />
    </div>
    <div class="w-5/6 overflow-hidden pl-2">
      <!-- Column Content -->
      <div
        class="title-text bold heading-4 text-center w-full vertical-align justify-start"
      >
        <mat-card-title>{{ notification.title }}</mat-card-title>
      </div>
      <div class="w-full vertical-align justify-start">
        <mat-card-subtitle class="subtitle-text medium">
          {{ notification.body }}
        </mat-card-subtitle>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-around">
    <button
      (click)="onNoClick()"
      class="body-1 medium cancel-btn footer-btn"
      color="primary"
      mat-raised-button
    >
      Cancel
    </button>
    <button
      (click)="onNoClick('yes')"
      class="body-1 medium submit-btn footer-btn"
      color="primary"
      mat-raised-button
    >
      Open
    </button>
  </div>
</mat-card>
