import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { LoaderService } from 'src/app/common/services/loader/loader.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { MY_DOUBTS_FILTER } from 'src/app/core/analytics-events';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit {
  selectedBatch: number = -1;
  batchList: Array<BatchDetailModel> = [];
  // statusArray = ['All', 'Awaiting', 'Solved', 'Solution Available'];
  slug: string;
  coming_source = '';
  chatQuery = {
    page: 1,
    limit: 20,
    mode: 1,
    isDoubtEnabled: true,
    sort: 'TAG_LIST',
    filter: true,
  };
  pagination: boolean = true;
  processing = false;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private storageService: StorageService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private loaderService: LoaderService,
    private batchService: BatchService,
    public dialogRef: MatDialogRef<FilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.batchList = this.data.batchList;
    if (this.batchList.length === 1) {
      this.selectedBatch = 0;
    }
    this.coming_source = data?.coming_source || '';
  }

  ngOnInit(): void {
    this.batchList.forEach((item: any, idx: number) => {
      if (item.slug === this.data.slug) {
        this.selectedBatch = idx;
      }
    });
  }

  async gotoDoubts(data: any) {
    const fata = {
      batch_name: this.batchList[this.selectedBatch].name,
      // doubt_status: this.statusArray[this.selectedDoubt],
    };
    this.firebaseAnalytics.logEvents(MY_DOUBTS_FILTER, fata, true);

    const query = {
      slug: this.batchList[data].slug,
      id: this.batchList[data]._id,
      // status: this.statusArray[this.selectedDoubt],
    };
    this.storageService.setMyDoubtSelectedBatch(query);
    await this.globalService.cameFromUrl$.next('study');
    this.closeModal(query);
  }

  closeModal(query?: any) {
    this.dialogRef.close(query);
  }

  selectBatch(data: any) {
    this.selectedBatch = data;
  }
  selectDoubt(data: any) {}

  reset() {
    this.selectedBatch = -1;
  }

  exploreBatch() {
    this.router.navigate([`batches`]);
    this.dialog.closeAll();
  }

  async getBatchList() {
    const message = 'plase wait ...';
    this.pagination = true;
    this.processing = true;
    try {
      const res = await lastValueFrom(
        this.batchService.getAllBatches(this.chatQuery)
      );
      let list: Array<BatchDetailModel> = [];
      if (res) {
        list = res.map((item: any) => new BatchDetailModel(item));
      }
      this.pagination = list.length >= this.chatQuery.limit ? true : false;
      this.batchList = [...this.batchList, ...list];
      this.batchList.forEach((item: any, idx: number) => {
        if (item.slug === this.data.slug) {
          this.selectedBatch = idx;
        }
      });
    } catch (error) {
    } finally {
      await this.loaderService.unloadData(message);
      this.processing = false;
    }
  }

  loadMore() {
    if (this.pagination && this.data.batchList.length >= this.chatQuery.limit) {
      this.chatQuery.page++;
      this.getBatchList();
    }
  }
  onScroll(event: any) {}
}
