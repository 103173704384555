<mat-dialog-content>
  <h1 class="text-center text-red-500">Locked item</h1>
  <h3 class="text-center whitespace-normal">
    Purchase this batch to unlock all the contents
  </h3>
</mat-dialog-content>
<div class="justify-end action" mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="purchase()">Purchase</button>
</div>
