import { Injectable } from '@angular/core';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  retry,
  shareReplay,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Lecture } from '../../../pages/courses/chapter-content/chapter-content.model';
import { ChapterObject } from '../../../pages/courses/chapters/chapters.model';
import {
  TopicData,
  TopicDataRootObject,
} from '../../../pages/courses/chapters/topic-card/topics.model';
import { handleError } from '../error-handler/error-handler.service';
import { StorageService } from '../storage/storage.service';
import { AppUrlService } from '../../app-url/app-url.service';
import { CommentRootObject } from '../../../pages/feeds/feeds-tab/feeds.model';
import { MAIN_PROGRAM_SLUG } from '../../../constants/global-constant/global-constant.service';
import { QuesBankContentRootObject } from '../../../pages/tests/qBank.model';

@Injectable({
  providedIn: 'root',
})
export class ChapterContentService {
  lecture$: BehaviorSubject<Lecture[]> = new BehaviorSubject<Lecture[]>([]);
  exerciseId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  subjectList$: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  saarthiId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  saarthiPlan$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  doubtQuestion$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  dppName$: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
  pauseDpp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  CHAPTER_DATA: string = 'CHAPTER_DATA';
  TOPIC_DATA: string = 'TOPIC_DATA';
  VIDEO = 'VIDEO_DETAILS';
  PDF = 'PDF';
  KHAZANA = 'KHAZANA';

  PDF_STATS = 'PDF_STATS';
  OPEN_PDF_STATS = 'OPEN_PDF_STATS';
  ACTIVE_SECTION = 'ACTIVE_SECTION';
  constructor(
    private apiService: PPApiService,
    private storage: StorageService,
    private appUrlService: AppUrlService
  ) {}

  getLectures(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/contents`,
      params: new PPApiParams()
        .append('organizationId', ORGANIZATION_ID)
        .append('type', query.type)
        .append('page', query.page)
        .append('programId', query.programId)
        .append('subjectId', query.subjectId)
        .append('chapterId', query.chapterId)
        .append('topicId', query.topicId),
    };

    return this.apiService.get<any>(options) /*.pipe(
      map((res) => res.data),
      tap((data) => {
        if (data && data.length > 0) {
          this.lecture$.next(data);
        }
      })
    )*/;
  }

  // https://api-dev.penpencil.xyz/v2/programs/5f59d787bba8fc15f36db0ab/subjects/607428701e3c470948aa9780/chapters?page=1

  getChapters(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/${query.programId}/subjects/${query.subjectId}/chapters`,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<ChapterObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      retry(1),
      catchError(handleError)
    );
  }

  getKhazanaChapters(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/${query.programId}/subjects/${query.subjectId}/chapters`,
      params: new PPApiParams()
        .append('page', query.page)
        .append('limit', query.limit),
    };

    return this.apiService.get<ChapterObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      retry(1),
      catchError(handleError)
    );
  }

  getChaptersSlug(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${MAIN_PROGRAM_SLUG}/subjects/${query.subjectSlug}/chapters`,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<ChapterObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getTopics(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${query.programId}/subjects/${query.subjectId}/chapters/${query.chapterId}/topics`,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<TopicDataRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getSubTopics(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${query.programId}/subjects/${query.subjectId}/chapters/${query.chapterId}/topics/${query.topicId}/contents/sub-topic`,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<TopicDataRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getTopicsSlug(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${MAIN_PROGRAM_SLUG}/subjects/${query.subjectSlug}/chapters/${query.chapterSlug}/topics`,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<TopicDataRootObject>(options).pipe(
      map((res) => res.data),

      shareReplay(),
      catchError(handleError)
    );
  }

  getContents(query: any, contentId?: string) {
    let options: PPApiOptions;
    if (contentId) {
      options = {
        apiPath: `v1/programs/${query.programId}/subjects/${query.subjectId}/chapters/${query.chapterId}/topics/${query.topicId}/contents`,
        params: new PPApiParams()
          .append('page', query.page)
          .append('contentId', contentId),
      };
    } else {
      options = {
        apiPath: `v1/programs/${query.programId}/subjects/${query.subjectId}/chapters/${query.chapterId}/topics/${query.topicId}/contents`,
        params: new PPApiParams().append('page', query.page),
      };
    }

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getLectureContents(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${query.programId}/subjects/${query.subjectId}/chapters/${query.chapterId}/topics/${query.topicId}/contents`,
      params: new PPApiParams()
        .append('page', query.page)
        .append('type', query.type),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v2/programs/contents?page=1&programId=5f59d787bba8fc15f36db0ab&subjectId=607428701e3c470948aa9780&chapterId=607428841e3c470948aa9781&type=Exercise

  getQuesBankContents(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/contents`,
      params: new PPApiParams()
        .append('page', query.page)
        .append('programId', query.programId)
        .append('subjectId', query.subjectId)
        .append('chapterId', query.chapterId)
        .append('type', query.type),
    };

    return this.apiService.get<QuesBankContentRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v2/programs/contents?page=1&programId=5eb3b1017b1fb86475ec30eb&subjectId=5eb3b15acb1fef7692701042&chapterId=5eb405b90a37ee67e97a0eff&topicId=5f17f11ce480ef00bc8dd77a
  getTopicContents(query: any) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/contents`,
      params: new PPApiParams()
        .append('page', query.page)
        .append('programId', query.programId)
        .append('subjectId', query.subjectId)
        .append('chapterId', query.chapterId)
        .append('topicId', query.topicId),
    };

    return this.apiService.get<QuesBankContentRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getContentsDetails(contentSlug: string) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/contents/${contentSlug}`,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  //https://api-staging.penpencil.xyz/v1/comments
  addComment(data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/comments`,
    };

    return this.apiService
      .post(data, options)
      .pipe(retry(1), debounceTime(1000), catchError(handleError));
  }

  //https://api-staging.penpencil.xyz/v1/comments/61c9a5a276e78f00267662b0/new-upvote
  likeVideoComments(data: any, commentId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/comments/${commentId}/new-upvote`,
    };

    return this.apiService
      .post(data, options)
      .pipe(retry(1), debounceTime(1000), catchError(handleError));
  }

  getVideoComments(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/comments/${query.id}`,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<CommentRootObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getActiveSaarthi(query: any) {
    const options: PPApiOptions = {
      apiPath: `v3/saarthi/my-active-saarthi`,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getActivePlan(saarthiId: string) {
    const options: PPApiOptions = {
      apiPath: `v3/saarthi/${saarthiId}/active-plan`,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getSaarthiSujectList(query: any) {
    const options: PPApiOptions = {
      apiPath: `v3/saarthi/details`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  setVideoDetails(details: any, chapterdetails?: any) {
    this.storage.setValue(this.VIDEO, details, 'json');
    this.storage.setValue(this.CHAPTER_DATA, chapterdetails, 'json');
  }

  getVideoDetails() {
    return this.storage.getValue(this.VIDEO, 'json');
  }

  getChapterDetails() {
    return this.storage.getValue(this.CHAPTER_DATA, 'json');
  }

  removeVideoDetails() {
    return this.storage.removeValue(this.VIDEO);
  }

  removeChapterDetails() {
    return this.storage.removeValue(this.CHAPTER_DATA);
  }

  setTopicData(topics: TopicData[]) {
    this.storage.setValue(this.TOPIC_DATA, topics, 'json');
  }

  getTopicData() {
    return this.storage.getValue(this.TOPIC_DATA, 'json');
  }

  removeTopicData() {
    return this.storage.removeValue(this.TOPIC_DATA);
  }

  setBookPdf(pdf: any) {
    this.storage.setValue(this.PDF, pdf, 'json');
  }

  getBookPdf() {
    return this.storage.getValue(this.PDF, 'json');
  }

  removeBookPdf() {
    this.storage.removeValue(this.PDF);
  }

  setKhazanaSubjectData(data: any) {
    this.storage.setValue(this.KHAZANA, data, 'json');
  }

  getKhazanaSubjectData() {
    return this.storage.getValue(this.KHAZANA, 'json');
  }

  deleteKhazanaSubjectData() {
    this.storage.removeValue(this.KHAZANA);
  }
  setPdfStats(data: any) {
    this.storage.setValue(this.PDF_STATS, data, 'json');
  }
  getPdfStats() {
    return this.storage.getValue(this.PDF_STATS, 'json');
  }
  removePdfStats() {
    this.storage.removeValue(this.PDF_STATS);
  }

  setOpenPdfStats(data: any) {
    this.storage.setValue(this.OPEN_PDF_STATS, data, 'json');
  }
  getOpenPdfStats() {
    return this.storage.getValue(this.OPEN_PDF_STATS, 'json');
  }
  removeOpenPdfStats() {
    this.storage.removeValue(this.OPEN_PDF_STATS);
  }

  setActiveSection(data: any) {
    this.storage.setValue(this.ACTIVE_SECTION, data, 'json');
  }
  getActiveSection() {
    return this.storage.getValue(this.ACTIVE_SECTION, 'json');
  }
  removeActiveSection() {
    this.storage.removeValue(this.ACTIVE_SECTION);
  }
}
