import { Component, Input, OnInit } from '@angular/core';
import { PROFANE_ICON } from 'src/app/core/assets.location';

@Component({
  selector: 'app-profanity-alert-warning',
  templateUrl: './profanity-alert-warning.component.html',
  styleUrls: ['./profanity-alert-warning.component.scss'],
})
export class ProfanityAlertWarningComponent implements OnInit {
  PROFANE_ICON = PROFANE_ICON;

  @Input('irreleventMessage') irreleventMessage: string = '';
  constructor() {}

  ngOnInit(): void {}
}
