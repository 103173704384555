import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom, Subscription } from 'rxjs';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { LoaderService } from 'src/app/common/services/loader/loader.service';
import {
  Exam,
  ExamPlanModal,
  PlansModel,
  SaarthiDetails,
  SaarthiService,
  SlotModal,
} from 'src/app/common/services/saarthi/saarthi.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { CLOSE_IMG, DIALOG_CLOSE_IMG } from 'src/app/core/assets.location';
import { ActivatedRoute, Router } from '@angular/router';
import { SELECT_CLASS, SELECT_EXAM, SELECT_SLOT } from '../../snackbar.data';
import {
  SAARTHI_HOME_PAGE_VIEW,
  SAARTHI_USER_STATUS,
} from 'src/app/core/analytics-events';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { MoengageService } from 'src/app/common/services/moengage/moengage.service';

@Component({
  selector: 'app-exam-modal',
  templateUrl: './exam-modal.component.html',
  styleUrls: ['./exam-modal.component.scss'],
})
export class ExamModalComponent implements OnInit, OnDestroy {
  examDetails: Exam[] = [];
  classes: any = [];
  dialogCloseImg = DIALOG_CLOSE_IMG;
  selectedExam = '';
  selectedClass = '';
  closeicon = CLOSE_IMG;
  examData: any = {};
  activePlanModal: ExamPlanModal[] = [];
  activePlan: PlansModel;
  saarthiId: string;
  timeSlots: Array<string>[] = [];
  final: string[];
  startt_notation: string;
  endt_notation: string;
  final_time: string;
  slotModal: SlotModal[] = [];
  selectedSlot = '';
  check: string[];
  checkAgain: string;
  id: string;
  checkOnce: string[];
  checkStartTime: any;
  checkStart: any;
  slotSelected: Subscription;
  isSelected: string;
  selectedNewClass = '';
  selectedNewExam = '';
  saarthiDetails: SaarthiDetails;
  checkEnd: string[];
  end: any;
  finalEnd: any;
  finalEndTime: any;
  last: any;
  ctr: any = 0;
  lastTime: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private saarthiService: SaarthiService,
    private dialog: MatDialogRef<ExamModalComponent>,
    private globalService: GlobalService,
    private storageService: StorageService,
    private showErrorService: ShowErrorService,
    private loaderService: LoaderService,
    private router: Router,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private moengageService: MoengageService
  ) {
    if (data && data.selectedExam && data.selectedClass) {
      this.selectedExam = data.selectedExam;
      this.selectedClass = data.selectedClass;
      this.selectedSlot = data.selectedSlot;
      this.examData.exam = this.selectedExam;
      this.examData.class = this.selectedClass;
      this.examData.slot = this.selectedSlot;
    }
    this.slotSelected = this.saarthiService.timeslot$.subscribe((x) => {
      if (x != '') {
        this.selectedSlot = x;
      }
    });
  }

  async ngOnInit() {
    this.getExamDetails();
    // this.showClass(this.selectedExam);
    this.getExamPlanModalDetails();
  }

  ngOnDestroy(): void {
    if (this.slotSelected) {
      this.slotSelected.unsubscribe();
    }
  }

  async getSlots() {
    try {
      const response = await lastValueFrom(
        this.saarthiService.getSlots(this.selectedClass, this.selectedExam)
      );
      if (response) {
        this.timeSlots = [];
        this.slotModal = response;
        this.slotModal.forEach((item) => {
          let start = item.start_time.split(':');
          let end = item.end_time.split(':');
          this.final = this.saarthiService.getRefinedTime(start, end);
          this.timeSlots.push(this.final);
          this.saarthiService.slot$.next(this.timeSlots);
        });
      }
    } catch (e) {
      this.showErrorService.showError(e);
    }
  }

  async getExamPlanModalDetails() {
    try {
      const response = await lastValueFrom(
        this.saarthiService.getMySaarthiPlans()
      );
      if (response && response.data) {
        this.activePlanModal = response.data.map(
          (item: any) => new ExamPlanModal(item)
        );
      } else {
        this.activePlanModal = [];
      }
    } catch (e) {
      this.showErrorService.showError(e);
    }
  }

  async getExamDetails() {
    try {
      this.loaderService.loadData('Loading...');
      const res = await lastValueFrom(this.saarthiService.getExams());
      if (res) {
        this.examDetails = res;
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.loaderService.unloadData('Loading...');
      this.showClass(this.selectedExam);
    }
  }

  showClass(exam: string) {
    this.selectedExam = exam;
    this.saarthiService.selectedExam$.next(this.selectedExam);
    this.selectedClass = '';
    this.classes = this.examDetails.find((_exam: any) => _exam.exams === exam);
    this.selectedSlot = '';
    this.timeSlots = [];
  }

  selectClass(event: any) {
    if (event.isUserInput) {
      this.selectedSlot = '';
      this.selectedClass = event.source.value;
      this.selectedSlot = '';
      this.saarthiService.selectedClass$.next(this.selectedClass);
      this.timeSlots = [];
      this.getSlots();
    }
  }

  selectSlot(event: any) {
    if (event.isUserInput) {
      this.selectedSlot = event.source.value;
    }
    if (this.selectedSlot) {
      const slotWithouSpaces = this.selectedSlot.replace(/ /g, '');
      let startHour = [];
      let endHour = [];
      let startTimeWith24Hours = '';
      let endTimeWith24Hours = '';
      const time = slotWithouSpaces.split('to');
      const [startTime, endTime] = [...time];

      if (startTime?.toLowerCase().includes('pm')) {
        startHour = startTime?.split(':');
        startTimeWith24Hours =
          parseInt(startHour[0]) === 12
            ? parseInt(startHour[0]).toString() +
              ':' +
              startHour[1]?.substring(0, startHour[1]?.length - 2)
            : (parseInt(startHour[0]) + 12).toString() +
              ':' +
              startHour[1]?.substring(0, startHour[1]?.length - 2);
      } else {
        startHour = startTime?.split(':');
        startTimeWith24Hours =
          startHour[0]?.length === 1
            ? '0' + startHour[0].toString()
            : startHour[0].toString() +
              ':' +
              startHour[1]?.substring(0, startHour[1]?.length - 2);
      }
      if (endTime?.toLowerCase().includes('pm')) {
        endHour = endTime?.split(':');
        endTimeWith24Hours =
          parseInt(endHour[0]) === 12
            ? parseInt(endHour[0]).toString() +
              ':' +
              endHour[1]?.substring(0, endHour[1]?.length - 2)
            : (parseInt(endHour[0]) + 12).toString() +
              ':' +
              endHour[1]?.substring(0, endHour[1]?.length - 2);
      } else {
        endHour = endTime?.split(':');

        endTimeWith24Hours =
          endHour[0]?.length === 1
            ? '0' + endHour[0].toString()
            : endHour[0].toString();
        endTimeWith24Hours +=
          ':' + endHour[1]?.substring(0, endHour[1]?.length - 2);
      }
      ///// Previous code for Slot selection
      this.check = this.selectedSlot.split(' Am');
      this.checkAgain = this.check[0];
      this.checkOnce = this.selectedSlot.split(' Pm to');
      this.checkEnd = this.selectedSlot.split('to ');
      this.checkStartTime = this.checkOnce[0].split(':');
      this.end = this.checkEnd[1].split(':');
      this.last = this.end[1].split(' Pm');

      this.lastTime = this.end[1].split(' Am');

      if (Number(this.lastTime[0]) || Number(this.lastTime[0]) == 0) {
        this.finalEndTime = '0' + Number(this.end[0]) + ':' + this.lastTime[0];
      } else {
        if (Number(this.end[0]) == 12) {
          this.finalEndTime = Number(this.end[0]) + ':' + this.last[0];
        } else {
          this.finalEndTime = Number(this.end[0]) + 12 + ':' + this.last[0];
        }
      }
      if (Number(this.checkStartTime[0]) == 12) {
        this.checkStart =
          Number(this.checkStartTime[0]) + ':' + this.checkStartTime[1];
      } else {
        this.checkStart =
          Number(this.checkStartTime[0]) + 12 + ':' + this.checkStartTime[1];
      }

      this.slotModal.forEach((item) => {
        // if (this.finalEndTime == item.end_time) {
        //   this.id = item.saarthi_id;
        // }
        // if (
        //   this.checkAgain == item.start_time ||
        //   this.checkStart == item.start_time
        // ) {
        //   this.ctr++;

        //   if (this.ctr > 0) {
        //     if (this.finalEndTime == item.end_time) {
        //       this.id = item.saarthi_id;
        //     }
        //   } else {
        //     this.id = item.saarthi_id;
        //   }
        // }
        if (
          item?.start_time.toLowerCase() === startTimeWith24Hours &&
          item.end_time.toLowerCase() === endTimeWith24Hours
        ) {
          this.id = item?.saarthi_id;
        }
      });

      this.saarthiService.saarthiId$.next(this.id);
      // this.saarthiService.selectedSlot$.next(this.id);
    }
  }

  getActiveDetails(item: any) {
    this.selectedClass = item.saarthi_id.classes[0];
    this.selectedExam = item.saarthi_id.exams[0];
    let start = item.saarthi_id.saarthi_slot.start_time.split(':');
    let end = item.saarthi_id.saarthi_slot.end_time.split(':');
    this.final_time = this.saarthiService.getRefinedTime(start, end);
    this.selectedSlot = this.final_time;
    this.id = item.saarthi_id._id;
    this.saarthiService.selectedClass$.next(this.selectedClass);
    this.saarthiService.selectedExam$.next(this.selectedExam);
    this.saarthiService.selectedSlot$.next(this.id);
    this.saarthiService.finalTime$.next(this.final_time);
    this.update();
  }

  update() {
    if (this.selectedClass.length === 0 || this.selectedClass === '') {
      this.globalService.showSnackBar(SELECT_CLASS);
      return;
    }
    if (!this.selectedExam || this.selectedExam === '') {
      this.globalService.showSnackBar(SELECT_EXAM);
      return;
    }
    if (this.selectedSlot.length === 0 || this.selectedSlot === '') {
      this.globalService.showSnackBar(SELECT_SLOT);
      return;
    }
    if (
      this.examData.exam != '' &&
      this.examData.class != '' &&
      this.examData.slot != ''
    ) {
      this.saarthiService.selectedSlot$.next(this.id);
      this.storageService.setValue(
        'SAARTHI_PREFERENCES',
        {
          selectedClass: this.selectedClass,
          selectedExam: this.selectedExam,
          selectedSlot: this.selectedSlot,
          availableSlots: this.timeSlots,
        },
        'json'
      );
      this.saarthiService._saarthiPreference$.next({
        selectedClass: this.selectedClass,
        selectedExam: this.selectedExam,
        selectedSlot: this.selectedSlot,
        availableSlots: this.timeSlots,
      });
      this.saarthiService.redirectDashboard$.next(false);
    }

    this.close(
      {
        class: this.selectedClass,
        exam: this.selectedExam,
        slot: this.selectedSlot,
      },
      'update'
    );
  }

  close(data?: any, cameFrom: string = 'close') {
    this.saarthiService.openModal$?.next(false);
    const saarthi_id = localStorage.getItem('SAARTHI_ID') as string;
    if (cameFrom?.toLowerCase() !== 'update' && !saarthi_id) {
      this.saarthiService.redirectDashboard$.next(true);
    }
    this.saarthiService.redirectDashboard$.subscribe((res) => {
      let redirect = res;
      if (redirect === true) {
        this.router.navigate([`/batches`]);
      } else {
        this.saarthiService.iframeLoaded$.next(true);
        this.router.navigate([`/saarthi`]);
      }
    });
    setTimeout(() => {
      this.logEvent();
    }, 1000);
    this.dialog.close(data);
  }

  logEvent() {
    let saarthi_user_type;
    let cohort_name;
    this.activePlan = this.storageService.getValue(
      'ACTIVE_SAARTHI_DETAILS',
      'json'
    );
    saarthi_user_type = this.activePlan?._id.length > 0 ? 'paid' : 'free';
    let saarthiPreference: any = JSON.parse(
      this.storageService.getSaarthiPreferences() || '{}'
    );
    if (
      saarthiPreference &&
      saarthiPreference.selectedClass &&
      saarthiPreference.selectedExam
    ) {
      cohort_name = `${saarthiPreference.selectedExam}-${saarthiPreference.selectedClass}`;
    }
    const fata = {
      saarthi_user_type: saarthi_user_type,
      cohort_name: cohort_name,
    };

    this.firebaseAnalytics.logEvents(SAARTHI_USER_STATUS, fata);
    this.storageService.removeValue('ACTIVE_SAARTHI_DETAILS');

    const moengageData = {
      origin: 'saarthi_home_page_view',
      time_slot: saarthiPreference?.selectedSlot,
      class: saarthiPreference?.selectedClass,
      exam: saarthiPreference?.selectedExam,
      cohort_name: `${saarthiPreference?.selectedExam}-${saarthiPreference?.selectedClass}`,
    };

    this.moengageService.trackMoengageEvent(
      SAARTHI_HOME_PAGE_VIEW,
      moengageData
    );
  }
}
