import { StorageService } from 'src/app/common/services/storage/storage.service';
import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { GlobalService } from '../global/global.service';
import { firebaseApp } from '../../../app.module';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { GlobalObjectService } from '../global-object/global-object.service';
import { Platform } from '../study/study.service';
import { CohortService } from '../cohort/cohort.service';
import { Base64 } from '../../utils/encode-utilities';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService implements OnDestroy {
  userInfo: any;
  analytics: any;
  userSub: Subscription;
  firebaseToken: string;
  uuid: string = '';
  user_segment: string = '';
  userPlatform: string = '';
  cohortId: string = '';
  campaignParam: any = {};

  constructor(
    private globalService: GlobalService,
    private storageService: StorageService,
    private globalObject: GlobalObjectService,
    private cohortService: CohortService
  ) {
    this.getUserInfo();
    setUserProperties(this.analytics, this.userInfo);
    this.firebaseToken = this.storageService.getDeviceId()!;
    this.uuid = this.storageService.getRandomId() || '';
    this.user_segment = this.globalService.getUserSegmentFromStorage() || '';
    this.userPlatform =
      this.globalObject.screen.width > 768 ? Platform.WEB : Platform.MWEB;
    this.cohortId = this.cohortService.getCohortIdFromLocal() || '';
    this.getCampaignData();
    this.storageService.isCampaignDataAvailable$.subscribe((res) => {
      if (res) {
        this.getCampaignData();
      }
    });
  }

  getCampaignData() {
    const encodedString = this.storageService.getCampaignData();

    if (encodedString) {
      const campaignData = Base64.decode(encodedString);
      this.campaignParam = JSON.parse(campaignData);
    }
  }

  getUserInfo() {
    this.analytics = getAnalytics(firebaseApp);
    this.userSub = this.globalService.getUser().subscribe((res) => {
      if (res) {
        this.userInfo = res;
        setUserProperties(this.analytics, {
          exam: this.userInfo?.profileId?.exams,
          class: this.userInfo?.profileId?.class,
          board: this.userInfo?.profileId?.board,
          language: this.userInfo?.profileId?.language,
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSub) this.userSub.unsubscribe();
  }

  logEvents(
    eventName: string,
    data?: any,
    addDeviceId?: boolean,
    shouldUserBeAdded: boolean = true,
    cohortId: boolean = false,
    user_segment: boolean = false,
    userPlatform: boolean = false,
    cameFrom?: string
  ): void {
    this.user_segment = this.globalService.getUserSegmentFromStorage() || '';
    this.cohortId = this.cohortService.getCohortIdFromLocal() || '';

    data = data || {};
    if (shouldUserBeAdded && data) {
      data['UserPhoneNumber'] =
        this.userInfo?.primaryNumber || data['UserPhoneNumber'] || '';
      data['UserId'] = this.userInfo?.id || '';
      data['device_id'] = this.uuid || '';
      data['username'] = this.userInfo?.lastName
        ? this.userInfo?.firstName + ' ' + this.userInfo?.lastName
        : this.userInfo?.firstName;
    }
    if (addDeviceId) {
      data['device_id'] = this.uuid || '';
    }

    if (cohortId) {
      data['user_cohort_id'] = this.cohortId || '';
    }
    if (user_segment) {
      data['user_segment'] = this.user_segment || '';
    }
    if (userPlatform) {
      data['userPlatform'] = this.userPlatform || '';
    }
    if (cameFrom === 'ADMISSION_JOURNEY') {
      const vidyapeethDetails = this.globalService.getCityCentreName();
      data['centre_city'] = vidyapeethDetails?.city;
      data['centre_name'] = vidyapeethDetails?.centre;
    }
    if (this.campaignParam && !this.globalService.isEmpty(this.campaignParam)) {
      data = {
        ...data,
        ...this.campaignParam,
      };
    }

    try {
      logEvent(this.analytics, eventName, data);
    } catch (e) {
      console.log(e);
    }
  }
}
