import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

class DialogData {}

@Component({
  selector: 'app-video-sol-modal',
  templateUrl: './video-sol-modal.component.html',
  styleUrls: ['./video-sol-modal.component.scss'],
})
export class VideoSolModalComponent implements OnInit {
  @ViewChild('htmliFrameElement') iframe: HTMLIFrameElement;

  url: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<VideoSolModalComponent>
  ) {}

  ngOnInit(): void {
    // @ts-ignore
    this.url = this.data['url'];
  }

  // iframe.onload = function(){
  //   iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
  // }
}
