import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isAfter } from 'date-fns';
import { Subscription, lastValueFrom } from 'rxjs';
import { MAIN_PROGRAM_SLUG } from 'src/app/constants/global-constant/global-constant.service';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { ResumeTestDialogComponent } from 'src/app/pages/tests/test-list/resume-test-dialog/resume-test-dialog.component';
import { StartTestDialogComponent } from 'src/app/pages/tests/test-list/start-test-dialog/start-test-dialog.component';
import {
  StudentTestMapping,
  TestList,
  TestStatus,
} from 'src/app/pages/tests/tests.model';
import { PurchaseAlertComponent } from '../../components/purchase-alert/purchase-alert.component';
import { GlobalService } from '../global/global.service';
import { ShowErrorService } from '../showError/show-error.service';
import { TestSeriesService } from './test-series.service';

@Injectable({
  providedIn: 'root',
})
export class TestSeriesHelperService {
  // batchData: BatchDetailModel;
  programSlug: string;
  constructor(
    private globalService: GlobalService,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private showErrorService: ShowErrorService,
    private testService: TestSeriesService
  ) {}

  async startTest(
    exercise: any,
    batchData: BatchDetailModel,
    source: string,
    subjectName?: string
  ) {
    this.globalService.setTestBackUrl(this.router.url);
    if (!batchData.isPurchased && !exercise.isFree) {
      const dialogRef = this.dialog.open(PurchaseAlertComponent, {
        data: {
          batchId: batchData.slug,
        },
        height: '160px',
        width: '450px',
        panelClass: 'purchase-alert',
      });

      return;
    }

    if (exercise && exercise.test && exercise.test._id) {
      const exerciseId = exercise.test._id;
      let mappingObject = new StudentTestMapping({});
      try {
        const res: any = await lastValueFrom(
          this.testService.getTestStatus({ testListId: exerciseId })
        );
        if (res) {
          this.globalService.setQBankReturnURL(this.router.url);
          mappingObject = new StudentTestMapping(res);
          if (
            mappingObject.testActivityStatus === 'Finished' ||
            mappingObject.testActivityStatus === 'Submitted'
          ) {
            await this.router.navigate(
              [`q-bank-result/${mappingObject._id}/${exerciseId}`],
              {
                queryParams: {
                  contentSlug: exercise?.test?._id || '',
                  title: exercise?.test?.name,
                  batchId: batchData._id,
                  batchScheduleId: exercise?.scheduleId,
                  subjectName,
                  source,
                  batchName: batchData?.name || '',
                  type: exercise?.tag || '',
                },
              }
            );
          } else {
            await this.router.navigate([`q-bank-exercise/${exerciseId}`], {
              queryParams: {
                contentSlug: exercise?.test?._id || '',
                title: exercise?.test?.name,
                cameFrom: 'dpp',
                subjectName,
                batchId: batchData._id,
                batchScheduleId: exercise?.scheduleId,
                batchName: batchData?.name || '',
                type: exercise?.tag || '',
              },
            });
          }
        }
      } catch (e) {
        await this.showErrorService.showError(e);
        this.location.back();
      } finally {
      }
    } else {
      this.globalService.showSnackBar('Exercise not valid!');
    }
  }

  async openTest(
    test: TestList,
    mappingId: string,
    batchData: BatchDetailModel,
    reattempt: boolean = false
  ) {
    let query = {
      testListId: test._id,
      totalQuestions: test.totalQuestions,
      totalMarks: test.enableNewJeeTestPatter
        ? '300'
        : test.enableNewNeetTestPatter
        ? '720'
        : test.totalMarks,
      duration: test.maxDuration,
      testName: test.name,
      testCatId: test.categoryId,
      programId: batchData.programId,
      testMappingId: mappingId,
      testSource: 'BATCH_TEST_SERIES',
      reattempt: reattempt,
    };
    if (!query.testCatId) {
      return this.globalService.showSnackBar('Test is not available');
    }
    const dialogRef = this.dialog.open(StartTestDialogComponent, {
      panelClass: 'start-test-dialog',
      width: '500px',
      height: '450px',
      data: { obj: query, testList: test, batchId: batchData.slug },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  resumeTest(test: TestList, mappingId: string, batchData: BatchDetailModel) {
    let query = {
      testListId: test.slug,
      totalQuestions: test.totalQuestions,
      totalMarks: test.totalMarks,
      duration: test.maxDuration,
      testName: test.name,
      testCatId: test.categoryId,
      programId: batchData.programId,
      testMappingId: mappingId,
    };
    const dialogRef = this.dialog.open(ResumeTestDialogComponent, {
      width: '310px',
      height: '220px',
      data: { obj: query, testList: test, batchId: batchData.slug },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.length > 0 && result === 'no') {
        this.testService.removeTabSwitch();
        this.globalService.removeTest();
        this.openTest(test, mappingId, batchData, true);
      }
    });
  }

  async checkTestSubmitStatus(
    test: TestList,
    testStatus: TestStatus,
    batchData: BatchDetailModel,
    cameFrom?: string
  ) {
    const localTest = this.globalService.getTest() || {};
    if (
      (testStatus && testStatus['testActivityStatus'] === 'Finished') ||
      testStatus['testActivityStatus'] === 'Submitted'
    ) {
      await this.router.navigate(
        [
          `test-series/${MAIN_PROGRAM_SLUG}/tests/${testStatus._id}/test-result`,
        ],
        {
          queryParams: {
            testDetailId: test._id,
            testName: test.name,
            batchId: batchData.slug,
            cameFrom: 'batch-test',
          },
        }
      );
    } else {
      if (test.type === 'Mock' || test.type === 'Live') {
        const serverTime = new Date();
        if (isAfter(serverTime, new Date(test.endTime))) {
          await this.globalService.showSnackBar('This test is expired');
        } else if (isAfter(serverTime, new Date(test.maxStartTime))) {
          await this.globalService.showSnackBar(
            `You are  late and now you can't join the test`
          );
        } else {
          if (localTest && localTest?.details?._id === testStatus._id) {
            await this.resumeTest(test, testStatus._id, batchData);
          } else {
            this.globalService.removeTest();
            await this.openTest(test, testStatus._id, batchData);
          }
        }
      } else {
        if (localTest && localTest?.details?._id === testStatus._id) {
          await this.resumeTest(test, testStatus._id, batchData);
        } else {
          this.globalService.removeTest();
          await this.openTest(test, testStatus._id, batchData);
        }
      }
    }
  }
}
