<div></div>

<div class="w-full iframe-container">
  <iframe
    #htmliFrameElement
    [src]="url | safe : 'resourceUrl'"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    frameborder="0"
    height="450"
    title="YouTube video player"
    width="560"
  >
  </iframe>
</div>
