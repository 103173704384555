import { Component, Input, OnInit } from '@angular/core';
import { Block_ICON } from 'src/app/core/assets.location';

@Component({
  selector: 'app-block-warning',
  templateUrl: './block-warning.component.html',
  styleUrls: ['./block-warning.component.scss'],
})
export class BlockWarningComponent implements OnInit {
  BLOCK_ICON = Block_ICON;
  @Input('blockMessage') blockMessage: string = '';
  constructor() {}

  ngOnInit(): void {}
}
