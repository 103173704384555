import { Doubt } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { FileModal } from '../base/base.modal';

export class DoubtListModel {
  status: string;
  _id: string;
  title: string;
  description: string;
  conversationId: string;
  slideNum: number;
  createdAt: string;
  uiStatus: string;
  createdBy: CreatedByModel;
  doubtId: string;
  parentId: string;
  batchId: string;

  constructor(props: any) {
    props = props || {};
    this._id = props._id || '';
    this.doubtId = props.doubtId || '';
    if (props && props.status && props.status.length > 0) {
      this.status = props.status.split(' ')[0];
    } else {
      this.status = '';
    }
    this.title = props.title || '';
    this.description = props.description || '';
    this.conversationId = props.conversationId || '';
    this.slideNum = props.slideNum || 0;
    this.createdAt = props.createdAt || '';
    this.parentId = props.parentId || '';
    this.batchId = props.batchId || '';
    if (
      props &&
      props.status &&
      props.status.toLowerCase() === 'unresolved ss'
    ) {
      this.uiStatus = 'Resolved';
    } else if (
      props &&
      props.status &&
      props.status.toLowerCase() === 'unresolved gs'
    ) {
      this.uiStatus = 'Awaiting';
    } else if (
      props &&
      props.status &&
      props.status.toLowerCase().includes('shared')
    ) {
      this.uiStatus = 'View solution';
    } else {
      this.uiStatus = this.status;
    }
    this.createdBy = new CreatedByModel(props.createdBy || {});
  }
}

export class CommonDoubtModel {
  isLiked: boolean;
  videoType: string;
  dTopicIds: Array<string>;
  access: string;
  // comments: Array<CommentModal>;
  type: string;
  correctAnswers: Array<any>;
  commentCount: number;
  isCommentDisabled: boolean;
  upvoteCount: number;
  status: string;
  sameDoubtCount: number;
  _id: string;
  title: string;
  description: string;
  dRoomId: string;
  createdBy: CreatedByModel;
  batchId: string;
  scheduleId: string;
  slideId: string;
  createdAt: string;
  slide: SlideModel;
  reportCount: number;
  isPopular: boolean;
  isRecommended: boolean;
  subjectId: string;
  enableSameDoubt: boolean;
  parentId: string;

  constructor(data: any) {
    data = data || {};
    this.videoType = data.videoType || '';
    this.dTopicIds = data.dTopicIds || [];
    this.access = data.access || '';
    this.type = data.type || '';
    // const commentList: Array<CommentModal> = [];
    // if (data['comments']) {
    //     data['comments'].forEach(c => {
    //         commentList.push(new CommentModal(c));
    //     });
    // }
    this.slide =
      new SlideModel(data.slide) ||
      new SlideModel(data.slideId) ||
      new SlideModel({});
    this.correctAnswers = data.correctAnswers || [];
    this.commentCount = data.commentCount || 0;
    this.isCommentDisabled = data.isCommentDisabled || true;
    this.upvoteCount = data.upvoteCount || 0;
    this.status = data.status || '';
    this.sameDoubtCount = data.sameDoubtCount || 0;
    this._id = data._id || data.doubtId || '';
    this.title = data.title || '';
    this.description = data.description || '';
    this.dRoomId = data.dRoomId || '';
    this.createdBy = new CreatedByModel(data.createdBy || '');
    this.batchId = data.batchId || '';
    this.scheduleId = data.scheduleId || '';
    this.slideId = data.slideId || data.typeId || '';
    this.createdAt = data.createdAt || '';
    this.reportCount = data.reportCount || 0;
    this.isLiked = data.isLiked || false;
    this.isPopular = data.isPopular || false;
    this.enableSameDoubt = data.enableSameDoubt || true;
    this.subjectId = data.subjectId || '';
    this.parentId = data.parentId || '';
    this.isRecommended = data.isRecommended || false;
  }
}

export interface SuccessResponse {
  success: boolean;
}

export interface SendSolutionResponse extends SuccessResponse {
  data: DoubtCommentModel[];
}

export interface StudentMastersResponse {
  success: boolean;
  data: StudentSolvingDetails;
}

export class StudentSolvingDetails {
  aboveMaxSatsfactoryRate: number;
  belowMinSatsfactoryRate: number;
  betweenSatsfactoryRate: number;
  satisfactoryRate: number;
  totalSolved: number;

  constructor(data: any) {
    this.aboveMaxSatsfactoryRate = data.aboveMaxSatsfactoryRate || 0;
    this.belowMinSatsfactoryRate = data.belowMinSatsfactoryRate || 0;
    this.betweenSatsfactoryRate = data.betweenSatsfactoryRate || 0;
    this.satisfactoryRate = data.satisfactoryRate || 0;
    this.totalSolved = data.totalSolved || 0;
  }
}

export class DoubtAnswerModel {
  _id: string;
  text: string;
  url: string;
  audioId: AudioPlayer;
  imageId: Image;
  createdBy: CreatedByModel;
  slug: string;
  createdAt: Date;
  upVoteCount: number;
  commentCount: number;
  isAnswered: boolean;
  audioFile: string;

  constructor(data: any) {
    this._id = data._id || '';
    this.text = data.text || '';
    this.url = data.url || '';
    this.audioId = data.audioId || {};
    this.imageId = data.imageId || {};
    this.createdBy = new CreatedByModel(data.createdBy) || {};
    this.slug = data.slug || '';
    this.createdAt = data.createdAt || '';
    this.upVoteCount = data.upVoteCount || 0;
    this.commentCount = data.commentCount || 0;
    this.isAnswered = data.isAnswered || false;
    this.audioFile = data.audioId.baseUrl + data.audioId.key;
  }
}

export interface AudioPlayer {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  duration: number;
}

export class CreatedByModel {
  _id: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  imgUrl: FileModal;
  fullName: string;
  imageId: ImageInfoModel;

  constructor(data: any) {
    this._id = data._id || data.id || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.userName = data.username || '';
    this.email = data.email || '';
    this.imageId = new ImageInfoModel(data.imageId || {});
    this.imgUrl =
      new FileModal(data.imgUrl) ||
      new FileModal(data.img) ||
      new FileModal({});
    if (this.imgUrl._id.length === 0 && data.imageId) {
      this.imgUrl = new FileModal(data.imageId);
    }
    this.fullName = this.firstName + ' ' + this.lastName;
  }
}

export class SlideModel {
  id: string;
  name: string;
  imgUrl: FileModal;
  status: string;
  slideNum: number;
  timeStamp: number;
  doubtCount: number;
  serialNumber: number;

  constructor(props: any) {
    props = props || {};
    this.id = props._id || '';
    this.name = props.name || '';
    this.imgUrl = new FileModal(props.img) || new FileModal({});
    this.status = props.status || '';
    this.slideNum = props.serialNumber || 0;
    this.timeStamp = props.timeStamp || 0;
    this.doubtCount = props.doubtCount || 0;
    this.serialNumber = props.serialNumber || 0;
  }
}

export class DoubtConversationModel {
  sender: string;
  senderName: string;
  status: string;
  text: string;
  createdAt: Date;
  type: string;
  conversationId: string;
  organizationId: string;
  pinnedBy: Array<any>;
  senderInfo: SenderInfoModel;
  updatedAt: string;
  __v: number;
  _id: string;

  constructor(props: any) {
    props = props || {};
    this.sender = props.sender || '';
    this.senderName = props.senderName || '';
    this.status = props.status || '';
    this.text = props.text || '';
    this.createdAt = props.createdAt || '';
    this.type = props.type || '';
    this.conversationId = props.conversationId || '';
    this.organizationId = props.organizationId || '';
    this.pinnedBy = props.pinnedBy || [];
    this.senderInfo = new SenderInfoModel(props.senderInfo);
    this.updatedAt = props.updatedAt || '';
    this.__v = props.__v || '';
    this._id = props._id || '';
  }
}

export class SenderInfoModel {
  imageId: ImageInfoModel;
  username: string;
  _id: string;

  constructor(data: any) {
    this.imageId = new ImageInfoModel(data.imageId || {});
    this.username = data.username || '';
    this._id = data._id || '';
  }
}

export class DoubtConnectListModel {
  _id: string;
  price: number;
  is_unlimited: boolean;
  sessions: number;
  duration: number;
  discount: number;
  tags: string[];
  display_order: number;
  is_available_from_points: boolean;
  max_wallet_point: number;
  title: string;
  description: string;

  constructor(data: any) {
    this._id = data._id || '';
    this.price = data.price || 0;
    this.is_unlimited = data.is_unlimited || false;
    this.sessions = data.sessions || 0;
    this.duration = data.duration || 0;
    this.discount = data.discount || 0;
    this.tags = data.tags || [];
    this.display_order = data.display_order || 0;
    this.is_available_from_points = data.is_available_from_points || false;
    this.max_wallet_point = data.max_wallet_point || 0;
    this.title = data.title || '';
    this.description = data.description || '';
  }
}

export interface DoubtConnectPlanObject {
  success: boolean;
  data: DoubtConnectListModel[];
}

export interface Image {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
}

export interface Testimonial {
  _id: string;
  name: string;
  description: string;
  about: string;
  image?: any;
}

export interface VideoDetails {
  name: string;
  image: string;
  embedCode: string;
  description: string;
  duration: string;
}

export class DoubtConnectRoom {
  _id: string;
  video_type: string;
  title: string;
  image: Image;
  testimonials: Testimonial[];
  slots: any[];
  videoDetails: VideoDetails;

  constructor(data: any) {
    this._id = data._id || '';
    this.video_type = data.video_type || '';
    this.title = data.title || '';
    this.image = data.image || {};
    this.testimonials = data.testimonials || [];
    this.slots = data.slots || [];
    this.videoDetails = data.video_details || {};
  }
}

export class MentorModel {
  _id: string;
  name: string;
  description: string;
  image: string;

  constructor(data: any) {
    this._id = data._id || '';
    this.name = data.name || '';
    this.description = data.description || '';
    this.image = data.image || '';
  }
}

export class ExpertInfoModel {
  about: string;
  featuredLine: string;
  firstName: string;
  lastName: string;
  fullName: string;
  qualification: string;
  tags: Array<string>;
  imageId: ImageInfoModel;

  constructor(data: any) {
    this.about = data.about || '';
    this.featuredLine = data.featuredLine || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.fullName = data.firstName + ' ' + data.lastName || '';
    this.qualification = data.qualification || '';
    this.tags = data.tags || [];
    this.imageId = new ImageInfoModel(data.imageId || {});
  }
}

export class UserProfileData {
  batchName: string;
  khazanaPurchased: boolean;
  saarthiPurchased: boolean;
  constructor(data: any) {
    this.batchName = data.batchName || '';
    this.khazanaPurchased = data.khazanaPurchased || false;
    this.saarthiPurchased = data.saarthiPurchased || false;
  }
}

export class ImageInfoModel {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
  __v: number;

  constructor(data: any) {
    this.baseUrl = data.baseUrl || '';
    this.name = data.name || '';
    this.key = data.key || '';
    this.organization = data.organization || '';
    this.createdAt = data.createdAt || '';
    this.__v = data.__v || 0;
    this._id = data._id || '';
  }
}

export interface TagsResponseModel {
  success: boolean;
  data: Array<string>;
  message: object;
}

export interface CommentsResponseModel {
  success: boolean;
  data: Array<DoubtCommentModel>;
  message: object;
}

export interface CommentResponseModel {
  success: boolean;
  data: DoubtCommentModel;
  message: object;
}

export class DoubtCommentModel {
  text: string;
  parentId: string;
  type: string;
  typeId: string;
  organizationId: string;
  createdBy: CreatedByModel;
  status: string;
  childCommentCount: number;
  upVoteCount: number;
  downVoteCount: number;
  satisfactoryRate: number;
  displayOrder: number;
  _id: string;
  createdAt: string;
  updatedAt: string;
  imageId: ImageInfoModel;
  audioId: AudioPlayer;
  videoType: string;
  videoUrl: string;
  isExpanded: boolean;
  rated: number;

  constructor(props: any) {
    this.text = props.text || '';
    this.parentId = props.parentId || '';
    this.type = props.type || '';
    this.typeId = props.typeId || '';
    this.organizationId = props.organizationId || '';
    this.createdBy = new CreatedByModel(props.createdBy || {});
    this.status = props.status || '';
    this.childCommentCount = props.childCommentCount || 0;
    this.upVoteCount = props.upVoteCount || 0;
    this.downVoteCount = props.downVoteCount || 0;
    this.satisfactoryRate = props.satisfactoryRate || 0;
    this.displayOrder = props.displayOrder || 0;
    this._id = props._id || '';
    this.createdAt = props.createdAt || '';
    this.updatedAt = props.updatedAt || '';
    this.imageId = new ImageInfoModel(props.imageId || {});
    this.audioId = <AudioPlayer>props.audioId || <AudioPlayer>{};
    this.videoType = props.videoType || '';
    this.videoUrl = props.videoUrl || '';
    this.isExpanded = false;
    this.rated = props.rated || 0;
  }
}

export interface ChapterWiseDoubtsResponse {
  success: boolean;
  data: Array<ChapterWiseDoubts>;
  message: string;
}

export class ChapterWiseDoubts {
  chapterId: string;
  chapterName: string;

  constructor(props: any) {
    this.chapterId = props.chapterId || '';
    this.chapterName = props.chapterName || '';
  }
}
export interface ScheduleWiseDoubtsResponse {
  success: boolean;
  data: Array<ScheduleWiseDoubts>;
  message: string;
}

export class ScheduleWiseDoubts {
  scheduleId: string;
  topic: string;
  url: string;
  urlType: string;
  batchSubjectId: string;
  slug: string;

  constructor(props: any) {
    this.scheduleId = props.scheduleId || '';
    this.topic = props.topic || '';
    this.url = props.url || '';
    this.urlType = props.urlType || '';
    this.batchSubjectId = props.batchSubjectId || '';
    this.slug = props.slug || '';
  }
}
export interface SlideWiseDoubtsResponse {
  success: boolean;
  data: Array<Doubt>;
  message: string;
}
