<section
  class="flex flex-col"
  *ngIf="!doubt.isRecommended"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
>
  <div class="flex items-center justify-between">
    <div
      class="flex flex-row items-center cursor-pointer"
      (click)="openSolverModal($event)"
    >
      <img
        [src]="doubtSolution.createdBy.imgUrl.url || userImg"
        class="user-img mr-2"
      />
      <span class="semibold theme-color caption-1 sm-caption1">{{
        doubtSolution &&
          doubtSolution.createdBy &&
          doubtSolution.createdBy.fullName
      }}</span>
      <img
        [src]="verifiedAssistant"
        alt=""
        class="ml-2 sm-img"
        *ngIf="
          doubtSolution.displayOrder === 2 || doubtSolution.displayOrder === 3
        "
      />
      <span class="semibold time-text caption-1 sm-caption1">
        &nbsp; {{ doubtSolution.createdAt | timeDifference }}
      </span>
    </div>
    <img
      [src]="pinImg"
      alt=""
      class="pin-img-dimensions sm-img"
      *ngIf="
        !isComment &&
        (doubtSolution.displayOrder === 2 || doubtSolution.displayOrder === 3)
      "
    />
  </div>
  <!-- main content of doubt here -->
  <!-- video link solution -->
  <div
    *ngIf="doubtSolution.videoType.length && doubtSolution.videoUrl.length"
    class="my-1"
  >
    <span
      (click)="detectUrl(doubtSolution.videoUrl, $event)"
      class="show-toggler-color body-2 underline cursor-pointer sm-body2"
      >Click here to view solution</span
    >
  </div>
  <!--image solution-->
  <div
    *ngIf="
      doubtSolution.imageId &&
      doubtSolution.imageId.baseUrl &&
      doubtSolution.imageId.key.length > 0
    "
    class="reply-message-card-content body-1 break-words my-1 cursor-pointer sm-body1"
    (click)="openSolutionModal($event)"
  >
    <img
      [src]="doubtSolution.imageId.baseUrl + doubtSolution.imageId.key"
      class="sol-img sm-img"
    />
  </div>

  <!-- text solution -->
  <div
    class="my-1 break-words"
    *ngIf="
      doubtSolution.text &&
      doubtSolution.text.length > 0 &&
      doubtSolution.displayOrder <= 1
    "
  >
    <span class="medium comment-text body-2 font-medium sm-body2">
      {{
        doubtSolution.text | decodedHTML | truncateText : calculateTextLength()
      }} </span
    ><span
      (click)="doubtSolution.isExpanded = !doubtSolution.isExpanded"
      *ngIf="doubtSolution.text.length > 175"
      class="show-toggler-color body-2 underline cursor-pointer sm-body2"
      >Show {{ doubtSolution.isExpanded ? 'less' : 'more' }}
    </span>
  </div>
  <div
    class="my-1 break-words"
    *ngIf="
      doubtSolution.text &&
      doubtSolution.text.length > 0 &&
      doubtSolution.displayOrder > 1
    "
  >
    <span
      class="medium comment-text body-2 font-medium sm-body2"
      [innerHtml]="
        doubtSolution.text
          | decodedHTML
          | truncateText
            : (doubtSolution.isExpanded
                ? doubtSolution.text.length
                : doubtSolution.text.includes('<!DOCTYPE html>')
                ? 475
                : 175)
          | safe : 'html'
      "
    >
    </span
    ><span
      (click)="doubtSolution.isExpanded = !doubtSolution.isExpanded"
      *ngIf="doubtSolution.text.length > 175"
      class="show-toggler-color body-2 underline cursor-pointer sm-body2"
      >Show {{ doubtSolution.isExpanded ? 'less' : 'more' }}
    </span>
  </div>
  <!-- audio solution -->
  <div
    *ngIf="
      doubtSolution.audioId &&
      doubtSolution.audioId.baseUrl &&
      doubtSolution.audioId.key.length > 0
    "
    class="w-full overflow-hidden my-2"
  >
    <div class="w-full flex">
      <audio controls controlsList="nodownload">
        <source
          [src]="doubtSolution.audioId.baseUrl + doubtSolution.audioId.key"
          type="audio/webm"
        />
      </audio>
    </div>
  </div>
  <!--doubt content ends here-->
  <div class="flex justify-between items-center my-2">
    <div class="flex flex-row items-center">
      <!--Like button of answer-->
      <div
        *ngIf="!isComment"
        class="flex items-center caption-1 pr-2 pl-1 rounded-full mr-1 cursor-pointer sm-caption1"
        (click)="openFeedbackModal($event, 'like')"
        [ngClass]="{
          'feedback-given-bg': doubtSolution.rated === 1,
          'light-purple-bg': doubtSolution.rated === 0,
          'disabed-bg':
            doubtSolution.rated === -1 ||
            userInfo.id === doubtSolution.createdBy._id
        }"
      >
        <img
          *ngIf="doubtSolution.rated === -1"
          [src]="likeDisabled"
          class="mr-1 sm-img"
        />
        <img
          *ngIf="
            doubtSolution.rated === 0 &&
            userInfo.id !== doubtSolution.createdBy._id
          "
          [src]="likeInactive"
          class="mr-1 sm-img"
        />
        <img
          *ngIf="doubtSolution.rated === 1"
          [src]="likeActive"
          class="mr-1 sm-img"
        />
        <span
          class="sm-body2"
          [ngClass]="{
            'text-white': doubtSolution.rated === 1,
            '  theme-color ': doubtSolution.rated === 0,
            '  feedback-given-text-bg':
              doubtSolution.rated === -1 ||
              userInfo.id === doubtSolution.createdBy._id
          }"
          >{{ doubtSolution.upVoteCount }}
          {{ userInfo.id === doubtSolution.createdBy._id ? 'Likes' : '' }}</span
        >
      </div>
      <!--Like button of comment-->
      <div
        *ngIf="isComment"
        class="flex items-center caption-1 pr-2 pl-1 rounded-full mr-1 cursor-pointer sm-caption1"
        [ngClass]="
          userInfo.id === doubtSolution.createdBy._id
            ? 'disabed-bg'
            : isLiked
            ? 'theme-color-bg'
            : 'light-purple-bg'
        "
        (click)="likeComment()"
      >
        <ng-container *ngIf="userInfo.id !== doubtSolution.createdBy._id">
          <img
            *ngIf="!isLiked"
            [src]="likeInactive"
            class="mr-1"
            class="sm-img"
          />
          <img *ngIf="isLiked" [src]="likeActive" class="mr-1" class="sm-img" />
        </ng-container>
        <!-- <ng-container *ngIf="userInfo.id === doubtSolution.createdBy._id">
          <img [src]="likeDisabled" class="mr-1" />
        </ng-container> -->
        <span
          [ngClass]="
            userInfo.id === doubtSolution.createdBy._id
              ? 'feedback-given-text-bg '
              : isLiked
              ? 'text-white'
              : 'theme-color'
          "
          class="sm-body1"
          >{{
            userInfo.id === doubtSolution.createdBy._id
              ? doubtSolution.upVoteCount + ' ' + 'Likes'
              : doubtSolution.upVoteCount
          }}</span
        >
      </div>
      <!--Disike button of answer-->
      <div
        *ngIf="!isComment"
        class="flex items-center justify-center caption-1 p-1 rounded-full cursor-pointer sm-caption1"
        (click)="openFeedbackModal($event, 'dislike')"
        [ngClass]="{
          'feedback-given-bg': doubtSolution.rated === -1,
          'light-purple-bg': doubtSolution.rated === 0,
          'disabed-bg':
            doubtSolution.rated === 1 ||
            userInfo.id === doubtSolution.createdBy._id
        }"
      >
        <img
          *ngIf="doubtSolution.rated === 1"
          [src]="dislikeDisabled"
          class="sm-img"
        />
        <img
          *ngIf="
            doubtSolution.rated === 0 &&
            userInfo.id !== doubtSolution.createdBy._id
          "
          [src]="dislikeInactive"
          class="sm-img"
        />
        <img
          *ngIf="doubtSolution.rated === -1"
          [src]="dislikeActive"
          class="sm-img"
        />
        <img
          *ngIf="userInfo.id == doubtSolution.createdBy._id"
          [src]="dislikeDisabled"
          class="sm-img"
        />
        <!-- <span
          [ngClass]="{
            'text-white': doubtSolution.rated === -1,
            'text-[#5a4bda]': doubtSolution.rated === 0,
            'text-[#767676]':
              doubtSolution.rated === 1 ||
              userInfo.id === doubtSolution.createdBy._id
          }"
          >{{
            userInfo.id === doubtSolution.createdBy._id
              ? doubtSolution.downVoteCount
              : ''
          }}
        </span> -->
      </div>
      <!--Reply button of answer-->
      <div
        *ngIf="!isComment"
        class="flex items-center caption-1 pr-2 pl-1 rounded-full cursor-pointer sm-caption1"
        (click)="replyToSolution()"
      >
        <img [src]="replyImg" class="mr-1" class="sm-img" />
        <span class="child-comment-text sm-body2">{{
          doubtSolution.childCommentCount
        }}</span>
      </div>
    </div>
    <img
      *ngIf="
        doubtSolution.createdBy._id !== userInfo.id &&
        doubtSolution.displayOrder === 1
      "
      [src]="reportImg"
      alt="report"
      (click)="openFeedbackModal($event, 'report')"
      class="cursor-pointer report-img sm-img"
    />
    <img
      *ngIf="userInfo.id === doubtSolution.createdBy._id && isComment"
      [src]="deleteImg"
      alt="delete"
      (click)="deleteUserComment($event)"
      class="cursor-pointer del-img sm-img"
    />
  </div>
</section>
<section
  class="flex flex-col bottom-bar"
  *ngIf="doubt && doubt.isRecommended && doubtSolution.displayOrder > 1"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
>
  <div class="flex items-center justify-between">
    <div class="flex flex-row items-center cursor-pointer">
      <img
        [src]="doubtSolution.createdBy.imgUrl.url || userImg"
        class="user-img mr-2"
      />
      <span *ngIf="doubtSolution.displayOrder === 2">
        PW Assistant's Answer
      </span>
      <span *ngIf="doubtSolution.displayOrder === 3">Teacher's Answer</span>
      <img
        [src]="verifiedAssistant"
        alt=""
        class="ml-2"
        *ngIf="
          doubtSolution.displayOrder === 2 || doubtSolution.displayOrder === 3
        "
      />
    </div>
  </div>
  <!-- video link solution -->
  <div
    *ngIf="doubtSolution.videoType.length && doubtSolution.videoUrl.length"
    class="my-1"
  >
    <span
      (click)="detectUrl(doubtSolution.videoUrl, $event)"
      class="show-toggler-color body-2 underline cursor-pointer"
      >Click here to view solution</span
    >
  </div>
  <!--image solution-->
  <div
    *ngIf="
      doubtSolution.imageId &&
      doubtSolution.imageId.baseUrl &&
      doubtSolution.imageId.key.length > 0
    "
    class="reply-message-card-content body-1 break-words my-1 cursor-pointer"
    (click)="openSolutionModal($event)"
  >
    <img
      [src]="doubtSolution.imageId.baseUrl + doubtSolution.imageId.key"
      class="sol-img"
    />
  </div>
  <!-- text solution -->
  <div
    class="my-1 break-words"
    *ngIf="doubtSolution.text && doubtSolution.text.length > 0"
  >
    <span
      class="medium comment-text body-2 font-medium"
      [innerHtml]="
        doubtSolution.text
          | truncateText
            : (doubtSolution.isExpanded
                ? doubtSolution.text.length
                : doubtSolution.text.includes('<!DOCTYPE html>')
                ? 475
                : 175)
          | safe : 'html'
      "
    >
    </span
    ><span
      (click)="doubtSolution.isExpanded = !doubtSolution.isExpanded"
      *ngIf="doubtSolution.text.length > 175"
      class="show-toggler-color body-2 underline cursor-pointer"
      >Show {{ doubtSolution?.isExpanded ? 'less' : 'more' }}
    </span>
  </div>
  <!-- audio solution -->
  <div
    *ngIf="
      doubtSolution.audioId &&
      doubtSolution.audioId.baseUrl &&
      doubtSolution.audioId.key.length > 0
    "
    class="w-full overflow-hidden my-2"
  >
    <div class="w-full flex">
      <audio controls controlsList="nodownload">
        <source
          [src]="doubtSolution.audioId.baseUrl + doubtSolution.audioId.key"
          type="audio/webm"
        />
      </audio>
    </div>
  </div>
</section>
