import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
// import { AutofocusDirective } from './autofocus.directive';

export const webViewPages: any = ['/results'];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'batches',
    pathMatch: 'full',
  },
  {
    path: 'tabs/tabs/batch-tab',
    redirectTo: 'batches',
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/batch-tab/:batchSlug/new-overview`,
    redirectTo: `batches/:batchSlug/batch-overview`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/batch-tab/:batchSlug/mahapack-overview`,
    redirectTo: `batches/:batchSlug/mahapack-overview`,
    pathMatch: 'full',
  },
  {
    path: 'job-alert',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/job-alets/job-alets.module').then(
        (m) => m.JobAletsModule
      ),
  },

  {
    path: `tabs/tabs/batch-tab/:batchId/batch-subjects/:subjectId/batch-subject-details/:mode/batch-subject-content/video-player`,
    redirectTo: `batches/:batchId/subjects/:subjectId/subject-details/:mode/batch-video-player`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/batch-tab/:batchId/subject/:subjectId/subject-weekly`,
    redirectTo: `batches/subject-weekly`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/batch-tab/:batchId/new-overview/payments`,
    redirectTo: `batches/:batchId/batch-overview/order-summary`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/batch-tab/:batchId/subject/:subjectId/subject-all-contents`,
    redirectTo: `batches/:batchId/subject/:subjectId/subject-topics`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/batch-tab/:batchId/subject/:subjectId/subject-all-contents/topic/all/topic-contents`,
    redirectTo: `batches/:batchId/subject/:subjectId/subject-topics/topics/all/contents`,
    pathMatch: 'full',
  },
  {
    path: `courses/:programId/test-list/:testCategoryId/test/:testId/my-result`,
    redirectTo: `test-series/:programId/tests/:testId/test-result`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/dashboard-tab`,
    redirectTo: `dashboard`,
    pathMatch: 'full',
  },
  {
    path: `programs/:programId/subjects/:subjectSlug/:subjectName/chapters`,
    redirectTo: `programs/subjects/:subjectSlug/:subjectName/chapters`,
    pathMatch: 'full',
  },
  {
    path: `programs/:programId/subjects/:subjectSlug/:subjectName/chapters/:chapterId/:chapterName/topics`,
    redirectTo: `programs/subjects/:subjectSlug/:subjectName/chapters/:chapterId/course`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/library-tab`,
    redirectTo: `library`,
    pathMatch: 'full',
  },
  {
    path: `subjects`,
    redirectTo: `dashboard/subjects`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/library-tab/recent-topics;chapterName=:chapterName;subjectName=:subjectName;topicName=:topicName;chapterId=:chapterId;topicId=:topicId;subjectId=:subjectId;programId=:programId`,
    redirectTo: `library/recent-topics?chapterId=:chapterId&topicId=:topicId&subjectId=:subjectId&programId=:programId`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/library-tab/:programSlug/subjects/:subjectSlug/library-subject`,
    redirectTo: `:programSlug/subject/:subjectSlug/:subjectName/chapters`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/test-tab`,
    redirectTo: `test-series`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/test-tab/test-category-details`,
    redirectTo: `test-series`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/test-tab/courses/:programId/test-list/:testCatId`,
    redirectTo: `test-series/:programId/tests/:testCatId/test-list`,
    pathMatch: 'full',
  },
  {
    path: `courses/:programId/test-list/:testId/test-web`,
    redirectTo: `:programId/tests/:testId/test-engine`,
    pathMatch: 'full',
  },
  {
    path: `courses/:programId/test-list/:testId/test`,
    redirectTo: `:programId/tests/:testId/test-engine`,
    pathMatch: 'full',
  },
  {
    path: `courses/:programId/test-list/:testCategoryId/test/:testMappingId/my-result`,
    redirectTo: `test-series/:programId/tests/:testMappingId/test-result`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/store-tab/store-payment-page`,
    redirectTo: `dashboard`,
    pathMatch: 'full',
  },
  {
    path: `books-public`,
    redirectTo: `books-public`,
    pathMatch: 'full',
  },
  {
    path: `books-public/overview-data`,
    redirectTo: `books-public`,
    pathMatch: 'full',
  },
  {
    path: `books-public/address-overview`,
    redirectTo: `books-public`,
    pathMatch: 'full',
  },
  {
    path: `books-public/book-payment-page`,
    redirectTo: `books-public`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/feeds-tab`,
    redirectTo: `feeds`,
    pathMatch: `full`,
  },
  {
    path: `tabs/tabs/feeds-tab/feeds/:feedId/feeds-details`,
    redirectTo: `feeds/:feedId/feeds-details`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/store-tab`,
    redirectTo: `offers`,
    pathMatch: 'full',
  },
  {
    path: `tabs/tabs/store-tab/store-payment-page`,
    redirectTo: `offers`,
    pathMatch: 'full',
  },
  {
    path: `contactus`,
    redirectTo: `contact-us`,
    pathMatch: 'full',
  },
  {
    path: `about`,
    redirectTo: `about-us`,
    pathMatch: 'full',
  },
  {
    path: `aboutus`,
    redirectTo: `about-us`,
    pathMatch: 'full',
  },
  {
    path: `terms-condition`,
    redirectTo: `termsandconditions`,
    pathMatch: 'full',
  },
  {
    path: 'library',
    loadChildren: () =>
      import('./pages/library/library-tab/library.module').then(
        (m) => m.LibraryModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'feeds',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/feeds/feeds-tab/feeds-tab.module').then(
        (m) => m.FeedsTabModule
      ),
  },
  {
    path: 'feeds/:feedId/feeds-details',
    canActivate: [AuthGuard],

    loadChildren: () =>
      import('./pages/feeds/feeds-details/feeds-details.module').then(
        (m) => m.FeedsDetailsModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'refer-earn',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/referral/referral.module').then((m) => m.ReferralModule),
  },
  {
    path: 'wallet',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/wallet/wallet.module').then((m) => m.WalletModule),
  },
  // {
  //   path: 'books-public',
  //   loadChildren: () =>
  //     import('./pages/books/books.module').then((m) => m.BooksModule),
  // },
  {
    path: 'school-contact-program',
    loadChildren: () =>
      import(
        './pages/school-contact-program/school-contact-program.module'
      ).then((m) => m.SchoolContactProgramModule),
  },
  {
    path: 'batches',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/batch/batch.module').then((m) => m.BatchModule),
  },
  {
    path: 'student-master-program',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './pages/student-master-program/student-master-program.module'
      ).then((m) => m.StudentMasterProgramModule),
  },

  {
    path: 'programs/:programId/subjects/:subjectId/:subjectName/chapters',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/courses/chapters/chapters.module').then(
        (m) => m.ChaptersModule
      ),
  },
  {
    path: `:programId/tests/:testId/new-test-engine`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/tests/new-test-engine/new-test-engine.module').then(
        (m) => m.NewTestEngineModule
      ),
    //canDeactivate: [StopRefreshGuard],
  },
  {
    path: `tests/:testId/new-test-result`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/tests/new-test-result/new-test-result.module').then(
        (m) => m.NewTestResultModule
      ),
  },
  {
    path: 'programs/subject/chapter/search-results',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/courses/search-result/search-result.module').then(
        (m) => m.SearchResultModule
      ),
  },
  {
    path: `programs/:programId/subjects/:subjectId/:subjectName/chapters/:chapterId/:topicId/chapter-content/video-player`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/courses-new/video-player/video-player.module').then(
        (m) => m.VideoPlayerModule
      ),
  },
  {
    path: 'programs/:programId/subjects/:subjectId/:subjectName/chapters/:chapterId/:topicId/chapter-content/pdf',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/show-pdf/show-pdf.module').then((m) => m.ShowPdfModule),
  },
  // {
  //   path: 'offers',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/offers/offers.module').then((m) => m.OffersModule),
  // },

  {
    path: 'about-us',
    loadChildren: () =>
      import('./pages/sites/aboutus/aboutus.module').then(
        (m) => m.AboutusModule
      ),
  },
  {
    path: 'termsandconditions',
    loadChildren: () =>
      import('./pages/sites/tac/tac.module').then((m) => m.TacModule),
  },
  {
    path: 'contact-us',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/sites/contactus/contactus.module').then(
        (m) => m.ContactusModule
      ),
  },
  {
    path: 'my-purchase',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/sites/mypurchase/mypurchase.module').then(
        (m) => m.MyPurchaseModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },
  // {
  //   path: 'bookmarks',
  //   canActivate: [AuthGuard],
  //
  //   loadChildren: () =>
  //     import('./pages/bookmarks/bookmarks.module').then(
  //       (m) => m.BookmarksModule
  //     ),
  // },
  // {
  //   path: 'notifications',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/notifications/notifications.module').then(
  //       (m) => m.NotificationsModule
  //     ),
  // },
  {
    path: `tests/sahayak`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/batch/batch-overview/sahayak/sahayak-component.module'
      ).then((m) => m.SahayakModule),
  },
  {
    path: `tests/ai-evaluation`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/ai-evaluation/ai-evaluation.module').then(
        (m) => m.AIEvaluationModule
      ),
  },
  {
    path: `tests/sahayak-ip-engine`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/batch/batch-overview/sahayak-ip-test-engine/sahayap-ip-test-engine-routing.module'
      ).then((m) => m.SahayapIpTestEngineRoutingModule),
  },
  {
    path: `tests/sahayak-learning-path`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/batch/batch-overview/sahayak-learning-path/sahayak-learning-path.module'
      ).then((m) => m.SahayakLearningPathModule),
  },
  {
    path: 'pw-centres-webinar',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pw-centres-webinar/pw-centres-webinar.module').then(
        (m) => m.PwCentresWebinarModule
      ),
  },
  {
    path: 'mentorship',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pw-centres-mentorship/pw-centres-mentorship.module').then(
        (m) => m.PwCentresMentorshipModule
      ),
  },
  {
    path: 'pw-scholarship',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pw-sat/pw-sat.module').then((m) => m.PwSatModule),
  },
  {
    path: `pw-scholarship/:testId/new-test-result`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/tests/new-test-result/new-test-result.module').then(
        (m) => m.NewTestResultModule
      ),
  },
  {
    path: 'test-series',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/new-test-series/new-test-series.module').then(
        (m) => m.NewTestSeriesModule
      ),
  },
  // {
  //   path: 'programs/subjects/:subjectSlug/:subjectName/chapters',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/courses-new/courses-new.module').then(
  //       (m) => m.CoursesNewModule
  //     ),
  // },
  {
    path: `course-video-player/:lectureId`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/courses-new/video-player/video-player.module').then(
        (m) => m.VideoPlayerModule
      ),
  },
  {
    path: `:programId/tests/:testId/test-engine`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/tests/test-engine/test-engine.module').then(
        (m) => m.TestEngineModule
      ),
    //canDeactivate: [StopRefreshGuard],
  },
  {
    path: `test-series/:programId/tests/:testId/test-result`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/tests/test-results/test-results.module').then(
        (m) => m.TestResultsModule
      ),
  },
  {
    path: `:programId/tests/:testId/new-test-engine`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/tests/new-test-engine/new-test-engine.module').then(
        (m) => m.NewTestEngineModule
      ),
    //canDeactivate: [StopRefreshGuard],
  },
  {
    path: `tests/infinite-practice`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/batch/batch-overview/infinite-practice/infinite-practice-component.module'
      ).then((m) => m.InfinitePracticeModule),
  },
  {
    path: `tests/battle-ground`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/batch/batch-overview/battle-ground/battle-ground-component.module'
      ).then((m) => m.BattleGroundModule),
  },
  {
    path: `ai-guru`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/batch/gyan-guru/gyan-guru-component.module').then(
        (m) => m.GyanGuruModule
      ),
  },
  {
    path: `tests/battle-ground-test-engine/:testId`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/batch/batch-overview/battle-ground-engine/battle-ground-engine.module'
      ).then((m) => m.BattleGroundTestEngineModule),
  },
  {
    path: `tests/battle-ground-result-analyzing-screen/:testId`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/batch/batch-overview/battle-ground-result/battle-ground-result.module'
      ).then((m) => m.BattleGroundResultModule),
  },
  {
    path: `:programId/tests/new-infinite-practice-engine`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/tests/new-infinite-practice-engine/new-infinite-practice-engine.module'
      ).then((m) => m.NewInfinitePracticeEngineModule),
  },
  {
    path: `tests/ai-evaluation-test`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/ai-evaluation-test-engine/ai-evaluation-test.module'
      ).then((m) => m.AIEvaluationTestEngineModule),
  },
  {
    path: `:programId/tests/new-infinite-practice-result`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/tests/new-infinite-practice-result/new-infinite-practice-result.module'
      ).then((m) => m.NewInfinitePracticeResultModule),
  },
  {
    path: `:programId/tests/infinite-practice-sessions`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/tests/infinite-practice-sessions/infinite-practice-sessions.module'
      ).then((m) => m.InfinitePracticeSessionsModule),
  },
  {
    path: `:programId/tests/infinite-practice-leaderboard`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../app/pages/tests/infinite-practice-leaderboard/infinite-practice-leaderboard.module'
      ).then((m) => m.InfinitePracticeLeaderboardModule),
  },
  {
    path: `tests/:testId/new-test-result`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/pages/tests/new-test-result/new-test-result.module').then(
        (m) => m.NewTestResultModule
      ),
  },
  {
    path: 'test-series/subjective/results',
    loadChildren: () =>
      import(
        './pages/tests/subjective-test/result-analysis/result-analysis.module'
      ).then((m) => m.ResultAnalysisModule),
  },
  {
    path: `q-bank-exercise/:exerciseId`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/tests/exercise/exercise.module').then(
        (m) => m.ExerciseModule
      ),
  },
  {
    path: `q-bank-result/:mappingId/:exerciseId`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/tests/q-bank-result/q-bank-result.module').then(
        (m) => m.QBankResultModule
      ),
  },
  {
    path: `exercise-feedback/:mappingId/:exerciseId`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/tests/exercise-feedback/exercise-feedback.module').then(
        (m) => m.ExerciseFeedbackModule
      ),
  },
  {
    path: `q-bank/video-solution`,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/video-player/video-player.module').then(
        (m) => m.VideoPlayerModule
      ),
  },
  // {
  //   path: `doubts-connect`,
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/doubts-connect/doubts-connect.module').then(
  //       (m) => m.DoubtsConnectModule
  //     ),
  // },
  {
    path: 'contact-us-public',
    loadChildren: () =>
      import('./pages/sites/contact-us-public/contact-us-public.module').then(
        (m) => m.ContactUsPublicModule
      ),
  },
  {
    path: `video-player-public`,
    loadChildren: () =>
      import('./pages/video-player/video-player.module').then(
        (m) => m.VideoPlayerModule
      ),
  },
  // {
  //   path: 'after-payment',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/after-payment/after-payment.module').then(
  //       (m) => m.AfterPaymentModule
  //     ),
  // },
  {
    path: 'under-maintenance',
    loadChildren: () =>
      import('./pages/under-maintainence/under-maintanence.module').then(
        (m) => m.UnderMaintanenceModule
      ),
  },
  // {
  //   path: 'api-error',
  //   loadChildren: () =>
  //     import('./pages/api-error/api-error.module').then(
  //       (m) => m.ApiErrorModule
  //     ),
  // },

  // {
  //   path: 'refund-policy',
  //   loadChildren: () =>
  //     import('./pages/refund-policy/refund-policy.module').then(
  //       (m) => m.RefundPolicyModule
  //     ),
  // },
  {
    path: 'referral-new',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/referral-new/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  // {
  //   path: 'recent-learning',
  //   loadChildren: () =>
  //     import('./pages/batch/study/recent-learning/recent-learning.module').then(
  //       (m) => m.RecentLearningModule
  //     ),
  // },
  {
    path: 'saarthi',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/saarthi/saarthi.module').then((m) => m.SaarthiModule),
  },
  {
    path: 'results',
    loadChildren: () =>
      import('./pages/results/results.module').then((m) => m.ResultsModule),
  },
  // {
  //   path: 'before-results',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/results/before-results/before-results.module').then(
  //       (m) => m.BeforeResultsModule
  //     ),
  // },
  {
    path: 'pw-centers',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pw-center-page/pw-center-page.module').then(
        (m) => m.PwCenterPageModule
      ),
  },
  // {
  //   path: 'new-test-series',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/new-test-series/new-test-series.module').then(
  //       (m) => m.NewTestSeriesModule
  //     ),
  // },
  // {
  //   path: 'pw-centres',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/pw-center-page/pw-center-page.module').then(
  //       (m) => m.PwCenterPageModule
  //     ),
  // },
  // {
  //   path: 'centres',
  //   loadChildren: () =>
  //     import('./pages/public-pw-center-page/public-pw-center-page.module').then(
  //       (m) => m.PublicPwCenterPageModule
  //     ),
  // },
  // {
  //   path: 'new-test-series',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/new-test-series/new-test-series.module').then(
  //       (m) => m.NewTestSeriesModule
  //     ),
  // },
  // {
  //   path: 'redirection',
  //   loadChildren: () =>
  //     import('./pages/redirection/redirection.module').then(
  //       (m) => m.RedirectionModule
  //     ),
  // },
  {
    path: 'pw-centres',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pw-center-page/pw-center-page.module').then(
        (m) => m.PwCenterPageModule
      ),
  },
  {
    path: 'centres',
    loadChildren: () =>
      import('./pages/public-pw-center-page/public-pw-center-page.module').then(
        (m) => m.PublicPwCenterPageModule
      ),
  },
  // {
  //   path: 'new-test-series',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/new-test-series/new-test-series.module').then(
  //       (m) => m.NewTestSeriesModule
  //     ),
  // },
  // {
  //   path: 'redirection',
  //   loadChildren: () =>
  //     import('./pages/redirection/redirection.module').then(
  //       (m) => m.RedirectionModule
  //     ),
  // },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/logged-out/logged-out.module').then(
        (m) => m.LoggedOutModule
      ),
  },
  {
    path: 'redirection',
    loadChildren: () =>
      import('./pages/redirection/redirection.module').then(
        (m) => m.RedirectionModule
      ),
  },
  {
    path: 'after-payment',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/new-after-payment/new-after-payment.module').then(
        (m) => m.NewAfterPaymentModule
      ),
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentModule),
  },

  {
    path: 'vp-live',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/vp-live/vp-live.module').then((m) => m.VpLiveModule),
  },

  {
    path: 'cohort-selection',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/cohort-selection/cohort-selection.module').then(
        (m) => m.CohortSelectionModule
      ),
  },

  {
    path: 'pw-scholars',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pw-scholar/pw-scholar.module').then(
        (m) => m.PwScholarModule
      ),
  },
  {
    path: 'batches/study',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./pages/batch/study/study.module').then((m) => m.StudyModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
