<!--<p>rating works!</p>-->
<article
  class="rating-submit-card flex flex-col items-center justify-between mt-5"
>
  <div class="w-full flex justify-end" *ngIf="data?.cameFrom === 'studycard'">
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>
  <span class="title-text bold mb-2 heading-4 bold-1">How was the Lecture</span>

  <span class="subtitle-text bold body-1 medium mb-4"
    >Your rating help us improve
  </span>

  <div class="card-content w-full">
    <div class="flex items-center mb-2">
      <div
        class="flex flex-wrap -mx-3 overflow-hidden star-container items-center"
      >
        <div
          *ngFor="let items of stars; index as i"
          class="px-1 w-1/5 overflow-hidden flex justify-center"
        >
          <img
            (click)="setStarRating(items, true)"
            [src]="items <= starRating ? ratedImg : notRatedImg"
            class="star_img cursor-pointer"
          />
        </div>
      </div>
    </div>
    <div
      *ngIf="starRating === 0"
      class="flex items-center mt-3 justify-evenly w-full rating-overview-text"
    >
      <span class="caption-1 medium rating-text-color">VERY POOR</span>
      <span class="caption-1 medium rating-text-color">EXCELLENT</span>
    </div>

    <!-- <button
      (click)="onNoClick()"
      class="bold continue-btn footer-btn cursor-pointer"
      color="primary"
      mat-raised-button
    ></button> -->
    <ng-container *ngIf="starRating > 0">
      <div class="pt-2 pb-6">
        <span *ngIf="starRating === 1" class="body-1 semibold rating-1"
          >VERY POOR</span
        >
        <span *ngIf="starRating === 2" class="body-1 semibold rating-2">
          POOR</span
        >
        <span *ngIf="starRating === 3" class="body-1 semibold rating-3"
          >AVERAGE</span
        >
        <span *ngIf="starRating === 4" class="body-1 semibold rating-4"
          >GOOD</span
        >
        <span *ngIf="starRating === 5" class="body-1 semibold rating-5"
          >EXCELLENT</span
        >
      </div>
      <div class="flex flex-col items-center lower-div p-4">
        <span class="bold-1 well-text body-1 mb-4">{{ ratingLabel }}</span>

        <div
          class="grid grid-cols-2 md:grid-cols-3 gap-2 place-items-center mb-4"
        >
          <div *ngFor="let item of tags" class="col-span-1">
            <button
              (click)="setActive(item)"
              [ngClass]="{ active_tag_button: item.isActive }"
              class="tag-button caption-1 medium flex justify-center items-center break-words w-full"
              mat-stroked-button
            >
              {{ item.name }}
            </button>
          </div>
        </div>

        <textarea
          #input
          class="mb-4 w-full feedback-textarea caption-1 medium"
          matInput
          placeholder="Enter your feedback here"
          rows="5"
        ></textarea>

        <button
          (click)="onNoClick(input)"
          class="bold active-continue-btn footer-btn body-1 semibold"
          color="primary"
          mat-raised-button
        >
          {{ data?.cameFrom === 'studycard' ? 'Submit' : 'Continue' }}
        </button>
      </div>
    </ng-container>
  </div>
</article>
