import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe/safe.pipe';
import { FormatTimePipe } from './formatTime/format-time.pipe';

import { GetCardImgPipe } from './batch-discovery/card-img/get-card-img.pipe';
import { GetVideoImgPipe } from './batch-discovery/video-img/get-video-img.pipe';
import { GetVideoNamePipe } from './batch-discovery/video-name/get-video-name.pipe';
import { GetVideoSubjectNamePipe } from './batch-discovery/video-subject-name/get-video-subject-name.pipe';
import { GetVideoDurationPipe } from './batch-discovery/video-duration/get-video-duration.pipe';
import { GetBatchImgPipe } from './batch/order-summary/get-batch-img/get-batch-img.pipe';
import { GetBookImgPipe } from './batch/order-summary/get-book-img/get-book-img.pipe';
import { GetPricePipe } from './batch/order-summary/get-price/get-price.pipe';
import { GetBookPricePipe } from './batch/order-summary/get-book-price/get-book-price.pipe';
import { ExpertRepliedPipe } from './expertReplied/expert-replied.pipe';
import { TimeDifferencePipe } from './time-difference/time-difference.pipe';
import { GetStatusPipe } from './refer-and-earn/landing-page/status-pipe/get-status.pipe';
import { GetCoinsPipe } from './refer-and-earn/wallet/get-status/get-coins.pipe';
import { GetWalletBankTransferPipe } from './refer-and-earn/wallet/wallet-bank-transfer/wallet-bank-transfer.pipe';
import { GetBannerPipe } from './refer-and-earn/landing-page/banner-pipe/get-banner.pipe';
import { GetIntviteStatusPipe } from './refer-and-earn/landing-page/invite-status/get-intvite-status.pipe';
import { GetVerificationStatusPipe } from './refer-and-earn/redeem-now-page/get-verification-status/get-verification-status.pipe';
import { GetPaymentStatusPipe } from './refer-and-earn/redeem-now-page/get-payment-status/get-payment-status.pipe';
import { GetCommonCoinsPipe } from './refer-and-earn/redeem-now-page/get-common-coins/get-common-coins.pipe';
import { GetTeacherImgPipe } from './khazana/khazana-chapter-card/teacher-img/get-teacher-img.pipe';
import { GetDescriptionPipe } from './khazana/khazana-chapter-card/description/get-description.pipe';
import { KhazanaVideoImagePipe } from './khazana/khazana-video-card/get-video-image/get-video-image.pipe';
import { KhazanaVideoDurationPipe } from './khazana/khazana-video-card/get-video-duration/khazana-video-duration.pipe';
import { TransactionHistoryStatusPipe } from './refer-and-earn/redeem-now-page/transaction-history-status/transaction-history-status.pipe';
import { ModifyFilterNamePipe } from './batch-discovery/filter-name/modify-filter-name.pipe';
import { StringTruncatePipe } from './string-truncate/string-truncate.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { GetTestImagePipe } from './test-series/get-test-image.pipe';
import { GetTestTextPipe } from './test-series/get-test-text.pipe';
import { BatchOrientationBannerPipe } from './batch/order-summary/get-batch-orientation-banner/batch-orientation-banner.pipe';
import { GetSubjectImagePipe } from './batch/get-subject-image/get-subject-image.pipe';
import { GetTestmonialImagePipe } from './batch/get-testmonial-image/get-testmonial-image.pipe';
import { GetExpiryDatePipe } from './batch/get-expiry-date/get-expiry-date.pipe';
import { CheckBookAddedPipe } from './batch/order-summary/check-book-added/check-book-added.pipe';
import { GetFloorValuePipe } from './batch/get-floor-value/get-floor-value.pipe';
import { GetPercentageValuePipe } from './batch/getPercentageValue/get-percentage-value.pipe';
import { GetImagePipePipe } from './batch/order-summary/getImagePipe/get-image-pipe.pipe';
import { GetDiscountedPricePipe } from './batch/getDiscountedPrice/get-discounted-price.pipe';
import { BatchFilterCountPipe } from './batch-filter-count/batch-filter-count.pipe';
import { InstallmentPipe } from './installment/installment.pipe';
import { GetQuestionImagePipe } from './dpp/get-question-image.pipe';
import { GetTextPipe } from './dpp/get-text.pipe';
import { GetOptionImagePipe } from './dpp/get-option-image.pipe';
import { GetItemImgPipe } from './batch/order-summary/get-item-image/get-item-img.pipe';
import { DecodedHTMLPipe } from './decode-html/decoded-html.pipe';
import { PaymentModePipe } from './partPayment/payment-mode.pipe';
import { GetImageUrlPipe } from './batch/get-image-url/get-image-url.pipe';
import { GetCeilValuePipe } from './batch/get-ceil-value/get-ceil-value.pipe';
import { GetOrdinalValuePipe } from './batch/get-ordinal-value/get-ordinal-value.pipe';

@NgModule({
  declarations: [
    SafePipe,
    FormatTimePipe,
    GetCardImgPipe,
    GetVideoImgPipe,
    GetVideoNamePipe,
    GetVideoSubjectNamePipe,
    GetVideoDurationPipe,
    GetBatchImgPipe,
    GetBookImgPipe,
    GetPricePipe,
    GetBookPricePipe,
    ExpertRepliedPipe,
    TimeDifferencePipe,
    GetTeacherImgPipe,
    GetDescriptionPipe,
    KhazanaVideoImagePipe,
    KhazanaVideoDurationPipe,
    GetStatusPipe,
    GetCoinsPipe,
    GetWalletBankTransferPipe,
    GetBannerPipe,
    GetIntviteStatusPipe,
    GetVerificationStatusPipe,
    GetPaymentStatusPipe,
    GetCommonCoinsPipe,
    TransactionHistoryStatusPipe,
    TruncateTextPipe,
    ModifyFilterNamePipe,
    StringTruncatePipe,
    GetTestImagePipe,
    GetTestTextPipe,
    BatchOrientationBannerPipe,
    TimeDifferencePipe,
    GetSubjectImagePipe,
    GetTestmonialImagePipe,
    CheckBookAddedPipe,
    GetExpiryDatePipe,
    CheckBookAddedPipe,
    GetExpiryDatePipe,
    CheckBookAddedPipe,
    GetFloorValuePipe,
    GetPercentageValuePipe,
    GetImagePipePipe,
    GetDiscountedPricePipe,
    BatchFilterCountPipe,
    InstallmentPipe,
    GetQuestionImagePipe,
    GetTextPipe,
    GetOptionImagePipe,
    GetItemImgPipe,
    DecodedHTMLPipe,
    PaymentModePipe,
    GetImageUrlPipe,
    GetCeilValuePipe,
    GetOrdinalValuePipe,
  ],
  exports: [
    SafePipe,
    FormatTimePipe,
    GetCardImgPipe,
    GetVideoImgPipe,
    GetVideoNamePipe,
    GetVideoSubjectNamePipe,
    GetVideoDurationPipe,
    GetBatchImgPipe,
    GetBookImgPipe,
    GetPricePipe,
    GetBookPricePipe,
    ExpertRepliedPipe,
    TimeDifferencePipe,
    GetStatusPipe,
    GetCoinsPipe,
    GetWalletBankTransferPipe,
    GetBannerPipe,
    GetIntviteStatusPipe,
    GetVerificationStatusPipe,
    GetPaymentStatusPipe,
    GetCommonCoinsPipe,
    GetTeacherImgPipe,
    GetDescriptionPipe,
    KhazanaVideoImagePipe,
    KhazanaVideoDurationPipe,
    ModifyFilterNamePipe,
    StringTruncatePipe,
    TransactionHistoryStatusPipe,
    TruncateTextPipe,
    GetTestImagePipe,
    GetTestTextPipe,
    BatchOrientationBannerPipe,
    TimeDifferencePipe,
    GetSubjectImagePipe,
    GetTestmonialImagePipe,
    CheckBookAddedPipe,
    GetExpiryDatePipe,
    CheckBookAddedPipe,
    GetExpiryDatePipe,
    CheckBookAddedPipe,
    GetFloorValuePipe,
    GetPercentageValuePipe,
    GetImagePipePipe,
    GetDiscountedPricePipe,
    BatchFilterCountPipe,
    InstallmentPipe,
    GetQuestionImagePipe,
    GetTextPipe,
    GetOptionImagePipe,
    GetItemImgPipe,
    DecodedHTMLPipe,
    PaymentModePipe,
    GetImageUrlPipe,
    GetCeilValuePipe,
    GetOrdinalValuePipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
