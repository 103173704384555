import { Injectable } from '@angular/core';
import { PPApiService } from '../../api/api.service';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { map, tap } from 'rxjs/operators';

import { BehaviorSubject, catchError, retry, Observable } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CommentResponseModel,
  CommonDoubtModel,
  DoubtConnectPlanObject,
  DoubtListModel,
  TagsResponseModel,
  SuccessResponse,
  DoubtCommentModel,
  CommentsResponseModel,
  StudentMastersResponse,
  SendSolutionResponse,
  ChapterWiseDoubtsResponse,
  ScheduleWiseDoubtsResponse,
  SlideWiseDoubtsResponse,
} from './doubt.model';
export enum warningType {
  PROFANE = 'profane',
  BLOCK = 'block',
  PERMANENTBLOCK = 'permanent_block',
}

@Injectable({
  providedIn: 'root',
})
export class DoubtsService {
  activeDoubt$: BehaviorSubject<CommonDoubtModel> =
    new BehaviorSubject<CommonDoubtModel>(new CommonDoubtModel({}));

  activeDoubtSolution$: BehaviorSubject<DoubtCommentModel> =
    new BehaviorSubject<DoubtCommentModel>(new DoubtCommentModel({}));

  doubtSection$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  pushDoubt$: BehaviorSubject<DoubtListModel> =
    new BehaviorSubject<DoubtListModel>(new DoubtListModel({}));

  doubtStatusChange$: BehaviorSubject<DoubtListModel> =
    new BehaviorSubject<DoubtListModel>(new DoubtListModel({}));

  constructor(
    private apiService: PPApiService,
    private appUrlService: AppUrlService,
    private http: HttpClient
  ) {}

  getAllDoubts(roomId: string, scheduleId: string, query?: any) {
    let url = this.appUrlService.GET_ALL_DOUBTS(roomId, scheduleId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap()
    );
  }

  setActiveDoubt(doubt: CommonDoubtModel) {
    this.activeDoubt$.next(doubt);
  }

  setActiveDoubtSection(section: string) {
    this.doubtSection$.next(section);
  }

  getSlideList(batchId: string, subjectId: string, scheduleId: string) {
    const url = this.appUrlService.GET_SCHEDULE_SLIDES(
      batchId,
      subjectId,
      scheduleId
    );
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  getCommonDoubts(scheduleId: string, slideId: string, query: any) {
    const url = this.appUrlService.GET_COMMON_DOUBTS(scheduleId, slideId);

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  getUserDoubts(dRoomId: string, scheduleId: string, query?: any) {
    const url = this.appUrlService.GET_USER_DOUBTS(dRoomId, scheduleId);

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService
      .get(options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  getAllSolutions(doubtId: string, query?: any) {
    const url = this.appUrlService.GET_ALL_SOLUTION_OF_DOUBT(doubtId);
    const options: PPApiOptions = {
      apiPath: url,
      // params: new PPApiParams().append('page', query.page),
    };
    return this.apiService.get<any>(options).pipe(
      retry(1),
      catchError(handleError),
      map((res) => res)
    );
  }

  getReportTags() {
    const url = this.appUrlService.GET_DOUBT_REPORT_TAGS();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<TagsResponseModel>(options).pipe(
      retry(1),
      catchError(handleError),
      map((res) => res)
    );
  }

  getUserDoubtOnSlide(slideId: string) {
    const url = this.appUrlService.GET_USERS_DOUBTS_ON_SLIDE(slideId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get(options).pipe(catchError(handleError));
  }

  createDoubt(data: any) {
    const url = this.appUrlService.CREATE_DOUBT;
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post(data, options)
      .pipe(catchError(handleError), tap());
  }

  checkDoubtRelevancy(data: any) {
    const url = this.appUrlService.CHECK_IRRELEVANCY;
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<any>(data, options)
      .pipe(catchError(handleError), tap());
  }
  userIsBlocked() {
    const url = this.appUrlService.USER_BLOCKED;
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get(options).pipe(
      map((res: any) => res),
      catchError(handleError)
    );
  }

  getDoubtChat(conversationId: string) {
    const appUrl = this.appUrlService.CHAT(conversationId);
    const options: PPApiOptions = {
      apiPath: appUrl,
    };
    return this.apiService.get(options).pipe(retry(1), catchError(handleError));
  }

  updateDoubt(doubtId: string, toDelete: boolean, data?: any) {
    const url = this.appUrlService.EDIT_DELETE_DOUBT(doubtId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    if (toDelete) {
      return this.apiService
        .delete<any>(options)
        .pipe(retry(1), catchError(handleError));
    }
    return this.apiService
      .put<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  getPopularDoubts(scheduleId: string, query?: any) {
    const url = this.appUrlService.GET_POPULAR_DOUBTS_LIST(scheduleId);

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().append('page', query.page),
    };

    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  createSameDoubt(doubt: any) {
    const url = this.appUrlService.CREATE_SAME_DOUBT();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post(doubt, options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  getDoubtDetails(doubtId: string) {
    const url = this.appUrlService.GET_DOUBT_DETAILS(doubtId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }
  getUserProfileDetails(conversationId: string) {
    const url = this.appUrlService.USER_PROFILE_URL(conversationId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }

  getExpertInfo(batchId: string, teacherId: string) {
    const url = this.appUrlService.GET_EXPERT_INFO(batchId, teacherId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }

  postCompleteDoubt(data: any) {
    const url = this.appUrlService.POST_COMPLETE_DOUBT();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.post<any>(data, options).pipe(
      map((res: any) => res.success),
      retry(1),
      catchError(handleError)
    );
  }

  /* DOUBT CONNECT */

  getDoubtConnectPlansListing(roomId: string, query?: any) {
    const url = this.appUrlService.GET_DOUBT_CONNECT_LIST(roomId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    if (!this.isEmpty(query)) {
      options.params = new PPApiParams().appendAll(query);
    }

    return this.apiService
      .get<DoubtConnectPlanObject>(options)
      .pipe(retry(1), catchError(handleError));
  }

  enrollInFreePlan(roomId: string, planId: string) {
    const url = this.appUrlService.ENROLL_IN_FREE_PLAN(roomId, planId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    const data = {
      planId,
    };

    return this.apiService.put<any>(data, options).pipe(
      map((res) => res.success),
      retry(1),
      catchError(handleError)
    );
  }

  getActivePlan(roomId: string) {
    const url = this.appUrlService.GET_USER_ACTIVE_PLAN(roomId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap()
    );
  }

  reportDoubt(data: any, doubtId: string) {
    const url = this.appUrlService.REPORT_DOUBT(doubtId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  reportComment(data: any, commentId: string) {
    const url = this.appUrlService.REPORT_DOUBT_COMMENT(commentId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError), tap());
  }

  getMetaData() {
    const url = this.appUrlService.GET_DOUBT_CONNECT_META_DATA();

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap()
    );
  }

  startSession(studentMappingId: string) {
    const url = this.appUrlService.START_DOUBT_SESSION(studentMappingId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    const data = {};

    return this.apiService
      .put<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  getDoubtConnectRoom() {
    const url = this.appUrlService.GET_DOUBT_CONNECT_ROOM();

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(map((res) => res.data));
  }

  subscribeToNotification(data: any) {
    const url = this.appUrlService.SUBSCRIBE_TO_NOTIFICATION();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  getMentorList(roomId: string) {
    const url = this.appUrlService.GET_MENTOR_LIST(roomId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(map((res) => res.data));
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /* BATCH ALL DOUBTS */

  getSubjects(batchId: string) {
    const url = this.appUrlService.GET_BATCHID_SUBJECTS(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap()
    );
  }

  getSchedules(batchId: string, subjectId: string, query?: any) {
    const url = this.appUrlService.GET_SCHEDULES(batchId, subjectId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    if (!this.isEmpty(query)) {
      options.params = new PPApiParams().appendAll(query);
    }

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getSolutionTags(query?: any) {
    const url = this.appUrlService.GET_SOLUTION_TAGS();

    const options: PPApiOptions = {
      apiPath: url,
    };

    if (!this.isEmpty(query)) {
      options.params = new PPApiParams().appendAll(query);
    }

    return this.apiService.get<TagsResponseModel>(options).pipe(
      map((res) => res),
      tap(),
      catchError(handleError)
    );
  }

  getDoubtComments(typeId: string, query?: any) {
    const url = this.appUrlService.GET_DOUBT_COMMENTS(typeId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    if (!this.isEmpty(query)) {
      options.params = new PPApiParams().appendAll(query);
    }

    return this.apiService.get<CommentsResponseModel>(options).pipe(
      map((res) => res),
      tap(),
      catchError(handleError)
    );
  }

  postDoubtComments(data: object) {
    const url = this.appUrlService.POST_DOUBT_COMMENTS();

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post<CommentResponseModel>(data, options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  deleteDoubtComments(commentId: string) {
    const url = this.appUrlService.DELETE_DOUBT_COMMENTS(commentId);

    const options: PPApiOptions = { apiPath: url };

    return this.apiService.delete<SuccessResponse>(options).pipe(
      map((res) => res),
      tap(),
      catchError(handleError)
    );
  }

  rateDoubtSolution(data: any) {
    let url = this.appUrlService.POST_RATING();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  getStudentDetails(query: any) {
    let url = this.appUrlService.GET_STUDENT_DETAILS();

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService
      .get<StudentMastersResponse>(options)
      .pipe(retry(1), catchError(handleError));
  }

  getSlideDetails(slideId: string) {
    const url = this.appUrlService.GET_SLIDE_DETAIL(slideId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  sendDoubtSolution(data: any) {
    let url = this.appUrlService.SEND_SOLUTION();
    const options: PPApiOptions = { apiPath: url };

    return this.apiService
      .post<SendSolutionResponse>(data, options)
      .pipe(catchError(handleError));
  }

  getChapterWiseDoubts(batchId: string, subjectId: string, query?: any) {
    const url = this.appUrlService.GET_SUBJECT_WISE_DOUBTS(batchId, subjectId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    if (!this.isEmpty(query)) {
      options.params = new PPApiParams().appendAll(query);
    }

    return this.apiService.get<ChapterWiseDoubtsResponse>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getSchedulwWiseDoubts(
    batchId: string,
    subjectId: string,
    chapterId: string,
    query?: any
  ) {
    const url = this.appUrlService.GET_SCHEDULE_WISE_DOUBTS(
      batchId,
      subjectId,
      chapterId
    );

    const options: PPApiOptions = {
      apiPath: url,
    };

    if (!this.isEmpty(query)) {
      options.params = new PPApiParams().appendAll(query);
    }

    return this.apiService.get<ScheduleWiseDoubtsResponse>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getSlideWiseDoubts(
    batchId: string,
    subjectId: string,
    chapterId: string,
    scheduleId: string,
    query?: any
  ) {
    const url = this.appUrlService.GET_SLIDE_WISE_DOUBTS(
      batchId,
      subjectId,
      chapterId,
      scheduleId
    );

    const options: PPApiOptions = {
      apiPath: url,
    };

    if (!this.isEmpty(query)) {
      options.params = new PPApiParams().appendAll(query);
    }

    return this.apiService.get<SlideWiseDoubtsResponse>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }
}
