import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';
import {
  DoubtCommentModel,
  CommonDoubtModel,
  CreatedByModel,
} from 'src/app/common/services/doubts/doubt.model';
import {
  DoubtsService,
  warningType,
} from 'src/app/common/services/doubts/doubts.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { LoaderService } from 'src/app/common/services/loader/loader.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import {
  SEND_BUTTON,
  SAME_DOUBT_ACTIVE,
  SAME_DOUBT_DEFAULT,
  REPORT,
  MIKE_IMG,
  ATTACHMENT_IMG,
  PIN,
  PERSONAL_ASSISTANT_VERIFIED,
  LIKE_INACTIVE,
  DISLIKE_INACTIVE,
  REPLY_TO_ANSWER,
  USER_IMG,
} from 'src/app/core/assets.location';

@Component({
  selector: 'app-doubt-comments',
  templateUrl: './doubt-comments.component.html',
  styleUrls: ['./doubt-comments.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate(200),
      ]),
      transition('* => void', [
        animate(200, style({ opacity: 0, transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class DoubtCommentsComponent implements OnInit {
  @Input('isBlockFromParent') isBlockFromParent: boolean = false;
  @Input('chatInputBlockedParent') chatInputBlockedParent: boolean = false;
  @Input('inputBlockMessageParent') inputBlockMessageParent: string;
  @Input('doubtSolution') doubtSolution: DoubtCommentModel;
  @Output('changeSection') changeSectionEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Input('doubt') doubt: CommonDoubtModel;
  @Input() coming_source: string = '';
  doubtSolutionComments: Array<DoubtCommentModel> = [];
  userInfo: any;
  addReplySub: Subscription;
  sendButton = SEND_BUTTON;
  sameDoubtActive = SAME_DOUBT_ACTIVE;
  sameDoubtDefault = SAME_DOUBT_DEFAULT;
  reportImg = REPORT;
  mikeImg = MIKE_IMG;
  attachmentImg = ATTACHMENT_IMG;
  pinImg = PIN;
  textValue: string = '';
  verifiedAssistant = PERSONAL_ASSISTANT_VERIFIED;
  likeInactive = LIKE_INACTIVE;
  dislikeInactive = DISLIKE_INACTIVE;
  replyImg = REPLY_TO_ANSWER;
  userImg = USER_IMG;
  processing: boolean = false;
  doubtCommentQuery: any = {};
  doubtCommentPaginate: boolean;
  confirmationText: string = '';
  relevant: boolean = false;
  isBlock: boolean = false;
  irreleventMessage: string = '';
  blockMessage: string = '';
  inputBlockMessage: string = '';
  chatInputBlocked: boolean = false;

  constructor(
    private globalService: GlobalService,
    private doubtService: DoubtsService,
    private showErrorService: ShowErrorService,
    private loaderService: LoaderService
  ) {
    this.doubtCommentQuery = {
      page: 1,
      limit: 20,
    };
  }

  ngOnInit(): void {
    this.getDoubtSolutionComments(this.doubtCommentQuery);
    this.getUserInfo();
  }

  ngOnDestroy(): void {
    this.addReplySub?.unsubscribe();
  }

  loadMore() {
    if (this.doubtCommentPaginate) {
      this.doubtCommentQuery.page++;
      this.getDoubtSolutionComments(this.doubtCommentQuery);
    }
  }

  getUserInfo() {
    this.userInfo = this.globalService.getUser().getValue();
  }

  async getDoubtSolutionComments(query: any) {
    this.isUserBlocked();
    if (!this.doubt._id)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_GET_DOUBT_ANSWERS
      );
    try {
      this.processing = true;
      query = { ...query, parentId: this.doubtSolution._id };
      const res = await lastValueFrom(
        this.doubtService.getDoubtComments(this.doubt._id, query)
      );
      if (res && res.success && res.data) {
        // if (res.data.length > 0) {
        let list: Array<DoubtCommentModel> = [];
        list = res.data.map((item: any) => new DoubtCommentModel(item));
        this.doubtCommentPaginate = list.length > 19;
        this.doubtSolutionComments = [...this.doubtSolutionComments, ...list];
        // }
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }

  async submitAnswer(msg: any) {
    if (!this.doubt._id)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_GET_DOUBT_ANSWERS
      );
    const data = {
      text: msg,
      type: 'DOUBTS',
      typeId: this.doubt._id,
      parentId: this.doubtSolution._id,
    };
    try {
      this.loaderService.loadData('Adding comment');
      const res = await lastValueFrom(
        this.doubtService.postDoubtComments(data)
      );
      // this.addReplySub = this.doubtService
      //   .postDoubtComments(data)
      //   .subscribe((res) => {
      if (res) {
        let userDetails = {
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          _id: this.userInfo.id,
          imageId: this.userInfo.imageId,
        };
        const commentData = {
          _id: res._id,
          createdAt: res.createdAt,
          text: res.text,
          createdBy: new CreatedByModel(userDetails),
          upVoteCount: res.upVoteCount,
        };
        this.globalService.showSnackBar(
          SNACKBAR_MESSAGE.COMMENT_ADDED_SUCCESSFULLY
        );
        this.doubtSolutionComments.unshift(new DoubtCommentModel(commentData));
        this.doubtSolution.childCommentCount += 1;
        // });
      }
    } catch (e: any) {
      if (e && !e['success'] && e['error']['warningType']) {
        if (e['error']['warningType'] === warningType.PROFANE) {
          this.irreleventMessage = e['error']['message'];
          this.relevant = true;
          this.isBlock = false;
        }
        if (e['error']['warningType'] === warningType.BLOCK) {
          this.blockMessage = e['error']['message'];
          this.isBlock = true;
          this.relevant = false;
        }
        if (e['error']['warningType'] === warningType.PERMANENTBLOCK) {
          this.chatInputBlocked = true;
          this.relevant = false;
          this.isBlock = false;
          this.inputBlockMessage = e['error']['message'];
        }
      } else {
        await this.showErrorService.showError(e);
      }
    } finally {
      this.loaderService.unloadData('Adding comment');
      msg = '';
    }
  }

  async deleteUserComment(comment: DoubtCommentModel) {
    if (!comment._id)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_DELETE_USER_COMMENT
      );
    let index = this.doubtSolutionComments.findIndex(
      (item) => item._id === comment._id
    );
    this.doubtSolutionComments.splice(index, 1);
    try {
      const res = await lastValueFrom(
        this.doubtService.deleteDoubtComments(comment._id)
      );
      if (res && res.success) {
        this.globalService.showSnackBar(
          SNACKBAR_MESSAGE.COMMENT_DELETED_SUCCESSFULLY
        );
      }
    } catch (e) {
      this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_DELETE_USER_COMMENT
      );
      this.doubtSolutionComments.splice(index, 0, comment);
    }
  }

  confirmationDialog(text: string) {
    if (text.toLowerCase() === 'report') {
      this.confirmationText = 'Reported';
      setTimeout(() => {
        this.confirmationText = '';
      }, 1000);
    }
  }
  async isUserBlocked() {
    this.chatInputBlocked = false;
    try {
      const res = await lastValueFrom(this.doubtService.userIsBlocked());
      if (res && res['success'] && res['data']['warningType']) {
        if (res['data']['warningType'] === warningType.BLOCK) {
          this.blockMessage = res['data']['message'];
          this.isBlock = true;
        }
        if (res['data']['warningType'] === warningType.PERMANENTBLOCK) {
          this.chatInputBlocked = true;
          this.isBlock = false;
          this.inputBlockMessage = res['data']['message'];
        }
      }
    } catch (e: any) {
      if (e && !e['success']) {
        await this.showErrorService.showError(e);
      }
    }
  }
}
