import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  loaderSub: Subscription;
  message: string;

  constructor(private loaderService: LoaderService, public dialog: MatDialog) {
    this.loaderSub = this.loaderService.loaderMessage.subscribe((data) => {
      if (data && data.length > 0) {
        this.message = data;
      }
    });
  }

  ngOnDestroy() {
    this.loaderSub.unsubscribe();
  }

  ngOnInit(): void {}
}
