import { Component, OnInit, Inject, Sanitizer } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';
import { admitCardSuccess } from 'src/app/core/assets.location';
import { AdmitCardModalComponent } from '../admit-card-modal.component';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
  sanitizedHtmlTitle: SafeHtml;
  sanitizedHtmlContent: SafeHtml;
  constructor(
    public dialogRef: MatDialogRef<AdmitCardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}
  isContentValid() {
    return (
      this.data.content !==
        `<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body>\n\n</body>\n</html>` &&
      this.data.content !== null
    );
  }
  isTitleValid() {
    return (
      this.data.title !==
        `<!DOCTYPE html>\n<html>\n<head>\n</head>\n<body>\n\n</body>\n</html>` &&
      this.data.title !== null
    );
  }

  ngOnInit(): void {
    this.sanitizedHtmlTitle = this.sanitizer.bypassSecurityTrustHtml(
      this.data.title
    );
    this.sanitizedHtmlContent = this.sanitizer.bypassSecurityTrustHtml(
      this.data.content
    );
  }
  closeSuccessModal = () => {
    this.dialogRef.close();
  };
  removeOrigin(link: string): string {
    const origin = window.location.origin;
    if (link.startsWith(origin)) {
      return link.substring(origin.length);
    }
    return link;
  }
  submitSuccessModal = () => {
    const { link, type } = this.data.url;
    if (type === 'native') {
      let pathWithoutOrigin = this.removeOrigin(link);
      if (pathWithoutOrigin.startsWith('/study')) {
        pathWithoutOrigin = pathWithoutOrigin.substring('/study'.length);
      }
      this.router.navigate([pathWithoutOrigin]);
    } else {
      window.open(link, '_blank');
    }
    this.dialogRef.close();
  };
  lottieOptions: AnimationOptions = {
    path: admitCardSuccess,
  };
}
