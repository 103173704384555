import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent implements OnInit {
  loading: AnimationOptions = {
    path: 'assets/batches/loader.json',
  };
  constructor(private dialog: MatDialogRef<LoadingPageComponent>) {}

  ngOnInit(): void {
    this.closeLoaderModal();
  }

  closeLoaderModal() {
    setTimeout(async () => {
      this.dialog.close();
    }, 4000);
  }
}
