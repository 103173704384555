import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-load-more',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss'],
})
export class LoadMoreComponent implements OnInit {
  @Input('loading') loading: boolean = false;
  @Output('loadMore') loadMore = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.loadMore.emit(true);
  }
}
