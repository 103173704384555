import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../services/global/global.service';
import { ContentService } from '../../services/content/content.service';
import { lastValueFrom } from 'rxjs';
import { DoubtsService } from '../../services/doubts/doubts.service';
import { DoubtsHelper } from '../../services/doubts/doubts.helper';
import { DIALOG_CLOSE_ICON } from '../../../core/assets.location';

@Component({
  selector: 'app-doubt-feedback-dialog',
  templateUrl: './doubt-feedback-dialog.component.html',
  styleUrls: ['./doubt-feedback-dialog.component.scss'],
})
export class DoubtFeedbackDialogComponent implements OnInit {
  @ViewChild('message') message: string;
  reportTags: Array<Tags> = [];
  activeTags: Array<string> = [];
  doubtHelper: DoubtsHelper;
  dialogValue: any;
  dialogCloseImg = DIALOG_CLOSE_ICON;

  constructor(
    public dialogRef: MatDialogRef<DoubtFeedbackDialogComponent>,
    private globalService: GlobalService,
    private contentService: ContentService,
    private doubtService: DoubtsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.doubtHelper = new DoubtsHelper(this.doubtService);
    this.dialogValue = data.dialogValue;
  }

  ngOnInit(): void {
    this.getReportingTags();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async getReportingTags() {
    let query = {};
    if (this.dialogValue === 'yes') {
      query = {
        status: 1,
      };
    } else {
      query = {
        status: 0,
      };
    }

    try {
      const res = await this.doubtHelper.getSolutionTags(query);
      if (res) {
        res.data.forEach((el: any) => {
          const tag = new Tags({
            name: el,
            isActive: false,
          });
          this.reportTags.push(new Tags(tag));
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  setTag(item: Tags) {
    if (this.activeTags.includes(item.name)) {
      this.activeTags.forEach((el, index) => {
        if (el === item.name) {
          this.activeTags.splice(index, 1);
        }
      });
    } else {
      this.activeTags.push(item.name);
    }
    item.isActive = !item.isActive;
  }

  onSubmitClick(msg: any): void {
    const data = {
      msg: msg.value,
      tags: this.activeTags,
    };
    this.dialogRef.close(data);
  }
}

class Tags {
  name: string;
  isActive: boolean;

  constructor(tag: any) {
    this.name = tag.name || '';
    this.isActive = false;
  }
}
