import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  SAVE_MONEY_LOGO,
  FOUNDATION_NUDGE_BG,
} from 'src/app/core/assets.location';
import { MatDialog } from '@angular/material/dialog';
import { DonateInfoComponent } from '../donate-info/donate-info.component';

@Component({
  selector: 'app-donate-card',
  templateUrl: './donate-card.component.html',
  styleUrls: ['./donate-card.component.scss'],
})
export class DonateCardComponent implements OnInit {
  saveMoneyLogo = SAVE_MONEY_LOGO;
  nudgeBGimg = FOUNDATION_NUDGE_BG;
  isChecked: boolean;

  @Output() ischeckedEvent = new EventEmitter<boolean>();
  @Input('isDefaultSelected') isDefaultSelected: boolean;
  @Input('donationAmount') donationAmount: number;
  constructor(private dialogRef: MatDialog) {}

  openDialog() {
    this.dialogRef.open(DonateInfoComponent, {
      width: '940px',
      maxWidth: '95%',
    });
  }
  emitCheckboxEvent(event: any) {
    this.ischeckedEvent.emit(this.isChecked);
  }
  ngOnInit(): void {
    this.isChecked = this.isDefaultSelected;
  }
}
