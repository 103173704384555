<div>
  <div class="flex justify-end cursor-pointer">
    <img [src]="crossIcon" (click)="close()" />
  </div>
  <div class="flex justify-center font-semibold heading-4 leading-8">
    {{ dialogInfo.title }}
  </div>
  <div class="flex justify-center py-3 text-center heading-5 leading-7">
    {{ dialogInfo.description }}
  </div>
  <div class="flex justify-center">
    <button mat-raised-button class="grant-access-btn" (click)="grantAccess()">
      <span class="font-normal body-1">Grant Access</span>
    </button>
  </div>
</div>
