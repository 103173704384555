import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  CHECK_BADGE,
  CLOSE_GRAY_BG_ICON,
  STUDENT_READING_BOOKS,
} from 'src/app/core/assets.location';
import { GlobalService } from '../../services/global/global.service';

@Component({
  selector: 'app-successfully-enrolled-modal',
  templateUrl: './successfully-enrolled-modal.component.html',
  styleUrls: ['./successfully-enrolled-modal.component.scss'],
})
export class SuccessfullyEnrolledModalComponent implements OnInit {
  closeImg = CLOSE_GRAY_BG_ICON;
  checkBadge = CHECK_BADGE;
  students = STUDENT_READING_BOOKS;
  batchNavigation: boolean = false;
  coming_source: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<SuccessfullyEnrolledModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
    private _globalService: GlobalService
  ) {
    this.batchNavigation = data;
    this.coming_source =
      this.activatedRoute.snapshot.queryParamMap.get('coming_source') ||
      this.activatedRoute.snapshot.queryParamMap.get('cameFrom') ||
      '';
  }

  ngOnInit(): void {}

  close() {
    if (this.coming_source === 'k8') {
      window.location.replace(localStorage.getItem('K8BACKURL') as string);
      return;
    }

    if (this.batchNavigation) this.navigateToBatchList();
    else this.dialogRef.close();
  }

  navigateToMyBatches() {
    if (this.coming_source === 'k8') {
      window.location.replace(localStorage.getItem('K8BACKURL') as string);
      return;
    }
    this.router.navigate(['/batches/study/my-batches'], {
      queryParams: { activeTab: 'Free' },
    });
    this.dialogRef.close();
  }

  async navigateToBatchList() {
    await this.getUserSegmentFromServer();
    this.router.navigate(['/batches']);
    this.dialogRef.close();
  }

  async getUserSegmentFromServer() {
    try {
      const query = {
        landingPage: true,
      };
      const res = await lastValueFrom(
        this._globalService.getBatchUserSegment(query)
      );
      if (res && res['batchUserSegment']) {
        await this._globalService.setUserSegmentToStorage(
          res['batchUserSegment']
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
}
