import { FileModal } from '../../common/services/base/base.modal';
export class ReferEarnModal {
  key: string;
  id: string;
  batchMessageReferAndEarn: string;
  batchPointsPerRefer: number;
  batchPointsPerReferToReferer: number;
  batchReferAndEarnStatus: boolean;
  images: Array<FileModal> = [];

  constructor(data: any) {
    data = data || {};
    this.id = data._id || '';
    this.key = data.key || '';
    if (data && data.value && Object.keys(data.value).length > 0) {
      this.batchMessageReferAndEarn = data.value.batchMessageReferAndEarn
        ? data.value.batchMessageReferAndEarn || ''
        : '';
      this.batchPointsPerRefer = data.value.batchPointsPerRefer
        ? data.value.batchPointsPerRefer || 0
        : 0;
      this.batchPointsPerReferToReferer = data.value
        .batchPointsPerReferToReferer
        ? data.value.batchPointsPerReferToReferer || 0
        : 0;
      this.batchReferAndEarnStatus = data.value.batchReferAndEarnStatus
        ? data.value.batchReferAndEarnStatus || false
        : false;
      if (
        data &&
        data.value &&
        data.value.images &&
        data.value.images.length > 0
      ) {
        data.value.images.forEach((item: any) => {
          this.images.push(new FileModal(item));
        });
      }
      // this.image = data.value.image ? data.value.image || 'assets/refer_earn/refer_default.webp' : 'assets/refer_earn/refer_default.webp';
    }
  }
}

export interface ReferralDataObject {
  success: boolean;
  data: ReferralData[];
}

export interface ReferralModel {
  _id: string;
  coinAmount: string;
  createdAt: string;
  referralThrough: string;
  referralType: string;
  referredBy: string;
  status: string;
  typeId: string;
  updatedAt: string;
}

export class ReferralData implements ReferralModel {
  _id: string;
  amount: number;
  coinAmount: string;
  createdAt: string;
  referralThrough: string;
  referralType: string;
  referredBy: string;
  status: string;
  typeId: string;
  updatedAt: string;
  userId: {
    firstName: string;
  };
  constructor(data: any = {}) {
    this._id = data._id || '';
    this.coinAmount = data.coinAmount || '';
    this.amount = data.amount || '';
    this.status = data.status || '';
    this.createdAt = data.createdAt || '';
    this.referralThrough = data.referralThrough || '';
    this.referralType = data.referralType || '';
    this.referredBy = data.referredBy || '';
    this.status = data.status || '';
    this.typeId = data.typeId || '';
    this.updatedAt = data.updatedAt || '';
  }
}

export interface CoinReferralModel {
  success: boolean;
  data: CoinReferralData[];
}

export class CoinReferralData implements ReferralModel {
  _id: string;
  coinAmount: string;
  createdAt: string;
  referralThrough: string;
  referralType: string;
  referredBy: string;
  status: string;
  typeId: string;
  updatedAt: string;
  userId: string;
  constructor(data: any = {}) {
    this._id = data._id || '';
    this.coinAmount = data.coinAmount || '';
    this.status = data.status || '';
    this.createdAt = data.createdAt || '';
    this.referralThrough = data.referralThrough || '';
    this.referralType = data.referralType || '';
    this.referredBy = data.referredBy || '';
    this.status = data.status || '';
    this.typeId = data.typeId || '';
    this.updatedAt = data.updatedAt || '';
  }
}

export class BankTransferModel {
  status: number;
  success: number;
  message: string;
  trace_id: string;

  constructor(data: any) {
    this.status = data.status || '';
    this.success = data.success || '';
    this.message = data.message || '';
    this.trace_id = data.trace_id || '';
  }
}

export class RedeemedVoucherModel {
  data: Array<RedeemVoucherDataModel> = [];
  merchantName: string;
  productId: string;
  email: string;

  constructor(data: any) {
    if (data && data.data && data.data.length > 0) {
      const redeemData: Array<RedeemVoucherDataModel> = [];
      data.data.forEach((el: RedeemVoucherDataModel) => {
        redeemData.push(new RedeemVoucherDataModel(el));
      });
      this.data = [...this.data, ...redeemData];
    }
    this.merchantName = data.merchantName || '';
    this.productId = data.productId || '';
    this.email = data.email || '';
  }
}

export class ReferProductModel {
  Client_dealId: number;
  product_id: number;
  autoId: number;
  dealType: number;
  merchantName: string;
  merchantDescription: string;
  bgImage1: string;
  bgImage2: string;
  merchantImage: string;
  merchantWebsite: string;
  merchantAddress: string;
  status: string;
  promotionalStatus: number;
  createdDate: Date;
  createdBy: number;
  updatedDate: Date;
  updatedBy: number;
  referenceId: string;
  countryCode: string;
  isWallet: number;
  oldDealId: string;
  extraFields: string;
  merchantSourceLink: string;
  merchantBarcode: string;
  merchantCountrySpecificLogo: number;
  merchantCountrySpecificLogoImg: string;
  tncFile: string;
  offerTnc: string;
  denomination: Array<DenominationObject>;
  merchantLogo: string;

  constructor(data: any = {}) {
    this.Client_dealId = data.Client_dealId || 0;
    this.product_id = data.product_id || 0;
    this.autoId = data.autoId || 0;
    this.dealType = data.dealType || 0;
    this.merchantName = data.merchantName || '';
    this.merchantDescription = data.merchantDescription || '';
    this.bgImage1 = data.bgImage1 || '';
    this.bgImage2 = data.bgImage2 || '';
    this.merchantImage = data.merchantImage || '';
    this.merchantWebsite = data.merchantWebsite || '';
    this.merchantAddress = data.merchantAddress || '';
    this.status = data.status || '';
    this.promotionalStatus = data.promotionalStatus || 0;
    this.createdDate = data.createdDate || '';
    this.createdBy = data.createdBy || 0;
    this.updatedDate = data.updatedDate || '';
    this.updatedBy = data.updatedBy || 0;
    this.referenceId = data.referenceId || '';
    this.countryCode = data.countryCode || '';
    this.isWallet = data.isWallet || 0;
    this.oldDealId = data.oldDealId || '';
    this.extraFields = data.extraFields || '';
    this.merchantSourceLink = data.merchantSourceLink || '';
    this.merchantBarcode = data.merchantBarcode || '';
    this.merchantCountrySpecificLogo = data.merchantCountrySpecificLogo || 0;
    this.merchantCountrySpecificLogoImg =
      data.merchantCountrySpecificLogoImg || '';
    this.tncFile = data.tncFile || '';
    this.offerTnc = data.offerTnc || '';
    this.denomination = data.denomination || [];
    this.merchantLogo = data.merchantLogo || '';
  }
}

export class ProductCouponModel {
  data: Array<ReferProductModel> = [];
  message: string;
  status: number;
  success: number;
  trace_id: string;

  constructor(data: any) {
    this.message = data.message || '';
    this.status = data.status || 0;
    this.success = data.success || 0;
    this.trace_id = data.trace_id || '';

    if (data && data.data && data.data.length > 0) {
      const productsData: Array<ReferProductModel> = [];
      data.data.forEach((el: ReferProductModel) => {
        productsData.push(new ReferProductModel(el));
      });
      this.data = [...this.data, ...productsData];
    }
  }
}

export class ProductCouponResponse {
  data: ProductCouponModel;
  success: boolean;

  constructor(data: any) {
    this.data = new ProductCouponModel(data.data) || {};
    this.success = data.success || false;
  }
}

export interface SuccessResponse {
  success: boolean;
}

export class UpiVerificationResponse {
  data: UpiVerificationModel;
  success: boolean;

  constructor(props: any) {
    this.data = new UpiVerificationModel(props.data) || {};
    this.success = props.success || false;
  }
}

export class UpiVerificationModel {
  vpa: string;
  success: boolean;
  customer_name: string;
  error: any;

  constructor(data: any) {
    this.vpa = data.vpa;
    this.success = data.success;
    this.customer_name = data.customer_name;
    this.error = data.error || {};
  }
}

export class ResponseData {
  data: Array<RedeemVoucherDataModel> = [];
  merchantName: string;
  constructor(data: any) {
    if (data && data.data && data.data.length > 0) {
      const transaction: Array<RedeemVoucherDataModel> = [];
      data.data.forEach((el: RedeemVoucherDataModel) => {
        transaction.push(new RedeemVoucherDataModel(el));
      });
      this.data = [...this.data, ...transaction];
    }
    this.merchantName = data.merchantName || '';
  }
}

// export class FilteredTransactions {
//   month: string;
//   year: number;
//   transactionDetails: Array<TransactionDetail> = [];
//   constructor(data: any, res: TransactionDetail) {
//     this.month = data.month;
//     this.year = data.year;
//     if (res && res.year > 0) {
//       const transaction: Array<TransactionDetail> = [];
//       transaction.push(new TransactionDetail(res));
//       this.transactionDetails = [...this.transactionDetails, ...transaction];
//     }

//     // if (data && data.length > 0) {
//     //   const transaction: Array<TransactionDetail> = [];
//     //   data.forEach((el: TransactionDetail) => {
//     //     transaction.push(new TransactionDetail(el));
//     //   });
//     //   this.transactionDetails = [...this.transactionDetails, ...transaction];
//     // }
//   }
// }

export interface UserDetails {
  amount: number;
  beneficiary_name: string;
  type: string;
  ifsc: string;
  type_info: number;
}

export class UserBankDetails {
  accountHolder: string;
  accountNumber: string;
  ifsc: string;
  confirmAccountNumber: string;

  constructor(data: any) {
    this.accountHolder = data.accountHolder || '';
    this.accountNumber = data.accountNumber || '';
    this.ifsc = data.ifsc || '';
    this.confirmAccountNumber = data.confirmAccountNumber || '';
  }
}

export interface BankDetailsSubResponse {
  bankDetails: UserBankDetails;
  createdAt: Date;
  updatedAt: Date;
  upiDetails: Array<string>;
  userId: string;
  __v: number;
  _id: string;
}

export interface BankDetailsResponse {
  data: BankDetailsSubResponse;
  success: boolean;
}

export class TransactionDetail {
  _id: string;
  userId: string;
  action: string;
  amount: number;
  order_no: string;
  previousCoins: number;
  actionText: string;
  responseData: any;
  createdAt: string;
  updatedAt: string;
  transactionTitle: string;
  __v: number;
  date: string;

  constructor(item: any) {
    const data = item || {};
    if (data.responseData) {
      this.responseData = new ResponseData(data.responseData);
    } else {
      this.responseData = new ResponseData({});
    }
    this._id = data._id || '';
    this.userId = data.userId || '';
    this.action = data.action || '';
    this.amount = data.amount || 0;
    this.order_no = data.order_no || '';
    this.previousCoins = data.previousCoins || 0;
    this.actionText = data.actionText || '';
    this.transactionTitle = data.transactionTitle || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.__v = data.__v || 0;
    const date = new Date(data.createdAt);
    const year = date.getFullYear();
    switch (date.getMonth() + 1) {
      case 1:
        this.date = 'January ' + year;
        break;
      case 2:
        this.date = 'February ' + year;
        break;
      case 3:
        this.date = 'March ' + year;
        break;
      case 4:
        this.date = 'April ' + year;
        break;
      case 5:
        this.date = 'May ' + year;
        break;
      case 6:
        this.date = 'June ' + year;
        break;
      case 7:
        this.date = 'July ' + year;
        break;
      case 8:
        this.date = 'August ' + year;
        break;
      case 9:
        this.date = 'September ' + year;
        break;
      case 10:
        this.date = 'October ' + year;
        break;
      case 11:
        this.date = 'November ' + year;
        break;
      case 12:
        this.date = 'December ' + year;
        break;
      default:
        this.date = '';
        break;
    }
  }
}

export class TransactionHistoryResponse {
  data: Array<TransactionDetail> = [];
  success: boolean;

  constructor(props: any) {
    if (props && props.data && props.data.length > 0) {
      const transactionData: Array<TransactionDetail> = [];
      props.data.forEach((el: TransactionDetail) => {
        transactionData.push(new TransactionDetail(el));
      });
      this.data = [...this.data, ...transactionData];
    }

    this.success = props.success || false;
  }
}
export class RedeemVoucherDataModel {
  card_price: number;
  cardnumber: number;
  expiry_date: string;
  pin_or_url: number;

  constructor(data: any) {
    this.card_price = data.card_price || 0;
    this.cardnumber = data.cardnumber || 0;
    this.expiry_date = data.expiry_date || '';
    this.pin_or_url = data.pin_or_url || 0;
  }
}

export class Image {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
  __v: number;
  url: string;

  constructor(data: any) {
    this.baseUrl = data.baseUrl || '';
    this._id = data._id || '';
    this.name = data.name || '';
    this.key = data.key || '';
    this.organization = data.organization || '';
    this.createdAt = data.createdAt || '';
    this.__v = data.__v || '';

    if (this.baseUrl && this.key) {
      this.url = this.baseUrl + this.key;
    }
  }
}

export interface BATCH {
  coinReferralStatus: boolean;
  batchCoinReferAndEarnMessage: string;
  offerTypeReferrer: string;
  offerTypeReferee: string;
  offerValueReferee: number;
  offerValueReferrer: number;
  images: Image[];
  maxCoinLimit: number;
}

export interface Value {
  BATCH: BATCH;
}

export class OrganizationPreferenceModel {
  _id: string;
  createdAt: string;
  updatedAt: string;
  organizationId: string;
  key: string;
  type: string;
  value: any;
  __v: string;

  constructor(data: any) {
    this._id = data._id || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.organizationId = data.organizationId || '';
    this.key = data.key || '';
    this.type = data.type || '';
    this.value = data.value;
    this.__v = data.__v || '';
  }
}
export interface WalletBankTransfer {
  status: boolean;
  minimumAmount: number;
  maximumAmount: number;
}
export interface PreferenceModel {
  WalletBankTransfer: WalletBankTransfer;
  WalletCouponCardTransfer: boolean;
}

export interface DenominationObject {
  denomination: string;
}

export interface CoinsObject {
  coins: number;
}

export interface VpaObject {
  vpa: string;
}

export interface RedeemPayloadObject {
  country_id: number;
  denomination: number;
  product_id: number;
}

export interface TransferDetailObject {
  amount: number;
  type?: string;
  beneficiary_name?: string;
  type_info?: string;
  ifsc?: string;
}

export interface ProductCouponsObject {
  checksum: string;
  country_id?: number;
  product_id?: number;
}

export class UserBankDetailsObject {
  vpa?: string;
  name?: string;
  accountHolder?: string;
  ifsc?: string;
  accountNumber?: string;
  confirmAccountNumber?: string;
  constructor(data: any) {
    this.vpa = data.vpa || '';
    this.name = data.name || '';
    this.accountHolder = data.accountHolder || '';
    this.ifsc = data.ifsc || '';
    this.accountNumber = data.accountNumber || '';
    this.confirmAccountNumber = data.confirmAccountNumber || '';
  }
}

export interface TransferStatusModal {
  WalletBankTransfer: WalletBankTransfer;
  WalletCouponCardTransfer: boolean;
}

export interface WalletBankTransfer {
  status: boolean;
  minimumAmount: number;
  maximumAmount: number;
}

export interface SendEmailObject {
  email: string;
}

export interface LandingPageImages {
  referringFriendImg: string;
  purchasebyFriendImg: string;
  earnbyFriendImg: string;
  playButton: string;
  rupees_coin: string;
  whatsappIcon: string;
  whatsappMobileIcon: string;
  whatsappInvitePlus: string;
  facebookIcon: string;
  mailIcon: string;
  referCode: string;
  arrowRight: string;
  arrowDown: string;
  bannerImg: string;
  floatingCoin: string;
  shareIcon: string;
  searchImg: string;
  userImg: string;
}

export interface AmountObject {
  amount: number;
}
