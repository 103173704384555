import { Injectable } from '@angular/core';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions } from '../../api/api.type';
import { AppUrlService } from '../../app-url/app-url.service';
import { catchError, lastValueFrom, tap } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import { MetaDetaVidyapeeth, NpfEvent, VIDYAPEETH_LEAD } from './npf.model';
import { StorageService } from '../storage/storage.service';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root',
})
export class NpfService {
  constructor(
    private appUrl: AppUrlService,
    private apiService: PPApiService,
    private storageService: StorageService,
    private globalService: GlobalService
  ) {}

  postVidyapeethLeadEvent(eventData: NpfEvent) {
    const url = this.appUrl.VIDYAPEETH_LEAD_EVENT();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post<NpfEvent>(eventData, options)
      .pipe(catchError(handleError));
  }

  async vidyapeethEvent(
    activity: string,
    paymentStatus: string,
    orderDetails: any
  ) {
    const overviewDetails = this.storageService.getBatchData();
    const userCohortConfig = this.storageService.getValue(
      'UserCohortConfig',
      'json'
    );
    const userInfo = this.globalService.getUser().getValue() || {};
    const metaVidyapeethData: MetaDetaVidyapeeth = {
      name: `${userInfo?.firstName}  ${userInfo?.lastName}` || '',
      email: userInfo?.email || '',
      countryDialCode: userInfo?.countryDialCode || '',
      exam: userCohortConfig?.exam ?? '',
      city: '',
      gender: userInfo?.profileId?.gender || '',
      centerName: orderDetails?.centerName || '',
      batchName: overviewDetails?.name || '',
      batch_offering: overviewDetails?.config?.offlineOfferingbatchTag || '',
      class: userCohortConfig?.class ?? '',
      courseDuration: orderDetails?.duration || '',
      paymentStatus: paymentStatus || '',
      gaurdianName: '',
      gaurdianMobileNumber: '',
    };
    const vidyaPeethEvent: NpfEvent = {
      eventName: VIDYAPEETH_LEAD,
      lastActivity: activity,
      eventMetaData: metaVidyapeethData,
    };

    try {
      const res: any = await lastValueFrom(
        this.postVidyapeethLeadEvent(vidyaPeethEvent)
      );
    } catch (error) {
      console.log(error, 'error');
    }
  }
}
