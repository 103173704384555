import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentService } from '../../../../common/services/content/content.service';
import { lastValueFrom } from 'rxjs';
import { NOT_RATED_IMG, RATED_IMG } from 'src/app/core/assets.location';
import { GlobalService } from 'src/app/common/services/global/global.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  stars: number[] = [1, 2, 3, 4, 5];
  starRating: number = 0;
  tags: Array<Tags> = [];
  selectedTags: Array<string> = [];
  textArea: any = [];

  ratedImg = RATED_IMG;
  notRatedImg = NOT_RATED_IMG;
  ratingLabel: string;

  constructor(
    public dialogRef: MatDialogRef<RatingComponent>,
    private contentService: ContentService,
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA)
    public data?: { rating?: number; cameFrom?: string; action?: string }
  ) {
    if (data && data.rating) {
      this.starRating = data.rating;
      this.setStarRating(this.starRating);
    }
  }

  ngOnInit(): void {}

  onNoClick(message?: any): void {
    message = message.value.trim();
    this.textArea = message;
    if (this.selectedTags.includes('Others') && message.length === 0) {
      this.globalService.showSnackBar('Please provide the details');
      return;
    }
    if (
      (this.selectedTags.length === 0 && this.data?.action !== 'auto') ||
      (this.selectedTags.length === 0 &&
        this.data?.cameFrom === 'studycard' &&
        this.starRating <= 2)
    ) {
      this.globalService.showSnackBar('Please select the tags');
      return;
    }
    if (
      (this.textArea.length < 5 && this.data?.action !== 'auto') ||
      (this.textArea.length < 5 &&
        this.data?.cameFrom === 'studycard' &&
        this.starRating <= 2)
    ) {
      this.globalService.showSnackBar('Please Enter the Feedback');
      return;
    }

    const data = {
      rating: this.starRating,
      tags: this.selectedTags,
      text: this.textArea,
    };
    this.dialogRef.close(data);
    this.starRating = 0;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async settextArea(text: any) {
    this.textArea = text;
  }

  async getRatingTags() {
    this.tags.splice(0);
    try {
      const res = await lastValueFrom(this.contentService.getRatingTags());
      const tags: Array<Tags> = [];

      res['data'][this.starRating].forEach((el: any) => {
        tags.push(new Tags(el));
      });
      this.tags = [...this.tags, ...tags];
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async setStarRating(rating: number, updateRating?: boolean) {
    if (
      this.data?.cameFrom === 'studycard' &&
      updateRating &&
      this.data?.action !== 'auto'
    )
      return;
    this.selectedTags.splice(0);
    this.starRating = rating;
    if (rating <= 2) {
      this.ratingLabel = 'What went bad?';
    }
    if (rating === 3) {
      this.ratingLabel = 'What can be improved?';
    }
    if (rating > 3) {
      this.ratingLabel = 'What went well?';
    }
    this.selectedTags.splice(0);
    await this.getRatingTags();
  }

  setActive(item: any) {
    if (this.selectedTags.includes(item.name)) {
      this.selectedTags.forEach((el, index) => {
        if (el === item.name) {
          this.selectedTags.splice(index, 1);
        }
      });
    } else {
      this.selectedTags.push(item.name);
    }
    item.isActive = !item.isActive;
  }
}

class Tags {
  name: string;
  isActive: boolean;

  constructor(data: any) {
    this.name = data.name;
    this.isActive = false;
  }
}
