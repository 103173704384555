import { Component, Input, OnInit } from '@angular/core';
import { QUIZ_ARROW_IMG, QUIZ_ICON_IMG } from 'src/app/core/assets.location';
import { TestList } from 'src/app/pages/tests/tests.model';

@Component({
  selector: 'app-test-card',
  templateUrl: './test-card.component.html',
  styleUrls: ['./test-card.component.scss'],
})
export class TestCardComponent implements OnInit {
  quizImg = QUIZ_ICON_IMG;
  quizArrowImg = QUIZ_ARROW_IMG;
  @Input() test: TestList;
  constructor() {}

  ngOnInit(): void {}
}
