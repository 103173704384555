import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
  PPApiConfig,
  PPApiHeaders,
  PPApiMethodOptions,
  PPApiOptions,
} from './api.type';
import { PP_API_CONFIG } from './api.constant';
import { PPApiTokenStorageType } from './api.enum';
import { PPCookieService } from '../services/cookie.service';
import { GlobalService } from '../services/global/global.service';

@Injectable({ providedIn: 'root' })
export class PPApiService implements OnDestroy {
  token: string;
  tokenSub: Subscription;

  constructor(
    @Inject(PP_API_CONFIG)
    private apiConfig: PPApiConfig,
    private http: HttpClient,
    private cookieService: PPCookieService
  ) {
    this.token = '';
    // this.tokenSub = this.globalService._accessToken$.subscribe((data) => {
    //   if (data && data.length > 0) {
    //     this.token = data;
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.tokenSub) {
      this.tokenSub.unsubscribe();
    }
  }

  /**
   * GET METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public get<T>(options: PPApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.get<T>(url, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
      // withCredentials: true,
    });
  }

  /**
   * POST METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public post<T>(body: any, options: PPApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.post<T>(url, body, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
      // withCredentials: true,
    });
  }

  /**
   * PUT METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public put<T>(body: any, options: PPApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.put<T>(url, body, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
      // withCredentials: true,
    });
  }

  /**
   * PATCH METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public patch<T>(body: any, options: PPApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.put<T>(url, body, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
      // withCredentials: true,
    });
  }

  /**
   * DELETE METHOD
   *
   * @param options : NfApiOptions
   *
   * @return Observable<T>
   */
  public delete<T>(options: PPApiOptions): Observable<T> {
    const url = this._getPath(options);
    const apiOptions = this._getOptions(options);
    return this.http.delete<T>(url, {
      headers: apiOptions.headers,
      params: apiOptions.params,
      responseType: apiOptions.responseType,
      // withCredentials: true,
    });
  }

  /**
   * Construct Path for Api Call
   *
   * @param config : NfApiConfig
   * @param options : NfApiOptions
   *
   * @reurn string;
   */
  private _getPath(options: PPApiOptions): string {
    const config = options.config ? options.config : this.apiConfig;
    let path = '';
    if (config.baseUrl) {
      path = config.baseUrl.trim() + options.apiPath.trim();
    } else {
      path = options.apiPath.trim();
    }
    return path;
  }

  /**
   * Construct Options for Api Call
   *
   * @param options : NfApiOptions
   *
   * @return any
   */
  private _getOptions(options: PPApiOptions): PPApiMethodOptions {
    const apiOptions: PPApiMethodOptions = {};
    const config = options.config ? options.config : this.apiConfig;

    // Set Headers
    if (config.headers) {
      apiOptions.headers = config.headers;
    }

    if (apiOptions.headers && options.headers) {
      const keys = options.headers.keys();
      keys.forEach((key) => {
        if (apiOptions.headers && options.headers) {
          let value = options.headers.get(key);
          if (!value) {
            value = '';
          }
          apiOptions.headers = apiOptions.headers.set(key, value);
        }
      });
    } else if (options.headers) {
      apiOptions.headers = options.headers;
    }

    // Set Credentials
    if (options.withCredentials === undefined || options.withCredentials) {
      let token = '';
      if (
        config.storedIn === PPApiTokenStorageType.LOCAL_STORAGE &&
        config.tokenKey
      ) {
        const tokenValue = this.token;
        if (tokenValue) {
          token = tokenValue;
        }
      }
      if (config.storedIn === PPApiTokenStorageType.COOKIE && config.tokenKey) {
        token = this.cookieService.getCookie(config.tokenKey);
      }
      if (token) {
        if (apiOptions.headers) {
          apiOptions.headers = apiOptions.headers.set(
            'Authorization',
            `${config.tokenType} ${token}`
          );
        } else {
          apiOptions.headers = new PPApiHeaders({
            Authorization: `${config.tokenType} ${token}`,
          });
        }
      }
    }

    // Set Params
    if (options.params) {
      apiOptions.params = options.params;
    }

    // Set Response Type
    if (options.responseType) {
      apiOptions.responseType = options.responseType;
    }

    // Return Options
    return apiOptions;
  }
}
