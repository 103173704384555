import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import {
  BATTLE_SWORD_IMG,
  QUESTIONS_IMG,
  BATTLE_CALENDAR_IMG,
  DOT_IMG,
  PEOPLE_JOINED_LOTTIE,
} from 'src/app/core/assets.location';
import { format } from 'date-fns';
import { BattleGroundDppQuiz } from 'src/app/common/services/batch/batch.modal';

@Component({
  selector: 'app-battle-ground-dpp-card',
  templateUrl: './battle-ground-dpp-card.component.html',
  styleUrls: ['./battle-ground-dpp-card.component.scss'],
})
export class BattleGroundDppCardComponent implements OnInit {
  @Input() dppData: BattleGroundDppQuiz;
  battleSwordImg = BATTLE_SWORD_IMG;
  questionsImg = QUESTIONS_IMG;
  calendarImg = BATTLE_CALENDAR_IMG;
  dotImg = DOT_IMG;
  peopleJoinedLottie: AnimationOptions = {
    path: PEOPLE_JOINED_LOTTIE,
  };
  dppStartDate: string;
  dppStartTime: string;
  dppStatus: any = {};

  constructor() {}

  getDPPStatus(dppStatus: string, battleStatus: string) {
    if (battleStatus === 'COMPLETED') {
      return {
        text: 'Completed',
        class: 'upcoming-dpp',
      };
    }
    if (dppStatus === 'LIVE') {
      return {
        text: 'Live',
        class: 'live-dpp',
      };
    }
    if (dppStatus === 'UPCOMING') {
      return {
        text: 'Upcoming',
        class: 'upcoming-dpp',
      };
    }
    if (dppStatus === 'COMPLETED') {
      return {
        text: 'Completed',
        class: 'upcoming-dpp',
      };
    }
    if (dppStatus === 'ENDED' && battleStatus !== 'NOT_STARTED') {
      return {
        text: 'Completed',
        class: 'upcoming-dpp',
      };
    }
    if (dppStatus === 'ENDED' && battleStatus === 'NOT_STARTED') {
      return {
        text: 'Missed',
        class: 'live-dpp',
      };
    }

    return 'Live';
  }

  ngOnInit(): void {
    this.dppStartDate = format(
      new Date(this.dppData?.startDate),
      'dd LLL yyyy'
    );
    this.dppStartTime = format(new Date(this.dppData?.startDate), 'hh:mm a');
    this.dppStatus = this.getDPPStatus(
      this.dppData?.studentSideProgressStatus,
      this.dppData?.studentBattleStatus
    );
  }
}
