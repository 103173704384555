import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObserversModule } from '@angular/cdk/observers';
import { PPApiModule } from '../common/api/api.module';
import { environment } from '../../environments/environment';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ObserversModule,
    LayoutModule,
    PPApiModule.forRoot(environment.apiConfig),
  ],
})
export class CoreModule {}
