<section>
  <div class="overflow-scroll main-height">
    <div class="flex justify-end items-end">
      <button (click)="onNoClick()" class="close-button">
        <img [src]="closeImg" />
      </button>
    </div>
  </div>
  <div style="height: 340px">
    <app-start-test-detail
      [query]="query"
      [testInstructionData]="testInstruction"
      [batchId]="batchId"
    ></app-start-test-detail>
  </div>

  <!-- !Button -->
  <div
    class="flex justify-center my-1 px-1 w-full overflow-hidden sticky btn-div bottom-0 bg-white"
  >
    <!-- Column Content -->
    <button
      (click)="checkProctoring()"
      [disabled]="timeRemaining > 0"
      class="h-12 test-start-btn"
    >
      <span
        [ngClass]="{
          startTest: timeRemaining <= 0 && query.instructionType === 'test'
        }"
        >{{
          timeRemaining / 86400 > 2
            ? 'Test Will be live after ' +
              (timeRemaining / 86400 | number : '1.0-0') +
              'days'
            : timeRemaining / 86400 > 1
            ? 'Test Will be live tomorrow'
            : timeRemaining / 86400 > 0
            ? 'Test Will be live after ' + (timeRemaining | formatTime)
            : ' Start Test'
        }}</span
      >
    </button>
  </div>
</section>
