import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { GlobalService } from '../global/global.service';
import { PPApiService } from '../../api/api.service';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiOptions } from '../../api/api.type';
import { handleError } from '../error-handler/error-handler.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { ShowNotificationComponent } from '../../components/show-notification/show-notification.component';
import { MatDialog } from '@angular/material/dialog';
import { DoubtsNotificationComponent } from '../../components/doubts-notification/doubts-notification.component';
import { PW_LOGO } from 'src/app/core/assets.location';
import { PersonalAssistantDialogComponent } from '../../components/personal-assistant-dialog/personal-assistant-dialog.component';
import { Router } from '@angular/router';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { DoubtModalComponent } from '../../components/doubt-modal/doubt-modal.component';
@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  currentMessage = new BehaviorSubject(null);
  storageToken = '';

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private storageService: StorageService,
    private globalService: GlobalService,
    private apiService: PPApiService,
    private appUrl: AppUrlService,
    public dialog: MatDialog,
    private router: Router,
    private dialogRef: NgDialogAnimationService
  ) {}

  requestPermission() {
    const token = this.storageService.getDeviceId();
    if (token && token?.length && token !== 'null') {
      return;
    }
    this.angularFireMessaging.requestToken.subscribe(
      async (token) => {
        console.log('Permission granted! Save to the server!', token);
        this.storageService.setDeviceId(token);
        const data = {
          deviceId: token || '',
          mobileType: 'web',
        };
        if (data.deviceId && data.deviceId.length > 0) {
          try {
            const res = await lastValueFrom(this.registerToken(data));
          } catch (e) {
            console.error(e);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  // async requestPermission() {
  //     try {
  //     const token = await lastValueFrom(this.angularFireMessaging.requestToken);
  //     const data = {
  //       deviceId: token || '',
  //       mobileType: 'web',
  //     };
  //     if (this.storageToken === token) {
  //       return;
  //     }

  //     if (data.deviceId.length === 0) {
  //       console.log('token not found');
  //       return;
  //     }
  //     this.registerToken(data).subscribe(
  //       (res) => {
  //         //  console.log('Register Token: ', res);
  //         this.storageService.setDeviceId(data.deviceId);
  //       },
  //       (err) => {
  //         console.log('Register Token Err: ', err);
  //       }
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //   }
  // }

  registerToken(data: any) {
    const url = this.appUrl.SET_FCM_TOKEN;
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.post(data, options).pipe(catchError(handleError));
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: '/study/assets/icons/logo.png',
      };
      let notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = () => {
        window.open('https://pw.live/study');
      };
    });
  }

  async showNotificationAlert(payload: any) {
    if (!this.globalService.isLoggedIn) {
      return;
    }
    const parsedData =
      payload['data']['data'] && typeof payload['data']['data'] === 'string'
        ? JSON.parse(payload['data']['data']) ||
          JSON.parse(payload['data']['data'])['payload'] ||
          {}
        : payload['data']['data'];

    if (parsedData && parsedData.slideId) {
      let notifData: any = {};
      if (parsedData.slideId) {
        notifData = {
          title: payload.notification.title,
          body: payload.notification.body,
          image: payload.notification.image || PW_LOGO,
        };
      } else if (parsedData.payload.saarthiId) {
        notifData = {
          title: parsedData.title || 'PW Saarthi chat',
          body: parsedData.message || 'New message',
          image: parsedData?.image || PW_LOGO,
        };
      }

      const dialogRef = this.dialog.open(ShowNotificationComponent, {
        panelClass: 'notification-dialog',
        height: '150px',
        data: {
          notifData: notifData,
        },
      });
      dialogRef.afterClosed().subscribe((data) => {
        if (data === 'yes' && parsedData.slideId) {
          // this.openDoubtConversation(parsedData);
          this.openDoubtModal(parsedData);
        } else if (data === 'yes' && parsedData.payload.saarthiId) {
          console.log('Changes>>>>', parsedData.payload);
          // this.router.navigateByUrl(
          //   `saarthi/${parsedData.payload.subjectId}/chat`
          // );
        }
      });
    } else {
      console.log('some error in data', payload);
    }
  }

  async openDoubtConversation(data: any) {
    const doubtArray =
      JSON.parse(this.storageService.getDoubtNotifications() || '') || [];
    if (doubtArray.length === 0) {
      return;
    } else {
      let doubtFromStorage = doubtArray.filter(
        (item: any) => item.slideId === data.slideId
      )[0];
      if (doubtFromStorage && doubtFromStorage.length === 0) {
        doubtFromStorage = doubtArray.filter(
          (item: any) => item._id === data.doubtId
        )[0];
      }
      if (doubtFromStorage && Object.keys(doubtFromStorage).length > 0) {
        const conversationId = doubtFromStorage.conversationId;
        const doubtId = doubtFromStorage._id;
        const dialogRef = this.dialog.open(PersonalAssistantDialogComponent, {
          panelClass: 'personal_assistant_dialog',
          width: '500px',
          data: {
            conversationId: conversationId,
            doubtId: doubtId,
            data,
          },
        });
        dialogRef.afterClosed().subscribe((res) => {});
      }
    }
  }

  openDoubtModal(data: any) {
    const doubtArray =
      JSON.parse(this.storageService.getDoubtNotifications() || '') || [];
    if (doubtArray.length === 0) return;

    let doubtFromStorage = doubtArray.find(
      (res: any) => res._id === data.doubtId
    );
    doubtFromStorage.commentCount++;
    const dialogRef = this.dialogRef.open(DoubtModalComponent, {
      panelClass: 'doubt-sidebar',
      position: { right: '0px' },
      data: {
        doubtFromStorage: doubtFromStorage,
        scheduleId: data.scheduleId,
        subjectId: data.subjectId,
        chapterId: data.chapterId,
        batchId: data.batchId,
      },
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 350 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 350 },
        },
      },
    });
  }
}
