import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import pkJson from 'package.json';
/*Sentry Config*/
Sentry.init({
  dsn: 'https://19c8320d13f4457dc62ca7d5c78dcd7b@us.sentry.io/4506698789093376',
  release: pkJson.version,
  environment: environment.runningEnvironment,
  integrations: [

  ],

  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
  enableProdMode();
  window.console.log = () => {};
  window.console.warn = () => {};
  window.console.time = () => {};
  window.console.timeEnd = () => {};
  window.console.error = () => {};
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
