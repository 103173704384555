import { Injectable } from '@angular/core';

import { catchError, map, Observable, retry } from 'rxjs';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions } from '../../api/api.type';
import { AppUrlService } from '../../app-url/app-url.service';
import { handleError } from '../error-handler/error-handler.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(private apiService: PPApiService) {}

  getProgramDetails(programId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${programId}`,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }
}
