<section class="image-modal-container">
  <div class="doubt-modal">
    <div class="flex justify-between items-center px-4 py-2 header-div">
      <h4 class="w-full text-center heading">
        {{ data.modalTitle ? data.modalTitle : 'Crop One Question Only' }}
      </h4>
      <h6
        class="ml-1 cross-icon cursor-pointer mt-1"
        (click)="onClose()"
        id="close"
      >
        &times;
      </h6>
    </div>

    <div class="flex justify-center items-center p-2 h-full imgCropper">
      <image-cropper
        [imageChangedEvent]="imgChangeEvt"
        [maintainAspectRatio]="false"
        (imageCropped)="cropImg($event)"
        (imageLoaded)="imgLoad()"
        (cropperReady)="initCropper()"
        (loadImageFailed)="imgFailed()"
        backgroundColor="#ffffff"
        [containWithinAspectRatio]="false"
        [alignImage]="'left'"
        [canvasRotation]="canvasRotation"
      ></image-cropper>
    </div>

    <div class="flex justify-between items-center py-1 px-4 footer-div">
      <div
        class="flex flex-col justify-center cursor-pointer"
        (click)="rotateElem()"
      >
        <img [src]="rotate" class="imgRotate" />
        <button id="rotate_btn">Rotate</button>
      </div>

      <div>
        <button
          class="px-6 py-2 rounded-3xl btn-primary"
          (click)="submitImage()"
          id="submit_btn"
          [disabled]="isSending"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</section>
