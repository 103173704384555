import { Injectable } from '@angular/core';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { PPApiService } from '../../api/api.service';
import { catchError, map, retry } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  constructor(
    private appUrl: AppUrlService,
    private PPApiService: PPApiService
  ) {}

  joinClass(batchId: any, batchSubjectId: any, scheduleId: any, query?: any) {
    let url = this.appUrl.JOIN_CLASS(batchId, batchSubjectId, scheduleId);
    if (!this.isEmpty(query)) {
      url = url + '?';
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          //
          url += key + '=' + query[key] + '&';
        }
      }
      url = url.substr(0, url.length - 1);
    }
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.PPApiService.get<any>(options).pipe(
      retry(1),
      catchError(handleError)
    );
  }

  insertChat(conversationId: string, data?: any) {
    const chatType = ['text', 'image', 'file', 'audio'];

    if (!conversationId) {
      throw { message: 'Conversation ID is required' };
    }

    if (data && !data.hasOwnProperty('type')) {
      throw { message: 'Chat type is required' };
    }

    if (data && chatType.indexOf(data.type) === -1) {
      throw { message: 'Invalid chat type' };
    }

    // if (
    //   data &&
    //   !data.hasOwnProperty('text') &&
    //   !data.hasOwnProperty('imageId')
    // ) {
    //   throw { message: 'Text or image must be supplied' };
    // }

    const url = this.appUrl.CHAT(conversationId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.PPApiService.post(data, options).pipe(catchError(handleError));
  }

  async getChatRules(conversationId: string) {
    if (!conversationId) {
      throw { message: 'Conversation ID is required' };
    }

    const url = this.appUrl.CHAT_RULE(conversationId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.PPApiService.get(options).pipe(
      retry(1),
      catchError(handleError)
    );
  }

  async getChat(conversationId: string, params = {}) {
    if (!conversationId) {
      throw { message: 'Conversation ID is required' };
    }
    const url = this.appUrl.CHAT(conversationId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(params),
    };
    return this.PPApiService.get<Array<any>>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}
