import { Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AddNewGoalsComponent } from '../components/add-new-goals/add-new-goals.component';
import { SaarthiService } from 'src/app/common/services/saarthi/saarthi.service';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ADD_NEW_CLICK } from 'src/app/core/analytics-events';
import { FaqModel } from '../../books/book.model';
import { GlobalService } from 'src/app/common/services/global/global.service';

enum HOW_PLANNER_WORKS {
  HOW_PLANNER_WORKS = 'how it works - Planner',
}

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
  providers: [GlobalService],
})
export class PlannerComponent implements OnInit, OnDestroy {
  hideBatches: boolean = false;
  tabs: Array<string> = ['Previous Week', 'Current Week', 'Upcoming Week'];
  activeTab = 'Current Week';

  // goalList = [];
  upcomingWeek: any = [];
  currentWeek: any = [];
  previousWeek: any = [];
  selectedDate: any;

  goalProgress: GoalProgressModel;
  current_year = 0;
  // current_week = 0;
  current_weekNumber = 0;
  obj: any = {};
  current_date = new Date();
  startTime: number;
  storageSerivce: any;
  plannerWorkVideo: FaqModel;
  cameFromStudy: boolean;
  batchSlug: string;

  constructor(
    public dialog: MatDialog,
    public saarthiService: SaarthiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.goalProgress = new GoalProgressModel({});
    this.saarthiService.isDeleteGoal$.subscribe((res) => {
      if (res) {
        this.getStudentProgress(this.obj);
      }
    });
    this.cameFromStudy =
      this.activatedRoute.snapshot.queryParamMap.get('came_from') === 'study' ||
      false;
    this.batchSlug =
      this.activatedRoute.snapshot.queryParamMap.get('batchSlug') || '';
  }

  ngOnInit(): void {
    this.getCurrentWeek();
    this.current_year = new Date().getFullYear();

    // find current week in a year start
    let currentDate: any = new Date();
    let startDate: any = new Date(currentDate.getFullYear(), 0, 1);
    var days = moment().dayOfYear();
    this.current_weekNumber = moment().week();
    if (this.current_weekNumber === 0) {
      this.current_weekNumber = 1;
    }

    this.obj = {
      currentYear: this.current_year,
      currentWeek: this.current_weekNumber,
    };
    this.saarthiService.updateStudentprogress$.subscribe((res) => {
      if (res) {
        this.getStudentProgress(this.obj);
      }
    });
    this.getStudentProgress(this.obj);
    this.startTime = new Date().getTime();
  }

  async getStudentProgress(obj: any) {
    if (obj.currentWeek < 10 && obj.currentWeek.toString().length < 2) {
      obj.currentWeek = `0${obj.currentWeek}`;
    }
    try {
      const response = await lastValueFrom(
        this.saarthiService.getStudentProgress(obj)
      );
      this.goalProgress = new GoalProgressModel(response.data);
    } catch (error) {
    } finally {
    }
  }

  goToDetails() {
    if (this.batchSlug) {
      this.router.navigate([`batches/${this.batchSlug}/batch-overview`]);
    } else {
      if (this.cameFromStudy) {
        this.router.navigate(['batches/study']);
      } else {
        this.router.navigate([`saarthi/`]);
      }
    }
  }

  refreshGoals(firstDate: any) {
    this.saarthiService.refreshListing$.next({
      tabs: this.activeTab,
      refresh: true,
      date: firstDate,
    });
  }

  changeSection(tab: string) {
    this.activeTab = tab;

    if (this.activeTab === 'Current Week') {
      this.getCurrentWeek();
      this.obj.currentWeek = this.current_weekNumber;
      this.obj.currentYear = this.current_year;
      this.getStudentProgress(this.obj);
    } else if (this.activeTab === 'Previous Week') {
      this.getPreviousWeek();
      if (this.current_weekNumber === 1) {
        this.obj.currentYear = this.current_year - 1;
        this.obj.currentWeek = 52;
      } else {
        this.obj.currentWeek = this.current_weekNumber - 1;
        this.obj.currentYear = this.current_year;
      }
      this.getStudentProgress(this.obj);
    } else {
      this.getUpcomingWeek();
      if (this.current_weekNumber + 1 > 52) {
        this.obj.currentYear = this.current_year + 1;
        this.obj.currentWeek = 1;
      } else {
        this.obj.currentWeek = this.current_weekNumber + 1;
        this.obj.currentYear = this.current_year;
      }
      this.getStudentProgress(this.obj);
    }
  }

  getPreviousWeek() {
    this.previousWeek = [];
    var previousDate = moment();
    previousDate = previousDate.subtract(7, 'days');
    let weekStart = previousDate.clone().startOf('isoWeek');
    let weekEnd = previousDate.clone().endOf('isoWeek');
    let days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format('LLLL'));
      this.previousWeek.push({
        days: days[i],
        isSelected: false,
      });
    }

    this.previousWeek[0].isSelected = true;
    this.saarthiService.date = this.previousWeek[0].days;
    this.currentWeek = this.previousWeek;
    // this.refreshGoals(this.saarthiService.date)
    this.saarthiService.goalListingFetch$.next({
      day: this.previousWeek[0].days,
    });
  }

  getCurrentWeek() {
    this.currentWeek = [];
    let currentDate = moment();
    let weekStart = currentDate.clone().startOf('isoWeek');
    let days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format('LLLL'));
      let current_trim_date = moment(this.current_date)
        .add('days')
        .format('LLLL');
      let current_trim_date_arr = current_trim_date
        .split(',')
        .map((item) => item.trim());
      let week_trim_date = moment(weekStart).add(i, 'days').format('LLLL');
      let week_trim_date_arr = week_trim_date
        .split(',')
        .map((item) => item.trim());
      // console.log("arr => ",week_trim_date_arr);
      if (current_trim_date_arr[0] == week_trim_date_arr[0]) {
        this.currentWeek.push({
          days: days[i],
          isSelected: true,
        });
        this.saarthiService.date = week_trim_date;
      } else {
        this.currentWeek.push({
          days: days[i],
          isSelected: false,
        });
      }
    }
    // console.log(this.currentWeek);

    this.saarthiService.goalListingFetch$.next({
      day: this.current_date,
    });
  }

  howPlannerWorkVideo() {
    this.saarthiService.howItWorkVideo(
      HOW_PLANNER_WORKS.HOW_PLANNER_WORKS,
      'How My Planner works'
    );
  }

  getUpcomingWeek() {
    this.upcomingWeek = [];
    var upcomingDate = moment();
    upcomingDate = upcomingDate.add(7, 'days');
    let weekStart = upcomingDate.clone().startOf('isoWeek');
    let weekEnd = upcomingDate.clone().endOf('isoWeek');
    let days = [];

    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days').format('LLLL'));
      this.upcomingWeek.push({
        days: days[i],
        isSelected: false,
      });
    }

    // console.log(this.upcomingWeek);
    this.upcomingWeek[0].isSelected = true;
    this.saarthiService.date = this.upcomingWeek[0].days;
    this.currentWeek = this.upcomingWeek;
    // this.refreshGoals(this.saarthiService.date)
    this.saarthiService.goalListingFetch$.next({
      day: this.upcomingWeek[0].days,
    });
  }

  addNewGoal() {
    // const isInitiated = true;
    this.saarthiService.logEvent(ADD_NEW_CLICK, {});
    const dialogRef = this.dialog.open(AddNewGoalsComponent, {
      panelClass: 'add_new_goal',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res: any) => {});
  }

  ngOnDestroy(): void {}
}

export class GoalProgressModel {
  completed_goal: number;
  percentage: number;
  total_goal: number;

  constructor(data: any) {
    data = data || {};
    this.completed_goal = data.completed_goal || 0;
    this.percentage = data.percentage || 0;
    this.total_goal = data.total_goal || 0;
  }
}
