import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global/global.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class MoengageService {
  userInfo: any;
  userSub: Subscription;
  uuid: string = '';
  constructor(
    private globalService: GlobalService,
    private storageService: StorageService
  ) {
    this.getUserInfo();
    this.uuid = this.storageService.getRandomId() || '';
  }

  moengageEvents: string[] = [
    'sign_up_success',
    'sign_in_success',

    'cohort_select',
    'cohort_change',

    'batch_listing_page_view',
    'batch_banner_click',
    'batch_description_page_view',
    'batch_orientation_click',
    'batch_share',
    'batch_enroll_successfull',

    'khazana_landing_page',

    'test_home_page_view',

    'buy_now_clicked',
    'proceed_to_buy',

    'transaction_success',
    'transaction_failed',

    'refer_n_earn_page_view',
    'refer_n_earn_code_share',

    'study_page_view',
    'ask_doubt_page_view',

    'library_page_view',
    'library_subject_page_view',

    'saarthi_home_page_view',
    'saarthi_subscribe_click',

    'centre_page_view',
    'contact_us_page_view',

    'champion_league_page_view',

    'demo_video_click',
    'order_summary_page_view',
    'demo_session_confirmation_vplive',
  ];

  getUserInfo() {
    this.userSub = this.globalService.getUser().subscribe((res: any) => {
      if (res) {
        this.userInfo = res;
        (window as any).Moengage?.add_first_name(this.userInfo?.firstName);
        (window as any).Moengage?.add_last_name(this.userInfo?.lastName);
        (window as any).Moengage?.add_email(this.userInfo?.email);
        (window as any).Moengage?.add_mobile(this.userInfo?.primaryNumber);
        (window as any).Moengage?.add_user_name(
          this.userInfo?.firstName + this.userInfo?.lastName
        ); // Full name for user
        (window as any).Moengage?.add_user_attribute(
          'created_date',
          this.userInfo?.createdAt || ' '
        );
        (window as any).Moengage?.add_user_attribute(
          'Username',
          this.userInfo?.id || ' '
        );
        (window as any).Moengage?.add_user_attribute(
          'Email',
          this.userInfo?.email || ' '
        );
        (window as any).Moengage?.add_user_attribute(
          'Mobile_no',
          this.userInfo?.primaryNumber || ' '
        );
        (window as any).Moengage?.add_user_attribute(
          'First_name',
          this.userInfo?.firstName || ' '
        );
        (window as any).Moengage?.add_user_attribute(
          'Last_name',
          this.userInfo?.lastName || ' '
        );
        // (window as any).Moengage?.add_gender(
        //   this.userInfo?.profileId?.gender || this.userInfo?.gender || 'Male'
        // );
      }
    });
  }

  setUserMobile(mobile: number) {
    (window as any).Moengage?.add_user_attribute('Mobile_no', mobile || ' ');
  }
  setUserGoal(goal: string) {
    (window as any).Moengage?.add_user_attribute('goal', goal || ' ');
  }
  setUserFirstName(firstName: string) {
    (window as any).Moengage?.add_user_attribute(
      'First_name',
      firstName || ' '
    );
  }
  setUserLastName(lastName: string) {
    (window as any).Moengage?.add_user_attribute('Last_name', lastName || ' ');
  }
  setUserEmail(email: string) {
    (window as any).Moengage?.add_user_attribute('Email', email || ' ');
  }
  setUserExamOne(exam: string) {
    (window as any).Moengage?.add_user_attribute('exam_1', exam || ' ');
  }
  setUserExamTwo(exam: string) {
    (window as any).Moengage?.add_user_attribute('exam_2', exam || ' ');
  }
  setUserExamThree(exam: string) {
    (window as any).Moengage?.add_user_attribute('exam_3', exam || ' ');
  }
  setUserClass(userclass: string) {
    (window as any).Moengage?.add_user_attribute('class', userclass || ' ');
  }
  setUserStream(userstream: string) {
    (window as any).Moengage?.add_user_attribute('stream', userstream || ' ');
  }
  setUserCurrentGoal(goal: string) {
    (window as any).Moengage?.add_user_attribute('current_goal', goal) || ' ';
  }
  setUserCurrentExamOne(exam: string) {
    (window as any).Moengage?.add_user_attribute('current_exam_1', exam || ' ');
  }
  setUserCurrentExamTwo(exam: string) {
    (window as any).Moengage?.add_user_attribute('current_exam_2', exam || ' ');
  }
  setUserCurrentExamThree(exam: string) {
    (window as any).Moengage?.add_user_attribute('current_exam_3', exam || ' ');
  }
  setUserCurrentClass(userclass: string) {
    (window as any).Moengage?.add_user_attribute(
      'current_class',
      userclass || ' '
    );
  }
  setUserCurrentStream(userstream: string) {
    (window as any).Moengage?.add_user_attribute(
      'current_stream',
      userstream || ' '
    );
  }
  setUserBatchStatus(batchStatus: string) {
    (window as any).Moengage?.add_user_attribute(
      'batch_status',
      batchStatus || ' '
    );
  }
  setUserTestSeriesStatus(testSeriesStatus: string) {
    (window as any).Moengage?.add_user_attribute(
      'test_series_status',
      testSeriesStatus
    );
  }

  setMoengageData(
    key: string,
    idx: number,
    cohortArray: any[],
    onRegistration: boolean = false
  ) {
    if (key === 'class') {
      if (onRegistration) {
        this.setUserClass(cohortArray[idx]?.value || ' ');
      }
      this.setUserCurrentClass(cohortArray[idx]?.value || ' ');
    } else if (key === 'exam') {
      if (idx === 0) {
        if (onRegistration) {
          this.setUserGoal(cohortArray[idx]?.value || ' ');
        }
        this.setUserCurrentGoal(cohortArray[idx]?.value || ' ');
      } else if (idx === 1) {
        if (onRegistration) {
          this.setUserExamOne(cohortArray[idx]?.value || ' ');
        }
        this.setUserCurrentExamOne(cohortArray[idx]?.value || ' ');
      } else if (idx === 2) {
        if (onRegistration) {
          this.setUserExamTwo(cohortArray[idx]?.value || ' ');
        }
        this.setUserCurrentExamTwo(cohortArray[idx]?.value || ' ');
      } else if (idx === 3) {
        if (onRegistration) {
          this.setUserExamThree(cohortArray[idx]?.value || ' ');
        }
        this.setUserCurrentExamThree(cohortArray[idx]?.value || ' ');
      }
    } else if (key === 'stream') {
      if (onRegistration) {
        this.setUserStream(cohortArray[idx]?.value || ' ');
      }
      this.setUserCurrentStream(cohortArray[idx]?.value || ' ');
    }
  }

  resetMoengageExamClassData() {
    this.setUserGoal(' ');
    this.setUserExamOne(' ');
    this.setUserExamTwo(' ');
    this.setUserExamThree(' ');
    this.setUserClass(' ');
    this.setUserStream(' ');
  }

  resetMoengageCurrentExamClassData() {
    this.setUserCurrentGoal(' ');
    this.setUserCurrentExamOne(' ');
    this.setUserCurrentExamTwo(' ');
    this.setUserCurrentExamThree(' ');
    this.setUserCurrentClass(' ');
    this.setUserCurrentStream(' ');
  }

  trackMoengageEvent(
    event_name: string,
    event_data: any,
    addDeviceId?: boolean,
    shouldUserBeAdded: boolean = true
  ) {
    if (this.moengageEvents.includes(event_name)) {
      if (shouldUserBeAdded && event_data) {
        event_data['UserPhoneNumber'] =
          this.userInfo?.username || event_data['phone_number'] || '';
        event_data['UserId'] = this.userInfo?.id || '';
        event_data['device_id'] = this.uuid || '';
        event_data['username'] = this.userInfo?.lastName
          ? this.userInfo?.firstName + ' ' + this.userInfo?.lastName
          : this.userInfo?.firstName;
      }
      if (addDeviceId) {
        event_data['device_id'] = this.uuid || '';
      }
      try {
        (window as any).Moengage?.track_event(event_name, event_data);
      } catch (e) {
        console.log(e);
      } finally {
      }
    } else {
      return;
    }
  }
}
