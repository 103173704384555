import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  DialogInfo,
  TestSeriesService,
} from 'src/app/common/services/tests/test-series.service';
import { CROSS } from 'src/app/core/assets.location';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  _dataSub$: Subscription;
  dialogInfo: DialogInfo;
  crossIcon = CROSS;
  constructor(
    private testService: TestSeriesService,
    private dialogRef: MatDialogRef<InfoDialogComponent>
  ) {
    this._dataSub$ = this.testService._infoData$.subscribe((data) => {
      this.dialogInfo = data;
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this._dataSub$) {
      this._dataSub$.unsubscribe();
    }
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  grantAccess() {
    this.close('check_permission');
  }
}
