import { KhazanaAccordionComponent } from '../pages/batch/batch-overview/khazana/khazana-component/khazana-accordion/khazana-accordion.component';
import { CardComponent } from '../pages/batch/batch-overview/khazana/khazana-component/card/card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { MaterialModule } from '../material/material.module';
import { AccordianComponent } from './components/accordian/accordian.component';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { ShowErrorComponent } from './components/show-error/show-error/show-error.component';
import { NotesCardComponent } from './components/notes-card/notes-card.component';
import { VideoCardComponent } from './components/video-card/video-card.component';
import { PurchaseAlertComponent } from './components/purchase-alert/purchase-alert.component';
import { PipesModule } from './pipes/pipes.module';
import { MathjaxDirective } from './directives/mathjax/mathjax.directive';
import { UserDoubtCardComponent } from './components/user-doubt-card/user-doubt-card.component';
import { PersonalAssistantDialogComponent } from './components/personal-assistant-dialog/personal-assistant-dialog.component';
import { DoubtFeedbackDialogComponent } from './components/doubt-feedback-dialog/doubt-feedback-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';
import { TextToLinkDirective } from './directives/TextToLink.directive';
import { HorizontalScrollDirective } from './directives/horizontalScroll/horizontal-scroll.directive';
import { DoubtInputBoxComponent } from './components/doubt-input-box/doubt-input-box.component';
import { MathjaxComponent } from './components/mathjax/mathjax.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { BatchCardNewComponent } from '../pages/batch/batch-card-new/batch-card-new.component';
import { CustomCardButtonComponent } from '../pages/batch/custom-card-button/custom-card-button.component';
import { ExamDetailsComponent } from '../pages/auth/auth/cohort/exam-details/exam-details.component';
import { ExamSelectComponentComponent } from '../pages/auth/auth/cohort/exam-select-component/exam-select-component.component';
import { ClassSelectComponentComponent } from '../pages/auth/auth/cohort/class-select-component/class-select-component.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { TestDppListingCommonComponent } from './components/test-dpp-listing-common/test-dpp-listing-common.component';
import { QuizCardComponent } from './components/test-dpp-listing-common/quiz-card/quiz-card.component';
import { TestCardComponent } from './components/test-dpp-listing-common/test-card/test-card.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { StartTestDialogComponent } from '../pages/tests/test-list/start-test-dialog/start-test-dialog.component';
import { StartTestDetailComponent } from '../pages/tests/test-list/start-test-dialog/start-test-detail/start-test-detail.component';
import { DoubtModalComponent } from './components/doubt-modal/doubt-modal.component';
import { DoubtAnswersComponent } from './components/doubt-modal/doubt-answers/doubt-answers.component';
import { DoubtCommentsComponent } from './components/doubt-modal/doubt-comments/doubt-comments.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoubtReplyCardComponent } from './components/doubt-modal/doubt-reply-card/doubt-reply-card.component';
import { ArrowButtonComponent } from '../pages/batch/batch-components/arrow-button/arrow-button.component';
import { AdmitCardModalComponent } from './components/admit-card-modal/admit-card-modal.component';
import { SuccessModalComponent } from './components/admit-card-modal/success-modal/success-modal.component';
import { ProfanityAlertWarningComponent } from './components/profanity-alert-warning/profanity-alert-warning.component';
import { BlockWarningComponent } from './components/block-warning/block-warning.component';
import { NudgeContainerComponent } from '../pages/batch/batch-components/nudge-container/nudge-container.component';
import { PwInputComponent } from '../pages/batch/new-order-summary/order-summary-components/pw-input/pw-input.component';
import { PaymentCardComponent } from '../pages/batch/new-order-summary/order-summary-components/payment-card/payment-card.component';
import { ToastMessageComponent } from '../pages/batch/new-order-summary/order-summary-components/toast-message/toast-message.component';
import { BatchFormComponent } from '../pages/batch/new-order-summary/order-summary-components/batch-form/batch-form.component';
import { CohortCtaComponent } from './components/cohort-cta/cohort-cta.component';
import { QuickAccessComponent } from './components/quick-access/quick-access.component';
import { QuickAccessTileComponent } from './components/quick-access/quick-access-tile/quick-access-tile.component';
import { studySliderComponent } from '../pages/batch/study/components/study-slider/study-slider.component';
import { NguCarouselModule } from '@ngu/carousel';
import { DashboardComponent } from '../pages/batch/study/components/dashboard/dashboard.component';
import { SubjectListPanelComponent } from './components/subject-list-panel/subject-list-panel.component';
import { StudyVideoCardComponent } from '../pages/batch/study/components/study-video-card/study-video-card.component';
import { DummyDropdownComponent } from '../pages/batch/study/components/dummy-dropdown/dummy-dropdown.component';
import { SatEntryPointComponent } from './components/sat-entry-point/sat-entry-point.component';
import { HamburgerInfoModalComponent } from './components/hamburger-info-modal/hamburger-info-modal.component';
import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';
import { CouponComponent } from '../pages/batch/new-order-summary/order-summary-components/coupon/coupon.component';
import { CouponCardComponent } from '../pages/batch/new-order-summary/order-summary-components/coupon-card/coupon-card.component';
import { PremiumBatchCardComponent } from '../pages/batch/premium-batch-card/premium-batch-card.component';
import { InViewDirective } from './directives/In-View/in-view.directive';
import { BattleGroundDppCardComponent } from './components/test-dpp-listing-common/battle-ground-dpp-card/battle-ground-dpp-card.component';
import { DonateCardComponent } from '../pages/batch/foundation/donate-card/donate-card.component';
import { DonateInfoComponent } from '../pages/batch/foundation/donate-info/donate-info.component';
import { GyanGuruWidgetComponent } from './components/gyan-guru-widget/gyan-guru-widget.component';
import { SharedModule } from './shared/shared.module';
import { XPGainBoxComponent } from './components/xp-gain-box/xp-gain-box.component';
import { XPHeaderButtonComponent } from './components/xp-header-button/xp-header-button.component';
import { Learn2EarnComponent } from './components/learn-2-earn/learn-2-earn.component';
import { Learn2EarnProfileComponent } from './components/learn-2-earn-profile/learn-2-earn-profile.component';
import { Learn2EarnBannerComponent } from './components/learn-2-earn-banner/learn-2-earn-banner.component';
import { Learn2EarnBadgeComponent } from './components/learn-2-earn-badge/learn-2-earn-badge.component';

@NgModule({
  declarations: [
    LayoutComponent,
    AccordianComponent,
    LoaderComponent,
    ShowErrorComponent,
    NotesCardComponent,
    VideoCardComponent,
    PurchaseAlertComponent,
    MathjaxDirective,
    TextToLinkDirective,
    UserDoubtCardComponent,
    PersonalAssistantDialogComponent,
    DoubtFeedbackDialogComponent,
    HeaderComponent,
    HamburgerComponent,
    CardComponent,
    KhazanaAccordionComponent,
    TextToLinkDirective,
    HorizontalScrollDirective,
    DoubtInputBoxComponent,
    MathjaxComponent,
    LoadingPageComponent,
    CustomCardButtonComponent,
    BatchCardNewComponent,
    ExamDetailsComponent,
    ExamSelectComponentComponent,
    ClassSelectComponentComponent,
    LoadMoreComponent,
    SkeletonLoaderComponent,
    TestDppListingCommonComponent,
    QuizCardComponent,
    TestCardComponent,
    StartTestDialogComponent,
    StartTestDetailComponent,
    DoubtModalComponent,
    DoubtAnswersComponent,
    DoubtCommentsComponent,
    DoubtReplyCardComponent,
    ArrowButtonComponent,
    AdmitCardModalComponent,
    AdmitCardModalComponent,
    SuccessModalComponent,
    // NewNotesCardComponent,
    ProfanityAlertWarningComponent,
    BlockWarningComponent,
    NudgeContainerComponent,
    PwInputComponent,
    PaymentCardComponent,
    ToastMessageComponent,
    BatchFormComponent,
    CohortCtaComponent,
    QuickAccessComponent,
    QuickAccessTileComponent,
    studySliderComponent,
    DashboardComponent,
    SubjectListPanelComponent,
    StudyVideoCardComponent,
    DummyDropdownComponent,
    CohortCtaComponent,
    SatEntryPointComponent,
    HamburgerInfoModalComponent,
    LanguageDropdownComponent,
    CouponComponent,
    CouponCardComponent,
    PremiumBatchCardComponent,
    InViewDirective,
    BattleGroundDppCardComponent,
    DonateCardComponent,
    DonateInfoComponent,
    GyanGuruWidgetComponent,
    XPGainBoxComponent,
    XPHeaderButtonComponent,
    Learn2EarnComponent,
    Learn2EarnProfileComponent,
    Learn2EarnBannerComponent,
    Learn2EarnBadgeComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,
    RouterModule,
    LottieModule,
    InfiniteScrollModule,
    NgxShimmerLoadingModule,
    NgxExtendedPdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    NguCarouselModule,
    SharedModule,
  ],
  exports: [
    LayoutComponent,
    AccordianComponent,
    LoaderComponent,
    NotesCardComponent,
    VideoCardComponent,
    PurchaseAlertComponent,
    MathjaxDirective,
    HamburgerComponent,
    HeaderComponent,
    UserDoubtCardComponent,
    PersonalAssistantDialogComponent,
    DoubtFeedbackDialogComponent,
    CardComponent,
    TextToLinkDirective,
    KhazanaAccordionComponent,
    TextToLinkDirective,
    DoubtInputBoxComponent,
    MathjaxComponent,
    LoadingPageComponent,
    CustomCardButtonComponent,
    BatchCardNewComponent,
    ExamDetailsComponent,
    ExamSelectComponentComponent,
    AdmitCardModalComponent,
    DashboardComponent,

    ClassSelectComponentComponent,
    LoadMoreComponent,
    SkeletonLoaderComponent,
    TestDppListingCommonComponent,
    QuizCardComponent,
    TestCardComponent,
    StartTestDialogComponent,
    StartTestDetailComponent,
    DoubtReplyCardComponent,
    // NewNotesCardComponent,
    ProfanityAlertWarningComponent,
    BlockWarningComponent,
    ArrowButtonComponent,
    NudgeContainerComponent,
    PwInputComponent,
    PaymentCardComponent,
    ToastMessageComponent,
    BatchFormComponent,
    CohortCtaComponent,
    QuickAccessComponent,
    QuickAccessTileComponent,
    studySliderComponent,
    SubjectListPanelComponent,
    StudyVideoCardComponent,
    DummyDropdownComponent,
    CohortCtaComponent,
    SatEntryPointComponent,
    HamburgerInfoModalComponent,
    LanguageDropdownComponent,
    CouponComponent,
    CouponCardComponent,
    PremiumBatchCardComponent,
    InViewDirective,
    GyanGuruWidgetComponent,
    XPGainBoxComponent,
    XPHeaderButtonComponent,
    Learn2EarnComponent,
    Learn2EarnProfileComponent,
    Learn2EarnBannerComponent,
    Learn2EarnBadgeComponent
  ],
})
export class PPCommonModule {}
