import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../../services/global/global.service';

@Pipe({
  name: 'timeDifference',
})
export class TimeDifferencePipe implements PipeTransform {
  constructor(private globalService: GlobalService) {}

  transform(time: string): string {
    const timeDiff = this.globalService.getTimeDifference(time);
    return timeDiff!;
  }
}
