import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { GlobalObjectService } from 'src/app/common/services/global-object/global-object.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { buildUrl } from 'src/app/common/utils/utils';
@Component({
  selector: 'app-batch-form',
  templateUrl: './batch-form.component.html',
  styleUrls: ['./batch-form.component.scss'],
})
export class BatchFormComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  iframe: any;
  isloaded = true;
  messageSub: Subscription;
  batchSlug: string;
  @Input() netPrice: number;
  @Input() dept: string = '';
  @Input() cls: string = '';
  @Input() cId: string = '';
  @Input() cityId: string = '';
  @Input() phase: string = '';
  @Input() batchId: string = '';
  @Input() preference: string = '';
  @Input() discountDetailName: string = '';
  @Input() discountDetailAmount: string = '';
  @Input() schemeId: string = '';
  @Input() courseDuration: string = '';
  @Input() date: string = '';
  @Input() currentCohortName: string = '';
  @Input() currentCohortClass: string = '';
  @Input() couponDisc: number;
  @Input() paymentMode: string = '';
  @Input() gst: number = 0;
  @Output() clickedEventEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter();

  url: string = 'https://widgets.pw.live/redirection';
  // url: string = 'http://localhost:3000/redirection';
  constructor(
    private globalService: GlobalService,
    private storageService: StorageService,
    private globalObjectService: GlobalObjectService,
    private activatedRoute: ActivatedRoute
  ) {}

  handleBack() {
    this.closeForm.emit(true);
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    this.isloaded = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // After Changes
    if (changes && changes?.cId) {
      this.cId = changes?.cId?.currentValue;
    }
    if (changes && changes?.dept) {
      this.dept = changes?.dept?.currentValue;
    }
    if (changes && changes?.cls) {
      this.cls = changes?.cls?.currentValue;
    }
    if (changes && changes?.schemeId) {
      this.schemeId = changes?.schemeId?.currentValue;
    }
    if (changes && changes?.preference) {
      this.preference = changes?.preference?.currentValue;
    }
    if (changes && changes?.discountDetailName) {
      this.discountDetailName = changes?.discountDetailName?.currentValue;
    }
    if (changes && changes?.discountDetailAmount) {
      this.discountDetailAmount = changes?.discountDetailAmount?.currentValue;
    }
    if (changes && changes?.phase) {
      this.phase = changes?.phase?.currentValue;
    }
    if (changes && changes?.courseDuration) {
      this.courseDuration = changes?.courseDuration?.currentValue;
    }
    if (changes && changes?.date) {
      this.date = changes?.date?.currentValue;
    }
    if (changes && changes?.batchId) {
      this.batchId = changes?.batchId?.currentValue;
    }
    if (changes && changes?.currentCohortName) {
      this.currentCohortName = changes?.currentCohortName?.currentValue;
    }
    if (changes && changes?.currentCohortClass) {
      this.currentCohortClass = changes?.currentCohortClass?.currentValue;
    }
    if (changes && changes?.netPrice) {
      this.netPrice = changes?.netPrice?.currentValue;

      if (this.netPrice === 0) {
        this.netPrice = (this.activatedRoute.snapshot.queryParamMap.get(
          'netPrice'
        ) || 0) as number;
      }
    }
    if (changes && changes?.cityId) {
      this.cityId = changes?.cityId?.currentValue;
    }

    if (changes && changes?.paymentMode) {
      this.paymentMode = changes?.paymentMode?.currentValue;
    }

    if (changes && changes?.gst) {
      this.gst = changes?.gst?.currentValue;
    }
  }

  ngOnInit(): void {
    this.batchSlug = this.activatedRoute.snapshot.paramMap.get('batchId') || '';
    if (this.cId.length === 0) {
      this.cId = this.activatedRoute.snapshot.queryParamMap.get('cId') || '';
    }
  }

  ngAfterViewInit(): void {
    const token = this.storageService.getUserToken();
    const randomId = this.globalService.getRandomId();
    const widget = 'BATCH_FORM';

    const params: Record<string, string> = {
      token: token !== null ? token : '',
      random_id: randomId,
      widget,
      totalAmount: this.netPrice !== null ? this.netPrice.toString() : '',
      batchSlug: this.batchSlug,
      centreId: this.cId,
      cls: this.cls,
      dept: this.dept,
      cityId: this.cityId,
      cohortName: this.currentCohortName,
      cohortClass: this.currentCohortClass,
      schemeId: this.schemeId,
      phase: this.phase,
      preference: this.preference,
      batchId: this.batchId,
      courseDuration: this.courseDuration,
      date: this.date,
      discountName: this.discountDetailName,
      discountAmount: this.discountDetailAmount,
      couponDiscount:
        this.couponDisc !== null ? this.couponDisc.toString() : '',

      ...(this.paymentMode
        ? {
            partPaymentEnabled: 'true',
            paymentMode: this.paymentMode,
            gst: this.gst !== null ? this.gst.toString() : '',
          }
        : {}),
    };

    this.url = buildUrl(this.url, params);

    setTimeout(() => {
      this.listenToMessages();
    }, 100);
  }

  listenToMessages() {
    this.messageSub = fromEvent(
      this.globalObjectService.window!,
      'message'
    ).subscribe((event: any) => {
      if (
        event &&
        event.data &&
        event.data.type === 'success' &&
        event.data.formData?.formId?.length > 0
      ) {
        this.clickedEventEmitter.emit(event.data.formData || {});
      }
    });
  }

  ngOnDestroy(): void {
    this.messageSub?.unsubscribe();
  }
}
