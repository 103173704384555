<!--<p>{{ data.dialogValue }}</p>-->

<div *ngIf="data.dialogValue === 'yes'; else noBlock">
  <mat-card class="assistant-card">
    <div class="dot flex-container justify-center items-center">
      <mat-icon class="done-icon"> done</mat-icon>
    </div>

    <mat-card-title class="title-text bold flex-container justify-center"
      >Thankyou
    </mat-card-title>

    <mat-card-subtitle
      class="subtitle-text medium flex-container justify-center"
      >Thank you for using pw doubt section I hope we have cleared your doubt!
    </mat-card-subtitle>

    <mat-card-content class="flex-container justify-center">
      <button
        (click)="onNoClick('doubt-cleared')"
        class="semibold submit-btn footer-btn"
        color="primary"
        mat-raised-button
      >
        Continue
      </button>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #noBlock>
  <mat-card class="no-block-assistant-card">
    <mat-card-title
      class="no-block-title-text semibold flex-container justify-center"
      >Do you wanna ask your doubt again?
    </mat-card-title>

    <mat-card-subtitle class="no-block-subtitle flex-container justify-center">
      <button
        (click)="onNoClick('not-ask-again')"
        class="medium no-btn no-block-footer-btn"
        color="primary"
        mat-raised-button
      >
        No
      </button>
      <button
        (click)="onNoClick('ask-again')"
        class="medium yes-btn no-block-footer-btn"
        color="primary"
        mat-raised-button
      >
        Yes
      </button>
    </mat-card-subtitle>

    <mat-card-content
      class="flex-container justify-center semibold content-text"
    >
      We will make sure to give you a better experience next time
    </mat-card-content>
  </mat-card>
</ng-template>
