<article>
  <section class="flex justify-end mt-1 mr-2 cursor-pointer" (click)="close()">
    <img [src]="cross" alt="" />
  </section>

  <!-- Teacher section -->
  <ng-container *ngIf="!processing && displayOrder === 2">
    <section class="flex flex-row items-center">
      <div class="w-1/4 solver-dp p-1 mr-2 items-center flex justify-end">
        <img [src]="solverImage || userImg" alt="" class="user-img" />
      </div>
      <div class="flex flex-col">
        <div class="flex flex-col">
          <span
            class="bold mr-1 heading-5"
            [matTooltip]="expertInfo.fullName"
            >{{ expertInfo.fullName | truncateText : 20 }}</span
          >
          <div class="flex flex-row">
            <span class="mr-1 body-2 medium text-color-1">PW Assistant</span>
            <img [src]="verified" alt="verified" />
          </div>
        </div>
        <div class="body-2 text-color-2 semibold">
          {{ expertInfo?.qualification }}
        </div>
      </div>
    </section>

    <section class="p-4">
      <div class="caption-1" [innerHtml]="expertInfo?.about"></div>
    </section>
  </ng-container>

  <!-- Student section -->

  <ng-container *ngIf="!processing && displayOrder === 1">
    <section class="flex flex-row items-center">
      <div class="w-1/3 solver-dp p-1 mr-2 items-center flex justify-end">
        <img
          [src]="createdBy.imageId.baseUrl + createdBy.imageId.key || userImg"
          alt=""
          class="user-img"
        />
      </div>
      <div class="flex flex-col">
        <span class="bold heading-5">{{ createdBy?.fullName }}</span>
        <div class="flex flex-row">
          <span class="body-2 text-color-1 mr-1 medium">PW Student</span>
          <img [src]="verified" alt="" class="verified-icon" />
        </div>
      </div>
    </section>

    <section class="p-3 flex flex-row items-center mt-2">
      <div class="vertical-line mr-2"></div>
      <div class="flex flex-col">
        <span class="body-2 text-color-2">Enrollments:</span>
        <span class="body-2 bold break-words"
          >{{ userProfileData.batchName }}
          {{ userProfileData.khazanaPurchased ? ', Khazana' : '' }}
          {{ userProfileData.saarthiPurchased ? ', Saarthi' : '' }}</span
        >
      </div>
    </section>

    <section class="flex justify-between items-center p-5">
      <div class="flex flex-col justify-center items-center semibold">
        <span class="body-1 theme-color">{{
          studentDetails?.totalSolved?.toFixed()
        }}</span>
        <span class="caption-1 text-color-3">Total Doubts Solved</span>
      </div>
      <div class="vertical-line vertical-line-color mr-2"></div>
      <div class="flex flex-col justify-center items-center semibold">
        <span class="body-1 theme-color"
          >{{ studentDetails?.satisfactoryRate?.toFixed() }}%</span
        >
        <span class="caption-1 text-color-3">Satisfaction Rate</span>
      </div>
    </section>
  </ng-container>

  <!-- shimmer loading section -->
  <section class="flex flex-col justify-center" *ngIf="processing">
    <div class="flex row items-center justify-center w-full">
      <ngx-shimmer-loading
        height="40px"
        width="40px"
        shape="circle"
      ></ngx-shimmer-loading>
      <ngx-shimmer-loading
        class="ml-2"
        height="20px"
        width="200px"
        shape="rect"
      ></ngx-shimmer-loading>
    </div>
    <ngx-shimmer-loading
      height="100px"
      width="80%"
      shape="rect"
      class="flex justify-center mt-2 mb-4"
    >
    </ngx-shimmer-loading>
  </section>
</article>
