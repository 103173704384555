<article>
  <section class="flex justify-between">
    <div class="flex flex-row items-center">
      <img [src]="teacherImage || userImg" class="user-img" />
      <div class="flex flex-col ml-2">
        <h1 class="name bold heading-5">
          {{ expertInfo?.firstName }} {{ expertInfo?.lastName }}
        </h1>
        <h3 class="bio caption-1">{{ expertInfo?.qualification }}</h3>
      </div>
    </div>
    <div>
      <img
        [src]="cross"
        mat-dialog-close="true"
        class="close-icon cursor-pointer"
      />
    </div>
  </section>
  <section class="mt-4 sme-info">
    <div class="body-2" [innerHtml]="expertInfo?.about | safe : 'html'"></div>
  </section>
</article>
