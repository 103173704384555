<section
  class="flex flex-col"
  [ngClass]="{ 'samsung-css': coming_source === 'samsung_tv' }"
>
  <div class="flex flex-col items-start gap-2 sm-gap4">
    <span class="heading-5 title-text semibold sm-heading5">{{
      openFrom === 'comment thread' ? 'Delete Comment' : 'Submit Doubt'
    }}</span>
    <span
      *ngIf="openFrom === 'ask doubt'"
      class="body-2 subtitle-text medium sm-body2"
      >Are you sure want to submit this doubt?</span
    >
    <span
      *ngIf="openFrom === 'comment thread'"
      class="body-2 subtitle-text medium sm-body2"
      >Are you sure want to delete this comment?</span
    >
  </div>

  <div class="flex justify-between items-center mt-4">
    <div
      class="cancel-button px-10 py-2 rounded-xl cursor-pointer"
      (click)="onNoClick()"
    >
      <span class="body-2 semibold sm-body2">Cancel</span>
    </div>
    <div
      class="submit-button px-10 py-2 rounded-xl cursor-pointer"
      (click)="onSubmitClick()"
    >
      <span class="body-2 semibold sm-body2">{{
        openFrom === 'comment thread' ? 'Delete' : 'Submit'
      }}</span>
    </div>
  </div>
</section>
