<div class="flex flex-col justify-between">
  <div class="flex justify-end">
    <button (click)="onNoClick()" class="close-button">
      <img [src]="closeImg" />
    </button>
  </div>

  <div class="mb-8">
    <h4 class="heading-5 semibold attachement-header mb-2">
      Do You Want To ...
    </h4>
  </div>

  <div class="flex my-1 px-1 w-full overflow-hidden">
    <!-- Column Content -->
    <button
      (click)="resumeTest('no')"
      class="w-full mx-0.5 h-10 test-start-btn"
    >
      Restart
    </button>
    <button
      (click)="resumeTest('yes')"
      class="w-full mx-0.5 h-10 test-start-btn test-resume"
    >
      Resume
    </button>
    <!-- <button (click)="onNoClick()" class="w-1/3 mx-0.5 h-12 test-start-btn">
      Cancel
    </button> -->
  </div>
</div>
