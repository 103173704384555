import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Router } from '@angular/router';
import { UpcomingInstalmentData } from 'src/app/common/services/batch/batch.modal';
import {
  PART_PAYMENT_CARDS,
  PartPaymentService,
} from 'src/app/common/services/part-payment/part-payment.service';
import { PENDING_ACCESS, ACCESS_BLOCKED } from 'src/app/core/assets.location';
import { BatchDetailModel } from '../../../batch-overview/batch-overview.model';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { CONTACT_US_VISIT } from 'src/app/core/analytics-events';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { CohortService } from 'src/app/common/services/cohort/cohort.service';

const DATA = {
  BATCH_ACCESS_PENDING_CARD: {
    heading: 'Batch Access Pending',
    subHeading:
      'Oops! You didn’t get your batch access. Please contact support team',
    variant: 'normal',
    image: PENDING_ACCESS,
  },
  ACCESS_BLOCKED_CARD: {
    heading: 'Access is Blocked',
    subHeading:
      'Your batch access is blocked because due emi wasn’t paid. Contact support team for further details.',
    variant: 'dark',
    image: ACCESS_BLOCKED,
  },
  ACCESS_BLOCKED_AND_PAY: {
    heading: 'Access is Blocked',
    subHeading:
      'Your batch access is blocked because due emi wasn’t paid. Pay Emi now to access the batch.',
    variant: 'dark',
    image: ACCESS_BLOCKED,
  },
};

interface cardType {
  heading: string;
  subHeading: string;
  variant: string;
  image: string;
}

@Component({
  selector: 'app-access-pending-card',
  templateUrl: './access-pending-card.component.html',
  styleUrls: ['./access-pending-card.component.scss'],
})
export class AccessPendingCardComponent implements OnInit, OnDestroy {
  pendingAccess = PENDING_ACCESS;
  accessBlocked = ACCESS_BLOCKED;
  cardData: cardType;
  batchDetail: BatchDetailModel;
  batchDetailSub: Subscription;
  isLoading$ = new BehaviorSubject<boolean>(false);
  userInfo: any;
  userSegmentGA: string;
  @Input() payEnable: boolean = false;
  @Input() isMasterBatch: boolean = false;
  @Input() instalmentData: UpcomingInstalmentData;
  @Input() cardType:
    | PART_PAYMENT_CARDS.BATCH_ACCESS_PENDING_CARD
    | PART_PAYMENT_CARDS.ACCESS_BLOCKED_CARD
    | PART_PAYMENT_CARDS.ACCESS_BLOCKED_AND_PAY;
  @Input() pageName: string = '';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: PART_PAYMENT_CARDS;
      isMasterBatch: boolean;
      payEnable: boolean;
      instalmentData: UpcomingInstalmentData;
      pageName: string;
    },
    private router: Router,
    private _batchService: BatchService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private _partPaymentService: PartPaymentService,
    private globalService: GlobalService,
    private cohortService: CohortService,
    @Optional() private dialogRef: MatDialogRef<AccessPendingCardComponent>
  ) {
    this.batchDetailSub = this._batchService._batchDetails$.subscribe(
      (res) => (this.batchDetail = res)
    );
  }

  ngOnInit(): void {
    this.cardData = DATA[this.cardType || this.data?.type];
    this.payEnable = this.payEnable || this.data?.payEnable;
    this.instalmentData = this.instalmentData || this.data?.instalmentData;
    this.userInfo = this.globalService.getUser().getValue() || {};
    this.userSegmentGA = this.globalService.getUserSegmentFromStorage();
  }

  ngOnDestroy(): void {
    this.batchDetailSub?.unsubscribe();
  }

  async redirectToContactSupport() {
    if (this.payEnable && !this.isLoading$.getValue()) {
      this.isLoading$.next(true);
      await this._partPaymentService.handleEMIPayOnClick({
        batchId: this.batchDetail?._id,
        surl: window.location.href,
        furl: window.location.href,
        installment_number: this.instalmentData?.installmentNumber,
        due_date: this.instalmentData?.dueDate,
        time_window: this.instalmentData?.daysLeft,
        page: this.pageName || this.data?.pageName,
      });
      this.isLoading$.next(false);
    } else {
      const eventData = {
        coming_from: this.pageName || this.data?.pageName,
        user_cohort_id:
          this.cohortService.getCohortIdFromLocal() ||
          this.userInfo?.profileId?.cohortId,
        user_segment: this.userSegmentGA,
        user_type:
          this.globalService.getIsPathShalaFromStorage() === 'true'
            ? 'offline'
            : 'online',
        isthis_batch_premium: this.batchDetail?.masterBatchId ? 'yes' : 'no',
      };
      this.firebaseAnalytics.logEvents(
        CONTACT_US_VISIT,
        eventData,
        false,
        true,
        true,
        true
      );
      this.router.navigate(['/contact-us']);
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
