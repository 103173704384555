<section
  class="flex justify-between items-center header-container py-2.5 px-4"
  [ngClass]="{ 'samsung-css px-6 py-4': coming_source === 'samsung_tv' }"
>
  <mat-icon
    (click)="activeSection = 'doubt answers'"
    [ngClass]="
      activeSection === 'doubt answers'
        ? 'text-white'
        : 'text-black cursor-pointer'
    "
    >keyboard_backspace</mat-icon
  >
  <span class="body-2 bold sm-body2">My Doubts</span>
  <mat-icon (click)="closeModal()" class="cursor-pointer">close</mat-icon>
</section>

<app-doubt-answers
  *ngIf="activeSection === 'doubt answers'"
  [addCommentIds]="addCommentIds"
  [doubt]="doubt"
  (changeSectionEvent)="changeSection($event)"
  [isBlockFromParent]="isBlock"
  [blockMessageParent]="blockMessage"
  [chatInputBlockedParent]="chatInputBlocked"
  [inputBlockMessageParent]="inputBlockMessage"
  [coming_source]="coming_source"
></app-doubt-answers>
<!-- </button> -->
<app-doubt-comments
  *ngIf="activeSection === 'doubt comments'"
  [doubtSolution]="doubtSolution"
  [doubt]="doubt"
  [isBlockFromParent]="isBlock"
  [chatInputBlockedParent]="chatInputBlocked"
  [inputBlockMessageParent]="inputBlockMessage"
  [coming_source]="coming_source"
></app-doubt-comments>
