import { Injectable } from '@angular/core';
import { formatRFC3339 } from 'date-fns';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  AllPurchasedBatches,
  BatchDetail,
  BatchDetailModel,
  RecentWatchedVideosModel,
  SubjectId,
  TodayScheduleModel,
} from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { BatchService } from '../batch/batch.service';
import { SaveVideoStatsModal } from '../content/content.service';
import { ShowErrorService } from '../showError/show-error.service';

@Injectable({
  providedIn: 'root',
})
export class StudyFunctionalityService {
  private batchList$: BehaviorSubject<AllPurchasedBatches[]> =
    new BehaviorSubject<AllPurchasedBatches[]>([]);

  private sliderImages$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  private recentLearningVideoList$: BehaviorSubject<
    RecentWatchedVideosModel[]
  > = new BehaviorSubject<RecentWatchedVideosModel[]>([]);

  private recommendationList$: BehaviorSubject<BatchDetailModel[]> =
    new BehaviorSubject<BatchDetailModel[]>([]);

  private myPaidBatchList$: BehaviorSubject<BatchDetailModel[]> =
    new BehaviorSubject<BatchDetailModel[]>([]);

  private myFreeBatchList$: BehaviorSubject<BatchDetailModel[]> =
    new BehaviorSubject<BatchDetailModel[]>([]);

  videoList: RecentWatchedVideosModel[] = [];
  studyLandingPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private batchService: BatchService,
    private showErrorService: ShowErrorService
  ) {}

  setBatchList(data: AllPurchasedBatches[]) {
    this.batchList$.next(data);
  }

  getBatchList() {
    return this.batchList$;
  }

  setSliderImage(data: any[]) {
    this.sliderImages$.next(data);
  }

  getSliderImage() {
    return this.sliderImages$;
  }

  setRecentVideoList(data: RecentWatchedVideosModel[]) {
    this.recentLearningVideoList$.next(data);
  }

  removeRecentVideoList() {
    this.recentLearningVideoList$.next([]);
  }

  getRecentVideoList() {
    return this.recentLearningVideoList$;
  }

  setRecommendList(data: BatchDetailModel[]) {
    this.recommendationList$.next(data);
  }

  getRecommendList() {
    return this.recommendationList$;
  }

  setMyPaidBatchList(data: BatchDetailModel[]) {
    this.myPaidBatchList$.next(data);
  }

  getMyPaidBatchList() {
    return this.myPaidBatchList$;
  }

  setMyFreeBatchList(data: BatchDetailModel[]) {
    this.myFreeBatchList$.next(data);
  }

  getMyFreeBatchList() {
    return this.myFreeBatchList$;
  }

  updateVideoList(
    video: TodayScheduleModel,
    videoList: RecentWatchedVideosModel[],
    videoStats: SaveVideoStatsModal,
    batchDetail: BatchDetail,
    subjectId: SubjectId,
    userId: string
  ) {
    const videoId = video?.videoDetails?._id || video?.videoDetails?.id;
    const isExist = (res: RecentWatchedVideosModel) => res.videoId === videoId;
    const index = videoList.findIndex(isExist);

    let newVideo = <RecentWatchedVideosModel>{};
    newVideo = {
      userId: userId,
      videoId: video?.videoDetails?.id || video?.videoDetails?._id,
      timeWatchedInSec: 0,
      type: videoStats.type,
      typeId: video.typeId,
      isComplete: videoStats.isComplete,
      batchId: batchDetail._id,
      batchSubjectId: videoStats.batchSubjectId,
      programId: videoStats.programId,
      subjectId: subjectId,
      chapterId: videoStats.chapterId,
      topicId: '',
      subTopicId: '',
      lastWatchedPointInSec: videoStats.lastWatchedPointInSec,
      urlType: video.urlType,
      startTime: new Date(video.startTime),
      endTime: new Date(video.endTime),
      isFree: video.isFree,
      isChatEnabled: video.isChatEnabled,
      isDoubtEnabled: video.isDoubtEnabled,
      isCommentDisabled: video.isCommentDisabled,
      isPathshala: video.isPathshala,
      slug: video.slug,
      conversationId: video.conversationId,
      dRoomId: video.dRoomId,
      videoDetails: video.videoDetails,
      topic: video.topic,
      date: new Date(video.date),
      batchDetail: batchDetail,
      homeworkIds: video.homeworkIds,
      scheduleId: video._id || video.typeId || video.scheduleId,
    };
    newVideo['batchDetail'] = batchDetail;

    if (index !== -1) {
      newVideo['lastWatchedPointInSec'] = videoStats.lastWatchedPointInSec;
      videoList.splice(index, 1);
      videoList.unshift(newVideo);
      this.setRecentVideoList(videoList);
    } else {
      videoList.unshift(newVideo);
      if (videoList.length > 10) videoList.pop();
      this.setRecentVideoList(videoList);
    }
  }

  async getRecentVideo() {
    const modifiedAt = formatRFC3339(
      new Date().setDate(new Date().getDate() - 7),
      { fractionDigits: 3 }
    ).split('+')[0];

    const videoQuery = {
      limit: 10,
      modifiedAt: modifiedAt,
    };
    this.videoList.splice(0);
    try {
      const res = await lastValueFrom(
        this.batchService.getRecentlyWatchedVideos(videoQuery)
      );

      let list: Array<RecentWatchedVideosModel> = [];
      if (res && res['data']) {
        list = <RecentWatchedVideosModel[]>res['data'];
        list = list.filter((res) => res.type === 'class');
      }
      this.videoList = [...list.filter((item) => item.type === 'class')];

      this.videoList = this.videoList.filter(
        (res: RecentWatchedVideosModel) => res?.videoDetails
      );
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      if (this.videoList.length > 0) {
        this.setRecentVideoList(this.videoList);
      }
    }
  }
}
