export default class Utils {
  static compareValues(key: string, order = 'asc') {
    return function innerSort(a: any, b: any) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
}

export function querySplitter(query: string) {
  let cookieArr = query.split('&');
  let queryObj: any = {};
  for (let i = 0; i < 3; i++) {
    let cookiePart = cookieArr[i].split(/=(.*)/s); //splits from first occurrence of "="
    queryObj[cookiePart[0]] = cookiePart[1];
  }
  return queryObj;
}

export const EXAMS_SET_VIDYAPEETH = ['IIT-JEE', 'NEET', 'SCHOOL_PREPARATION'];

export function getDomainName() {
  let _tempArr = window.location.host.split('.');
  const domain = _tempArr[1] + '.' + _tempArr[2];
  return domain;
}

export function buildUrl(
  baseUrl: string,
  params: Record<string, string>
): string {
  const paramString = Object.entries(params)
    ?.map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    ?.join('&');

  return `${baseUrl}?${paramString}`;
}
export const getUtcStartEndTime = (
  initialStartDate: Date,
  initialEndDate: Date
) => {
  const startDate = new Date(initialStartDate);
  startDate.setHours(0, 0, 0, 0);
  const utcStartDate = startDate.toISOString();

  const endDate = new Date(initialEndDate);
  endDate.setHours(23, 59, 59, 0);
  const utcEndDate = endDate.toISOString();

  const startDateTomorrow = new Date();
  startDateTomorrow.setDate(startDateTomorrow.getDate() + 1);
  startDateTomorrow.setHours(0, 0, 0, 0);
  const utcStartDateTomorrow = startDateTomorrow.toISOString();

  if (utcStartDate && utcEndDate && utcStartDateTomorrow) {
    return { utcStartDate, utcEndDate, utcStartDateTomorrow };
  }

  return { utcStartDate: '', utcEndDate: '', utcStartDateTomorrow: '' };
};
