<div class="w-full vertical-align sign-up-text">
  <h2 class="heading-2 semibold">Sign up Incomplete!</h2>
</div>

<div class="flex flex-wrap overflow-hidden">
  <div class="my-4 px-8 w-full overflow-hidden inner-div">
    <!-- Column Content -->
    <input
      #name
      placeholder="Enter your name here..."
      [value]="userName"
      id="name_ipt"
    />
  </div>
  <hr />
  <div class="my-4 px-8 w-full overflow-hidden inner-div">
    <!-- Column Content -->
    <input #email placeholder="Enter your email here..." id="email_ipt" />
  </div>
  <hr />

  <div
    class="my-3 px-4 lg:my-4 w-full overflow-hidden continue-button text-center"
  >
    <!-- Column Content -->
    <button (click)="submit(name, email)" id="cont_btn">Continue</button>
  </div>
</div>
