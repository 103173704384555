import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  PW_FOUNDATION_LOGO,
  CROSS_PW,
  PW_FOMO,
  EDUCATION_PW,
  HEALTHCARE_PW,
  EMPOWERMENT_PW,
  FOUNDATION_INFO_BG,
} from 'src/app/core/assets.location';
import { DonateCardComponent } from '../donate-card/donate-card.component';

@Component({
  selector: 'app-donate-info',
  templateUrl: './donate-info.component.html',
  styleUrls: ['./donate-info.component.scss'],
})
export class DonateInfoComponent implements OnInit {
  pwFoundationLogo = PW_FOUNDATION_LOGO;
  crossIcon = CROSS_PW;
  fomoIcon = PW_FOMO;
  educationIcon = EDUCATION_PW;
  healthcareIcon = HEALTHCARE_PW;
  empowermentIcon = EMPOWERMENT_PW;
  foundationInfoBG = FOUNDATION_INFO_BG;

  constructor(private dialogRef: MatDialogRef<DonateInfoComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
