<mat-toolbar class="relative">
  <div class="w-full">
    <button
      (click)="handleBack()"
      class="bg-white flex items-center hover:bg-gray-100 text-gray-800 heading-5 font-semibold"
      mat-button
    >
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>
  </div>
</mat-toolbar>
<article class="form-container">
  <iframe
    #formFrame
    class="form-container"
    id="admit-card-frame"
    (load)="checkLoaded($event, formFrame)"
    [src]="url | safe : 'resourceUrl'"
  ></iframe>
</article>
