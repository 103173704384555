import { StorageService } from 'src/app/common/services/storage/storage.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import { lastValueFrom, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { GlobalService } from '../../services/global/global.service';
import { ShowErrorService } from '../../services/showError/show-error.service';
import { MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '../../services/loader/loader.service';
import { EditprofileService } from '../../services/edit-profile/editprofile.service';
import { MAIN_PROGRAM } from '../../../constants/global-constant/global-constant.service';
import { TICK_IMG } from 'src/app/core/assets.location';

@Component({
  selector: 'app-update-academic-info',
  templateUrl: './update-academic-info.component.html',
  styleUrls: ['./update-academic-info.component.scss'],
})
export class UpdateAcademicInfoComponent implements OnInit, OnDestroy {
  preferences: any;
  filterBoard: Array<Tags> = [];
  filterExams: Array<Tags> = [];
  filterClasses: Array<Tags> = [];
  filterLanguages: Array<Tags> = [];
  formArray = [];
  selectedBoard: string;
  selectedClass: string;
  selectedExams: Array<string> = [];
  user: any;
  selectedLanguage: string;
  noOfFilters: number = 0;
  filterType: Array<Tags> = [];
  includedInFilter: Array<String> = [];
  tickImg = TICK_IMG;
  userSub: Subscription;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private globalService: GlobalService,
    private showErrorService: ShowErrorService,
    public dialogRef: MatDialogRef<UpdateAcademicInfoComponent>,
    private editProfileService: EditprofileService,
    private storageService: StorageService
  ) {
    this.filterType.push(new Tags({ title: 'Free' }));
    this.filterType.push(new Tags({ title: 'Paid' }));

    this.getUserInfo();
  }

  async ngOnInit() {}

  ngOnDestroy(): void {
    // this.filterBoard.splice(0);
    // this.filterExams.splice(0);
    // this.filterClasses.splice(0);
    // this.filterLanguages.splice(0);
    // this.selectedExams.splice(0);
    // this.includedInFilter.splice(0);
  }

  getUserInfo() {
    const res = this.storageService.getUser();
    this.user = typeof res === 'string' ? JSON.parse(res) : res;
    if (this.user) {
      this.getPreferences();
    }
  }

  selectClass(c: Tags) {
    if (!c.isActive) {
      this.filterClasses.forEach((item) => (item.isActive = false));
      c.isActive = !c.isActive;
      if (c.isActive) {
        this.selectedClass = c.title;
      }
      if (!this.includedInFilter.includes('Class')) {
        this.includedInFilter.push('Class');
        this.noOfFilters += 1;
      }
    } else {
      c.isActive = !c.isActive;
      this.includedInFilter.splice(this.includedInFilter.indexOf('Class'), 1);
    }
  }

  selectExam(e: Tags) {
    e.isActive = !e.isActive;
    if (e.isActive && !this.selectedExams.includes(e.title)) {
      this.selectedExams.push(e.title);
    } else {
      this.selectedExams.splice(this.selectedExams.indexOf(e.title), 1);
    }
    if (!this.includedInFilter.includes('Exam')) {
      this.includedInFilter.push('Exam');
      this.noOfFilters += 1;
    }
    if (this.selectedExams.length === 0) {
      this.includedInFilter.splice(this.includedInFilter.indexOf('Exam'), 1);
    }
  }

  selectBoard(b: Tags) {
    if (!b.isActive) {
      this.filterBoard.forEach((item) => (item.isActive = false));
      b.isActive = !b.isActive;

      if (b.isActive) {
        this.selectedBoard = b.title;
      }
      if (!this.includedInFilter.includes('Board')) {
        this.includedInFilter.push('Board');
        this.noOfFilters += 1;
      }
    } else {
      b.isActive = !b.isActive;
      this.includedInFilter.splice(this.includedInFilter.indexOf('Board'), 1);
    }
  }

  selectLanguage(b: Tags) {
    if (!b.isActive) {
      this.filterLanguages.forEach((item) => (item.isActive = false));
      b.isActive = !b.isActive;

      if (b.isActive) {
        this.selectedLanguage = b.title.toLowerCase();
      }
      if (!this.includedInFilter.includes('Language')) {
        this.includedInFilter.push('Language');
        this.noOfFilters += 1;
      }
    } else {
      b.isActive = !b.isActive;
      this.includedInFilter.splice(
        this.includedInFilter.indexOf('Language'),
        1
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  getPreferences() {
    const res = this.globalService.getPreferences();

    if (res) {
      res.forEach((row: any) => {
        switch (row.key) {
          case 'Boards':
            const list: Array<Tags> = [];
            row.valueArray.forEach((el: string) => {
              list.push(new Tags({ title: el }));
            });
            this.filterBoard = [...list];

            if (this.filterBoard.length > 0) {
              this.noOfFilters += 1;
            }
            break;
          case 'Classes':
            const classList: Array<Tags> = [];
            row.valueArray.forEach((el: string) => {
              classList.push(new Tags({ title: el }));
            });
            this.filterClasses = [...classList];
            if (this.filterClasses.length > 0) {
              this.noOfFilters += 1;
            }
            break;
          case 'Exams':
            const examsList: Array<Tags> = [];

            row.valueArray.forEach((el: string) => {
              examsList.push(new Tags({ title: el }));
            });

            this.filterExams = [...examsList];
            if (this.filterExams.length > 0) {
              this.noOfFilters += 1;
            }
            break;
          case 'Languages':
            const langList: Array<Tags> = [];
            row.valueArray.forEach((el: string) => {
              langList.push(new Tags({ title: el }));
            });
            this.filterLanguages = [...langList];
            if (this.filterLanguages.length > 0) {
              this.noOfFilters += 1;
            }
            break;
        }
      });
    } else {
      const query = {
        organizationId: ORGANIZATION_ID,
        prefs: 'Languages,Classes,Boards,Exams',
      };
      this.getPreferencesFromServer(query);
    }
    this.setActivePreferences();
  }

  async getPreferencesFromServer(query: any) {
    this.loaderService.loadData('Loading...');
    try {
      const res = await lastValueFrom(this.authService.getPreferences(query));
      if (res && res['data']) {
        res['data'].forEach((row: any) => {
          switch (row.key) {
            case 'Boards':
              const list: Array<Tags> = [];
              row.valueArray.forEach((el: string) => {
                list.push(new Tags({ title: el }));
              });
              this.filterBoard = [...list];
              break;
            case 'Classes':
              const classList: Array<Tags> = [];
              row.valueArray.forEach((el: string) => {
                classList.push(new Tags({ title: el }));
              });
              this.filterClasses = [...classList];
              break;
            case 'Exams':
              const examsList: Array<Tags> = [];
              row.valueArray.forEach((el: string) => {
                examsList.push(new Tags({ title: el }));
              });
              this.filterExams = [...examsList];
              break;
            case 'Languages':
              const langList: Array<Tags> = [];
              row.valueArray.forEach((el: string) => {
                langList.push(new Tags({ title: el }));
              });
              this.filterLanguages = [...langList];
              break;
          }
        });
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
      // this.authService.setBoards(this.boardsArray);
      // this.authService.setClasses(this.classArray);
      // this.authService.setExams(this.examsArray);
      // this.authService.setLanguages(this.langArray);
      this.loaderService.unloadData('Loading...');
    }
  }

  setActivePreferences() {
    this.selectedClass = this.user['profileId']['class'] || '';
    this.selectedExams = this.user['profileId']['exams'] || [];
    this.selectedBoard = this.user['profileId']['board'] || '';
    this.selectedLanguage = this.user['profileId']['language'] || '';

    if (this.filterClasses.length > 0) {
      this.filterClasses.forEach((item) => {
        if (item.title === this.selectedClass) {
          item.isActive = true;
        }
      });
    }
    if (this.filterBoard.length > 0) {
      this.filterBoard.forEach((item) => {
        if (item.title === this.selectedBoard) {
          item.isActive = true;
        }
      });
    }
    if (this.filterLanguages.length > 0) {
      this.filterLanguages.forEach((item) => {
        if (item.title.toLowerCase() === this.selectedLanguage.toLowerCase()) {
          item.isActive = true;
        }
      });
    }
    if (this.filterExams.length > 0) {
      this.filterExams.forEach((item) => {
        if (this.selectedExams.includes(item.title)) {
          item.isActive = true;
        }
      });
    }
  }

  async updateUser() {
    if (this.selectedExams.length === 0) {
      this.globalService.showSnackBar(`Please select exam(s)...`);
      return;
    }
    if (this.selectedLanguage.length === 0) {
      this.globalService.showSnackBar(`Please select a language...`);
      return;
    }
    if (this.selectedClass.length === 0) {
      this.globalService.showSnackBar(`Please select a class...`);
      return;
    }
    if (this.selectedBoard.length === 0) {
      this.globalService.showSnackBar(`Please select a board...`);
      return;
    }
    const data = {
      language: this.selectedLanguage,
      class: this.selectedClass,
      exams: this.selectedExams,
      board: this.selectedBoard,
      isProfileCompleted: true,
    };
    const message = 'Updating Your Goal';
    await this.loaderService.loadData(message);
    try {
      const res: any = await lastValueFrom(
        this.editProfileService.updateProfile(data)
      );
      if (res) {
        this.user['profileId']['language'] = res['data']['language'];
        this.user['profileId']['class'] = res['data']['class'];
        this.user['profileId']['exams'] = res['data']['exams'];
        this.user['profileId']['board'] = res['data']['board'];
        this.user['profileId']['isProfileCompleted'] = true;
        if (res['programId'] && res['programId'].length > 0) {
          await this.globalService.setProgram(res['programId']);
        } else {
          await this.globalService.setProgram(MAIN_PROGRAM);
        }
        await this.globalService.setUser(this.user);
        this.globalService.showSnackBar('User updated successfully...');
        this.close();
      }
    } catch (e) {
      console.error(e);
      await this.showErrorService.showError(e);
    } finally {
      await this.loaderService.unloadData(message);
    }
  }
}

class Tags {
  title: string;
  isActive: boolean;
  constructor(data: any) {
    this.title = data.title || '';
    this.isActive = false;
  }
}
