import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgAudioRecorderService, OutputFormat } from 'ng-audio-recorder';
import { lastValueFrom, Subscription } from 'rxjs';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { ContentService } from 'src/app/common/services/content/content.service';
import {
  AudioPlayer,
  CommonDoubtModel,
  CreatedByModel,
  DoubtCommentModel,
  ImageInfoModel,
  SlideModel,
} from 'src/app/common/services/doubts/doubt.model';
import {
  DoubtsService,
  warningType,
} from 'src/app/common/services/doubts/doubts.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { LoaderService } from 'src/app/common/services/loader/loader.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import {
  SEND_BUTTON,
  SAME_DOUBT_ACTIVE,
  SAME_DOUBT_DEFAULT,
  REPORT,
  MIKE_IMG,
  ATTACHMENT_IMG,
  PIN,
  PERSONAL_ASSISTANT_VERIFIED,
  LIKE_INACTIVE,
  DISLIKE_INACTIVE,
  REPLY_TO_ANSWER,
  DELETE_RED,
  STOP_REC,
  SOUND_WAVE,
  USER_IMG,
} from 'src/app/core/assets.location';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';

@Component({
  selector: 'app-doubt-answers',
  templateUrl: './doubt-answers.component.html',
  styleUrls: ['./doubt-answers.component.scss'],
  providers: [NgAudioRecorderService],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      transition('void => *', [
        style({ opacity: 0, transform: 'translateY(100%)' }),
        animate(200),
      ]),
      transition('* => void', [
        animate(200, style({ opacity: 0, transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class DoubtAnswersComponent implements OnInit, OnChanges, OnDestroy {
  @Output('reportDoubtEvent') reportDoubtEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Output('changeSectionEvent') changeSectionEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Input('doubt') doubt: CommonDoubtModel;
  @Input('addCommentIds') addCommentIds: any;
  @Input('isBlockFromParent') isBlockFromParent: boolean = false;
  @Input('blockMessageParent') blockMessageParent: string;
  @Input('chatInputBlockedParent') chatInputBlockedParent: boolean = false;
  @Input('inputBlockMessageParent') inputBlockMessageParent: string;
  @Input() coming_source: string = '';
  doubtSolutionPaginate: boolean;
  processing: boolean = false;
  doubtSolution: Array<DoubtCommentModel> = [];
  doubtSolutionQuery: any = {};
  uploadFileSub: Subscription;
  selectedFile: File;
  previewImagePosition: string = '30px';
  previewImage: any;
  textValue: string = '';
  batchDetails: BatchDetailModel;
  isRecording: boolean = false;
  readyToSend: boolean = false;
  blobUrl: any;
  recTimer: number = 0;
  audioFile: any;
  recTimerInerval: any;
  timeTaken: number;
  confirmationText: string = '';
  sendButton = SEND_BUTTON;
  sameDoubtActive = SAME_DOUBT_ACTIVE;
  sameDoubtDefault = SAME_DOUBT_DEFAULT;
  reportImg = REPORT;
  mikeImg = MIKE_IMG;
  attachmentImg = ATTACHMENT_IMG;
  pinImg = PIN;
  verifiedAssistant = PERSONAL_ASSISTANT_VERIFIED;
  likeInactive = LIKE_INACTIVE;
  dislikeInactive = DISLIKE_INACTIVE;
  replyImg = REPLY_TO_ANSWER;
  deleteRed = DELETE_RED;
  stopRec = STOP_REC;
  soundWave = SOUND_WAVE;
  userImg = USER_IMG;
  userInfo: any;
  userInfoSub: Subscription;
  relevant: boolean = false;
  isBlock: boolean = false;
  irreleventMessage: string = '';
  blockMessage: string = '';
  inputBlockMessage: string = '';
  chatInputBlocked: boolean = false;
  constructor(
    private globalService: GlobalService,
    private doubtService: DoubtsService,
    private showErrorService: ShowErrorService,
    private loaderService: LoaderService,
    private contentService: ContentService,
    private batchService: BatchService,
    private audioRecorderService: NgAudioRecorderService,
    private sanitizer: DomSanitizer
  ) {
    this.doubtSolutionQuery = {
      page: 1,
      limit: 20,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.doubt) {
      this.doubt = changes.doubt.currentValue;
      this.getDoubtComments(this.doubtSolutionQuery);
    }
  }

  ngOnInit(): void {
    this.batchDetails = this.batchService.getBatchData();

    this.userInfoSub = this.globalService.getUser().subscribe((res) => {
      this.userInfo = res;
    });
  }

  ngOnDestroy(): void {
    this.userInfoSub?.unsubscribe();
  }

  loadMore() {
    if (this.doubtSolutionPaginate) {
      this.doubtSolutionQuery.page++;
      this.getDoubtComments(this.doubtSolutionQuery);
    }
  }

  async getDoubtComments(query: any) {
    if (!this.doubt._id)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_GET_DOUBT_ANSWERS
      );
    try {
      this.processing = true;
      const res = await lastValueFrom(
        this.doubtService.getDoubtComments(this.doubt._id, query)
      );
      if (res && res.success && res.data) {
        let list: Array<DoubtCommentModel> = [];
        list = res.data.map((item: any) => new DoubtCommentModel(item));
        this.doubtSolutionPaginate = list.length > 19;
        this.doubtSolution = [...this.doubtSolution, ...list];
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.processing = false;
    }
  }

  getSubjectId() {
    if (this.addCommentIds?.subjectId?.length)
      return this.addCommentIds?.subjectId;
    let subject = this.batchDetails.subjects.find(
      (sub) => sub._id === this.addCommentIds.batchSubjectId
    );
    return subject?.subjectId || '';
  }

  async preparingData(msg: any, audioFile?: AudioPlayer) {
    let data = {
      doubtIds: [this.doubt._id],
      scheduleId: this.addCommentIds.scheduleId,
      batchId: this.addCommentIds?.batchId || this.batchDetails._id,
      subjectId: this.getSubjectId(),
      chapterId: this.addCommentIds.chapterId,
    };
    if (audioFile) {
      data = { ...data, ...{ audioId: audioFile._id, solutionType: 'AUDIO' } };
    } else {
      if (msg.value)
        data = {
          ...data,
          ...{ solutionText: msg.value.trim(), solutionType: 'TEXT' },
        };
      if (this.previewImage) {
        msg.style.height = '30px';
        return await this.getImageData(data);
      }
    }
    await this.addComment(data, audioFile);
    if (msg) {
      msg.value = '';
      msg.style.height = '30px';
    }
  }
  async addComment(
    data: any,
    audioFile?: AudioPlayer,
    imageData?: ImageInfoModel
  ) {
    try {
      this.loaderService.loadData('Adding comment');
      const res = await lastValueFrom(
        this.doubtService.sendDoubtSolution(data)
      );
      if (res && res['success']) {
        let userDetails = {
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          _id: this.userInfo.id,
          imageId: this.userInfo.imageId,
        };
        const commentData = {
          _id: res.data[0]._id,
          createdAt: Date.now(),
          text: data.solutionText,
          audioId: audioFile,
          imageId: imageData,
          createdBy: new CreatedByModel(userDetails),
          upVoteCount: res.data[0].upVoteCount,
        };
        this.globalService.showSnackBar(
          SNACKBAR_MESSAGE.COMMENT_ADDED_SUCCESSFULLY
        );
        this.doubtSolution.unshift(new DoubtCommentModel(commentData));
        this.doubt.commentCount++;
      }
    } catch (e: any) {
      if (e && !e['success'] && e['error']['warningType']) {
        if (e['error']['warningType'] === warningType.PROFANE) {
          this.irreleventMessage = e['error']['message'];
          this.relevant = true;
          this.isBlock = false;
          this.chatInputBlocked = false;
        }
        if (e['error']['warningType'] === warningType.BLOCK) {
          this.blockMessage = e['error']['message'];
          this.isBlock = true;
          this.relevant = false;
          this.chatInputBlocked = false;
        }
        if (e['error']['warningType'] === warningType.PERMANENTBLOCK) {
          this.chatInputBlocked = true;
          this.inputBlockMessage = e['error']['message'];
          this.relevant = false;
          this.isBlock = false;
        }
        data.value = '';
      } else {
        await this.showErrorService.showError(e);
      }
    } finally {
      this.loaderService.unloadData('Adding comment');
      // if (msg) msg.value = '';
      this.textValue = '';
      this.uploadFileSub?.unsubscribe();
      this.clearPreview();
    }
  }

  clearPreview() {
    this.previewImage = null;
    this.selectedFile = new File([], '');
  }

  goToDoubtComments(doubtAnswer: DoubtCommentModel) {
    this.changeSectionEvent.emit(doubtAnswer);
  }

  async deleteUserComment(comment: DoubtCommentModel) {
    if (!comment._id)
      return this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_DELETE_USER_COMMENT
      );
    let index = this.doubtSolution.findIndex(
      (item) => item._id === comment._id
    );
    this.doubtSolution.splice(index, 1);
    try {
      const res = await lastValueFrom(
        this.doubtService.deleteDoubtComments(comment._id)
      );
      if (res && res.success) {
        this.globalService.showSnackBar(
          SNACKBAR_MESSAGE.COMMENT_DELETED_SUCCESSFULLY
        );
      }
    } catch (e) {
      this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.CANNOT_DELETE_USER_COMMENT
      );
      this.doubtSolution.splice(index, 0, comment);
    }
  }

  getImageData(data: any) {
    const formData = new FormData();
    formData.append('file', this.selectedFile);

    let imageData = {};
    this.loaderService.loadData('Uploading Image...');
    this.uploadFileSub = this.contentService
      .uploadFile(formData)
      .subscribe((res: any) => {
        if (res && res['data']) {
          imageData = {
            baseUrl: res['data']['baseUrl'],
            key: res['data']['key'],
            _id: res['data']['_id'],
          };
          this.loaderService.unloadData('Uploading Image...');
          this.addComment(
            {
              ...data,
              ...{ imageId: res['data']['_id'], solutionType: 'IMAGE' },
            },
            <AudioPlayer>{},
            new ImageInfoModel(imageData)
          );
        }
      });
  }

  autoGrowTextZone(e: any) {
    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 10 + 'px';

    if (this.getHeight(e.target.style.height) < 90)
      this.previewImagePosition = e.target.style.height;
  }

  getHeight(height: string): number {
    let pixel = height.match(/\d/g)!;
    return +pixel.join('');
  }

  startRecording() {
    this.isRecording = !this.isRecording;
    if (this.isRecording) {
      this.audioRecorderService.startRecording();
      this.timeTaken = Date.now();
      this.startRecTmer();
    }
  }

  async stopRecording(action: any) {
    clearInterval(this.recTimerInerval);
    this.recTimer = 0;
    if (action === 'cancel') {
      this.isRecording = false;
      this.readyToSend = false;
      this.blobUrl = null;
      return;
    }

    const duration = Date.now() - this.timeTaken;
    this.audioRecorderService
      .stopRecording(OutputFormat.WEBM_BLOB)
      .then(async (output: any) => {
        this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(output)
        );
        this.readyToSend = true;
        const file = await this.blobToBase64(output);
        this.audioFile = {
          data: file,
          duration: duration,
          type: output['type'],
        };
        // await this.sendRecording();
      })
      .catch((errorCase: any) => {});
    this.audioRecorderService.getUserContent().then(async (output: any) => {});
  }

  blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  confirmationDialog(text: string) {
    if (text === 'like' || text === 'dislike') text = 'Feedback Submitted';
    else text = 'Reported';
    this.confirmationText = text;
    setTimeout(() => {
      this.confirmationText = '';
    }, 1000);
  }

  async sendRecording() {
    try {
      this.uploadFileSub = this.contentService
        .sendRecording(this.audioFile)
        .subscribe(async (res: any) => {
          if (res && res['data']) {
            let audioData = {
              baseUrl: res['data']['baseUrl'],
              key: res['data']['key'],
              _id: res['data']['_id'],
            };
            await this.preparingData(null, <AudioPlayer>audioData);
          }
        });
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      this.loaderService.unloadData('Sending audio...');
    }

    this.isRecording = false;
    this.readyToSend = false;
    this.blobUrl = null;
  }

  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.type.includes('image')) {
      this.previewImage = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(this.selectedFile)
      );
    } else {
      this.globalService.showSnackBar(
        SNACKBAR_MESSAGE.ONLY_IMAGES_ARE_ACCEPTED
      );
    }
  }

  onFileClick(event: any) {
    event.target.value = '';
  }

  startRecTmer() {
    this.recTimerInerval = setInterval(() => {
      this.recTimer++;
    }, 1000);
  }

  reportDoubtEventEmit(event: any) {
    this.reportDoubtEvent.emit(event);
  }
}
