import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  discount,
  DISABLED_DISCOUNT,
} from './../../../../../core/assets.location';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { OrderSummarySlideOverComponent } from '../order-summary-slide-over/order-summary-slide-over.component';
import { CouponService } from 'src/app/common/services/coupon/coupon.service';
import {
  Observable,
  Subject,
  Subscription,
  forkJoin,
  lastValueFrom,
  switchMap,
  throttleTime,
} from 'rxjs';
import {
  Coupon,
  CouponApplied,
  UserCoupons,
} from 'src/app/common/services/coupon/coupon.type';
import {
  CartItemsType,
  PaymentService,
} from 'src/app/common/services/payment/payment.service';
import { SelectedType } from '../add-more-wrapper/add-more-wrapper.component';
import {
  BatchDetailModel,
  BatchStudentFee,
} from '../../../batch-overview/batch-overview.model';
import { SaarthiService } from 'src/app/common/services/saarthi/saarthi.service';
import { Plans } from 'src/app/common/services/batch/batch.modal';
import { StoreService } from 'src/app/common/services/store/store.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { VIEW_ALL_COUPONS } from 'src/app/core/analytics-events';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit, OnChanges {
  @Input('walletsApplied') walletsApplied: boolean = false;
  @Input('source') source: string;

  discountImg = discount;
  disabledDiscount = DISABLED_DISCOUNT;
  couponApplied: boolean = false;
  couponCode: string;
  coupons: UserCoupons;
  recommendedCoupon: Coupon;
  cartSub: Subscription;
  cartItems: SelectedType[] = [];
  batchDetail: BatchDetailModel;
  feeIdSub: Subscription;
  feeId: string;
  saarthiSub: Subscription;
  couponResponseSubs: Subscription;
  couponResponse: CouponApplied;
  couponProcessing: boolean;
  plansSub: Subscription;

  _selectedIds$: Observable<CartItemsType[]>;
  _plans$: Observable<Plans>;
  selectedPlan: Plans;
  cartAmount: number;

  sub: Subscription;
  khazanaSub: Subscription;

  isSelfLearning: boolean;
  isPurchased: boolean;
  isFree: boolean;

  couponProcessingSub: Subscription;
  applyCouponProcessing: boolean;
  throttleButtonClick = new Subject<void>();

  constructor(
    private dialog: NgDialogAnimationService,
    private couponService: CouponService,
    private paymentService: PaymentService,
    private saarthiService: SaarthiService,
    private storeService: StoreService,
    private globalService: GlobalService,
    private fireBaseService: FirebaseAnalyticsService
  ) {
    this.batchDetail = this.paymentService._batchDetail$.getValue();

    this._plans$ = this.paymentService.getSelectedPlans;
    this._selectedIds$ = this.paymentService.getCartItems;

    this.couponResponse = new CouponApplied();

    this.throttleButtonClick.pipe(throttleTime(1500)).subscribe(() => {
      this.applyCoupon();
    });
  }

  subscriptions() {
    this.couponProcessingSub = this.couponService.couponProcessing$.subscribe(
      (res) => {
        this.applyCouponProcessing = res;
      }
    );

    this.sub = this._plans$
      .pipe(
        switchMap((res) => {
          if (res) {
            this.couponService.setCouponCode(null);
            this.couponService.setCouponResponse(null);
          }

          return this._selectedIds$;
        })
      )
      .subscribe((res) => {
        if (this.source !== 'fbt') return;
        if (!res) return;

        this.cartItems = res.map((item) => {
          return {
            type: item.type,
            typeId: item.id,
          };
        });

        if (this.batchDetail?.isPurchased) {
          const data = {
            type: 'BATCH',
            typeId: this.batchDetail?._id,
          };

          this.cartItems.push(data);
        }

        this.cartAmount = res.reduce((acc: number, curr: CartItemsType) => {
          return acc + (curr.price - curr?.discount);
        }, 0);

        if (this.cartAmount > 0) {
          const couponPayload = {
            cartItems: this.cartItems,
            cartAmount: this.cartAmount,
          };

          this.getCoupon(couponPayload);
        } else {
          this.couponService.setCouponCode(null);
          this.coupons = new UserCoupons({});
          this.recommendedCoupon = new Coupon({});
        }
      });

    this.saarthiSub = this.saarthiService.selectedPlan$.subscribe(
      async (res) => {
        if (res?._id?.length > 0) {
          if (this.source === 'saarthi') {
            const cartItems = [
              {
                type: 'SAARTHI',
                typeId: res?._id,
              },
            ];

            this.cartAmount = res?.total;

            const couponPayload = {
              cartItems,
              cartAmount: this.cartAmount,
            };

            if (res?.total > 0) {
              await this.getCoupon(couponPayload);
            } else {
              this.recommendedCoupon = new Coupon({});
              this.coupons = new UserCoupons({});
            }
          }
        }
      }
    );

    this.couponResponseSubs = this.couponService.couponResponse$.subscribe(
      (res) => {
        if (!res) {
          this.couponApplied = false;
          return;
        }

        this.couponApplied = true;
        this.couponResponse = new CouponApplied(res);
      }
    );

    this.khazanaSub = this.couponService.selectedKhazanaId$.subscribe(
      async (res) => {
        if (this.source !== 'khazana') return;
        if (res?.ids.length) {
          const cartItems = res.ids.map((id) => ({
            type: 'PACKAGE',
            typeId: id,
          }));

          const payload = {
            cartItems,
            cartAmount: res.amount,
          };

          await this.getCoupon(payload);
        }
      }
    );
  }

  ngOnInit() {
    this.subscriptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.source) {
      this.source = changes?.source?.currentValue;
    }
  }

  applyCoupon() {
    this.couponService.setCouponSource(this.source);
    this.couponService.setCouponProcessing(true);
    this.couponService.setCouponCode(this.recommendedCoupon);
    this.couponService.applyCouponGAEvent(true, true, false);
  }

  async removeCoupon() {
    if (this.source === 'fbt') {
      await this.cancelOrder();
    }
    this.couponApplied = false;
    this.couponService.setCouponCode(null);
    this.couponService.setCouponResponse(null);
  }

  async getCoupon(data: { cartItems: SelectedType[]; cartAmount: number }) {
    this.couponProcessing = true;
    try {
      const res = await lastValueFrom(this.couponService.getUserCoupons(data));
      if (res?.data) {
        this.coupons = res?.data;
        this.recommendedCoupon = new Coupon(res?.data?.recommendedCoupons?.[0]);
        this.couponService.setIsCouponApplicable(
          res?.data?.recommendedCoupons.length > 0
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.couponProcessing = false;
    }
  }

  openCouponDialog() {
    if (this.walletsApplied) return;

    const userInfo = this.globalService.getUser().getValue();

    const eventData = {
      userId: userInfo?.id,
    };

    this.fireBaseService.logEvents(VIEW_ALL_COUPONS, eventData, false, false);

    const dialogRef = this.dialog.open(OrderSummarySlideOverComponent, {
      panelClass: 'mahapack-side-dialog',
      position: { right: '0px' },
      disableClose: false,
      data: {
        title:
          this.source === 'fbt'
            ? this.batchDetail?.name
            : this.source === 'KHAZANA'
            ? 'Khazana'
            : 'Saarthi',
        activeType: 'coupons',
        coupons: this.coupons,
        cartAmount: this.cartAmount,
        couponSource: this.source,
      },
      animation: {
        to: 'left',
        incomingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
        outgoingOptions: {
          keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
        },
      },
    });
  }

  ngOnDestroy() {
    this.cartSub?.unsubscribe();
    this.saarthiSub?.unsubscribe();
    this.couponProcessingSub?.unsubscribe();
  }

  async cancelOrder() {
    await lastValueFrom(this.storeService.emptyCart());
    const orderId = this.paymentService._orderId$;

    if (orderId?.length) {
      await lastValueFrom(this.storeService.cancelOrder(orderId));
    }
  }
}
