import { Injectable } from '@angular/core';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { BehaviorSubject, catchError, map } from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';
import {
  // ActiveSlotsObject,
  BookedSlotsObject,
  ScheduleDatesObject,
  ScheduleSlots,
} from './premium-batch.type';
import {
  BatchDetailModel,
  Subject,
  TeacherModel,
} from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { FileModal } from '../base/base.modal';
import { Router } from '@angular/router';
import { GlobalService } from '../global/global.service';
import { PREMIUM_COURSE_EVENTS } from 'src/app/core/analytics-events';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class PremiumBatchService {
  private isKhazanaApplicable$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private khazanaProgramId$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  private shouldFetchBookedSlots$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  constructor(
    private appUrlService: AppUrlService,
    private apiService: PPApiService,
    private router: Router,
    private globalService: GlobalService,
    private firebaseAnalytics: FirebaseAnalyticsService
  ) {}

  // getActiveSlots(webinarId: string) {
  //   const url = this.appUrlService.GET_ACTIVE_SLOTS(webinarId);
  //   const options: PPApiOptions = {
  //     apiPath: url,
  //   };

  //   return this.apiService.get<ActiveSlotsObject>(options).pipe(
  //     map((res) => res.data),
  //     catchError(handleError)
  //   );
  // }

  getBookedSlots(webinarId: string, query: any) {
    const params = query || {};
    const url = this.appUrlService.GET_BOOKED_SLOTS(webinarId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(params),
    };

    return this.apiService.get<BookedSlotsObject>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  getScheduleDates(webinarId: string) {
    const url = this.appUrlService.GET_SCHEDULE_SLOT_DATES(webinarId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<ScheduleDatesObject>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  getActiveDateSlot(webinarId: string, query: any) {
    const params = query || {};
    const url = this.appUrlService.GET_ACTIVE_DATE_SLOTS(webinarId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(params),
    };

    return this.apiService.get<ScheduleSlots>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  _shouldFetchBookedSlots$() {
    return this.shouldFetchBookedSlots$;
  }

  setshouldFetchBookedSlots(val: boolean) {
    this.shouldFetchBookedSlots$.next(val);
  }
  setIsKhazanaApplicable(val: boolean) {
    this.isKhazanaApplicable$.next(val);
  }

  get _isKhazanaApplicable$() {
    return this.isKhazanaApplicable$.getValue();
  }

  setKhazanaProgramId(val: string) {
    this.khazanaProgramId$.next(val);
  }

  get _khazanaProgramId$() {
    return this.khazanaProgramId$.getValue();
  }

  getTeachers(batchDetail: BatchDetailModel) {
    /* teacher mapping using batchDetails response */
    const teachers = new Array<TeacherModel>();
    batchDetail?.subjects.forEach((item) => {
      item.teacherIds.forEach((el: any) => {
        if (el.imageId) {
          el.imageId.url = el.imageId.baseUrl + el.imageId.key;
        } else {
          el.imageId = new FileModal({});
        }

        el.subject = item.subject;
        teachers.push(new TeacherModel(el));
      });
    });
    return teachers;
  }

  async openContent(content: Subject) {
    /* Logic for opening schedule content */
    if (content.fileId && content.fileId.baseUrl && content.fileId.key) {
      const data = content.fileId.baseUrl + content.fileId.key;
      if (data && data.length > 0) {
        /* open pdf, if found */
        this.globalService.setPdfUrl(
          JSON.stringify({ src: data, title: content.fileId.name })
        );
        await this.router.navigate(['batches/open-pdf']);
      } else {
        /* show snackbar with message  */
        this.globalService.showSnackBar(`No pdf url found...`);
      }
    } else {
      this.globalService.showSnackBar(`No pdf url found...`);
    }
  }

  getPremiumBatchListing(cohortId: string, query: any) {
    const url = this.appUrlService.GET_PREMIUM_BATCHES(cohortId);

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  easeInOutQuad(t: any, b: any, c: any, d: any) {
    /* Logic for smooth scrolling */
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  scrollTo(
    element: HTMLElement,
    to: number,
    duration: number,
    successCallback: any
  ) {
    if (element) {
      let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
      const animateScroll = () => {
        currentTime += increment;
        let val = this.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        } else {
          /* callback for handling operations after scroll completion */
          successCallback();
        }
      };
      animateScroll();
    }
  }
  logNAVClickEvent(batchId: string, buttonName: string) {
    const eventData = {
      page_name: 'study_page',
      batch_id: batchId,
      button_name: buttonName,
    };
    this.firebaseAnalytics.logEvents(
      PREMIUM_COURSE_EVENTS.NAV_CTA_CLICK,
      eventData,
      false,
      true,
      true,
      true
    );
  }
}
