import { DoubtsService } from './doubts.service';
import { lastValueFrom } from 'rxjs';

export class DoubtsHelper {
  constructor(protected doubtService: DoubtsService) {}

  async getSubjects(batchId: string) {
    return await lastValueFrom(this.doubtService.getSubjects(batchId));
  }

  async getSchedules(batchId: string, subjectId: string, query: any) {
    return await lastValueFrom(
      this.doubtService.getSchedules(batchId, subjectId, query)
    );
  }

  async getSolutionTags(query?: any) {
    return await lastValueFrom(this.doubtService.getSolutionTags(query));
  }

  async updateDoubt(doubtId: string, toDelete: boolean, data?: any) {
    return await lastValueFrom(
      this.doubtService.updateDoubt(doubtId, toDelete, data)
    );
  }
}
