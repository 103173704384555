<section class="flex flex-col gap-5 items-center">
  <img [src]="cardData.image" alt="access-pending" />
  <span class="heading-4 bold">{{ cardData.heading }}</span>
  <span class="body-1 text-center">{{ cardData.subHeading }}</span>

  <ng-container *ngIf="payEnable">
    <section class="emi-card">
      <div class="left-bar"></div>
      <section class="emi-info">
        <div class="info-box">
          <div class="body-2">Missed Due Date</div>
          <div class="body-1 font-bold">
            {{ instalmentData.dueDate | date : 'dd MMM YYY' | uppercase }}
          </div>
        </div>

        <div class="info-box">
          <div class="body-2">Amount:</div>
          <div class="body-1 font-bold">
            ₹ {{ instalmentData.amount | number : '1.0-0' }}/-
          </div>
        </div>

        <div class="info-box">
          <div class="body-2">Instalment No.</div>
          <div class="body-1 font-bold">
            {{ instalmentData.installmentNumber | getOrdinalValue }}
          </div>
        </div>
      </section>
    </section>
  </ng-container>

  <button
    (click)="redirectToContactSupport()"
    [disabled]="isLoading$ | async"
    class="popup-cta body-1"
    [ngClass]="{
      'master-theme': isMasterBatch,
      dark: cardData.variant === 'dark',
      'opacity-40 cursor-not-allowed': (isLoading$ | async)
    }"
  >
    {{ payEnable ? 'Pay now' : 'Contact Support' }}
  </button>
</section>
