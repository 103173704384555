export class DateTime {
  value: string;

  constructor(value: string) {
    this.value = value;
  }
}
export class ExamDate {
  time: string;
  startAt: DateTime[];

  constructor(time: string, startAt: DateTime[]) {
    this.time = time;
    this.startAt = startAt;
  }
}
export class ExamResponse {
  name: string;
  dates: ExamDate[];
  message: string;
  field_errors: any[];
  error: boolean;

  constructor(
    name: string,
    data: ExamDate[],
    message: string,
    field_errors: any[],
    error: boolean
  ) {
    this.name = name || '';
    this.dates = data || [];
    this.message = message || '';
    this.field_errors = field_errors || [];
    this.error = error;
  }
}

export class FeeDetails {
  registrationFee: number;
  tutionFee: number;
  studyMatrial: number;
  scholarship?: string;

  constructor(
    registrationFee: number,
    tutionFee: number,
    studyMatrial: number,
    scholarship: string
  ) {
    this.registrationFee = registrationFee || 0;
    this.tutionFee = tutionFee || 0;
    this.studyMatrial = studyMatrial || 0;
    this.scholarship = scholarship || '';
  }
}

export class Installment {
  amount: number;
  emi: number;
  dueDate: string;
  nonDiscountAbleAmt: number;

  constructor(
    amount: number,
    emi: number,
    dueDate: string,
    nonDiscountAbleAmt: number
  ) {
    this.amount = amount || 0;
    this.emi = emi || 0;
    this.dueDate = dueDate || '';
    this.nonDiscountAbleAmt = nonDiscountAbleAmt || 0;
  }
}

export class FeeInstallment {
  data: {
    feeDetails: FeeDetails;
    installments: Installment[];
  };

  constructor(data: { feeDetails: FeeDetails; installments: Installment[] }) {
    this.data = data || {};
  }
}

export class PostFeeDetails {
  feeDetails: FeeDetails;
  installments: Installment[];
  constructor(data: any) {
    this.feeDetails = data.feeDetails || {};
    this.installments = data.installments || [];
  }
}

interface Center {
  name: string;
  cityId?: string;
  centerId: string;
}

export class CenterModel {
  name: string;
  cityId?: string;
  centerId: string;

  constructor(center: Center) {
    this.name = center.name;
    this.cityId = center.cityId;
    this.centerId = center.centerId;
  }
}
export class Classes {
  name: string;
  constructor(classes: PathShalaDepartment) {
    this.name = classes.name;
  }
}

interface PathShalaDepartment {
  name: string;
}

export class Departments {
  name: string;
  constructor(department: PathShalaDepartment) {
    this.name = department.name;
  }
}

interface CityData {
  name: string;
  _id: string;
  state: string;
}

export class Cities {
  name: string;
  _id: string;
  state: string;
  constructor(cities: CityData) {
    this.name = cities.name;
    this._id = cities._id;
    this.state = cities?.state;
  }
}

export class PathShalaDetails {
  centers: CenterModel[];
  departments: Departments[];
  classes: Classes[];
  courseDuration: Array<any>[];
  cities: Cities[];
  top_cities: Cities[];
  constructor(data: any) {
    this.centers = data.centers || [];
    this.departments = data.departments || [];
    this.classes = data.classes || [];
    this.courseDuration = data.courseDuration || [];
    this.cities = data.cities || [];
    this.top_cities = data.top_cities || [];
  }
}

export interface scholarship {
  scholarship: number;
  hasAttempted: boolean;
  isExpired: boolean;
  examMode: string;
  isUsed: boolean;
  isEligible: boolean;
  thresholdValue: number;
  isInterviewMandatory: boolean;
}

export class GetScholarship {
  scholarship: number;
  hasAttempted: boolean;
  isExpired: boolean;
  examMode: string;
  isUsed: boolean;
  isEligible: boolean;
  thresholdValue: number;
  isInterviewMandatory: boolean;
  constructor(data: scholarship) {
    this.thresholdValue = data?.thresholdValue || 0;
    this.isInterviewMandatory = data?.isInterviewMandatory || false;
    this.scholarship = data?.scholarship || 0;
    this.hasAttempted = data?.hasAttempted || false;
    this.isExpired = data?.isExpired || false;
    this.examMode = data?.examMode || '';
    this.isUsed = data?.isUsed || false;
    this.isEligible = data?.isEligible || false;
  }
}

/* programme dates */
export class Scheme {
  date: string;
  phase: string;
  schemeId: number;
  preferences: string[];

  constructor(
    date: string,
    phase: string,
    schemeId: number,
    preferences: string[]
  ) {
    this.date = date;
    this.phase = phase;
    this.schemeId = schemeId;
    this.preferences = preferences;
  }
}

export class ProgrammeDates {
  data: Scheme[];

  constructor(data: Scheme[]) {
    this.data = data || [];
  }
}

export interface DiscountCouponsDetails {
  discountExpiryDate: any;
  discountLabel: string;
  discountValue: number;
}

export class OrderDetails {
  centerName: string;
  course: string;
  classStartDate: string;
  duration: string;

  constructor(data: any) {
    this.centerName = data.centerName || '';
    this.course = data.course || '';
    this.classStartDate = data.classStartDate || '';
    this.duration = data.duration || '';
  }
}

export class UserSchemeData {
  schemeData: PostFeeDetails;
  orderId: string;
  batchId: string;
  discountLabel: string;
  discountValue: string;
  hasSatAttemped: boolean;
  scholoarship: number;
  selectedDiscount: string;
  orderDetails: OrderDetails;
  examMode: string;
  isInterviewMandatory: boolean;
  thresholdValue: any;
  couponDiscount: any;

  constructor(data: any) {
    this.schemeData = data.schemeData || {};
    this.orderId = data.orderId || '';
    this.batchId = data.batchId || '';
    this.discountLabel = data.discountLabel || '';
    this.discountValue = data.discountValue || '';
    this.hasSatAttemped = data.hasSatAttemped || false;
    this.scholoarship = data.scholoarship || 0;
    this.selectedDiscount = data.selectedDiscount || '';
    this.orderDetails = data.orderDetails || {};
    this.examMode = data.examMode || '';
    this.isInterviewMandatory = data.isInterviewMandatory || false;
    this.thresholdValue = data.thresholdValue || '';
    this.couponDiscount = data.couponDiscount || 0;
  }
}
export class BatchSchemeData {
  data: UserSchemeData;
  success: boolean;
  constructor(data: any) {
    this.data = data.data || {};
    this.success = data.success || false;
  }
}

export class DiscountDetails {
  name: string;
  amount: number;

  constructor(data: any) {
    this.name = data.name || '';
    this.amount = data.amount || 0;
  }
}

export interface AdmissionForm {
  _id: string;
  type: string;
  batchId: string;
  orderId: string;
  url: string;
  createdAt: string;
  __v: number;
  updatedAt: string;
}

export class AdmissionFormModel {
  _id: string;
  type: string;
  batchId: string;
  orderId: string;
  url: string;
  createdAt: string;
  __v: number;
  updatedAt: string;
  constructor(data: AdmissionForm) {
    this._id = data._id || '';
    this.type = data.type || '';
    this.batchId = data.batchId || '';
    this.url = data.url || '';
    this.createdAt = data.createdAt || '';
    this.__v = data.__v || 0;
    this.updatedAt = data.updatedAt || '';
  }
}

export interface DiscountObj {
  discountLabel: string;
  discountValue: any;
  selectedDiscount: string;
}

export interface VidyapeethDetails {
  cityName: string;
  centreName: string;
}

/* New API Model */
export class ProgramData {
  data: Program[];
  constructor(data: Program[]) {
    this.data = data;
  }
}

export class Program {
  year: string;
  data: ProgramInfo[];

  constructor(year: string, data: ProgramInfo[]) {
    this.year = year;
    this.data = data;
  }
}

export class ProgramInfo {
  date: string;
  phase: string;
  schemeId: number;
  preferences: string[];
  regFromDate: string;
  regToDate: string;
  year: any;

  constructor(
    date: string,
    phase: string,
    schemeId: number,
    preferences: string[],
    regFromDate: string,
    regToDate: string
  ) {
    this.date = date;
    this.phase = phase;
    this.schemeId = schemeId;
    this.preferences = preferences;
    this.regFromDate = regFromDate;
    this.regToDate = regToDate;
  }
}

export class AmountDetails {
  nonDiscountAbleAmt: number;
  discountAbleAmt: number;

  constructor(nonDiscountAbleAmt: number, discountAbleAmt: number) {
    this.nonDiscountAbleAmt = nonDiscountAbleAmt;
    this.discountAbleAmt = discountAbleAmt;
  }
}
export class FeeStructureDetail {
  label: string;
  amount: string;
  order: number;

  constructor(label: string, amount: string, order: number) {
    this.label = label;
    this.amount = amount;
    this.order = order;
  }
}
export class FeeInstallmentInfo {
  amountDetails: AmountDetails;
  feeDetails: FeeStructureDetail[];
  installments: Installment[];

  constructor(
    amountDetails: AmountDetails,
    feeDetails: FeeStructureDetail[],
    installments: Installment[]
  ) {
    this.amountDetails = amountDetails;
    this.feeDetails = feeDetails;
    this.installments = installments;
  }
}
