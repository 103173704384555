import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class MentorshipService {
  private mentorShipPage$: BehaviorSubject<IFrameMessageProps[]> =
    new BehaviorSubject<IFrameMessageProps[]>([]);
  constructor(private storageService: StorageService) {}

  setMentorShipPageArray(data: IFrameMessageProps[]) {
    this.mentorShipPage$.next(data);
    this.storageService.setMentorShipPageArray(data);
  }

  get getMentorShipPageArray() {
    const data = this.storageService.getMentorShipPageArray() || [];
    if (data.length > 0) {
      this.setMentorShipPageArray(data);
    }
    return this.mentorShipPage$;
  }

  removeMentorShipPageArray() {
    this.mentorShipPage$.next([]);
  }
}

export enum IFrameActions {
  CHILD_NAVIGATION = 'CHILD_NAVIGATION',
  PARENT_NAVIAGTION = 'PARENT_NAVIAGTION',
  LOCALSTORAGE = 'LOCALSTORAGE',
  CHILD_NAVIGATION_BACK = 'CHILD_NAVIGATION_BACK',
}

export enum ApplicationTypes {
  PW_MENTORSHIP = 'PW_MENTORSHIP',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: any;
  applicationType: ApplicationTypes;
}
