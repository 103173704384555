export enum SNACKBAR_MESSAGE {
  NO_CODE_PRESENT = 'No Code Present!',
  COPIED = 'Copied',
  NO_PREVIEW_AVAILABLE = 'No Preview Available',
  OK = 'OK',
  ALREADY_RATED = 'You have already given the rating',
  THANKS_FOR_RATING = 'Thanks for rating',
  THANKS_FOR_VOTING = 'Thanks for Voting',
  NO_END_TIME_FOUND = `No End Time found. Taking You Back...`,
  NO_VIDEO_URL_EXISTS = 'Video url does not exists',
  VIDEO_NOT_SUPPORTED = 'Video is not supported',
  HAPPY_TO_HELP = 'Happy to help you',
  SELECT_APPROPRIATE_CATEGORY = 'Please select the appropriate category',
  PROVIDE_ISSUE = 'Please provide the issue',
  VIDEO_REPORTED_SUCCESSFULLY = 'Video reported successfully',
  TRY_AGAIN = 'There was an error, Please Try Again!',
  VERIFY_ACCOUNT = 'Please Verify Your Account Number!',
  ACCOUNT_NUMBER_DOES_NOT_MATCH = 'Account number does not match!',
  ENTER_NUMBERS_ONLY = 'Please enter numbers only',
  CHECK_EMAIL = 'Please check your email!',
  VOUCHER_WILL_BE_EMAILED_TO_YOUR_ACCOUNT = 'Voucher will be emailed to your account!',
  SELF_FEEDBACK_NOT_ALLOWED = 'You cannot give feedback to your own comment',
  SELF_LIKE_NOT_ALLOWED = 'You cannot like your own comment',
  ALREADY_REPORTED_COMMENT = 'You have already reported this comment!',
  COMING_SOON = 'Coming soon...',
  TEACHER_DETAILS_DONT_EXIST = 'This teacher details does not exist!',

  // comments in video player section
  CANNOT_CHAT_ANY_MORE = 'You cannot chat anymore.',
  BLOCK_FROM_ACCESSING_CHAT = 'You are blocked from accessing chat',
  CHAT_CLOSED = 'The chat is closed',
  COMMENT_DELETED_SUCCESSFULLY = 'Comment deleted successfully',
  COMMENT_REPORTED_SUCCESSFULLY = 'Comment reported successfully. Thanks for reporting!!',
  ENTER_MESSAGE = 'Enter message',
  PLEASE_WAIT = 'Please Wait for 30 seconds...',
  MESSAGE_CANNOT_BE_BLANK_MESSAGE = 'Message cannot be blank',
  BEST_EXPERIENCE_MESSAGE = 'Please switch to Full Screen Landscape mode for best experience!',
  RESPONSE_SUBMITTED = 'Response submitted',
  POLL_HAS_ENDED_PLEASE_TRY_AGAIN_LATER = 'Poll has ended, Please try again next time!',
  NO_ANSWER_FOUND = 'No answer found',
  VIDEO_URL_DOES_NOT_EXIST = 'Video url does not exists',
  NO_VIDEO_FOUND = 'No Video Found',
  AVAILABLE_FOR_PREMEIUM_USERS = 'This feature is available only for premium users.',
  PLEASE_PROVIDE_THE_ISSUE = 'Please provide the Issue',
  YOU_CANNOT_CHAT_ANYMORE = 'You cannot chat anymore',
  MESSAGE_CANNOT_BE_BLANK = 'Message cannot be blank',
  CHAT_IS_CLOSED = 'Chat is closed',
  YOU_ARE_BLOCKED_FROM_ACCESSING_THE_CHAT = `You are blocked from accessing chat`,
  YOU_HAVE_BEEN_BLOCKED_BY_TEACHER = 'You have been blocked by teacher!!!',
  IMAGE_NOT_UPLOADED = 'Image Is Not Uploaded',
  PLEASE_UPLOAD_FILES = 'Please upload files',
  AUDIO_FILE_NOT_UPLOADED = 'Audio File Not Uploaded',
  YOU_HAVE_BEEN_BLOCKED = 'You have been blocked',
  FILE_FORMAT_NOT_SUPPORTED = 'File Format Not Supported',
  DOWNLOADING_FILES = 'Downloading file. Please check download section of your browser',
  COMMENT_ADDED_SUCCESSFULLY = 'Comment added successfully',

  //doubts
  DOUBT_CANNOT_BE_BLANK = 'Doubt cannot be blank',
  DOUBT_CANNOT_BE_CREATED = 'Doubt cannot be created',
  DOUBT_CREATED_CHECK_NOTIFICATION_PERMISSION = 'Doubt Created, Check notification permissions',
  DOUBT_IS_ALREADY_IN_LIST = 'This Doubt is already in your doubts!',
  YOU_HAVE_BEEN_BLOCKED_FROM_ASKING_DOUBT = 'You have been blocked from asking doubt',
  CANNOT_REPORT_OWN_DOUBT = 'You cannot report your own doubt!',
  THANKS_FOR_REPORTING = 'Thanks for Rerporting!',
  RESPONSE_RECORDED_SUCCESSFULLY = 'Response recorded successfully. Thanks!!',
  CANNOT_DELETE_USER_COMMENT = 'Cannot delete user comment!',
  CANNOT_GET_DOUBT_ANSWERS = 'Cannot get doubt Answers!',
  ONLY_IMAGES_ARE_ACCEPTED = 'Only images are accepted!',
  CANNOT_GIVE_FEEDBACK_TO_YOUR_OWN_SOLUTION = 'You cannot give feedback to your own solution!',
  CANNOT_REPORT_SAME_COMMENT_TWICE = 'You cannot report same comment twice!',
  NO_TEACHER_DATA_FOUND = 'No teacher data found!',
  CANNOT_LIKE_OWN_COMMENT = 'You cannot like your own comment!',
  PLEASE_PROVIDE_ISSUE = 'Please provide Issue!',
  SOMETHING_WENT_WRONG = 'Something went wrong',
  THANKS_FOR_YOUR_FEEDBACK = 'Thanks for your feedback',
  SOLUTION_DOWNLOADED_SUCCESSFULLY = 'Solution Downloaded Successfully.',
  PDF_NOT_AVAILABLE = 'Pdf not available',
  CHECK_YOUR_INTERNET_CONNECTION = 'Check your internet connection',
  THANKS_FOR_YOUR_RESPONSE = 'Thanks for your response',
  ENTER_SOMETHING_FIRST = 'Enter something first...',
  ERROR_POSTING_COMMENT = 'Error Posting Comment. Please try again',
  MENTOR_IS_NOT_AVAILABLE = 'Mentor will be assigned soon',
}

export enum ERROR_MESSAGE {
  TRY_AGAIN,
}
