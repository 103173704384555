import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PwCentresMentorshipRoutingModule } from './pw-centres-mentorship-routing.module';
import { PwCentresMentorshipComponent } from './pw-centres-mentorship.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PipesModule } from 'src/app/common/pipes/pipes.module';

@NgModule({
  declarations: [PwCentresMentorshipComponent],
  imports: [
    CommonModule,
    PwCentresMentorshipRoutingModule,
    PipesModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
  ],
})
export class PwCentresMentorshipModule {}
