import { Injectable } from '@angular/core';
import {
  catchError,
  debounceTime,
  map,
  retry,
  tap,
  BehaviorSubject,
  Subject,
  lastValueFrom,
  Observable,
} from 'rxjs';
import { ORGANIZATION_ID } from 'src/app/constants/app.constant';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { AppUrlService } from '../../app-url/app-url.service';
import { handleError } from '../error-handler/error-handler.service';
import { Metadata } from 'libphonenumber-js';
import {
  ActivePlanRootObject,
  ImageId,
  SaarthiSubjectRootObject,
} from './saarthi.model';
import { GoalProgressModel } from 'src/app/pages/saarthi/planner/planner.component';
import { StorageService } from '../storage/storage.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';

import { GlobalService } from '../global/global.service';
import { ModalComponent } from 'src/app/pages/saarthi/doubts/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { FaqModel, FaqModelObject } from 'src/app/pages/books/book.model';
import { ShowErrorService } from '../showError/show-error.service';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import { BatchService } from '../batch/batch.service';
import { Router } from '@angular/router';
import { SAARTHI_ROUTES } from 'src/app/constants/api-route.constants';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})

// SAARTHI
export class SaarthiService {
  openModal$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  iframeLoaded$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  displayMetaMsg$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  redirectedFromVideo$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  display$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  redirectDashboard$: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  isDeleteGoal$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  goalsAdded$: BehaviorSubject<any> = new BehaviorSubject<GoalProgressModel>(
    new GoalProgressModel({})
  );
  // isDeletedGoal$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  updateStudentprogress$: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );
  goalEdited$: BehaviorSubject<any> = new BehaviorSubject<GoalProgressModel>(
    new GoalProgressModel({})
  );
  goalListingFetch$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  goalData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  refreshListing$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  filterGoalBySubject$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  planPurchased$: BehaviorSubject<PlansModel> = new BehaviorSubject<PlansModel>(
    new PlansModel({})
  );
  askDoubtSearchList$: BehaviorSubject<Result[]> = new BehaviorSubject<
    Result[]
  >([]);
  planListing$: BehaviorSubject<PlansModel[]> = new BehaviorSubject<
    PlansModel[]
  >([]);
  unreadData$: BehaviorSubject<Array<UnreadConversation>> = new BehaviorSubject<
    Array<UnreadConversation>
  >([]);
  timeslot$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.storageService.getValue('SAARTHI_PREFERENCES', 'json')?.selectedSlot ||
      //   this.storageService.getValue('SAARTHI_PREFERENCES', 'json').saarthi_slot
      //     .end_time
      // || {}
      {}
  );
  default$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  sessionSidebarOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  slot$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selected$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selectedClass$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selectedExam$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selectedPlan$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  selectedSlot$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  saarthiId$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.storageService.getValue('SAARTHI_ID', 'string') || ''
  );
  finalTime$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  item$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  timeChange$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  isShown$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  sessionForFree$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  mathPixText$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  plannerWorkVideo: FaqModel;
  chatDoubtImageData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  // share goals array by subject
  shareGoalsData = new Subject();
  image: any;
  date: any = '';
  selectedAnswer: any;
  selectedQuestion: any;
  selectedVideoUrl: any;
  selectedVideoType: any;
  enterSearchText: any;
  startt_notation: string;
  endt_notation: string;
  final_time: any;
  startTime: any;
  endTime: number;
  SAARTHI_HOW_IT_WORkS_FAQ_ID: string = '63907804c36b7e02adcafc2f'; // dev 63887bee57f6ba0025b4f96e // prod 63907804c36b7e02adcafc2f
  _saarthiPreference$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.getPreferences()
  );
  constructor(
    private appUrl: AppUrlService,
    private apiService: PPApiService,
    private storageService: StorageService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    public dialog: MatDialog,
    private showErrorService: ShowErrorService,
    private globalService: GlobalService,
    private batchService: BatchService,
    private router: Router
  ) {}
  meta: Metadata;

  setActivePlan(plan: PlansModel) {
    this.planPurchased$.next(plan);
  }

  removeActivePlan() {
    this.planPurchased$.next(new PlansModel({}));
  }
  getPreferences() {
    const dataNeeded = this.storageService.getValue(
      'SAARTHI_PREFERENCES',
      'json'
    );
    return dataNeeded;
  }
  getRefinedTime(start: string[], end: string[]) {
    if (Number(start[0]) > 12) {
      this.startTime = Number(start[0]) - 12;
    } else {
      this.startTime = start[0];
      if (this.startTime.startsWith('00')) {
        this.startTime = '12';
      }
    }

    this.startt_notation = Number(start[0]) >= 12 ? 'Pm' : 'Am';
    if (Number(end[0]) > 12) {
      this.endTime = Number(end[0]) - 12;
    } else {
      this.endTime = Number(end[0]);
    }

    this.endt_notation = Number(end[0]) >= 12 ? 'Pm' : 'Am';
    this.final_time =
      this.startTime +
      ':' +
      start[1] +
      ' ' +
      this.startt_notation +
      ' to ' +
      this.endTime +
      ':' +
      end[1] +
      ' ' +
      this.endt_notation;
    return this.final_time;
  }
  getSlots(classes: any, exam: any, next_slot?: any): Observable<SlotModal[]> {
    let query: any = {};
    if (next_slot) {
      query['next_slot'] = next_slot;
    }
    const url = this.appUrl.SLOTS_DATA(classes, exam);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<SlotResponseModal>(options).pipe(
      map((res: SlotResponseModal) => {
        const data = res.data.map((item: SlotModal) => new SlotModal(item));
        return data;
      }),
      tap(),
      catchError(handleError)
    );
  }

  getSaarthiDetails(query: any) {
    const url = this.appUrl.SAARTHI_DETAIL() + `?saarthi_id=${query}`;
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  videoUrl() {
    const url = this.appUrl.GET_VIDEO_URL();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  getFaqs(catId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/faq-category/${catId}/list`,

      params: new PPApiParams().append('organizationId', ORGANIZATION_ID),
    };
    return this.apiService.get<FaqModelObject>(options).pipe(
      map((res) => res.data),
      tap()
    );
  }

  getFaqId() {
    return this.SAARTHI_HOW_IT_WORkS_FAQ_ID;
  }

  async howItWorkVideo(value: string, title: string) {
    title = title || '';
    const faqId = environment.SAARTHI_INRO_VIDEOS_FAQ_ID;
    let list: Array<FaqModel> = [];
    try {
      const res = await lastValueFrom(this.getFaqs(faqId));

      if (res) {
        list = res.map((item) => new FaqModel(item));
        this.plannerWorkVideo =
          list.find((o) => o.title === value) || new FaqModel({});
      }
    } catch (e) {
      this.showErrorService.showError(e);
    } finally {
      if (
        this.plannerWorkVideo?.videoUrl === '' ||
        !this.plannerWorkVideo?.videoUrl
      ) {
        this.globalService.showSnackBar(
          SNACKBAR_MESSAGE.NO_PREVIEW_AVAILABLE,
          SNACKBAR_MESSAGE.OK
        );
      } else {
        const videoObject = {
          embedCode: this.plannerWorkVideo?.videoUrl,
          name: title,
          type: this.plannerWorkVideo.videoType,
        };
        this.globalService.setVideoDetails(videoObject);
        this.batchService.setVideoBackUrl(this.router.url);
        this.router.navigate([SAARTHI_ROUTES.VIDEO_PLAYER], {
          queryParams: {
            type: this.plannerWorkVideo.videoType,
            cameFrom: 'saarthi-how-it-works',
          },
        });
      }
    }
  }
  // Saarthi Subjects
  getSaarthiSubjects(saarthiId: any) {
    const url = this.appUrl.SAARTHI_SUBJECTS(saarthiId);
    const options: PPApiOptions = {
      apiPath: url,
      // params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<SaarthiSubjectRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  //  Saarthi Active Plans
  getSaarthiActivePlans(saarthiId: any) {
    const url = this.appUrl.SAARTHI_ACTIVE_PLANS(saarthiId);
    const options: PPApiOptions = {
      apiPath: url,
      // params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<ActivePlanRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getFacultyDetails(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/users/${query.teacherId}/teacher-details`,
    };

    return this.apiService.get<FacultyRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getSolutionFeedback(question_id: string, query: any) {
    query = query || {};
    const url = this.appUrl.SOLUTION_FEEDBACK(question_id);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }

  postSolutionLikeCount(data: any) {
    const url = this.appUrl.SOLUTION_LIKE_COUNT();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post(data, options)
      .pipe(tap(), catchError(handleError));
  }

  enrollStudent(saarthiId: string, saarthiPlanId: any) {
    const url = this.appUrl.ENROLL_STUDENT(saarthiId, saarthiPlanId);
    const options: PPApiOptions = {
      apiPath: url,
      // params: new PPApiParams().appendAll(query),
    };

    return this.apiService
      .post({}, options)
      .pipe(tap(), catchError(handleError));
  }

  // Saarthi GetExams
  getExams() {
    const url = this.appUrl.EXAMS_DATA();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getPlans(saarthiId: string, query: any) {
    const url = this.appUrl.GET_PLANS(saarthiId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      retry(1),
      debounceTime(1000),
      catchError(handleError)
    );
  }
  //getSaarthi Orders
  createOrder(data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/orders`,
    };
    // return this.apiService.post(data, options).pipe(retry(2), debounceTime(1000), catchError(handleError));
    return this.apiService
      .post<any>(data, options)
      .pipe(tap(), catchError(handleError));
  }
  getStudentMapping(saarthiId: string, subjectId: string) {
    const url = this.appUrl.GET_STUDENT_MAPPING(saarthiId, subjectId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), debounceTime(1000), catchError(handleError));
  }

  // to get active Plan details - Exam Plan Modal

  getMySaarthiPlans() {
    const url = this.appUrl.GET_MY_SAARTHI_PLANS();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get<any>(options);
  }

  // get student progress
  getStudentProgress(obj: any) {
    const url = this.appUrl.GET_STUDENT_PROGRESS(obj);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get<any>(options);
  }

  // add goals
  addGoals(values: any) {
    const url = this.appUrl.ADD_GOALS();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.post<any>(values, options);
  }

  // edit goals
  editGoals(values: any, id: any) {
    const url = this.appUrl.EDIT_GOALS(id);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.put<any>(values, options);
  }

  // get student planner goal
  getStudentPlannerGoal(date: any) {
    const url = this.appUrl.GET_STUDENT_PLANNER_GOALS(date);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), debounceTime(1000), catchError(handleError));
  }

  setGoalsData(data: any) {
    this.shareGoalsData.next(data);
  }

  getGoalsData() {
    return this.shareGoalsData.asObservable();
  }

  // deleteStudentPlannerGoal
  deleteStudentPlannerGoal(id: any) {
    const url = this.appUrl.DELETE_STUDENT_PLANNER_GOALS(id);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.delete<any>(options);
  }

  updatePlannerCheckBox(values: any, id: any) {
    const url = this.appUrl.COMPLETE_PLANNER_STUDENT(id);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.put<any>(values, options);
  }

  getDoubtImage(imageUrl: any, id: any, saarthiId: string = '') {
    let params: any = {
      image_url: imageUrl,
      image_id: id,
    };
    if (saarthiId.length > 0) {
      params = { ...params, saarthi_id: saarthiId };
    }
    const url = this.appUrl.GET_ASK_DOUBT_IMAGE();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }

  getSignedUrl(name: any, extension: any, type: any) {
    const params = {
      name: name,
      extension: extension,
      type: type,
    };
    const url = this.appUrl.GET_SIGNED_URL();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }
  getDoubtText(text: any, saarthiId: string = '') {
    let params: any = {};
    if (saarthiId.length > 0) {
      params = { ...params, saarthi_id: saarthiId };
    }
    const url = this.appUrl.GET_DOUBT_TEXT();
    const options: PPApiOptions = {
      apiPath: `${url}?text=${text}`,
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }

  // Live-chat teacher online or not
  teacherOnlineOrNot(saarthId: string, teacherId: string) {
    const url = this.appUrl.TEACHER_ONLINE_OR_NOT(saarthId, teacherId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }

  async logEvent(eventName: string, data: any) {
    let saarthiPreference: any = JSON.parse(
      this.storageService.getSaarthiPreferences() || '{}'
    );
    if (
      saarthiPreference &&
      saarthiPreference.selectedClass &&
      saarthiPreference.selectedExam
    ) {
      data[
        'cohort_name'
      ] = `${saarthiPreference.selectedExam}-${saarthiPreference.selectedClass}`;
    }
    data['saarthi'] = await this.checkSaarthiType();

    this.firebaseAnalytics.logEvents(eventName, data);
  }

  async checkSaarthiType() {
    const res = await lastValueFrom(this.getMySaarthiPlans());
    if (res && res['data'] && res['data'].length > 0) {
      return 'paid';
    } else {
      return 'free';
    }
  }

  async getUnreadConversation(activePlanId: string) {
    const url = this.appUrl.GET_UNREAD_CONVERSATION(activePlanId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }

  getZoomMeetingUrl(query: any) {
    const url = this.appUrl.GET_ZOOM_MEET_URL();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options);
  }
  publicEnquiryTicket(data: any) {
    const url = this.appUrl.PUBLIC_TICKET_ENQUIRY;
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }
  getTags(data: any, organizationId: string) {
    const url = this.appUrl.GET_TAGS();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(data),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }
  getUnclearCount(conversationId: string) {
    const url = this.appUrl.UNREAD_CLEAR(conversationId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .put<any>({}, options)
      .pipe(tap(), retry(1), catchError(handleError));
  }

  async uploadFile(event: any, file: any) {
    if (file?.files?.length) {
      console.log(file);
      const fileTypes = ['jpg', 'png', 'jpeg'];
      const name =
        file.files[0].name.split('.')[file.files[0].name.split('.').length - 1];
      if (!fileTypes.includes(name.toLowerCase())) {
        this.globalService.showSnackBar('File format not supported!!');
        return false;
      }
      const size = file.files[0].size;
      if (size > 2097152) {
        this.globalService.showSnackBar(
          'Upload file size should be less than 2MB !!'
        );
        return false;
      }
    }
    return true;
  }
  async askImgModalOpen(event: any, file: any, cameFrom?: any, title?: string) {
    const dialogRef = this.dialog.open(ModalComponent, {
      disableClose: true,
      data: {
        fileEvt: event,
        file: file,
        cameFrom: cameFrom,
        modalTitle: title,
      },
      panelClass: 'image-dialog-modal',
    });
    dialogRef.afterClosed().subscribe((res) => {
      file = new File([], '');
      // return true;
      // this.fileInput.nativeElement.value = '';
    });
  }

  getPlannerSubjects(cohortId: string) {
    const url = this.appUrl.GET_PLANNER_SUBJECTS(cohortId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .get<any>(options)
      .pipe(retry(1), catchError(handleError));
  }
  getBusyCheck(query: any) {
    const url = this.appUrl.BUSY_CHECK();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }
  getOtherCoach(query: any) {
    const url = this.appUrl.FIND_OTHER_COACH();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }
  pushNotification(data: any) {
    const url = this.appUrl.NOTIFICATION_API();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  testQuestion(questionId: string, testMappingId: string) {
    const options: PPApiOptions = {
      apiPath: `v3/test-service/tests/questions/${questionId}/details`,
      params: new PPApiParams().append('testMappingId', testMappingId),
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }
  postDoubtSolutionInChat(data: any, conversation_id: string) {
    const url = this.appUrl.GET_DOUBT_SOLUTIONS_CHAT(conversation_id);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post(data, options)
      .pipe(tap(), catchError(handleError));
  }
  rateDoubtMessage(data: any) {
    let url = this.appUrl.POST_RATING();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  deleteMessage(conversation: string, chat: string, type: string) {
    const url = this.appUrl.DELETE_MESSAGE(conversation, chat);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().append('type', type),
    };
    return this.apiService.delete<any>(options);
  }

  // fetcher function for sessionisation session
  getStudentSessionList(conversationId: string, query: any) {
    const url = this.appUrl.GET_STUDENT_SESSION_LIST(conversationId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }

  // fetcher for marking doubt session of sessionisation
  markDoubtSession(sessionId: string, data: any) {
    let url = this.appUrl.MARK_SESSION(sessionId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .put<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  // fetcher for getting sessionisation sessions chat inside drawer
  getAllSession(conversationId: string, query: any) {
    const url = this.appUrl.GET_ALL_SESSIONS(conversationId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), retry(1), catchError(handleError));
  }

  // fetcher for marking all doubt session of sessionisation
  markAllSession(data: any) {
    const url = this.appUrl.MARK_ALL_SESSION();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .put<any>(data, options)
      .pipe(tap(), retry(1), catchError(handleError));
  }
}

export class PlansModel {
  _id: string;
  is_recommended: boolean;
  discount: number;
  months: number;
  duration: string;
  price: number;
  description: string;
  title: string;
  total: number;
  isSelected: boolean;
  is_extendable: boolean;
  is_expired: boolean;
  expiry: string;
  saarthi_slot: SaarthiSlot;
  saarthi_id: newSaarthiId;
  duration_type: string;
  imageId: ImageId;
  complementary_plans: Array<complementaryPlans> = [];
  expire_at: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.is_recommended = data.is_recommended || false;
    this.discount = data.discount || 0;
    this.months = data.duration || 0;
    this.duration_type = data.duration_type || '';
    if (this.months <= 1) {
      if (this.duration_type.toLowerCase().includes('month')) {
        this.duration = `${data.duration} Month`;
      } else {
        this.duration = `${data.duration} Day`;
      }
    } else {
      if (this.duration_type.toLowerCase().includes('month')) {
        this.duration = `${data.duration} Months`;
      } else {
        this.duration = `${data.duration} Days`;
      }
    }
    this.price = data.price || 0;
    this.description = data.description || '';
    this.title = data.title || '';
    this.total = data.price * (1 - this.discount / 100);
    this.expiry = data.expiry_date || '';
    this.is_extendable = data.is_extendable || false;
    this.is_expired = data.is_expired || false;
    this.isSelected = data.isSelected || false;
    this.saarthi_id = new newSaarthiId(data.saarthi_id || {});
    this.saarthi_slot = new SaarthiSlot(data.saarthi_slot || {});
    this.imageId = data.image_id || {};
    this.expire_at = data.expire_at || '';
    if (data.complementary_plans) {
      data.complementary_plans.forEach((el: any) => {
        this.complementary_plans.push(new complementaryPlans(el));
      });
    }
  }
}
export class newSaarthiId {
  _id: string;
  exams: string[];
  classes: string[];
  saarthi_slot: SaarthiSlot;
  constructor(data: any) {
    this._id = data._id || '';
    this.saarthi_slot = new SaarthiSlot(data.saarthi_slot || {});
    this.exams = data.exams || [];
    this.classes = data.classes || [];
  }
}

export interface FacultyRootObject {
  success: boolean;
  data: FacultyData;
}
export interface FacultyImageId {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
  __v: number;
}
export class FacultyData {
  tags: string[];
  _id: string;
  firstName: string;
  lastName: string;
  imageId: FacultyImageId;
  featuredLine: string;
  qualification: string;
  about: string;
}

// Saarthi Details

export class MentorsMetadata {
  college: string;
  name: string;
  image_details: FileModal;
  constructor(data: any) {
    this.college = data.college || '';
    this.name = data.name || '';
    this.image_details = new FileModal(data.image_id);
  }
}

export class VideoDetails {
  name: string;
  image: string;
  embedCode: string;
  description: string;
  duration: string;
  _id: string;
  id: string;
  videoUrl: string;
  status: string;
  types: string[];
  hls_url: string;
  vimeoId: string;
  embed_code: string;

  constructor(data: any) {
    this.name = data.name || '';
    this.image = data.image || '';
    this.videoUrl = data.video_url || '';
    this.duration = data.duration || '';
    this.status = data.status || '';
    this.id = data.id || '';
    this.types = data.types || [];
    this.vimeoId = data.vimeoId || '';
    this.hls_url = data.hls_url || '';
    this.embedCode = data.embedCode || '';
    this.description = data.description || '';
    this._id = data._id || '';
    this.embed_code = data.embed_code || '';
  }
}

export class SaarthiSubjectMentor {
  _id: string;
  image_id: FileModal;
  first_name: string;
  last_name: string;
  full_name: string;
  user_id: string;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.image_id = new FileModal(data.image_id || {});
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.full_name = data.first_name + ' ' + data.last_name;
    this.user_id = data.user_id || '';
  }
}

export interface LabelModel {
  description: string;
  title: string;
  image_details: FileModal;
}

export class SaarthiDetails {
  _id: string;
  faq_cat_id: string;
  mentors_metadata: Array<MentorsMetadata> = [];
  video_type: string;
  video_url: string;
  enable_planner: boolean;
  enable_concept: boolean;
  enable_doubt: boolean;
  description: string;
  title: string;
  why_saarthi: LabelModel[];
  how_saarthi: LabelModel[];
  subjects: Array<SaarthiSubjectModel> = [];
  saarthi_slot: SaarthiSlot;
  constructor(detail: any) {
    const data = detail || {};
    this._id = data._id || '';
    this.faq_cat_id = data.faq_cat_id || '';
    if (data.mentors_metadata) {
      data.mentors_metadata.forEach((el: any) => {
        this.mentors_metadata.push(new MentorsMetadata(el));
      });
    }

    // this.mentors_metadata = data.mentors_metadata || [];
    this.why_saarthi = data.label_2 || [];
    this.video_type = data.video_type || [];
    this.how_saarthi = data.label_1 || [];

    this.enable_planner = data.enable_planner || '';
    this.enable_concept = data.enable_concept || '';
    this.enable_doubt = data.enable_doubt || '';

    this.description = data.description || '';
    if (data.subjects) {
      data.subjects.forEach((el: any) => {
        this.subjects.push(new SaarthiSubjectModel(el));
      });
    } else {
      this.subjects = [];
    }
    this.title = data.title || '';
    this.saarthi_slot = new SaarthiSlot(data.saarthi_slot || {});
  }
}

// Exams

export class LabelData {
  description: string;
  imageId: Array<labelImgData> = [];
  title: string;

  constructor(data: any) {
    this.description = data.description;
    this.title = data.title;
    if (data && data.imageId && data.imageId.length > 0) {
      const imageData: Array<labelImgData> = [];
      data.imageId.forEach((el: labelImgData) => {
        imageData.push(new labelImgData(el));
      });
      this.imageId = [...this.imageId, ...imageData];
    }
  }
}

export class labelImgData {
  base_url: string;
  key: string;
  name: string;
  _id: string;

  constructor(data: any) {
    this.base_url = data.base_url;
    this.key = data.key;
    this.name = data.name;
    this._id = data.id;
  }
}

export class Exam {
  static replace(arg0: RegExp, arg1: string): any {
    throw new Error('Method not implemented.');
  }
  exams: string;
  // saarthi_ids: Array<SaarthiId> = [];
  classes: Array<string> = [];
  constructor(data: any) {
    this.exams = data.exam;
    // this.saarthi_ids = data.saarthi_ids;
    this.classes = data.classes || '';
  }
}

export class TeacherModel {
  firstName: string;
  lastName: string;
  imageId: FileModal;
  sub: string;
  _id: string;
  featuredLine: string;
  tags: Array<String> = [];
  about: string;
  qualification: string;

  constructor(props: any) {
    props = props || {};
    this._id = props._id || '';
    this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    this.sub = props.subName || '';
    if (props && props.imageId) {
      this.imageId = new FileModal(props.imageId) || new FileModal({});
    } else {
      this.imageId = new FileModal({});
    }
    this.about = props.about || '';
    this.qualification = props.qualification || '';
    if (props && props.tags && props.tags.length > 0) {
      this.tags = props.tags;
    }
    this.featuredLine = props.featuredLine || '';
  }
}

export class SaarthiId {
  classes: Array<string> = [];
  _id: any;
  constructor(data: any) {
    this.classes = data.classes;
    this._id = data._id;
  }
}

export class EnableCDP {
  enable_planner: boolean;
  enable_concept: boolean;
  enable_doubt: boolean;
  constructor(data: any) {
    this.enable_concept = data.enable_concept || '';
    this.enable_doubt = data.enable_doubt || '';
    this.enable_planner = data.enable_planner || '';
  }
}

export class SaarthiSubjectModel {
  _id: string;
  display_order: number;
  image_id: FileModal;
  description: string;
  name: string;

  constructor(data: any) {
    this._id = data.slug || data._id || '';
    this.display_order = data.display_order || '';
    this.image_id = new FileModal(data.image_id);
    this.description = data.description || '';
    this.name = data.name || 'Subject';
  }
}

export class StudentMapping {
  is_chat_blocked: boolean;
  is_blocked: boolean;
  is_free: boolean;
  conversation_id?: any;
  subject_id: SaarthiSubject;
  mentor_id: SaarthiSubjectMentor;
  other_coach_message: string;
  enable_ask_doubt_in_chats: boolean;
  saarthi_slot: SaarthiSlot;
  sessionisation_sidebar_time_in_seconds: number;
  enable_sessionisation: boolean;
  disable_video_icon: boolean;
  constructor(data: any) {
    data = data || {};
    this.is_chat_blocked = data.is_chat_blocked || false;
    this.is_free = data.is_free || false;
    this.conversation_id = data.conversation_id || '';
    this.subject_id = new SaarthiSubject(data.subject_id || {});
    this.mentor_id = new SaarthiSubjectMentor(data.mentor_id || {});
    this.other_coach_message = data.other_coach_message || '';
    this.is_blocked = data.is_blocked || false;
    this.enable_ask_doubt_in_chats = data.enable_ask_doubt_in_chats || false;
    this.saarthi_slot = new SaarthiSlot(data.saarthi_slot || {});
    this.sessionisation_sidebar_time_in_seconds =
      data.sessionisation_sidebar_time_in_seconds || 15 * 60;
    this.enable_sessionisation = data.enable_sessionisation || false;
    this.disable_video_icon = data.disable_video_icon || false;
  }
}
export class SaarthiSubject {
  name: string;
  _id: string;
  constructor(data: any) {
    this._id = data._id || '';
    this.name = data.name || '';
  }
}

class FileModal {
  base_url: string;
  key: string;
  name: string;
  _id: string;
  url: string;
  constructor(data: any) {
    data = data || {};
    this.base_url = data.base_url || '';
    this.key = data.key || '';
    this.name = data.name || '';
    this._id = data._id || '';
    this.url = this.base_url + this.key;
  }
}

// Ask Concepts
export class SuggestiveContent {
  _id: string;
  name: string;
  text: string;
  score: number;

  constructor(data: any) {
    this._id = data._id || '';
    this.name = data.name || '';
    this.text = data.text || '';
    this.score = data.score || '';
  }
}

export class ContentResult {
  _id: string;
  title: string;
  content: Array<ContentData> = [];
  imgUrl: string;
  subject: string;

  constructor(data: any) {
    this._id = data._id || '';
    this.title = data.title || '';
    this.subject = data.subject || '';
    if (data.content) {
      data.content.forEach((el: any) => {
        this.content.push(new ContentData(el));
      });
    }
  }
}

class ContentData {
  _id: string;
  text: string;
  fileId: string;
  timeline: Array<Timeline> = [];
  constructor(data: any) {
    this._id = data._id || '';
    this.text = data.text || '';
  }
}

class Timeline {}

export class ContentDetailModel {
  _id: string;
  text: string;
  videoType: string;
  videoDetails: VideoDetails;
  constructor(data: any = {}) {
    this._id = data._id || '';
    this.text = data.text || '';
    this.videoType = data.videoType || '';
    this.videoDetails = new VideoDetails(data.video_details || {});
  }
}
export class SaarthiActiveId {
  _id: string;
  classes: Array<string> = [];
  exams: Array<string> = [];
  saarthi_slot: SaarthiSlot;
  constructor(data: any) {
    this._id = data._id;
    this.classes = data.classes;
    this.exams = data.exams;
    this.saarthi_slot = new SaarthiSlot(data.saarthi_slot);
  }
}

export class SaarthiPlanId {
  _id: string;
  duration: number;
  title: string;
  description: string;
  constructor(data: any) {
    this._id = data._id || '';
    this.duration = data.duration;
    this.title = data.title || '';
    this.description = data.description || '';
  }
}

export class ExamPlanModal {
  _id: string;
  expiry_date: string;
  saarthi_id: SaarthiActiveId;
  saarthi_plan_id: SaarthiPlanId;
  constructor(data: any) {
    this._id = data._id || '';
    this.expiry_date = data.expiry_date || '';
    this.saarthi_id = new SaarthiActiveId(data.saarthi_id || {});
    this.saarthi_plan_id = new SaarthiPlanId(data.saarthi_plan_id || {});
  }
}
// Ask Doubt

export class Result {
  _id: string;
  question_id: string;
  score: number;
  solutions: Array<TextSolution> = [];
  question_text: string;
  fuzz_ratio: number;
  constructor(data: any = {}) {
    this._id = data._id || '';
    this.question_id = data.question_id || '';
    this.score = data.score || '';
    if (data.solutions) {
      data.solutions.forEach((el: any) => {
        this.solutions.push(new TextSolution(el));
      });
    }
    this.question_text = data.question_text || '';
    this.fuzz_ratio = data.fuzz_ratio || '';
  }
}

export class TextSolution {
  default: DoubtDefault;
  constructor(data: any = {}) {
    this.default = new DoubtDefault(data.default || {});
  }
}

export class DoubtDefault {
  text: string;
  video_solution: VideoSolution;
  other_solution: string;
  constructor(data: any = {}) {
    this.text = data.text || '';
    this.video_solution = new VideoSolution(data.video_solution || {});
    this.other_solution = data.other_solution || '';
  }
}

export class VideoSolution {
  url_type: number;
  url: string;
  video_details: VideoDetails;
  constructor(data: any = {}) {
    this.url_type = data.url_type || '';
    this.url = data.url || '';
    this.video_details = new VideoDetails(data.video_details || {});
  }
}
export class Solution {
  default: DoubtDefault;
  constructor(data: any = {}) {
    this.default = new DoubtDefault(data.default || {});
  }
}
export class AskDoubtImage {
  _id: string;
  question_id: string;
  score: number;
  solutions: Array<Solution> = [];
  constructor(data: any = {}) {
    this._id = data._id || '';
    this.question_id = data.question_id || '';
    this.score = data.score || '';
    if (data.solutions) {
      data.solutions.forEach((el: any) => {
        this.solutions.push(new Solution(el));
      });
    }
  }
}
export class SignedUrl {
  uploadUrl: string;
  _id: string;
  name: string;
  key: string;
  baseUrl: string;
  constructor(data: any = {}) {
    this.uploadUrl = data.uploadUrl;
    this._id = data._id;
    this.name = data.name;
    this.key = data.key;
    this.baseUrl = data.baseUrl;
  }
}

export class AskDoubtText {
  result: Array<Result> = [];
  mathpix_text: string;
  constructor(data: any = {}) {
    if (data.result) {
      data.result.forEach((el: any) => {
        this.result.push(new Result(el));
      });
    }
    this.mathpix_text = data.mathpix_text || '';
  }
}

// Live Check
export class LiveCheck {
  is_live: boolean;
  message: string;
  constructor(data: any) {
    this.is_live = data.is_live || '';
    this.message = data.message || '';
  }
}
export class UnreadConversation {
  conversation_id: string;
  mentor_id: string;
  subject_id: string;
  unread_count: number;
  constructor(data: any) {
    this.conversation_id = data.conversation_id || '';
    this.mentor_id = data.mentor_id || '';
    this.subject_id = data.subject_id || '';
    this.unread_count = data.unread_count || 0;
  }
}
export class complementaryPlans {
  _id: string;
  description: string;
  title: string;
  duration: number;
  saarthi_id: saarthiId;
  constructor(data: any) {
    this._id = data._id || '';
    this.duration = data.duration || '';
    this.title = data.title || '';
    this.saarthi_id = new saarthiId(data.saarthi_id || {});
    this.description = data.description || '';
  }
}
export class saarthiId {
  _id: string;
  saarthi_slot: SaarthiSlot;
  constructor(data: any) {
    this._id = data._id || '';
    this.saarthi_slot = new SaarthiSlot(data.saarthi_slot || {});
  }
}

export class SaarthiSlot {
  start_time: string;
  end_time: string;
  constructor(data: any) {
    this.start_time = data.start_time || '';
    this.end_time = data.end_time || '';
  }
}
export class SlotResponseModal {
  success: boolean;
  data: SlotModal[];
  constructor(props: any = {}) {
    this.success = props.success || false;
    if (props && props.data) {
      this.data = props.data.map((item: SlotModal) => new SlotModal(item));
    } else {
      this.data = [];
    }
  }
}
export class SlotModal {
  start_time: string;
  end_time: string;
  saarthi_id: string;
  next_slot: boolean;
  constructor(data: any) {
    this.start_time = data.start_time || '';
    this.end_time = data.end_time || '';
    this.saarthi_id = data.saarthi_id || '';
    this.next_slot = data.next_slot || false;
  }
}

export class Busy {
  value: boolean;
  message: string;
  learners: number;
  constructor(data: any = {}) {
    this.value = data.value || false;
    this.message = data.message || '';
    this.learners = data.learners || 0;
  }
}

export class Break {
  value: boolean;
  message: string;
  end_time: string;
  is_absent: boolean;
  constructor(data: any = {}) {
    this.value = data.value || false;
    this.message = data.message || '';
    this.end_time = data.end_time || '';
    this.is_absent = data.is_absent || false;
  }
}

export class Live {
  value: boolean;
  message: string;
  constructor(data: any = {}) {
    this.value = data.value || false;
    this.message = data.message || '';
  }
}

export class BusyCheck {
  busy: Busy;
  break: Break;
  live: Live;
  is_planned_session: boolean;
  planned_session_end_time: string;
  session_id: string;
  constructor(data: any = {}) {
    this.planned_session_end_time = data.planned_session_end_time || '';
    this.is_planned_session = data.is_planned_session || false;
    this.busy = new Busy(data.busy || {});
    this.break = new Break(data.break || {});
    this.live = new Live(data.live || {});
    this.session_id = data.session_id || '';
  }
}

export class SessionisationStudentData {
  teacher_resolved_count: number;
  session_details: StudentSession[];
  constructor(data: any) {
    data = data || {};
    this.teacher_resolved_count = data.teacher_resolved_count || 0;
    if (data.session_details) {
      this.session_details = data.session_details.map(
        (item: StudentSession) => new StudentSession(item)
      );
    } else {
      this.session_details = [];
    }
  }
}
export class StudentSession {
  _id: string;
  conversation_id: string;
  start_time: string;
  current_status: string;
  chat_id: SessionisationChat[];
  chat_type: string;
  last_teacher_status: string;
  last_teacher_updated: string;
  last_updated_by: string;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.conversation_id = data.conversation_id || '';
    this.start_time = data.start_time || '';
    this.current_status = data.current_status || '';
    this.chat_id = data.chat_id || '';
    this.chat_type = data.chat_type || '';
    this.last_teacher_status = data.last_teacher_status || '';
    this.last_teacher_updated = data.last_teacher_updated;
    this.last_updated_by = data.last_updated_by || '';
  }
}

export class SessionisationChat {
  meta: Meta;
  _id: string;
  senderInfo: SenderInfo;
  senderName: string;
  text: string;
  type: string;
  conversationId: string;
  organizationId: string;
  pinnedBy: any[];
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  audioId: ImageId;
  fileId: ImageId;
  imageId: ImageId;
  constructor(data: any) {
    data = data || {};
    this.meta = new Meta(data.meta || {});
    this._id = data.id || '';
    this.senderInfo = new SenderInfo(data.senderInfo || {});
    this.senderName = data.senderName || '';
    this.text = data.text || '';
    this.type = data.type || '';
    this.conversationId = data.conversationId || '';
    this.organizationId = data.organizationId || '';
    this.pinnedBy = data.pinnedBy || [];
    this.status = data.status || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.__v = data.__v || 0;
    this.audioId = new ImageId(data.audioId || {});
    this.fileId = new ImageId(data.fileId || {});
    this.imageId = new ImageId(data.imageId || {});
  }
}

export class SenderInfo {
  _id: string;
  username: string;
  imageId: ImageId;
  constructor(data: any) {
    this._id = data._id || '631b1f11c8b30c00117c50de';
    this.username = data.username || '';
    this.imageId = new ImageId(data.imageId || {});
  }
}
export class Meta {
  deviceInfo: DeviceInfo;
  text: any;
  imageId: ImageId;
  anchorTags: any;
  htmlString: string;
  batch: string;
  batchtitle: string;
  testTitle: string;
  testName: string;
  type: string;
  recommendationLevel: string;
  solution: SessionResult;
  rating: string;
  isMoreSolutionAvailable: string;
  constructor(data: any) {
    data = data || {};
    this.deviceInfo = new DeviceInfo(data.deviceInfo || {});
    this.text = data.text || '';
    this.imageId = new ImageId(data.imageId) || new ImageId({});
    this.type = data.type || '';
    this.recommendationLevel = data.recommendationLevel || '';
    this.solution = new SessionResult(data.solution) || new SessionResult({});
    this.rating = data.rating || '';
    this.isMoreSolutionAvailable = data.isMoreSolutionAvailable || '';
  }
}
export class SessionResult {
  _id: string;
  question_id: string;
  score: number;
  solutions: SessionDoubtDefault;
  question_text: string;
  fuzz_ratio: number;
  category_configuration_id: string;
  is_ratio_enabled: string;
  constructor(data: any = {}) {
    this._id = data._id || '';
    this.question_id = data.question_id || '';
    this.score = data.score || '';
    this.solutions = new SessionDoubtDefault(data.solutions || {});
    this.question_text = data.question_text || '';
    this.fuzz_ratio = data.fuzz_ratio || '';
  }
}

export class SessionDoubtDefault {
  text: string;
  video_solution: VideoSolution;
  other_solution: string;
  constructor(data: any = {}) {
    this.text = data.text || '';
    this.video_solution = new VideoSolution(data.video_solution || {});
    this.other_solution = data.other_solution || '';
  }
}

export class DeviceInfo {
  clientType: string;
  clientVersion: string;
  constructor(data: any) {
    data = data || {};
    this.clientType = data.clientType || '';
    this.clientVersion = data.clientVersion || '';
  }
}

export class SessionSummary {
  _id: string;
  conversation_id: string;
  status: string;
  created_by: string;
  start_time: string;
  current_status: string;
  history: any[];
  chat_id: string[];
  chat_type: string;
  assigned_teacher: string;
  created_at: string;
  updated_at: string;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.conversation_id = data.conversation_id || '';
    this.status = data.status || '';
    this.created_by = data.created_by || '';
    this.start_time = data.start_time || '';
    this.current_status = data.current_status || '';
    this.history = data.history || [];
    this.chat_id = data.chat_id || [];
    this.chat_type = data.chat_type || '';
    this.assigned_teacher = data.assigned_teachers || '';
    this.created_at = data.created_at || '';
    this.updated_at = data.updated_at || '';
  }
}

export enum IFrameActions {
  CHILD_NAVIGATION = 'CHILD_NAVIGATION',
  PARENT_NAVIAGTION = 'PARENT_NAVIAGTION',
  PLAY_VIDEO = 'PLAY_VIDEO',
  TEACHER_CONNECT = 'TEACHER_CONNECT',
  PLANNER_REDIRECT = 'PLANNER_REDIRECT',
  REFER_EARN_REDIRECT = 'REFER_EARN_REDIRECT',
  PLAN_REDIRECT = 'PLAN_REDIRECT',
  CLIPBOARD_COPY = 'CLIPBOARD_COPY',
  SHARE_WHATSAPP = 'SHARE_WHATSAPP',
  SHARE_EMAIL = 'SHARE_EMAIL',
  SET_SAARTHI_DETAILS = 'SET_SAARTHI_DETAILS',
  ACTIVE_SAARTHI_PLAN = 'ACTIVE_SAARTHI_PLAN',
  SELECTED_CLASS_EXAM = 'SELECTED_CLASS_EXAM',
  IFRAME_LOADED = 'IFRAME_LOADED',
}

export enum ApplicationTypes {
  PW_CENTRES = 'PW_CENTRES',
  PW_TEST_SERIES = 'PW_TEST_SERIES',
  PW_SAARTHI = 'PW_SAARTHI',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: any;
  applicationType: ApplicationTypes;
}
