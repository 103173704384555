<div>
  <div class="px-4 py-4 border-b justify-between flex items-center">
    <ng-container *ngIf="isTitleValid(); else fallback">
      <span class="heading-4 bold" [innerHtml]="sanitizedHtmlTitle"></span>
    </ng-container>
    <mat-icon (click)="closeSuccessModal()" class="cursor-pointer"
      >close</mat-icon
    >

    <ng-template #fallback>
      <span class="heading-4 bold">Submitted Form Successfully 🥳</span>
    </ng-template>
  </div>
  <div class="flex flex-col justify-center items-center px-4 py-4">
    <div>
      <ng-lottie
        height="120px"
        width="120px"
        [options]="lottieOptions"
      ></ng-lottie>
    </div>
    <div
      *ngIf="isContentValid(); else defaultContent"
      class="font-semibold text-base leading-6 tracking-normal text-center px-4"
      [innerHTML]="sanitizedHtmlContent"
    ></div>

    <ng-template #defaultContent>
      <div
        class="font-semibold text-base leading-6 tracking-normal text-center px-4"
      >
        Thank you for submitting your details!
      </div>
    </ng-template>
    <button
      *ngIf="
        data.ctaText !== undefined &&
        data.ctaText !== null &&
        data.url.link !== null &&
        data.url.type !== null
      "
      class="bg-indigo-600 rounded-md hover:bg-blue-600 text-white font-semibold py-2 px-4 w-full flex items-center justify-center"
      (click)="submitSuccessModal()"
    >
      <img src="assets/vector-cross/Frame.svg" alt="Icon" class="mr-2" />
      <span>{{ data.ctaText }}</span>
    </button>
  </div>
</div>
