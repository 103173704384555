<div
  class="flex flex-col rounded-lg bg-cover"
  [style.background-image]="'url(' + foundationInfoBG + ')'"
>
  <div class="flex justify-between items-center mb-2 pb-1 sm:mb-4 sm:pb-2">
    <div class="w-[120px] h-12">
      <img [src]="pwFoundationLogo" alt="PW FOUNDATION LOGO" class="" />
    </div>
    <div
      class="flex items-center justify-end cursor-pointer"
      (click)="closeDialog()"
    >
      <img [src]="crossIcon" alt="PW FOUNDATION LOGO" class="" />
    </div>
  </div>

  <hr class="mb-3" />
  <div
    class="flex flex-col justify-center items-center sm:justify-start sm:items-start"
  >
    <div class="text-neutral-800 sm:text-2xl text-lg font-bold leading-loose">
      Donate for PW Foundation
    </div>
    <div
      class="text-center sm:text-left text-neutral-700 text-sm sm:text-lg font-semibold leading-7"
    >
      PW Foundation empowers lives through supporting marriages financially of
      needy people, advancing education of children, and promoting healthcare in
      underserved communities. Donate to support the cause.
    </div>
  </div>
  <div
    class="flex flex-row gap-[6px] items-center my-3 justify-center sm:justify-start h-9"
  >
    <img [src]="fomoIcon" alt="FOMO icon" class="w-15 h-6 md:w-20 md:h-9" />
    <div class="text-xs sm:text-sm">
      <span class="font-bold">58K+</span> beneficiaries
    </div>
  </div>
  <div
    class="flex flex-row gap-3 sm:gap-1 flex-wrap justify-center items-center sm:justify-start sm:items-start"
  >
    <div
      class="flex flex-row p-2 rounded-lg border border-[#EFEFEF] gap-2 items-center"
    >
      <img
        [src]="educationIcon"
        alt="Education Icon"
        class="h-6 w-6 sm:h-9 sm:w-9"
      />
      <span
        class="text-neutral-800 text-[8px] md:text-sm font-semibold leading-tight"
        >EDUCATION</span
      >
    </div>
    <div
      class="flex flex-row p-2 rounded-lg border border-[#EFEFEF] gap-2 items-center"
    >
      <img
        [src]="healthcareIcon"
        alt="health icon"
        class="h-6 w-6 sm:h-9 sm:w-9"
      />
      <span
        class="text-neutral-800 text-[8px] md:text-sm font-semibold leading-tight"
        >HEALTHCARE</span
      >
    </div>
    <div
      class="flex flex-row p-2 rounded-lg border border-[#EFEFEF] gap-2 items-center"
    >
      <img
        [src]="empowermentIcon"
        alt="Empowerment Icon"
        class="h-6 w-6 sm:h-9 sm:w-9"
      />
      <span
        class="text-neutral-800 text-[8px] md:text-sm font-semibold leading-tight"
        >EMPOWERMENT</span
      >
    </div>
  </div>
  <hr class="mt-2 sm:mt-4" />
  <div class="mt-2 sm:mt-4">
    <div
      class="text-neutral-500 text-[8px] sm:text-xs font-medium leading-[18px]"
    >
      Donations made by Indian taxpayers are eligible for tax deduction under
      section 80G of the Income Tax Act of 1861 as per the applicability. The
      required details for the issuance of requisite Form 10BE (Donation
      certificate) including but not limited to PAN details may be collected on
      behalf of PW Foundation.
    </div>
  </div>
</div>
