<article class="loading-container">
  <mat-toolbar>
    <div class="flex items-center gap-5 w-full">
      <img src="assets/icons/logo.png" width="38" height="38" alt="" />
      <span class="body-1 bold">PhysicsWallah</span>
    </div>
  </mat-toolbar>

  <ng-lottie
    width="500px"
    [options]="loading"
    class="mx-auto"
    containerClass="coming-soon-lottie"
  ></ng-lottie>

  <div class="flex items-center gap-2">
    <span class="bold heading-2">Customizing</span
    ><img src="assets/batches/pw.svg" alt="" /><span class="bold heading-2"
      >web as per your selection...</span
    >
  </div>
</article>
