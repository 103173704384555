<ng-container *ngIf="processing && !iframeLoaded; else batchPlusIframe">
  <div class="h-full w-full loading-screen flex items-center justify-center">
    <div class="loader"></div>
  </div>
</ng-container>

<ng-template #batchPlusIframe>
  <iframe
    allow="clipboard-read; clipboard-write; accelerometer; autoplay;fullscreen; encrypted-media; gyroscope; picture-in picture "
    #batchPlus
    id="batchPlus"
    class="responsive-iframe"
    (load)="checkLoaded($event, batchPlus)"
    [src]="url | safe : 'resourceUrl'"
  ></iframe>
</ng-template>
