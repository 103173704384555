import { Component, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  AllInstalmentData,
  EMIInVoiceData,
  Instalment,
  Invoice,
} from 'src/app/common/services/batch/batch.modal';
import { PartPaymentService } from 'src/app/common/services/part-payment/part-payment.service';
import {
  CLOSE_OVERLAY,
  CROSS_PW,
  DOWNLOAD_DISABLED,
  DOWNLOADING,
} from 'src/app/core/assets.location';
import { BatchDetailModel } from '../../../batch-overview/batch-overview.model';
import { BatchService } from 'src/app/common/services/batch/batch.service';

const FileSaver = require('file-saver');
@Component({
  selector: 'app-emi-breakup-table',
  templateUrl: './emi-breakup-table.component.html',
  styleUrls: ['./emi-breakup-table.component.scss'],
})
export class EmiBreakupTableComponent implements OnInit {
  crossIcon = CROSS_PW;
  download = DOWNLOADING;
  downloadDisabled = DOWNLOAD_DISABLED;
  close = CLOSE_OVERLAY;
  orderId: string = '';
  isProcessing: boolean = true;
  invoiceData: EMIInVoiceData;
  instalmentNumberToPay: number = 0;
  batchDetails: BatchDetailModel = new BatchDetailModel({});
  _isLoading$ = new BehaviorSubject<boolean>(false);
  isMasterBatch: boolean = false;
  shouldDisable: boolean = false;
  allEmisPaid: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EmiBreakupTableComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      instalmentData: Instalment[];
      isMasterBatch: boolean;
      shouldDisable: boolean;
      allEmisPaid: boolean;
    },
    private _partPaymentService: PartPaymentService,
    private _batchService: BatchService
  ) {
    this.isMasterBatch = this.data?.isMasterBatch;
    this.shouldDisable = this.data?.shouldDisable;
    this.allEmisPaid = this.data?.allEmisPaid;
    const dueInstalment = this.data?.instalmentData?.find((instalment) =>
      ['DUE', 'TODAY']?.includes(instalment?.status)
    );
    if (dueInstalment) {
      this.instalmentNumberToPay = dueInstalment.installmentNumber;
    }

    this._batchService._batchDetails$.subscribe((res) => {
      if (res) {
        this.batchDetails = new BatchDetailModel(res);
      }
    });
  }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }

  async handleEMIPaymentClick() {
    const dueInstalment = this.data?.instalmentData?.find((instalment) =>
      ['DUE', 'TODAY']?.includes(instalment?.status)
    );
    if (this.shouldDisable) {
      return;
    }
    if (!this._isLoading$.getValue()) {
      this._isLoading$.next(true);
      await this._partPaymentService.handleEMIPayOnClick({
        batchId: this.batchDetails?._id,
        surl: window.location.href,
        furl: window.location.href,
        installment_number: dueInstalment?.installmentNumber,
        due_date: dueInstalment?.dueDate,
        time_window: dueInstalment?.daysLeft,
        page: 'course_details_i_button',
      });
      this._isLoading$.next(false);
    }
  }
}
