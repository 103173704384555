import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodedHTML',
})
export class DecodedHTMLPipe implements PipeTransform {
  transform(textInput: string): any {
    let decodedText = textInput;
    try {
      decodedText = decodeURIComponent(textInput);
    } catch (e) {}
    return decodedText;
  }
}
