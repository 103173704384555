import { BehaviorSubject, catchError, map, retry } from 'rxjs';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions } from '../../api/api.type';
import { AppUrlService } from './../../app-url/app-url.service';
import { Injectable } from '@angular/core';
import { handleError } from '../error-handler/error-handler.service';
import { Coupon, CouponApplied, UserCouponResponse } from './coupon.type';
import { SelectedType } from 'src/app/pages/batch/new-order-summary/order-summary-components/add-more-wrapper/add-more-wrapper.component';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { APPLY_COUPON_CLICK } from 'src/app/core/analytics-events';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  private _selectedKhazana$: BehaviorSubject<{
    ids: string[];
    amount: number;
  } | null> = new BehaviorSubject<{ ids: string[]; amount: number } | null>(
    null
  );

  constructor(
    private appUrlService: AppUrlService,
    private apiService: PPApiService,
    private fireBaseService: FirebaseAnalyticsService,
    private globalService: GlobalService
  ) {}

  private _isCouponApplicable$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  get isCouponApplicable$() {
    return this._isCouponApplicable$;
  }

  private _coupon$: BehaviorSubject<Coupon | null> =
    new BehaviorSubject<Coupon | null>(null);

  get coupon$() {
    return this._coupon$;
  }

  private _couponResponse$: BehaviorSubject<CouponApplied | null> =
    new BehaviorSubject<CouponApplied | null>(null);

  get couponResponse$() {
    return this._couponResponse$;
  }

  private _couponSource$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );

  get couponSource$() {
    return this._couponSource$;
  }

  private _couponProcessing$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  get couponProcessing$() {
    return this._couponProcessing$;
  }

  get selectedKhazanaId$() {
    return this._selectedKhazana$;
  }

  setCouponSource(source: string) {
    this._couponSource$.next(source);
  }

  setCouponProcessing(data: boolean) {
    this._couponProcessing$.next(data);
  }

  setIsCouponApplicable(data: boolean) {
    this._isCouponApplicable$.next(data);
  }

  setCouponCode(code: Coupon | null) {
    this._coupon$.next(code);
  }

  setCouponResponse(res: CouponApplied | null) {
    this._couponResponse$.next(res);
  }

  setSelectedKhazana(data: { ids: string[]; amount: number } | null) {
    this._selectedKhazana$.next(data);
  }

  getUserCoupons(data: { cartItems: SelectedType[]; cartAmount: number }) {
    const url = this.appUrlService.GET_COUPON_LIST();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post<UserCouponResponse>(data, options).pipe(
      map((res) => res),
      retry(1),
      catchError(handleError)
    );
  }

  applyCouponGAEvent(
    isTrayButton: boolean,
    isRecemmendedCoupon: boolean,
    isBrandCoupon: boolean
  ) {
    const userInfo = this.globalService.getUser().getValue();
    const eventData = {
      userId: userInfo?.id,
      istray_button: isTrayButton,
      isrecommended_coupon: isRecemmendedCoupon,
      isbrand_coupon: isBrandCoupon,
    };

    this.fireBaseService.logEvents(APPLY_COUPON_CLICK, eventData, false, false);
  }

  reset() {
    this.setCouponCode(null);
    this.setCouponResponse(null);
    this.setCouponProcessing(false);
    this.setIsCouponApplicable(false);
    this.setCouponSource('');
  }
}
