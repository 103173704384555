import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CouponService } from 'src/app/common/services/coupon/coupon.service';
import {
  Coupon,
  UserCoupons,
} from 'src/app/common/services/coupon/coupon.type';
import { PackageList } from 'src/app/common/services/payment/payment.type';
import { CLOSE_IMG, CROSS_ICON } from 'src/app/core/assets.location';

@Component({
  selector: 'app-order-summary-slide-over',
  templateUrl: './order-summary-slide-over.component.html',
  styleUrls: ['./order-summary-slide-over.component.scss'],
})
export class OrderSummarySlideOverComponent implements OnInit {
  closeImg = CROSS_ICON;
  title = '';
  activeType = '';
  comboData: PackageList;
  titleLength: number;
  coupons: UserCoupons;
  couponSub: Subscription;
  cartAmount: number;
  couponSource: string;

  constructor(
    private couponService: CouponService,
    private dialog: MatDialogRef<OrderSummarySlideOverComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      activeType: string;
      comboData?: PackageList;
      coupons?: UserCoupons;
      cartAmount?: number;
      couponSource?: string;
    }
  ) {
    this.title = data?.title || '';
    this.titleLength = this.title.length;
    this.activeType = data?.activeType || '';
    if (data && data.comboData) {
      this.comboData = data.comboData;
    }

    if (data && data.coupons) {
      this.coupons = data.coupons;
    }

    if (data && data.cartAmount) {
      this.cartAmount = data.cartAmount;
    }

    if (data && data.couponSource) {
      this.couponSource = data.couponSource;
    }
  }

  subscriptions() {
    this.couponSub = this.couponService.coupon$.subscribe(
      (res: Coupon | null) => {
        if (res?._id.length && this.activeType === 'coupons') {
          this.dialog.close();
        }
      }
    );
  }

  ngOnInit(): void {
    this.subscriptions();
  }

  ngOnDestroy() {
    this.couponSub?.unsubscribe();
  }

  close(event: any) {
    if (typeof event === 'number') {
      this.titleLength = event;
      return;
    }

    if (typeof event === 'string' && event.toLowerCase() === 'close') {
      this.dialog.close();
      return;
    }
    if (typeof event === 'string' && event.toLowerCase() === 'close_payment') {
      this.dialog.close(event);
    }
  }
}
