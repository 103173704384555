import { Injectable } from '@angular/core';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiService } from '../../api/api.service';
import {
  AdmissionForm,
  BatchSchemeData,
  DiscountDetails,
  FeeInstallment,
  FeeInstallmentInfo,
  Program,
  ProgrammeDates,
} from './admission-journey.model';
import { PPApiOptions } from '../../api/api.type';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  mergeMap,
  retryWhen,
  throwError,
  timer,
} from 'rxjs';
import { handleError } from '../error-handler/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AdmissionJourneyService {
  private centerId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private cityId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private phase: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private schemeId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private preference: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private courseDuration: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  private date: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private discountDetail: BehaviorSubject<DiscountDetails> =
    new BehaviorSubject<DiscountDetails>(new DiscountDetails({}));
  // TODO will be deleted
  private discountIndex: BehaviorSubject<number> = new BehaviorSubject<number>(
    -1
  );

  feeData: any = {
    grandTotal: '',
    preselected_discount_amount: '',
    preselected_discount_type: '',
  };

  constructor(
    private appUrl: AppUrlService,
    private apiService: PPApiService
  ) {}

  getCENTERID() {
    return this.centerId;
  }

  getCITYID() {
    return this.cityId$;
  }

  getActiveprogramme() {
    return this.courseDuration;
  }

  getStartDate() {
    return this.date;
  }

  getDiscountIndex() {
    return this.discountIndex;
  }

  setDiscountIndex(index: number) {
    this.discountIndex.next(index);
  }

  // set centreID
  setCenterId(id: string) {
    this.centerId.next(id);
  }

  // get centreID
  getCenterId(): Observable<string> {
    return this.centerId.asObservable();
  }

  // set cityID
  setCityId(id: string) {
    this.cityId$.next(id);
  }

  // get cityID
  getCityId(): Observable<string> {
    return this.cityId$.asObservable();
  }

  // set discountDetail
  setDiscountDetail(name: string, amount: number) {
    this.discountDetail.next({ name, amount });
  }

  // get discountDetail
  getDiscountDetail() {
    return this.discountDetail.asObservable();
  }

  /* set phase */
  setPhase(phase: string) {
    this.phase.next(phase);
  }

  /* get phase */
  getPhase(): Observable<string> {
    return this.phase.asObservable();
  }

  /* set schemeId */
  setschemeId(schemeId: number) {
    this.schemeId.next(schemeId);
  }

  /* get phase */
  getschemeId(): Observable<number> {
    return this.schemeId.asObservable();
  }

  /* set preference */
  setpreference(preference: string) {
    this.preference.next(preference);
  }

  /* get preference */
  getpreference(): Observable<string> {
    return this.preference.asObservable();
  }

  /* set courseDuration  */
  setCourseDuration(courseDuration: string) {
    this.courseDuration.next(courseDuration);
  }

  /* get courseDuration */
  getCourseDuration(): Observable<string> {
    return this.courseDuration.asObservable();
  }

  /* set date  */
  setDate(date: string) {
    this.date.next(date);
  }

  /* get date */
  getDate(): Observable<string> {
    return this.date.asObservable();
  }

  callPhoneNumber() {
    window.location.href = 'tel:+9513350005';
  }

  removeCityCenter() {
    this.centerId.next('');
    this.cityId$.next('');
  }

  removeDate_Course_Discount() {
    this.phase.next('');
    this.preference.next('');
    this.discountDetail.next({
      name: '',
      amount: 0,
    });
    this.courseDuration.next('');
    this.date.next('');
    this.discountIndex.next(-1);
  }

  // remove data
  removeCentreDetails() {
    this.removeCityCenter();
    this.removeDate_Course_Discount();
  }

  getFeeStructureDetails() {
    return this.feeData;
  }

  setFeeStructureDetails(
    grandTotal: number,
    preselected_discount_amount: any,
    preselected_discount_type: string,
    registration_fee: any
  ) {
    this.feeData = {
      grandTotal,
      preselected_discount_amount,
      preselected_discount_type,
      registration_fee,
    };
  }

  getPathShalaDetails(batchSlug: string) {
    const url = this.appUrl.GET_PATHSHALA_DETAILS(batchSlug);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  postFetchDates(data: ProgrammeDates) {
    const url = this.appUrl.FETCH_DATES();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<ProgrammeDates>(data, options)
      .pipe(catchError(handleError));
  }

  postFeeInstallment(data: FeeInstallment) {
    const url = this.appUrl.FETCH_FEE_INSTALLMENT();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<FeeInstallment>(data, options)
      .pipe(catchError(handleError));
  }

  getScholarship(cohortName: string, cohortClass: string) {
    const url = this.appUrl.GET_SCHOLARSHIP(cohortName, cohortClass);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  postUserSchemeData(data: any) {
    const url = this.appUrl.USER_SCHEME_DATA();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.post<BatchSchemeData>(data, options).pipe(
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((error, index) => {
            const retryCount = index + 1;
            if (retryCount >= 30) {
              // give up after 30 retries
              return throwError(error);
            }
            return timer(2000); // delay for 2 seconds before retrying
          })
        )
      ),
      catchError(handleError)
    );
  }

  postPurchaseSchemeData(data: any) {
    const url = this.appUrl.USER_SCHEME_DATA();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<BatchSchemeData>(data, options)
      .pipe(catchError(handleError));
  }

  getAdmissionForm(orderId: string) {
    const url = this.appUrl.GET_ADMISSION_FORM(orderId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get(options).pipe(
      map((res: unknown) => res as AdmissionForm),
      catchError(handleError)
    );
  }

  getOrderReceipt(orderId: string) {
    const url = this.appUrl.GET_ORDER_RECIEPT(orderId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get(options).pipe(
      map((res: unknown) => res as AdmissionForm),
      catchError(handleError)
    );
  }

  /* new API enhancement */
  getProgramDates_ProgramDuration(data: Program) {
    const url = this.appUrl.GET_PROGRAMDATES_PROGRAMDURATION();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<Program>(data, options)
      .pipe(catchError(handleError));
  }

  postFeeDetails_FeeInstallment(data: FeeInstallmentInfo) {
    const url = this.appUrl.GET_FEEDETAILS_FEEINSTALLMENT();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService
      .post<FeeInstallmentInfo>(data, options)
      .pipe(catchError(handleError));
  }

  /* For isat landing page */
  getSatLandingPageDetail(examName: string) {
    const url = this.appUrl.SAT_ENTRY_POINT(examName);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }
}
