<main class="modal_container bg-gray-900 h-full">
  <div class="relative w-full">
    <button
      class="w-8 h-8 flex justify-center items-center hover_black absolute cursor-pointer top-4 right-4"
      (click)="close('api')"
    >
      <mat-icon class="text-base bold">close</mat-icon>
    </button>

    <img
      [src]="upgradeData.upgradeBanner.imageUrl"
      alt="banner"
      class="w-full banner"
    />
    <div class="w-full flex justify-center absolute bottom-3 sm:bottom-6">
      <button
        class="text-yellow-400 caption-1 w-fit underline explore_btn"
        (click)="expoleBatchPlusTable()"
      >
        {{ upgradeData.upgradeBanner.title }}
      </button>
    </div>
  </div>

  <section
    class="text-white flex justify-between items-center px-4 pb-4 relative"
  >
    <div class="flex flex-col gap-1.5">
      <div class="flex gap-2">
        <span class="price heading-4 bold"
          >₹ {{ upgradeData.total | getFloorValue }}</span
        >
        <del class="body-1" *ngIf="upgradeData.discount > 0">{{
          upgradeData.price | getFloorValue
        }}</del>
      </div>
      <div
        class="discount_chip px-4 py-0.5 rounded-md"
        *ngIf="upgradeData.discount > 0"
      >
        <span class="caption-1 bold"
          >{{ upgradeData.discount | getFloorValue }}% OFF</span
        >
      </div>
    </div>
    <button
      class="py-3 buy_now rounded-md body-1 semibold"
      (click)="goToOrderSummary()"
    >
      Buy Now
    </button>
  </section>
</main>
