<div class="flex flex-col gap-4">
  <div class="flex gap-4">
    <img [src]="battleSwordImg" />
    <div class="flex flex-col">
      <p class="bg-title">{{ dppData?.title }}</p>
      <div class="battle-status-container">
        <div class="subject-chip">{{ dppData?.subjectName }}</div>
        <div class="divider">|</div>
        <img [src]="questionsImg" />
        <div class="dpp-questions-count">
          {{ dppData?.questionCount }} Questions
        </div>
      </div>
    </div>
  </div>

  <div class="battle-status-container">
    <div class="dpp-status" [ngClass]="[dppStatus?.class]">
      {{ dppStatus?.text }}
    </div>
    <!-- <div class="divider">|</div>
    <ng-lottie [options]="peopleJoinedLottie" height="22px"></ng-lottie>
    <div class="players-joined">
      <span class="players-joined-count">2.5k</span> players have joined
    </div> -->
  </div>

  <div class="flex gap-3 items-center">
    <img [src]="calendarImg" />
    <div class="dpp-time">{{ dppStartDate }}</div>
    <img [src]="dotImg" />
    <div class="dpp-time">{{ dppStartTime }}</div>
  </div>
</div>
