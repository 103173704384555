<div class="p-4">
  <div class="flex flex-col justify-end">
    <div class="flex justify-end mb-1">
      <!-- <button [mat-dialog-close]="true" class="close-button">
        <img src="/study/assets/batches-new/dialog_close_icon.svg" />
      </button>-->
    </div>
    <div class="text-center py-4">
      <h2>Do you Want to Logout?</h2>
    </div>
    <div class="flex w-full">
      <div class="w-1/2 mx-2 cursor-pointer">
        <button
          (click)="onSubmit('yes')"
          class="w-full confirm-submit-btn"
          mat-button
        >
          Yes
        </button>
      </div>
      <div class="w-1/2 mx-2 cursor-pointer">
        <button
          [mat-dialog-close]="true"
          class="w-full confirm-submit-btn"
          mat-button
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
