import { InterceptorService } from 'src/app/interceptor.service';
import {
  APP_INITIALIZER,
  ErrorHandler,
  isDevMode,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './core/core.module';

import { MaterialModule } from './material/material.module';

import { PPbackButtonDirective } from './common/directives/PPbackButton/ppback-button.directive';

import { TimeagoModule } from 'ngx-timeago';

import { AuthService } from './common/services/auth/auth.service';
import { StorageService } from './common/services/storage/storage.service';
import { GlobalService } from './common/services/global/global.service';
import { PPCommonModule } from './common/common.module';
import { environment } from '../environments/environment';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getRemoteConfig, RemoteConfig } from 'firebase/remote-config';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { UpdateAcademicInfoComponent } from './common/components/update-academic-info/update-academic-info.component';
import { DoubtsNotificationComponent } from './common/components/doubts-notification/doubts-notification.component';
import { FirebaseMessagingService } from './common/services/firebase-messaging/firebase-messaging.service';
import { AngularFireModule } from '@angular/fire/compat';
import { ConfirmLogoutDialogComponent } from './common/components/confirm-logout-dialog/confirm-logout-dialog.component';
import { ShowNotificationComponent } from './common/components/show-notification/show-notification.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { HelpModalComponent } from './pages/auth/auth-components/help-modal/help-modal.component';
import {
  AngularFireRemoteConfigModule,
  DEFAULTS,
  SETTINGS,
} from '@angular/fire/compat/remote-config';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PipesModule } from './common/pipes/pipes.module';
import * as Sentry from '@sentry/angular-ivy';
import { MqttModule } from 'ngx-mqtt';
import { MQTT_OPTIONS } from './common/services/mqtt-service/mqtt.service';
import { CommonModule } from '@angular/common';
import { PwCentresWebinarModule } from './pages/pw-centres-webinar/pw-centres-webinar.module';
import { PwCentresMentorshipModule } from './pages/pw-centres-mentorship/pw-centres-mentorship.module';
import { Router } from '@angular/router';
import { PartPaymentService } from './common/services/part-payment/part-payment.service';

export let firebaseApp: FirebaseApp;
export let remoteConfig: RemoteConfig;

firebaseApp = initializeApp(environment.firebase);
remoteConfig = getRemoteConfig(firebaseApp);

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    PPbackButtonDirective,
    UpdateAcademicInfoComponent,
    DoubtsNotificationComponent,
    ShowNotificationComponent,
    ConfirmLogoutDialogComponent,
    HelpModalComponent,
  ],
  imports: [
    ClipboardModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireRemoteConfigModule,
    MaterialModule,
    FormsModule,
    CoreModule,
    PipesModule,

    TimeagoModule.forRoot(),
    PPCommonModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register(environment.ngswWorker, {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    LottieModule.forRoot({ player: playerFactory }),
    MqttModule.forRoot(MQTT_OPTIONS),
    PwCentresWebinarModule,
    PwCentresMentorshipModule,
  ],
  providers: [
    FirebaseMessagingService,
    AuthService,
    StorageService,
    GlobalService,
    PartPaymentService,
    { provide: 'app_id', useValue: 'kophysicswallah-web-f5ueju7' },
    { provide: 'verbose', useValue: false },
    { provide: 'use_cookie', useValue: true },
    { provide: 'custom_traits', useValue: {} },
    { provide: 'disable_auto_install', useValue: false },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'en-EN' },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    { provide: DEFAULTS, useValue: { show_juspay: true } },
    {
      provide: SETTINGS,
      useFactory: () =>
        isDevMode()
          ? { minimumFetchIntervalMillis: 3_600 }
          : { minimumFetchIntervalMillis: 3_600 },
    },

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],

  bootstrap: [AppComponent],
  exports: [PPbackButtonDirective],
})
export class AppModule {}
