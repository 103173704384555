<div class="h-full overflow-scroll" style="overflow: scroll !important">
  <h4 class="heading-5 semibold attachement-header mb-2">
    {{ query?.name || 'Test Details' }}
  </h4>

  <div class="flex flex-wrap mx-1">
    <div
      class="my-1 px-1 w-full"
      *ngIf="testInstruction && testInstruction.testInstructions"
    >
      <!-- Column Content -->
      <h5 class="body-2 semibold attachement-title mb-2">Syllabus</h5>
      <div
        *ngIf="testInstruction && testInstruction.generalInstructions"
        [innerHTML]="testInstruction.generalInstructions | safe : 'html'"
      ></div>
    </div>

    <div class="my-1 px-1 w-full overflow-hidden">
      <!-- Column Content -->
      <h5 class="body-2 semibold attachement-title mb-2">Instructions</h5>
      <div class="flex flex-wrap -mx-1 overflow-hidden">
        <div class="my-1 px-1 w-1/3 overflow-hidden">
          <!-- Column Content -->
          <h3>Time</h3>
          <h3 class="bold">{{ query?.duration }} mins</h3>
        </div>

        <div class="my-1 px-1 w-1/3 overflow-hidden">
          <!-- Column Content -->
          <h3>Questions</h3>
          <h3 class="bold">{{ query?.totalQuestions }}</h3>
        </div>

        <div class="my-1 px-1 w-1/3 overflow-hidden">
          <!-- Column Content -->
          <h3>Marks</h3>
          <h3 class="bold">{{ query?.totalMarks }}</h3>
        </div>
      </div>
      <div
        *ngIf="testInstruction.testInstructions"
        [innerHTML]="testInstruction.testInstructions | safe : 'html'"
      ></div>
    </div>
  </div>
</div>
